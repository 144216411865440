/** The references object of all modules needed for components initialization */
var references = {
    'CustomerMigration'         : require('./components/customer/CustomerMigration'),
    'fullPage'                  : require('_core_ext/components/global/fullpage'),
    'QuickViewGlobal'           : require('_core_ext/components/global/QuickViewGlobal'),
    'availability'              : require('_core_ext/components/product/availability'),
    'ProductInfo'               : require('_core_ext/components/product/ProductInfo'),
    'Magazine'                  : require('./components/global/magazine'),
    'BarbaMagazine'             : require('./components/global/barba_magazine'),
    'slick'                     : require('./components/global/slick')
};

/** The components initialization configuration object */
var configuration = {
    'global': {
        'components': {
            'fullPage' : {},
            'slick' : {
                'enabled' : ('slick' in $.fn)
            }
        }
    },
    'account': {
        'components': {
            'CustomerMigration' : {}
        }
    },
    'checkout': {
        'components': {
            'CustomerMigration': {}
        }
    },
    'product' : {
        'components' : {
            'ProductInfo': {}
        }
    },
    'wishlist': {
        'components': {
            'CustomerMigration': {}
        }
    },
    'orderconfirmation' : {
        'components' : {
        }
    },
    'magazine' : {
        'components' : {
            'instagram' : {
                'enabled' : SitePreferences.INSTAGRAM_ENABLED
            },
            'isotope' : {},
            'marker' : {},
            'QuickViewGlobal': {},
            'availability' : {},
            'Magazine' : {},
            'BarbaMagazine' : {}
        }
    }
};

module.exports = {
    'configuration': configuration,
    'references': references
};
