import Slick from '_core_ext/components/global/slick';
import layout from '_core_ext/components/global/layout';

const SLICK_ACTIVE_CLASS = 'slick-active';
const ARIA_ATTR = 'aria-hidden';

export default class SlickExt extends Slick {
    /**
     * @description Current workaround is required to let slider dots properly change its active status, as dots are placed in the middle of every slide (.slider__pagination)
     *
     * @memberof SlickExt
     */
    initExt() {
        if (!this.$masonrySlick.length || this.$masonrySlick.data('slickExtLoaded')) {
            return;
        }

        const $masonrySlick = this.$masonrySlick;
        $masonrySlick.data('slickExtLoaded', true);

        /**
         * Since we have dots placed on every slide, native slick dots transition fails, so we need to handle this manually,
         * saving dots pointer to variable and nulling => do transition > return dots into slick object
         */

        $masonrySlick.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            const $slideDots = slick.$slides.find('.slider__items');

            $slideDots.find('li').removeClass(SLICK_ACTIVE_CLASS).attr(ARIA_ATTR, true);
            $slideDots.each(function() {
                $(this).find('li').eq(nextSlide).addClass(SLICK_ACTIVE_CLASS).attr(ARIA_ATTR, false);
            });

            $masonrySlick[0].slick.$dots = null;
        }).on('afterChange', function(event, slick) {
            $masonrySlick[0].slick.$dots = slick.$dots;
        });
    }

    initTransformationTemplate() {
        const mobileTransformationParam = this.$masonrySlick.data('mobile-transformation-param');
        const desktopTransformationParam = this.$masonrySlick.data('desktop-transformation-param');

        if (this.$masonrySlidePictures && this.$masonrySlidePictures.length && layout.isMobile()) {
            for (let i = 0; i < this.$masonrySlidePictures.length; i++) {
                let pictureHTML = this.$masonrySlidePictures[i].innerHTML;
                pictureHTML = pictureHTML.replace(new RegExp(desktopTransformationParam, 'g'), mobileTransformationParam);
                this.$masonrySlidePictures[i].innerHTML = pictureHTML;
            }
        }
    }

    initCache() {
        this.$masonrySlick = $('.js-masonry-slick');
        this.$masonrySlidePictures = this.$masonrySlick.find('picture');
    }
    /**
     * Method called when creating component
     *
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args)
        this.initCache();
        this.initExt();
        this.initTransformationTemplate();
    }
}

module.exports = SlickExt;
