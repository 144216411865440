import eventMgr from '_core_ext/eventMgr';

/**
 * Made a singleton, as it must persist it's state between variation changes - when target element (VTO iframe) removed
 */
var virtualTryOn = {
    /**
     * VTO button
     */
    $el: null,
    
    /**
     * VTO element, if not null - means iframe was created. Includes iframe and close button.
     */
    $vtoFrame: null,
    
    /**
     * VTO WebSocket reference
     */
    ws: null,
    
    /**
     * Initialization, must be called once upon page load.
     */
    init: function() {
        var $el = $('.js-virtual-try-on');
        if (!$el.length) {
            // do nothing if there is no VTO button on page
            return;
        }
        
        this.reInit();

        eventMgr.on('variant.updated', this.reInit.bind(this));
    },
    
    /**
     * This called upon variation change
     */
    reInit: function() {
        this.$el = $('.js-virtual-try-on');
        this.$el.on('click', this.openVTO.bind(this));
        
        // if VTO was opened - update it with opened variation
        if (this.$vtoFrame) {
            this.sendMessage();
        }
    },
    
    openVTO: function() {
        var thumbnailsPresent = $('#thumbnails').length > 0;
        this.$vtoFrame = $(`
            <div class="try-on-wrapper ${thumbnailsPresent ? 'try-on-thumbnails' : ''}">
                <iframe id="memomi" class="memomi-iframe" allow="camera *;"></iframe>
                <div class="close-btn js-close"></div>
            </div>
        `);
        this.$vtoFrame.prependTo(this.$el.attr('data-attach-to'));   // must be attached to some node which is not removed upon variation change!
        this.$vtoFrame.find('.js-close').on('click', this.closeVTO.bind(this));
        
        $('body').addClass('vto-opened');
        
        this.ws = new WebSocket(this.$el.attr('data-ws-url'));
        this.ws.onmessage = this.onMessage.bind(this);
    },
    
    closeVTO: function() {
        this.$vtoFrame.remove();
        this.$vtoFrame = null;
        $('body').removeClass('vto-opened');

        this.ws.close();
        this.ws = null;
    },
    
    sendMessage: function() {
        var msg = {
            brandId: this.$el.attr('data-brand-id'),
            productId: this.$el.attr('data-pid')
        };
        this.ws.send(JSON.stringify(msg));
    },
    
    onMessage: function(e) {
        if (!this.$vtoFrame) {
            return;
        }
        
        var data = JSON.parse(e.data);
        
        if (data.Token) {
            // token given once after socket initialization 
            this.$vtoFrame.find('#memomi').attr('src', String.format(this.$el.attr('data-iframe-url'), data.Token));

        } else if (data === 'cameraConnected') {
            // sent product id takes effect only after 'cameraConnected' event received
            this.sendMessage();
        }
    }
};

module.exports = virtualTryOn;
