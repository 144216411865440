'use strict';

var nameSpace = SitePreferences.QUBIT_NAMESPACE,
    sendProductListingEvents = SitePreferences.SEND_PRODUCT_LISTING_EVENTS,
    productListingEventLimit = SitePreferences.PRODUCT_LISTING_EVENTS_LIMIT,
    alwaysSendBasketEvents = SitePreferences.ALWAYS_SEND_BASKET_EVENTS,
    qubitPackage = SitePreferences.QUBIT_PACKAGE,
    emitEvents = SitePreferences.EMIT_EVENTS,
    qubitEnable = SitePreferences.QUBIT_ENABLE,
    count = 0;

/**
 * @variable
 * @description this variable contain call back function for fetching ecEvent according to page type
 */
var pageContextMap = {
    'product' : function() {
        ecProductInitializationItems();
    },
    'search' : function() {
        if (sendProductListingEvents) {
            ecProductInitializationItems();
        }
    }
};

/**
 * @variable
 * @description this variable contain namespace for ecEvents
 */
var isNameSpaceAvailable = nameSpace != null;
var nameSpaceMap = {
    'ecView' : isNameSpaceAvailable ? nameSpace + '.ecView' : 'ecView',
    'ecUser' : isNameSpaceAvailable ? nameSpace + '.ecUser' : 'ecUser',
    'ecProduct' : isNameSpaceAvailable ? nameSpace + '.ecProduct' : 'ecProduct',
    'ecBasketItem' : isNameSpaceAvailable ? nameSpace + '.ecBasketItem' : 'ecBasketItem',
    'ecBasketSummary' : isNameSpaceAvailable ? nameSpace + '.ecBasketSummary' : 'ecBasketSummary',
    'ecBasketItemAction' : isNameSpaceAvailable ? nameSpace + '.ecBasketItemAction' : 'ecBasketItemAction',
    'ecBasketItemTransaction' : isNameSpaceAvailable ? nameSpace + '.ecBasketItemTransaction' : 'ecBasketItemTransaction',
    'ecBasketTransactionSummary' : isNameSpaceAvailable ? nameSpace + '.ecBasketTransactionSummary' : 'ecBasketTransactionSummary',
}

/**
 * @private
 * @function
 * @description this function will initialize all the ecEvents for pages.
 */
function initializeEvents() {
    loadEcEventsForAllPages();

    var allowedPages = ['product', 'search'];
    if (allowedPages.indexOf(pageContext.ns) !== -1) {
        pageContextMap[pageContext.ns]();
    }

    $( "body" ).on( "updateProductVariation", function(event, container) {
        updateProductVariation(container);
    });

    $( "body" ).on( "updateProductListing", function(event, listingType) {
        if (listingType == 'pagination') {
            count = 0;
        }
        ecProductInitializationItems(listingType);
    });

    $( "body" ).on( "productQuickView", function() {
        var $lineItems = $("#QuickViewDialog [data-qubit-product-data]");
        if ($lineItems.length > 0) {
            $lineItems.each(function(index) {
                let qubitProductData = $(this).data('qubitProductData');
                if (!jQuery.isEmptyObject(qubitProductData)) {
                    initializeEcEvents('ecProduct', qubitProductData);
                }
            });
        }
    });

    $( "body" ).on( "ecBasketItemAction", function(event, data, action, callback) {
        if (action == 'add') {
            data = data.serialize()+'&action='+action;
        }
        ecBasketItemAction(data, action, function() {
            if (typeof callback === 'function') {
                callback();
            }
        });
    });
}

function updateProductVariation(container) {
    var $qubitProductData = container.find("[data-qubit-product-data]").data('qubitProductData');
    if (!jQuery.isEmptyObject($qubitProductData)) {
        initializeEcEvents('ecProduct', $qubitProductData);
    }
}

/**
 * @private
 * @function
 * @description this function will initialize ecProduct events for listing page.
 */
function ecProductInitializationItems() {

    var emitEvents = true,
        limitListingEvent = (pageContext.ns == 'search' && productListingEventLimit != null && productListingEventLimit > 0);

    if (emitEvents) {
        if (pageContext.ns == 'product'){
            var $lineItems = $("[data-qubit-product-data]");
        } else {
            var $lineItems = $(".search-result-content #search-result-items:last [data-qubit-product-data]");
        }

        $lineItems.each(function(index) {
            if (limitListingEvent && count >= productListingEventLimit) {
                emitEvents = false;
            }
            if (emitEvents) {
                let qubitProductData = $(this).data('qubitProductData');
                if (!jQuery.isEmptyObject(qubitProductData)) {
                    initializeEcEvents('ecProduct', qubitProductData);
                    count++;
                }
            }
        });
    }

}

/**
 * @private
 * @function
 * @description contain basket events
 */
function basketEvents() {

    var initBasketEvents = true;
    if (!alwaysSendBasketEvents) {
        var checkoutPages = ['cart', 'checkout', 'orderconfirmation'],
            isOnlyCheckoutFunnel = (checkoutPages.indexOf(pageContext.ns) !== -1);

        initBasketEvents = isOnlyCheckoutFunnel ? true : false;
    }

    if (initBasketEvents) {

        var qubitBasketSummeryData = $("[data-qubit-basket-summery-data]").data('qubitBasketSummeryData');
        if (!jQuery.isEmptyObject(qubitBasketSummeryData)) {
            var $tempArraySummary = jQuery.extend(true, {}, qubitBasketSummeryData);
            delete $tempArraySummary.basket['subtotal'];
            initializeEcEvents('ecBasketSummary', $tempArraySummary);
        }

        if (qubitPackage == 'pro') {
            var $lineItems = $("[data-qubit-basket-product-data]");
            if ($lineItems != undefined && $lineItems != null && $lineItems.length > 0) {
                $lineItems.each(function(index) {
                    let qubitBasketProductData = $(this).data('qubitBasketProductData');
                    if (!jQuery.isEmptyObject(qubitBasketProductData)) {
                        var $tempArraySummary = jQuery.extend(true, {}, qubitBasketSummeryData);
                        delete $tempArraySummary.basket['subtotal'];
                        qubitBasketProductData.basket = $tempArraySummary.basket;
                        if (!jQuery.isEmptyObject(qubitBasketProductData)) {
                            initializeEcEvents('ecBasketItem', qubitBasketProductData);
                        }
                    }
                });
            }
        }

        if (pageContext.ns == 'orderconfirmation') {

            var $orderLineItems = $("[data-qubit-basket-item-transaction-data]"),
                $transactionId = $("[data-qubit-transaction-id]").data('qubitTransactionId'),
                $transactionSummary = $("[data-qubit-basket-transaction-summary-data]").data('qubitBasketTransactionSummaryData');

            if ($orderLineItems != undefined && $orderLineItems != null && $orderLineItems.length > 0) {
                $orderLineItems.each(function(index) {
                    let qubitBasketItemTransactionData = $(this).data('qubitBasketItemTransactionData');
                    if (!jQuery.isEmptyObject(qubitBasketItemTransactionData)) {
                        if (!jQuery.isEmptyObject($transactionId) && !jQuery.isEmptyObject(qubitBasketItemTransactionData)) {
                            qubitBasketItemTransactionData.transaction = $transactionId;
                        }
                        if (!jQuery.isEmptyObject($transactionSummary) && 'basket' in $transactionSummary) {
                            var $tempArrayItemTransactionSummary = jQuery.extend(true, {}, $transactionSummary);
                            if (qubitPackage == 'pro') {
                                delete $tempArrayItemTransactionSummary.basket['subtotal'];
                            } else {
                                delete $tempArrayItemTransactionSummary.basket['subtotalIncludingTax'];
                            }
                            qubitBasketItemTransactionData.basket = $tempArrayItemTransactionSummary.basket;
                        }
                        if (!jQuery.isEmptyObject(qubitBasketItemTransactionData)) {
                            initializeEcEvents('ecBasketItemTransaction', qubitBasketItemTransactionData);
                        }
                    }
                });
            }


            var $ecBasketTransactionSummary = {};
            if (!jQuery.isEmptyObject($transactionId) && !jQuery.isEmptyObject($transactionSummary)) {
                $ecBasketTransactionSummary.transaction = $transactionId;
            }

            if (!jQuery.isEmptyObject($transactionSummary) && 'basket' in $transactionSummary) {
                var $tempArrayTransactionSummary = jQuery.extend(true, {}, $transactionSummary);
                delete $tempArrayTransactionSummary.basket['subtotal'];
                $ecBasketTransactionSummary.basket = $tempArrayTransactionSummary.basket;
            }
            if (!jQuery.isEmptyObject($ecBasketTransactionSummary)) {
                initializeEcEvents('ecBasketTransactionSummary', $ecBasketTransactionSummary);
            }
        }
    }

}

/**
 * @private
 * @function
 * @description this function will initialize all the common ecEvents which will used in every page.
 */
function loadEcEventsForAllPages() {

    var qubitPageTypeMap = {
        "storefront" : "home",
        "product" : "product",
        "search" : "category",
        "cart" : "basket",
        "checkout" : "checkout",
        "orderconfirmation" : "confirmation"
    },
    pageType = qubitPageTypeMap[pageContext.ns],
    $viewData = $("[data-qubit-view-data]").data('qubitViewData');

    pageType != null ? $viewData.type = pageType : $viewData.type = 'content';

    if (!jQuery.isEmptyObject($viewData)) {
        initializeEcEvents('ecView', $viewData);
    }

    if (qubitPackage == 'pro') {
        var $userData = $("[data-qubit-user-data]");
        if ($userData.length > 0) {
            var qubitUserData = $userData.data('qubitUserData');
            if (!jQuery.isEmptyObject(qubitUserData)) {
                initializeEcEvents('ecUser', qubitUserData);
            }
        }
    }

    basketEvents();
}

/**
 * @private
 * @function
 * @description fire ecBasketItemAction ecEvents
 */
function ecBasketItemAction(data, action, callback) {
    $.ajax({
        type: 'GET',
        url: Urls.ecBasketItemAction,
        data: data,
        success: function(response) {
            if (response.status && !jQuery.isEmptyObject(response.object)) {
                var qubitBasketSummeryData = $("[data-qubit-basket-summery-data]").data('qubitBasketSummeryData'),
                    qubitBasketItemActionProductData = {};

                if (qubitPackage == 'pro') {
                    qubitBasketItemActionProductData = response.object;
                }

                qubitBasketItemActionProductData.basket = qubitBasketSummeryData.basket;
                if (!jQuery.isEmptyObject(qubitBasketItemActionProductData)) {
                    initializeEcEvents('ecBasketItemAction', qubitBasketItemActionProductData);
                }

                callback();
            }
        }
    })
}

/**
 * @private
 * @function
 * @description fire ecEvents
 */
function initializeEcEvents(eventType, data) {
    if (!jQuery.isEmptyObject(data)) {
        uv.emit(nameSpaceMap[eventType], data);
    }
}

exports.init = function () {
    if (qubitEnable && emitEvents) {
        initializeEvents();
    }
};
