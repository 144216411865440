var ajax = require('_core_ext/ajax');

/**
 * @private
 * @function
 * @description Events for contact-us form
 */
function initializeEvents() {
    var $contactusFormWrapper = $('.js-contactus-form-wrapper');

    $contactusFormWrapper.on('submit', 'form', function (event){
        event.preventDefault();

        var $form = $(this);
        var data = $form.serializeObject();
        var $csrfTokenInput = $contactusFormWrapper.find('#contact_us_csrf_token');
        var contactusData = {
            'formData': JSON.stringify(data)
        };

        if ($csrfTokenInput.length) {
            contactusData[$csrfTokenInput.attr('name')] = $csrfTokenInput.attr('value');
        }

        if (!('valid' in $form) || $form.valid()) {
            ajax.getJson({
                url: Urls.contactUsConfirmation,
                method: 'POST',
                data: contactusData,
                callback: function (response) {
                    if (!response) {
                        return false;
                    }

                    $contactusFormWrapper.replaceWith(response.message);

                    if (SitePreferences.CONTACTUS_CONFIRMATION_MESSAGE_CLASS) {
                        var $confirmationMessage = $('.' + SitePreferences.CONTACTUS_CONFIRMATION_MESSAGE_CLASS);
                        if ($confirmationMessage.length) {
                            var $stickyHeader = $('.is-sticky > .js-header');
                            var topOffset = $confirmationMessage.offset().top - $stickyHeader.height();
                            $('html, body').animate({
                                scrollTop: topOffset
                            }, 300);
                        }
                    }
                }
            });
        }
    });

    initializeCaptcha($contactusFormWrapper);
}

function initializeCaptcha($wrapper) {
    if (SitePreferences.CONTACTUS_CAPTCHA_ENABLED) {
        // Explicit rendering reCAPTCHA
        window.grecaptcha.ready(() => {
            window.grecaptcha.render('g-recaptcha', {
                'sitekey' : SitePreferences.CONTACTUS_CAPTCHA_SITE_KEY
            });
        });

        // Show reCAPTCHA inside asset
        $('.js-recaptcha2').show();

        // Bind reCAPTCHA validation
        $wrapper.click('form button[type=submit]', () => {
            const reCaptchaResponse = window.grecaptcha.getResponse();
            const reCaptchaIsNotValid = reCaptchaResponse.length === 0;
            $('#g-recaptcha + input').prop('required', reCaptchaIsNotValid);
        });
    }
}

/**
 * @function
 */

var contactus = {
    init: function () {
        initializeEvents();
    }
};

module.exports = contactus;
