var progress = require('_core_ext/progress');


"use strict";

if((window.location.pathname.includes('payment') || window.location.pathname.includes('Adyen-ShowConfirmation')) && window.isAdyenPayment) {
var checkout;
  async function handleAction(action) {
    window.Configuration.onAdditionalDetails = onAdditionalDetails;
     checkout = await AdyenCheckout(window.Configuration);
    const actionContainer = document.getElementById('action-container');
    checkout.createFromAction(action).mount(actionContainer);
  }

  // onAdditionalDetails event handler to be included in Adyen Component configuration
  var onAdditionalDetails = function (state) {
    $.ajax({
      type: 'POST',
      url: window.paymentsDetails,
      data: JSON.stringify({
        data: state.data,
        orderToken: window.orderToken,
      }),
      contentType: 'application/json; charset=utf-8',
      async: false,
      success: function (data) {
        if (!data.response.isFinal && typeof data.response.action === 'object') {
          handleAction(data.action);
        } else {
          window.location.href = data.response.redirectUrl;
        }
      },
    });
  }

  // serializes form data and submits to place order. Then proceeds to handle the result
   function placeOrder(formId) {
    const form = $('#' + formId);
    $.ajax({
      method:'POST',
      url: window.summarySubmitUrl,
      data: $(form).serialize(),
      async: false,
      success: function(data) {
        if (data.action) {
          window.orderToken = data.orderToken;
          document.getElementById('action-modal-SG').style.display = "block";
          handleAction(data.action);
        } else {
          window.location.href = data.continueUrl;
        }
      },
      error: function(err) {
      }
    });
  }

  

  module.exports.placeOrder = placeOrder;
}





/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
exports.update = function () {
    if (!SitePreferences.CHECKOUT_SUMMARY_UPDATES_ENABLE) {
        return;
    }

    var $summary = $('#secondary .js-summary');
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL);
};



