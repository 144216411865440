var ajax = require('_core_ext/ajax'),
    util = require('_core_ext/util'),
    eventMgr = require('_core_ext/eventMgr'),
    validator = require('_core_ext/components/global/validator'),
    dialog = require('_core_ext/dialog'),
    summary = require('_core_ext/pages/checkout/summary');

/**
 * This module serves as Mediator for Single shipping page.
 * It wires interaction between ShippingAddress component and Shipping method switcher (also updates minisummary if Shipping method selector present)
 * and controls state of the "Proceed" CTA and other additional elements depending on the state of the above.
 * BillingAddress and Shipping method switcher are optional to present.
 * It must not access directly anything that is inside of shipping/billing address components, but use only component's public methods and events.
 */

var shippingAddress,
    $form,
    $shippingMethodList,
    $cncStoresList,
    $useShippingAddressNames,
    $cncFirstName,
    $cncLastName,
    shippingEmitter = eventMgr.getEmitter('shipping');

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    const params = shippingAddress || {};
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID, time, date) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }

    // hide cnc stores list
    $cncStoresList.addClass('hidden');

    // attempt to set shipping method
    var params = {
        shippingMethodID: shippingMethodID,
        time: time || '',
        date: date || ''
    }

    var url = getShippingMethodURL(Urls.selectShippingMethodsList, params);
    ajax.getJson({
        url: url,
        callback: function (data) {
            summary.update();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');  //NOSONAR
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

            showShippingMethodSlots(data);

            //Show cnc stores list
            if (shippingMethodID === $shippingMethodList.data('cncId')) {
                $cncStoresList.removeClass('hidden');
            }
        }
    });
}

/**
 * Case 1: If "Use <firstName> <lastName>?" checkbox is checked, fill the name inputs with the names from shippingAddress
 * then trigger "change" and "blur" events on them in order to validate and make them active ( currently their type is RedesignInput )
 * Case 2: If firstName or lastName is being changed, then uncheck "Use <firstName> <lastName>?" checkbox, because the names will become different
 */
function initCncAddressNamesEvents() {
    $cncFirstName.prop('required', true);
    $cncLastName.prop('required', true);

    $cncFirstName.on('keydown', () => {
        if (!$useShippingAddressNames.is(':checked')) {
            return;
        }

        $useShippingAddressNames.prop('checked', false);
    });

    $cncLastName.on('keydown', () => {
        if (!$useShippingAddressNames.is(':checked')) {
            return;
        }

        $useShippingAddressNames.prop('checked', false);
    });


    $useShippingAddressNames.on('change', function () {
        const shippingAddressNames = $(this).data('shippingAddressNames');

        if (!$(this).is(':checked') || !shippingAddressNames) {
            return;
        }

        $cncFirstName
            .val(shippingAddressNames.firstName)
            .trigger('change')
            .trigger('blur');

        $cncLastName
            .val(shippingAddressNames.lastName)
            .trigger('change')
            .trigger('blur');
    });
}

function showShippingMethodSlots(data) {
    if (!data.selectedSlot) { // if selected shpping method without slots or slot is not selected yet - remove selection classes
        $('.checkout_dateslot_selected').removeClass('checkout_dateslot_selected');
        $('.checkout_timeslot_selected').removeClass('checkout_timeslot_selected');
        $('.carousel_dateslot_selected').removeClass('carousel_dateslot_selected');
        $('.js-timeslot-selector, .js-selected_banner').addClass('hidden');
        $shippingMethodList.find('select').val(''); // clear selections
        shippingEmitter.emit('shippingMethodSelected', data.shippingMethodID)
    }
    var $shippingMethodSlots = $shippingMethodList.find('.js-timeslot-selector[data-method-id="' + data.shippingMethodID + '"]');
    if ($shippingMethodSlots.length) {
        $shippingMethodSlots.removeClass('hidden');
        app.components.initComponent('TimeslotSelector', $shippingMethodList);
    }
}

function handleShippingMethodsPage() {
    // extend default validator settings to keep right error placing
    var defaultInvalidHandler = validator.settings.invalidHandler;
    $.extend(validator.settings, {
        invalidHandler: (e, validator) => {
            defaultInvalidHandler(e, validator);

            //check if error present
            if (!validator.numberOfInvalids()) {
                return;
            }
            //check if at least one element related to slot selection
            var slotSelectionError = validator.errorList.some((error) => error.element.name.indexOf('slot') > -1);
            if (slotSelectionError) {
                displaySlotSelectedBanner({error: true});
                return;
            }
        },
        submitHandler: (form, e) => {
            // Is form already submitted
            if ($form.data('isSubmiting') === true) {
                e.preventDefault();
                return false;
            }
            validateShippingSlots(e, $form);
            if (!e.isDefaultPrevented()) {
                $form.find('button[type=submit]').addClass('inactive');
                $form.data('isSubmiting', true);
                form.submit();
            }
        }
    });

    $form.validate(validator.settings);
}

function validateShippingSlots(e, $form) {
    if (!checkShippingSlots($form)) {
        e.preventDefault();
        displaySlotSelectedBanner({error: true});
    }
}

function checkShippingSlots($form) {
    // find selected shipping method
    const $selectedShippingMethod = $form.find('.input-radio:checked');
    var $slotSelectors = $selectedShippingMethod.closest('.form-row').find('select');
    var allSelectsNotEmpty = true;
    if ($slotSelectors.length) {
        $slotSelectors.each(function () {
            if ($(this).val() === '') {
                allSelectsNotEmpty = false;
            }
        })
    }
    return allSelectsNotEmpty;
}

function displaySlotSelectedBanner(settings) {
    //hide all as default
    var $banner = $('.js-selected_banner').toggleClass('hidden', !settings.error);
    $banner.find('.js-selected_banner-error').toggleClass('hidden', !settings.error);
}

/**
 * @private
 * @function
 * @description Binds events to the shipping methods page
 */
function initializeEvents() {
    $shippingMethodList.find('[name$="_shippingMethodID"]').on('change', function () {
        selectShippingMethod($(this).val());
    });

    eventMgr.on('TimeslotSelector.slotSelected', (params) => {
        selectShippingMethod(...params)
    });

    eventMgr.on('GiftWrapOptions.refresh', () => {
        dialog.close();
        summary.update();
    });
}

exports.init = function () {
    $form = $('#delivery-options-form');
    $shippingMethodList = $('#shipping-method-list');
    $useShippingAddressNames = $form.find('input.js-use-shipping-names');
    $cncStoresList = $form.find('.js-cnc-stores-list');
    $cncFirstName = $form.find('input[name$="_addressFields_firstName"]');
    $cncLastName = $form.find('input[name$="_addressFields_lastName"]');

    app.components.initComponent('TimeslotSelector', $shippingMethodList);

    handleShippingMethodsPage();
    initCncAddressNamesEvents();
    initializeEvents();
};

exports.selectShippingMethod = selectShippingMethod;
