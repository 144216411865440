var dialog = require('_core_ext/dialog'),
    product = require('_core_ext/pages/product'),
    util = require('_core_ext/util'),
    _findIndex = require('lodash/array/findIndex');

const eventMgr = require('_core_ext/eventMgr');

var makeUrl = function (url, source, productListID) {
    url = util.getCurrentProtocolUrl(url);
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var removeParam = function (url) {
    if (url.indexOf('?') !== -1) {
        return url.substring(0, url.indexOf('?'));
    } else {
        return url;
    }
};

var _setupMagazine = function ($container) {
    if (pageContext.ns === 'magazine') {
        var $contentWrap, $uiDialog;
        
        $('body,html').css('overflow', 'hidden');
        $uiDialog = $container.closest('.ui-dialog');
        if (!$uiDialog.hasClass('js-modified')) {
            $contentWrap = $('<div/>').css({
                'position': 'relative'
            });
            $uiDialog.css({
                'max-height': '100vh',
                'overflow': 'auto'
            }).addClass('js-modified').wrapInner($contentWrap);
        }
    }
};

var _onCloseMagazine = function () {
    if (pageContext.ns === 'magazine') {
        $('body,html').css('overflow', 'inherit');
    }
}

var quickview = {
    init: function () {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'QuickViewDialog').appendTo(document.body);
        }
        var $searchItemsContainer = $('#product-search-result-items');
        this.productLinks = $searchItemsContainer.find('.js-component-product-tile .js-thumb-link').map(function (index, thumbLink) {
            return $(thumbLink).attr('href');
        });
    },

    setup: function (qvUrl) {

        var $btnNext = $('.quickview-next'),
            $btnPrev = $('.quickview-prev');

        product.initializeEvents(this.$container);

        _setupMagazine(this.$container);
        
        this.productLinkIndex = _findIndex(this.productLinks, function (url) {
            return removeParam(url) === removeParam(qvUrl);
        });

        // hide the buttons on the compare page or when there are no other products
        if (this.productLinks.length <= 1 || $('.compareremovecell').length > 0) {
            $btnNext.hide();
            $btnPrev.hide();
            return;
        }

        if (this.productLinkIndex === this.productLinks.length - 1) {
            $btnNext.attr('disabled', 'disabled');
        }
        if (this.productLinkIndex === 0) {
            $btnPrev.attr('disabled', 'disabled');
        }

        $btnNext.on('click', function (e) {
            e.preventDefault();
            this.navigateQuickview(1);
        }.bind(this));
        $btnPrev.on('click', function (e) {
            e.preventDefault();
            this.navigateQuickview(-1);
        }.bind(this));

        // Hack to prevent unexpected opening of select boxes on iOS
        $(document.activeElement).trigger('blur');
    },
    
    onClose: function() {
        var videoElement = (this.$container.find('video'));
        if (videoElement.length > 0) {
            videoElement.parent('.video-js').get(0).player.pause();
        }
        _onCloseMagazine();
    },

    /**
     * @param {Number} step - How many products away from current product to navigate to. Negative number means navigate backward
     */
    navigateQuickview: function (step) {
        // default step to 0
        this.productLinkIndex += (step ? step : 0);
        var url = makeUrl(this.productLinks[this.productLinkIndex], 'quickview');
        var navigateQV = dialog.replace({
            url: url,
            callback: this.setup.bind(this, url)
        });

        if (navigateQV) {
            navigateQV.then(function () {
                $('body').trigger('productQuickView');
            });
        }
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var url;
        if (!this.exists()) {
            this.init();
        }
        url = makeUrl(options.url, options.source, options.productlistid);
        this.quickViewInitialized = false;

        dialog.open({
            target: this.$container,
            url: url,
            options: {
                width: 920,
                title: Resources.QUICK_VIEW_POPUP,
                dialogClass: 'dialog-quickview',
                open: function () {
                    if (!this.quickViewInitialized) {
                        this.setup(url);
                        $('body').trigger('productQuickView');
                        if (typeof options.callback === 'function') { options.callback(); }
                        this.quickViewInitialized = true;
                    }
                }.bind(this),
                close: function () {
                    if (options.source === 'wishlistadd') {
                        eventMgr.execute('wishlist.quickViewClose');
                    }
                    this.onClose();
                }.bind(this)
            }
        });
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports = quickview;
