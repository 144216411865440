/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

/* eslint-env browser, commonjs, jquery */

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}


require('_core_ext/async-css');
require('_core_ext/js-lib')();
require('./brand-js-lib')();
require('_core_ext/js-ext')();
require('_core_ext/jquery-ext')();
require('_core_ext/cookieprivacy')();
require('_core_ext/captcha')();

var app = window.app = {
    init: function () {
        this.components.extendConfig(require('./components-config')).initAll();
    },
    'page' : require('_core_ext/page'),
    'components' : require('_core_ext/components')
};

// initialize app
$(document).ready(function () {
    app.init();
});
