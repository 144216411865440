/*eslint no-use-before-define: "off"*/

var ajax = require('_core_ext/ajax'),
    dialog = require('_core_ext/dialog'),
    image = require('_core_ext/pages/product/image'),
    progress = require('_core_ext/progress'),
    addToCart = require('_core_ext/pages/product/addToCart'),
    productStoreInventory = require('_core_ext/storeinventory/product'),
    tooltip = require('_core_ext/components/global/tooltip'),
    util = require('_core_ext/util'),
    eventMgr = require('_core_ext/eventMgr');

var updateContent;

const emitter = eventMgr.getEmitter('variant');

/**
 * @description Function used to reinit all components when variation is changed by user
 * @private
 * @param {jQuery} $target
 * @param {jQuery} $pdpMain
 */
function _reloadComponents($target, $pdpMain) {
    const isQuickView = dialog.isOpen() && (dialog.getCurrent().attr('id') === 'QuickViewDialog');
    
    app.components.initComponent('input', $target);
    app.components.initComponent('validator', $target);
    app.components.initComponent('productvideojs');
    app.components.initComponent('dialogify', $target);
    app.components.initComponent('swatchSample', $target);
    app.components.initComponent('PrintableMessage', $target);

    if (!isQuickView) {
        app.components.initComponent('stickyKit', $target);
    }

    app.components.initComponent('Expandable', $pdpMain);
    app.components.initComponent('AddToWishlist', $target);
    app.components.initComponent('ProductInfo', $target);
    app.components.initComponent('ProductPromotions', $target);
    addToCart.init($pdpMain.parent());
    tooltip.init();
}

/**
 * @description Function used to handle mobile container after variation changed by user
 * @private
 * @param {jQuery} hiddenMobileContent
 * @param {jQuery} mobileContentContainer
 */
function _handleMobileContainer(hiddenMobileContent, mobileContentContainer) {
    mobileContentContainer.html(hiddenMobileContent.html());
    hiddenMobileContent.remove();
}

/**
 * @description Function for removal hidden content that was rendered for particular components
 * @private
 * @param {jQuery} $pdpMain
 */
function _removeHiddenComponents ($pdpMain) {
    // remove hidden furniture-specific update content
    $pdpMain.find('.js-additional-content').remove();
}

/**
 * @description Handle product tabs selection after variation has changed by user
 * @private
 * @param {jQuery} $pdpMain
 * @param {object} params
 */
function _handleProductTabs($pdpMain, params) {
    var $productVariationsExplore = $pdpMain.find('.js_product-variations-explore');
    $productVariationsExplore.tabs();
    if ('activeTab' in params) {
        var index = $productVariationsExplore.find('li a').index($pdpMain.find('a[href="#' + params.activeTab + '"]').get(0));
        $productVariationsExplore.tabs({
            active: index
        });
    }
}

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
updateContent = function ($pdpMain, href, exploreClose) {
    if (href) {
        var $pdpForm = $pdpMain.find('.pdpForm');
        var $productVariationsTabs = $pdpMain.find('.product-variations-tabs');
        var qty = $pdpForm.find('.js-quantity, input[name="Quantity"]').first().val();
        var params = {
            Quantity: isNaN(qty) ? '1' : qty,
            format: 'ajax',
            productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
        };

        if ($pdpMain.find('.product-variations-tabs').length && 'undefined' === typeof  exploreClose) {
            params.activeTab = $productVariationsTabs.find('.ui-tabs-active').attr('aria-controls');
        }

        progress.show($pdpMain);
        var $target = $pdpMain.find('#product-content-wrapper');
        ajax.load({
            url: util.appendParamsToUrl(href, params),
            target: $target,
            callback: function (response, textStatus, responseText) {
                _handleMobileContainer($pdpMain.find('.js-hidden-mobile-wrapper'), $pdpMain.find('.js-mobile-wrapper'));
                _removeHiddenComponents($pdpMain);

                productStoreInventory.init($pdpMain.parent());
                image.replaceImages($pdpMain.parent());

                $(window).trigger('productChanged', [response, textStatus, responseText]);

                _handleProductTabs($pdpMain, params);
                initExploreLink($pdpMain);
                _reloadComponents($target, $pdpMain);

                // quantity updated with same value, but for another variation
                // so we need re-validate it to show correct value
                var $qty = $target.find('.js-quantity');
                if ($qty.length) {
                    $qty.valid();
                }

                emitter.emit('updated', response);
            }
        });
    }
};


function initExploreLink($pdpMain) {
    $pdpMain.on('click', '#js_explore-colours-link', function(e) {
        e.preventDefault();
        var $this = $(this);
        var url = $this.attr('href');
        var $pdpMain = $this.closest('.js-pdp-main');
        var $target = $pdpMain.find('#js_variation-container');
        if ($target.find('.color li.selected').length > 0) {
            $target = $pdpMain.find('#product-content-wrapper');
        }
        ajax.load({
            url: url,
            target: $target,
            callback: function (response, textStatus, responseText) {
                $(window).trigger('productChanged', [response, textStatus, responseText]);
                $pdpMain.find('.js_product-variations-explore').tabs();
                $pdpMain.find('#js_explore-colours-link-close').on('click', function(e) {
                    e.preventDefault();
                    updateContent($pdpMain, this.href, true);
                });
            }
        });
    }).on('click', '#js_explore-colours-link-close', function(e) {
        e.preventDefault();
        updateContent($(this).closest('.js-pdp-main'), this.href, true);
    });
}

function highlightSizeNotSelected($pdpMain) {
    // called when variation not selected and we need to highlight error on not-selected variation attribute
    // as color always preselected, we highlight size
    var $sizeSelector = $pdpMain.find('.variation-select');
    if ($sizeSelector.find('option:selected').hasClass('emptytext')) {
        var $error = $sizeSelector.siblings('.js-error-notselected');
        $error.text($error.data('text')).removeClass('hidden');
        $sizeSelector.closest('.attribute').addClass('error');
    }

    highlightSizeNotSelectedV2($pdpMain);
}

function highlightSizeNotSelectedV2($pdpMain) {
    const $sizeSelectorV2 = $pdpMain.find('.js-sizeselector');
    
    if ($sizeSelectorV2.length > 0 && $sizeSelectorV2.find('.sizeselector-selected').length < 1) {
        const $error = $sizeSelectorV2.find('.js-error-notselected');
        $error.text($error.data('text')).removeClass('hidden');
        $sizeSelectorV2.find('.sizeselector-dropdown').addClass('error');
    }
}

module.exports = function ($container) {
    $container = $container || $(document);
    var $pdpMain = $container.find('.js-pdp-main');

    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
        e.preventDefault();
        var $this = $(this);
        if ($this.hasClass('js-unselectable') || $this.hasClass('js-selected')) {
            return;
        }
        updateContent($this.closest('.js-pdp-main'), this.href);
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function () {
        var $this = $(this);
        if ($this.val().length === 0) {
            return;
        }
        updateContent($this.closest('.js-pdp-main'), $this.val());
    });

    $pdpMain.on('click', '.js-size-option', function () {
        var $this = $(this);

        if ($this.attr('data-selected') === 'selected') {
            $(document).trigger('click');
            return;
        }

        updateContent($pdpMain, $this.attr('data-value'));
    });

    var highlightSizeNotSelectedBound = highlightSizeNotSelected.bind(null, $pdpMain);
    eventMgr.on('productPage.notselected', highlightSizeNotSelectedBound);
    eventMgr.on('addToCart.notselected', highlightSizeNotSelectedBound);
    eventMgr.on('ColorPicker.selected', function (href) {
        updateContent($('.js-pdp-main'), href);
    });

    initExploreLink($pdpMain);
};
