import Component from '_core_ext/components/Component';

export default class Isotope extends Component {
    static get selector() {
        // for some reason isotope attaches itself automatically to .js-isotope element which cause issues with container height calculations,
        // so using another selector
        return '.js-isotope-component';
    }
    static contentSearch($container, inputValue) {
        var query = new RegExp(inputValue ? inputValue.toLowerCase() : '');
        $container.isotope({
            filter: function() {
                var name = $(this).text().toLowerCase();
                return name.match(query);
            }
        });
    }
    get configDataAttrName() {
        return 'isotope-options';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this._initEvents();
    }
    _initEvents() {
        this.reInit();
        this.event('click', '.js-isotope-filters-clear-all', () => {
            var $filterWrappers = this.$el.find('.js-isotope-filter-wrapper');
            for (var i = 0; i < $filterWrappers.length; i++) {
                var $filterWrapper = $($filterWrappers[i]);
                var $filters = $filterWrapper.find('.js-isotope-filter');
                $filters.removeClass('is-checked');

                var $selected = $filterWrapper.find('.js-isotope-selected');
                $selected.data('filter', '*');
                $selected.text($filters.first().text());
            }
            this.filter();
        });

        this.event('click', '.js-isotope-filter', (element) => {
            var $el = $(element);
            var $filterWrapper = $el.closest('.js-isotope-filter-wrapper');

            //set classes for active element
            $filterWrapper.find('.js-isotope-filter').removeClass('is-checked');
            $el.addClass('is-checked');

            var $selected = $filterWrapper.find('.js-isotope-selected');

            $selected.text($el.text());
            $selected.data('filter', $el.data('filter'));
            $('#storyArchiveQuery').removeClass('x onX').val('').trigger('change');
            this.filter();
        });
    }
    filter() {
        var filterValues = [];
        var resultFilter = '*';
        var $filtersSelected = this.$el.find('.js-isotope-selected');
        for (var i = 0; i < $filtersSelected.length; i++) {
            var itemfilter = $($filtersSelected[i]).data('filter');
            if (itemfilter && itemfilter !== '*') {
                filterValues.push(itemfilter);
            }
        }

        if (filterValues.length) {
            resultFilter = filterValues.join('');
        }
        this.$el.find('.js-isotope-grid, .js-isotope-grid-whatson').isotope({
            filter: resultFilter
        });
    }
    reInit() {
        var $el = this.$el;
        var $grid = $el.find('.js-isotope-grid'),
            $gridwhatson = $el.find('.js-isotope-grid-whatson');

        $gridwhatson.imagesLoaded(() => {
            $gridwhatson.isotope({
                itemSelector: '.asset',
                layoutMode: 'packery'
            });
        });

        var gridLayoutMode = 'packery';
        if ($grid.hasClass('order-details-grid')) {
            gridLayoutMode = 'masonry';
        }

        $grid.imagesLoaded(() => {
            $grid.isotope({
                itemSelector: '.asset',
                layoutMode: gridLayoutMode,
                packery: {
                    gutter: '.isotope-gutter-sizer'
                }
            });
        });
    }
}

module.exports = Isotope;
