'use strict';

/**
 * @private
 * @function
 * @description Binds event to the place holder (.blur)
 */
function initializeEvents() {
    
    $('#header-search-form-wrapper').find('form').on('submit', function(e) {
        var queryInput = e.target.querySelector('#q');
        if(queryInput.value == queryInput.getAttribute('placeholder') || queryInput.value == '') {
            e.preventDefault();
        }
    });
    
    $('#q').on('focus', function () {
        var input = $(this);
        if (input.val() === input.attr('placeholder')) {
            input.val('');
        }
    })
    .on('blur', function () {
        var input = $(this);
        if (input.val() === '' || input.val() === input.attr('placeholder')) {
            input.val(input.attr('placeholder'));
        }
    })
    .trigger('blur');
}

exports.init = initializeEvents;
