import Component from '_core_ext/components/Component';

export default class Filterable extends Component {
    static get selector() {
        return '.js-filterable';
    }

    init(...args) {
        super.init(args);
        const $container = this.$el;
        this.$el.find('.js-filter').on('click', function() {
            $container.find($(this).data('filter-selector')).toggleClass('hidden', $(this).prop('checked'));
        });
    }
}

module.exports = Filterable;
