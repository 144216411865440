(function (doc){
    var noscriptContainer = doc.getElementById('async-stylesheets');  
    if (noscriptContainer) {  
        var head = doc.getElementsByTagName('head')[0],
            container = document.createElement('div'), 
            nodes;  
        container.innerHTML = noscriptContainer.textContent;
        nodes = container.childNodes;
        for (var i = nodes.length - 1; i >= 0; i--) {
            head.appendChild(nodes[i]);
        }
    }
})(document);
