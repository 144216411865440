var dialog = require('_core_ext/dialog'),
    searchsuggest = require('_core_ext/searchsuggest-beta'),
    util = require('_core_ext/util'),
    noScroll = require('_core_ext/components/noscroll'),
    _throttle = require('lodash/function/throttle'),
    ajax = require('_core_ext/ajax');

const eventMgr = require('_core_ext/eventMgr');
const Promise = require('promise');
require('es6-promise').polyfill();

/**
 * @description Moved from SG app.js
 */
function initializeEvents() {
    var $document = $(document);
    var $header = $('.js-header');

    /**
     * initialize search suggestions if not disabled by isSearchSuggestionsDisabled site preference
     * */
    if ($header.length) {
        var $searchField = $header.find('.js-header-search-field'),
            $searchContainer = $header.find('#header-search-form-wrapper');
        $header.on('click', '.js-header-search-icon', function(e) {
            e.preventDefault();

            var $this = $(this);
            $this.toggleClass('expanded');
            $this.parent().toggleClass('expanded');
            $this.next('.toggle-content').toggleClass('expanded');
            if ($(this).hasClass('expanded')) {
                $searchField.trigger('focus');
            }
            return false;
        });

        if (!SitePreferences.SEARCH_SUGGESTIONS_DISABLED) {
            searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);
        }
    }

    // add show/hide navigation elements
    var $secondaryNavigation = $('.secondary-navigation .js-toggle-nav');
    $secondaryNavigation.on('click', function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });

    // check that slot wrapper is not empty in drop down menu
    $('#navigation').find('.menu-content-wrapper').each(function() {
        var currentWrapper = $(this),
            dataColumns, wrapper;
        if ($(this).find('.slot').length > 0) {
            wrapper = currentWrapper.closest('.level-2-content');
            dataColumns = parseInt(wrapper.data('columns'), 10);
            wrapper.removeClass('menu-category-columns-' + dataColumns);
            dataColumns++;
            wrapper.attr('data-columns' , dataColumns);
            wrapper.addClass('menu-category-columns-' + dataColumns);
        }
    });

    //add show/hide footer
    var footerEmitter = eventMgr.getEmitter('footer');
    $('.footer-column').on('click', 'dt', function() {
        $(this).toggleClass('expanded');
        var footer = $('.footer-container');
        footer.toggleClass('expanded');
        footerEmitter.emit('expanded', footer.hasClass('expanded'));
    });

    // fire event when footer links gets in/out viewport (on mobile)
    const footerLinks = $('.footer-links');
    // if it's fixed - then it's always in viewport (desktop and tablet)
    if (footerLinks.length && footerLinks.css('position') !== 'fixed') {
        const footerLinksEl = footerLinks.get(0);
        let prevInViewport = util.elementInViewport(footerLinksEl, 0);
        $(window).on('scroll', _throttle(function() {
            if (util.elementInViewport(footerLinksEl, 0) && !prevInViewport) {
                // entered viewport
                footerEmitter.emit('inviewport', true);
                prevInViewport = true;

            } else if (!util.elementInViewport(footerLinksEl, 0) && prevInViewport) {
                // left viewport
                footerEmitter.emit('inviewport', false);
                prevInViewport = false;
            }
        }, 100));
    }

    // add generic toggle functionality
    /*$('.toggle').next('.toggle-content').hide();
    $('.toggle').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });*/

    $document.on('click', '.js-toggle, .toggle',function (e) {
        var $el = $(this);
        if (!$el.hasClass('js-toggle-disabled')) {
            $el.toggleClass('expanded');
            $el.next('.js-toggle-content, .toggle-content').toggleClass('expanded');
            if (!$el.hasClass('js-toggle-click') || $el.hasClass('js-toggle-prevent-click')) {
                e.preventDefault();
                return false;
            }
        }
    });

    // collapse toggle element with 'js-collapse-out-click' class when clicked outside of it, relates to '.js-toggle, .toggle' elements above
    $document.on('click', function (e) {
        // when clicked outside of toggle elem and it's target content
        if (!$(e.target).closest('.js-toggle, .toggle, .js-toggle-content, .toggle-content').length) {
            $('.js-toggle, .toggle')
                .filter('.js-collapse-out-click').removeClass('expanded')
                .next('.js-toggle-content, .toggle-content').removeClass('expanded');
        }
    });

    $document.on('touchstart', '.ui-dialog-titlebar-close',function () {
        $(this).trigger('click');
    });

    $document.on('click', '.js-toggle-collapse', function (e) {
        e.preventDefault();
        $('.js-toggle-collapse').not(this).removeClass('expanded');
    });

    $document.on('click', '.js-toggle-parent', function (e) {
        e.preventDefault();
        $(this).parent().find('.js-toggle').toggleClass('expanded');
    });

    // store details toggle functionality
    $document.on('click touchend', '.js-select-storecontent', function(e) {
        e.preventDefault();
        var $this = $(this),
            $parent = $this.parent().parent(),
            $storeDetails = $('.store-details-inner'),
            option = $this.text(),
            container = $this.data('storecontainer');

        $('.store-link-current').html(option);
        $storeDetails.children().hide();
        $storeDetails.find('.' + container).show();
        $parent.find('.js-toggle').toggleClass('expanded');
    });
 // can be RE-used as component's style - to collapse tabs, etc into custom dropdown select
    $('.js-collapse-to-dropdown').each(function() {
        $(this).find('.selected-value').text($(this).find('.collapse-to-dropdown__item:first a').text());
    });
    $document.on('click', '.js-collapse-to-dropdown',function (e) {
        e.preventDefault();
        $(this).toggleClass('expanded');
        $(this).find('ul').toggleClass('expanded');
    });
    $document.on('click', '.js-collapse-to-dropdown .collapse-to-dropdown__item',function (e) {
        e.preventDefault();
        $(this).closest('.collapse-to-dropdown').find('.selected-value').text($(this).find('a').text());
    });

    $document.on('click', function(e) {
        if (!$(e.target).closest('.js-isotope-filter-wrapper').length) {
            $('.js-isotope-filter-wrapper .js-toggle-collapse').removeClass('expanded');
            $('.js-isotope-filter-wrapper .js-toggle-content').find('ul').removeClass('expanded');
        }
    });

    var wishlistItemsPromise = null;
    eventMgr.registerAction('common.updateWishlistCounter', function(data) {
        return new Promise((res) => {
            wishlistItemsPromise = null;    // reset stored items fetching promise if WL was updated
            var $headerWishlist = $('.js-header-wishlist');
            var $itemsCount = $headerWishlist.find('.js-wishlist-items-count');
            if ($itemsCount.length) {
                $headerWishlist.toggleClass('wishlist-empty', !data.count);
                res($itemsCount.text(data.count ? data.count : ''));
            }
        });
    });

    // action to fetch array product IDs that are in wishlist
    eventMgr.registerAction('common.getWishlistItems', function () {
        if (!wishlistItemsPromise) {
            wishlistItemsPromise = ajax.getJson({
                url: Urls.wishlistItems,
                dataType: 'jsonp'
            });
        }
        return wishlistItemsPromise;
    });

    // auto-add placeholders according to it's labels for assets. Can be expanded for more possible future cases.
    if ($('.pt_content .content-asset form').length > 0) {
        var $inputFields = $('.pt_content .content-asset form').find('input[type="text"], input[type="email"]');

        $inputFields.each(function() {
            var $field = $(this);

            if (util.isEmpty($field.attr('placeholder'))) {
                $field.attr('placeholder', $field.parent().siblings('label').text());
            }
        });
    }

    // ***** Amplience Phone Slider ******
    var $phoneSlider = $('.js-phone-slider');

    function setEqualContentHeight($selectors) {
        var maxSelectorHeight = 0;
        // get max selector height
        $selectors.each(function(i, item) {
            $(item).css('height','auto'); // reset height
            if ($(item)[0].clientHeight > maxSelectorHeight) {
                maxSelectorHeight =  $(item)[0].clientHeight;
            }
        });
        // set max selector height
        $selectors.each(function(i, item) {
            if ($(item)[0].clientHeight !== maxSelectorHeight) {
                $(item).css('height', maxSelectorHeight);
            }
        });
    }

    function updatePhoneSliderState($headers, $descriptions) {
        // set equal minimal height to fit all content
        setEqualContentHeight($headers);
        setEqualContentHeight($descriptions);
    }

    if ($phoneSlider.length > 0) {

        var $descriptions = $('.js-phone-slider').find('.phone-slide__description');
        var $headers = $('.js-phone-slider').find('.phone-slide__header');

        // apply window resize event actions to description (set equal minimal height to fit all description content)

        updatePhoneSliderState($headers, $descriptions); // init state;
        $(window).resize(function() {
            updatePhoneSliderState($headers, $descriptions);
        });
    }

    // Amplience resources (Slider Reveal Template)
    $('.js-twentytwenty-container').twentytwenty({
        /* eslint-disable camelcase */
        default_offset_pct: 0.7
        /* eslint-enable camelcase */
    });

    // ***** detect and handle inView functionality (when element appears or scrolled to in viewport) (usually it will trigger the animation) *****
    var $inViewSelectors = $('.js-inView');

    // handle annimation\appearance of selectors depending on current document scroll position
    function updateInViewStates($animationObjectsInQueue, animationSequenceDelay) {
        var windowBottomY = $document.scrollTop() + $(window).height();

        $inViewSelectors.each(function(i, item) {
            var selectorBottomY = $(item)[0].y + $(item)[0].offsetHeight;
            // if bottom of the object is in the viewport (so it's fully can be shown) AND it's not shown yet AND it doesn't have property set animation-triggered=true
            if (windowBottomY >= selectorBottomY && !$(this).hasClass('inView') && !$(this).data('animation-triggered')) {
                $(this).data('animation-triggered', true);
                $animationObjectsInQueue.push($(this));
            }
        });

        // if there's objects in the queue that are waiting to be shown - iterate through them and show each next with delay
        if ($animationObjectsInQueue.length > 0) {
            $animationObjectsInQueue.forEach(function(item, index) {
                setTimeout(function() {
                    $(item).addClass('inView'); // this class will trigger animation start (in css)
                }, index * animationSequenceDelay);
            });
            $animationObjectsInQueue = [];
        }
    }

    if ($inViewSelectors.length > 0) {
        var $animationObjectsInQueue = [];
        var animationSequenceDelay = 300; // ms - delay between 2 or more animations in a row

        updateInViewStates($animationObjectsInQueue, animationSequenceDelay); // init state;
        $document.on('scroll', function () {
            updateInViewStates($animationObjectsInQueue, animationSequenceDelay);
        });
    }

    $document.on('click touchend', '.js-toggle-mobile', function (e) {
        e.preventDefault();
        $(this).toggleClass('expanded-mobile');
        $(this).next('.js-toggle-content-mobile').toggleClass('expanded-mobile');
        if (!$(this).hasClass('js-toggle-click')) {
            return false;
        }
    });

    $document.on('click', '.js-toggle-content-mobile', function (e) {
        $(this).prev('.js-toggle-mobile').toggleClass('expanded-mobile');
        var $target = $(e.target);
        if (!$target.hasClass('js-toggle-click')) {
            e.preventDefault();
            return false;
        }
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0) {
        $subscribeEmail.on('focus', function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });

    // main menu toggle
    if ($header.length) {
        const menuEmitter = eventMgr.getEmitter('mainmenu');
        if (util.isTouch()) {
            // this handles mobile hamburger and tablet(touch) desktop nav

            var $wrapper = $('#wrapper'), // page wrapper
                $nav = $('#navigation'), // main menu
                $menuCloseButton = $wrapper.find('.js-close-category-menu'), //hamburger menu Close button
                $window = $(window);

            if (util.isTablet()) {
                $header.addClass('is-tablet');

                $window.on('scroll', _throttle(function() {
                    var $el = $header.find('.js-menu-item-wrapper.active');
                    $el.removeClass('active')
                        .closest('.js-menu-sub-level').removeClass('nested-item-active');
                    $el.trigger('mouseleave');
                    if ($el.length) {
                        menuEmitter.emit('closed');
                    }
                }, 100));
            }

            $header.on('click', '.js-menu-item', function (e) {
                var $el = $(this),
                    $parentWrapper = $el.closest('.js-menu-item-wrapper'),
                    $parentLevel2Content = $el.closest('.level-2-content'),
                    $subItemsWrapper = $parentWrapper.find('.js-menu-sub-level');
                    
                if ($subItemsWrapper.length &&
                        !$parentWrapper.hasClass('active') &&
                        !(util.isTablet() && $parentLevel2Content.length)) { // this will skip cases when user clicks on level-2 menus on tablets\ipad - they just should be normally treated as links

                    $parentWrapper.siblings('.active').removeClass('active')
                        .closest('.js-menu-sub-level').removeClass('nested-item-active');
                    $parentWrapper.addClass('active')
                        .closest('.js-menu-sub-level').addClass('nested-item-active');
                    menuEmitter.emit('opened');
                    e.preventDefault();
                    return false;
                }
            })
            .on('click', '.js-menu-back', function (e) {
                var $el = $(this),
                    $parentWrapper = $el.closest('.js-menu-item-wrapper');
                $parentWrapper.removeClass('active')
                    .closest('.js-menu-sub-level').removeClass('nested-item-active');
                e.preventDefault();
                return false;
            })
            .on('click', '.js-menu-toggle', function () {
                var $headerCookies = $wrapper.find('.header-cookies');

                $nav.css('height', $(window).innerHeight());
                $nav.css('top', -$('.header-banner').height());
                $wrapper.addClass('menu-active');
                $menuCloseButton.css('top', $headerCookies.height());
                if ($wrapper.find('.is-sticky').length) {
                    $wrapper.find('#header-search-form-wrapper').css('opacity', '0');
                }
                noScroll.enable();
            });
            $menuCloseButton.on('click', function () {
                noScroll.disable();
                $wrapper.removeClass('menu-active');
                $wrapper.find('#header-search-form-wrapper').css('opacity', '');
            })
        } else {
            var $menuTimer,
                $transitionTimer;
            $('.menu-category').on('mouseenter', function() {
                var $el = $(this);
                if (!$el.hasClass('.menu-category-hover')) {
                    $menuTimer = setTimeout(function() {
                        $el.addClass('menu-category-hover');

                        // we need a transition of menu appearance when pointer entered the menu line,
                        // but not between top level items, so after initial transition ends, we should disable all transitions
                        // until cursor leaves menu again
                        $transitionTimer = setTimeout(function() {
                            $el.addClass('no-transition');
                        }, SitePreferences.MENU_TRANSITION_DURATION + 20);
                    }, SitePreferences.MENU_HOVER_DELAY);
                }
                menuEmitter.emit('opened');
            }).on('mouseleave', function(e) {
                var $target = $(e.toElement || e.relatedTarget);
                if (!$target.closest('.menu-category-hover').length) {
                    clearTimeout($menuTimer);
                    clearTimeout($transitionTimer);
                    $(this).removeClass('menu-category-hover no-transition');
                }
                menuEmitter.emit('closed');
            });
        }
        $header.on('click', '.js-user-account', function (e) {
            e.preventDefault();
            $(this).closest('.js-user-info').toggleClass('active');
            return false;
        });
    }
}

/**
 * Returns true if Hamburger menu rendered.
 * Idea: https://gomakethings.com/the-easy-way-to-manage-css-breakpoints-in-javascript/
 * @returns {boolean}
 */
function isHamburgerMenu() {
    const nav = document.getElementById('navigation');
    return nav && (util.getCSSContentValue(nav) === 'js-hamburger');
}

/**
 * @description Moved from SG app.js
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }

    if ($('.user-agent-header').length) {
        $('body').addClass('user-agent-logged');
    }
    // load js specific styles

    if (document.cookie.length === 0) {
        $('<div/>').addClass('browser-compatibility-alert no-cookies').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
    }

    // open current category in Mobile Hamburger menu
    if (isHamburgerMenu()) {
        var navPath = window.pageContext && window.pageContext.navPath || [];
        var $itemWrapper = $('#navigation');
        for (let i = 0; i < navPath.length; i++) {
            const cgid = navPath[i];
            $itemWrapper = $itemWrapper.find(`.js-menu-item-wrapper[data-cgid="${cgid}"]`);  // go down by nav tree

            if ($itemWrapper.length && $itemWrapper.find('.js-menu-sub-level').length) {
                $itemWrapper.addClass('active')
                    .closest('.js-menu-sub-level').addClass('nested-item-active');
            } else {
                break;
            }
        }
    }
}

module.exports = {
    'init' : function () {
        initializeDom();
        initializeEvents();
    }
};
