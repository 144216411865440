'use strict';

var util = require('_core_ext/util');

exports.init = function () {
    var tabsSelector = '.js-tabs' + (util.isMobile() ? ', .js-tabs-mobile' : '');
    var $tabs = $(tabsSelector);

    $tabs.tabs({
        active: 0,
        activate: function (event, ui) {
            var $this = $(this);
            var selectedField = $this.find('.js-tabs-selected').first();
            var selectedTabText = ui.newTab.text();
            selectedField.text(selectedTabText);
            app.components.reInitComponent('isotope');
        }
    });
};
