var account = require('_core_ext/pages/account'),
    page = require('_core_ext/page'),
    ajax = require('_core_ext/ajax'),
    util = require('_core_ext/util'),
    dialog = require('_core_ext/dialog'),
    validator = require('_core_ext/components/global/validator');

const Promise = require('promise');
const eventMgr = require('_core_ext/eventMgr');

var $quickviewSender;

exports.init = function () {
    var $cache = {};
    $cache.document = $(document);

    $cache.editAddress = $('#editAddress');
    $cache.listLink = $('.js-list-link');
    $cache.listShare = $('.js-list-share');
    $cache.wishlistHeader = $('.js-header-wishlist');
    $cache.wishlistHeaderCount = $cache.wishlistHeader.find('span');
    $cache.wishlistProductsContainer = $('.js-wishlist-products');
    $cache.wishlistActiveTab = $('.js-wishlist-active-tab');

    var sendWishListToFriend = function () {
        var $form = $('.js-form-send-to-friend-wishlist');
        var isFormValid = $form.valid();
        if (isFormValid) {
            var $submit = $form.find('[name$="_sendWishList"]');
            dialog.submit($submit.attr('name'))
                .done(function(data, textStatus, jqXHR) {
                    if (jqXHR.status === 201) {
                        dialog.replace({
                            html: data,
                            options : {
                                buttons: [{
                                    text: Resources.CLOSE,
                                    'class' : 'sendtofriend-wishlist-close-btn',
                                    click: function () {
                                        dialog.close();
                                    }
                                }]
                            }
                        });
                    }
                });
        }
    };

    $cache.editAddress.on('change', function () {
        page.redirect(util.appendParamToURL(Urls.wishlistAddress, 'AddressID', $(this).val()));
    });

    //add js logic to remove the , from the qty feild to pass regex expression on client side
    $('.option-quantity-desired input').on('focusout', function () {
        $(this).val($(this).val().replace(',', ''));
    });

    $cache.document.on('click', '.js-remove-list-item', function(event) {
        event.preventDefault();
        var $this = $(this);
        ajax.getJson({
            url: $this.attr('href'),
            callback: function(response) {
                if (response.success) {
                    $this.closest('.wishlist-products-item').remove();
                    if ($cache.wishlistActiveTab.length) {
                        $cache.wishlistActiveTab.text(response.currentWLTabData.title);
                    }
                    var $bonusDialog = $('.bonus-product-dialog');
                    var timer;
                    $bonusDialog.on('dialogopen', function() {
                        if (timer) {
                            clearTimeout(timer);
                        }
                    }).on('dialogclose', function() {
                        $bonusDialog.addClass('initialized');
                    });
                    eventMgr.execute('common.updateWishlistCounter', {count : response.productsCount});
                    if (!response.productsCount) {
                        if (!$cache.wishlistProductsContainer.find('.js-component-product-tile ').length) {
                            if ($bonusDialog.length && !$bonusDialog.hasClass('initialized')){
                                $bonusDialog.on('dialogclose', function() {
                                    page.refresh();
                                });
                            } else {
                                timer = setTimeout(function(){
                                    page.refresh();
                                },1200);
                            }
                            return;
                        }
                        $cache.listLink.addClass('hide');
                        $cache.listShare.find('button').prop('disabled', true);
                        $cache.wishlistHeader.addClass('wishlist-empty');
                        $cache.wishlistHeaderCount.empty();
                    }
                }
            }
        }); 
        return false;
    });

    eventMgr.on('addToCart.product.added', (form) => {
        var $removeListItem = $(form).find('.js-remove-list-item');
        if (!util.isMobile() && !$removeListItem.length && $quickviewSender && $quickviewSender.data('source') === 'wishlistadd') {
            $removeListItem = $quickviewSender.closest('form').find('.js-remove-list-item');
            $quickviewSender = null;
        }
        $removeListItem.trigger('click');
    });

    eventMgr.registerAction('wishlist.quickViewOpen', (sender) => {
        $quickviewSender = $(sender);
        return Promise.resolve($quickviewSender.length);
    });

    eventMgr.registerAction('wishlist.quickViewClose', () => {
        $quickviewSender = null;
        return Promise.resolve($quickviewSender);
    });

    $cache.document.on('click', '.js-sendtofriend-dialog', function (e) {
        e.preventDefault();
        var $this = $(this);
        dialog.open({
            url: this.href,
            options: {
                title : $this.data('dialog-title') || '',
                dialogClass : 'dialog-sendtofriend-wishlist',
                width: '600px',
                buttons: [{
                    text: Resources.CANCEL,
                    'class': 'sendtofriend-wishlist-cancel-btn',
                    click: function () {
                        dialog.close();
                    }
                },
                {
                    text: Resources.SEND,
                    'class': 'sendtofriend-wishlist-send-btn',
                    click: function () {
                        sendWishListToFriend();
                    }
                }]
            },
            callback : function() {
                validator.initForm($('.js-form-send-to-friend-wishlist'));
            }
        });
        return false;
    });

    $cache.document.on('keydown', '.js-form-send-to-friend-wishlist input', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            sendWishListToFriend();
        }
    });

    $cache.document.on('keyup keydown', '.js-input-message', function() {
        var $this = $(this),
            tval = $this.val(),
            tlength = tval.length,
            max = $this.attr('maxlength') || 250,
            remain = parseInt(max - tlength, 10);

        $('.js-wishlist-message-characters-remain').text(Resources.WISH_LIST_MESSAGE_LIMIT.replace('{{remain}}', remain));
        if (remain <= 0) {
            $this.val((tval).substring(0, max));
        }
    });

    account.initNavigationEvents();
    account.handleScroll();
};
