/**
 * Component rendering Size selector pop-up (for mobile) when A2C clicked prior to selecting size.
 * Called from outside explicitly in A2C flow.
 */
const dialog = require('_core_ext/dialog'),
    util = require('_core_ext/util'),
    eventMgr = require('_core_ext/eventMgr'),
    TPromise = require('promise');

// callback to pass control further from this dialog
// stub, to be overridden in promise constructor
var next = function() {};

function initializeEvents($container) {
    eventMgr.once('dialog.closed', () => next(false));

    $container
        .on('click', '.js-cancel-size-selector', () => dialog.close())
        .on('click', '.js-selectable-size', (e) => {
            var $el = $(e.currentTarget);
            next({
                productId: $el.data('pid')
            });
            dialog.close();
        });
}

const sizeSelectorView = {
    show: function (params) {
        var promise = new TPromise((resolve) => {
            // store reference to callback, to call out of this function
            next = resolve;
        });

        // open dialog with size selector
        dialog.open({
            target: '#size-selector-dialog',
            url: util.appendParamsToUrl(Urls.sizeSelector, params),
            options: {
                dialogClass : 'sizeselector-dialog-mobile js-size-selector-dialog',
                width: 'auto'
            },
            callback: () => {
                var $container = $('.js-size-selector-dialog');
                initializeEvents($container);
            }
        });
        
        return promise;
    }
}

module.exports = sizeSelectorView;
