import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';
import DD from '_core_ext/components/deviceDetector';
import Promise from 'promise';

export default class StickyKit extends Component {
    static get selector() {
        return '.js-sticky-kit';
    }
    get configDefault() {
        return {
            /**
             * 'isDisabledForMobile' and 'isDisabledForMobile' are legacy ones and should not be used now on,
             * 'isEnabledByCSS' should be used instead
             */
            isDisabledForMobile: false,
            isDisabledForPortrait: false,
            isEnabledByCSS: false,
            parent: '.column-left-sidebar',
            force_sticky: true,
            offset_top: 30, // top offset for sticky element
            offsetTopListener: false    // if true, listens to 'stickyHeader.stick' event to re-calculate offset_top based on it's height (every sticky-kit cmp should use it)
        };
    }
    get configDataAttrName() {
        return 'sticky';
    }

    /**
     * Method called when creating component
     *
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.initSticky().then(() => {
            // force recalc at once for the case when it must be sticky at the moment of init-n
            this.$el.trigger('sticky_kit:recalc');

            eventMgr.on('sticky.recalc', () => {
                this.$el.trigger('sticky_kit:recalc');
            });
    
            // listen to the moment when header become sticky and update current sticky-kit's offset_top
            if (this.config.offsetTopListener) {
                eventMgr.on('stickyHeader.stick', () => {
                    eventMgr.execute('stickyHeader.getHeight')
                    .then((top) => {
                        this.initConfig({offset_top: top});
                        this.reInit({hard: true});
                    });
                });
            }
    
            eventMgr.on('deviceDetector.deviceChange', () => this.reInit({hard: true}));
    
            // to get 'deviceDetector.deviceChange' fired when device orientation changed
            DD.startWatchSize();
        });
    }

    initSticky() {
        if (this.isEnabled()) {
            return eventMgr.execute('stickyHeader.getHeight')
                .then((top) => {
                    this.initConfig({offset_top: top});
                    this.$el.stick_in_parent(this.config);
                })
                .catch(() => this.$el.stick_in_parent(this.config));    // if there is no stickyHeader on page
        }
        return Promise.resolve();
    }

    isEnabled() {
        if ((this.config.isDisabledForMobile && util.isMobile()) ||
            (this.config.isDisabledForPortrait && util.isPortrait())) {
            return false;
        } else if (this.config.isEnabledByCSS) {
            // check if enabled for layout - determined by css ::before content set to 'js-sticky-enabled'
            return util.getCSSContentValue(this.$el[0]) === 'js-sticky-enabled';
        }
        return true;
    }

    reInit(options) {
        // 'hard' re-init - detach and attach back (if it's applicable for current layout - see init() method)
        if (options && options.hard) {
            this.destroy();
            this.initSticky();
        } else {
            // 'soft' re-init - just recalculate position
            this.$el.trigger('sticky_kit:recalc');
        }
    }

    destroy() {
        this.$el.trigger('sticky_kit:detach');
    }
}

module.exports = StickyKit;
