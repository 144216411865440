import Component from '_core_ext/components/Component';

const EXPANDED_CLASS = 'expanded';

export default class Expandable extends Component {
    static get selector() {
        return '.js-expandable';
    }

    init(...args) {
        super.init(args);
        this.onToggle = this.onToggle.bind(this);
        this.event('click', '.js-toggler', this.onToggle);

        if (this.config.isExpanded) {
            this.$el.addClass(EXPANDED_CLASS);
        }
    }

    onToggle() {
        this.$el.toggleClass(EXPANDED_CLASS);
    }
}

module.exports = Expandable;
