import Component from '_core_ext/components/Component';
import util from '_core_ext/util';

export default class ScrollTo extends Component {
    static get selector() {
        return '.js-scroll-to';
    }
    get configDataAttrName() {
        return 'scroll-to-options';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this._initEvents();
    }
    _initEvents() {
        this.event('click', (element, event) => {
            var $el = $(element),
                target = $el.attr('href').split('#');
            //at first, we need to check, what we have target at hash part
            var targetHasHash = target.length === 2;
            if (targetHasHash) {
                var $targetEl = $('a[name="' + target[1] + '"]');
                if ($targetEl.length) {
                    var top = $targetEl.offset().top;
                    util.scrollBrowser(top);
                }
                event.preventDefault();
                return false;
            }
        });
    }
}

module.exports = ScrollTo;
