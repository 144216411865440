import Carousel from '_core_ext/components/global/carousel';
import layout from '_core_ext/components/global/layout';

export default class ProductCarousel extends Carousel {
    static get selector() {
        return '.js-product-carousel';
    }

    get configDefault() {
        var configDefault = super.configDefault;

        configDefault.animation.duration = SitePreferences.PRODUCT_CAROUSEL_ANIMATION_DURATION;

        return configDefault;
    }

    /**
     * Method called when creating component
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        super.init(...args);
        this.$el.jcarouselSwipe({
            'perSwipe': layout.isMobile() ? 2 : 1
        });
    }

    _initEvents(...args) {
        this.$el.on('jcarousel:create jcarousel:reload', (event) => {
            var $element = $(event.target);
            var $items = $element.jcarousel('items');
            var $emptyItems = $items.filter('.js-empty-carousel-item');

            if (layout.isMobile()) {
                if ($items.length > 1 && $items.length % 2 !== 0 && !$emptyItems.length) {
                    $element.find('ul').append($('<li/>', {
                        class: $items.attr('class') + ' js-empty-carousel-item'
                    }));
                }
            } else {
                $emptyItems.remove();
            }
            this._reInitControls();
        });
        super._initEvents(...args);
    }
    _reInitControls() {
        var step = layout.isMobile() ? 2 : 1;
        super._reInitControls(step);
    }

    reInit(...args) {
        super.reInit(...args);
        this.$el.jcarouselSwipe({
            'perSwipe': layout.isMobile() ? 2 : 1
        });
    }
}

module.exports = ProductCarousel;
