/**
 * Component rendering Cross-sell dialog for just added item if cross-sell Recommendations present for it.
 * Called from outside explicitly in A2C flow.
 */
const dialog = require('_core_ext/dialog'),
    ajax = require('_core_ext/ajax'),
    TPromise = require('promise');

function initDialog($container) {
    app.components.initComponent('ProductTile', $container);
    app.components.initComponent('ProductCarousel', $container);
    
    $container.find('.js-cross-sell-cancel').on('click', () => {
        dialog.close();
    });
}

const crossSellView = {
    
    /**
     * Returns Promise which will be resolved with true value if popup shown, and false otherwise.
     * @param {jQuery} $pdpMain PDP container to check if cross-sell recommendations present
     * @param {Array} addedItems Array of added product line items
     * @returns Promise
     */
    load: function($pdpMain, addedItems) {
        
        var next;   // function to pass control further
        var promise = new TPromise((resolve) => {
            // store reference to callback, to call out of this function
            next = resolve;
        });
        // we have to show cross-sell popup only on PDP where these recommendations present
        var recommendations = $pdpMain.find('.js-product-recommendations');
        addedItems = addedItems || [];
        if (!recommendations.length ||
            !addedItems.length) {
            next(false);
        } else {
            ajax.load({
                url: Urls.crossSell,
                method: 'POST',
                data: JSON.stringify(addedItems),
                contentType: 'application/json'
            }).then((response) => {
                dialog.open({
                    target: '#cross-sell-dialog',
                    html: response,
                    options: {
                        title: Resources.CROSS_SELL_TITLE,
                        dialogClass: 'cross-sell-dialog',
                        width: 'auto'
                    }
                });
                initDialog($('#cross-sell-dialog'));
                next(true);
            });
        }
        
        return promise;
    }
};

module.exports = crossSellView;
