import Carousel from '_core_ext/components/global/carousel';
import 'jquery-touchswipe';

export default class ProductImageCarousel extends Carousel {
    static get selector() {
        return '.js-product-images-carousel';
    }
    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this._initArrowsEvents();
    }
    selectPrevious(event, element) {
        event.preventDefault();
        var $productImageContainer = element.closest('.js-product-image-container');
        var $selectedElement = $productImageContainer.find('.thumb.selected');
        if ($selectedElement.prev('li').length) {
            $selectedElement.prev('li').find('.productthumbnail').trigger('click');
        } else {
            $productImageContainer.find('.thumb').last().find('.productthumbnail').trigger('click');
        }
    }
    selectNext(event, element) {
        event.preventDefault();
        var $productImageContainer = element.closest('.js-product-image-container');
        var $selectedElement = $productImageContainer.find('.thumb.selected');
        if ($selectedElement.next('li').length) {
            $selectedElement.next('li').find('.productthumbnail').trigger('click');
        } else {
            $productImageContainer.find('.thumb').first().find('.productthumbnail').trigger('click');
        }
    }
    _initArrowsEvents() {
        $('.product-primary-image').swipe({
            swipeLeft: (event, direction, distance, duration, fingerCount) => {
                this.selectNext(event, $(event.target));
            },
            swipeRight: (event, direction, distance, duration, fingerCount) => {
                this.selectPrevious(event, $(event.target));
            },
            allowPageScroll: 'auto'
        });
        
        this.event('click', '.jcarousel-prev', (element, event) => {
            this.selectPrevious(event, $(element));
        });

        this.event('click', '.jcarousel-next', (element, event) => {
            this.selectNext(event, $(element));
        });
    }
}

module.exports = ProductImageCarousel;
