import Component from '_core_ext/components/Component';

/**
 * Global component listening for when predictive recommendation slots loaded (as they loaded asynchronously)
 * and initializes product carousel and tiles, as this shared functionality between different slot types.  
 */
export default class PredictiveRecommendations extends Component {
    static get selector() {
        return 'body';
    }

    init(...args) {
        //call parent init method with same args
        super.init(...args);
        this._initEvents();
    }

    _initEvents() {
        $(document).on('recommendations.initPredictiveRecommendations', (event, slotID) => {
            this._initPredictiveRecomms(slotID);
        });
    }

    // init Predictive recommendations slot injected asynchronously
    _initPredictiveRecomms(slotID) {
        const $recommendSlot = this.$el.find(`[data-slot-id="${slotID}"]`);

        if ($recommendSlot.find('.js-component-product-tile').length) {
            // init carousel and tiles if there are any
            app.components.initComponent('ProductSlickCarousel', $recommendSlot);
            app.components.initComponent('ProductCarousel', $recommendSlot);
            app.components.initComponent('ProductTile', $recommendSlot);
        }
    }
}

module.exports = PredictiveRecommendations;
