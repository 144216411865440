var ajax = require('_core_ext/ajax'),
    page = require('_core_ext/page'),
    util = require('_core_ext/util'),
    dialog = require('_core_ext/dialog');

const eventMgr = require('_core_ext/eventMgr');
const Promise = require('promise');

var $cache = {},
    billingAddressCmp;  // instance of BillingAddress component

function initializeCache() {
    $cache.document = $(document);
    $cache.orderSummaryContainer = $cache.document.find('.js-summary');
    $cache.orderLineItemsContainer = $cache.document.find('.js-payment-line-items');
    $cache.orderPaymentFormContainer = $cache.document.find('.js-paymentform-container');
    $cache.orderSummaryMobile = $cache.document.find('.payment-mobile-accordion');
    $cache.giftWrap = $cache.document.find('.gift-wrap');
    $cache.loyaltyGiftDialog = $cache.document.find('.loyalty-gift-dialog');
    $cache.paymentError = $cache.document.find('.js-payment-error');
}

function _updateContainer($container, isUpdatePaymentTotal) {
    return new Promise((res, rej) => {
        ajax.load({
            url: $container.data('url'),
            callback: function(response, textStatus) {
                if (response) {
                    $container.html(response);
                    if (isUpdatePaymentTotal) {
                        var totalValue = $container.find('.js-ordertotals-order-value').html();
                        _updatePaymentTotal(totalValue);
                    }
                    res(response);
                } else {
                    rej(textStatus);
                }
            }
        });
    });
}

function _updatePaymentTotal(totalValue) {
    if (totalValue) {
        $cache.orderPaymentFormContainer.find('.js-paymentform-order-value').html(totalValue);
    }
}

function refreshPayment() {
    page.redirect(Urls.payment);
}

function initializeError() {
    if ($cache.paymentError.length) {
        dialog.open({
            html: $cache.paymentError.children().first().html(),
            options: {
                dialogClass: 'b-checkout_dialog m-error'
            }
        });
    }
}

function initializeEvents() {
    $('.form-row-loyalty').addClass('hide');

    $('.form-fieldset-loyalty').find('input[type="radio"]').on('click', function () {
        if ($(this).attr('id') === 'is-UPDATE_CARD') {
            $('.form-row-loyalty').removeClass('hide');
        } else {
            $('.form-row-loyalty').addClass('hide');
        }
    });

    $cache.document.on('click', '.js-close-payment-error', function() {
        dialog.close();
        util.scrollBrowser($cache.orderPaymentFormContainer.offset().top, 500);
    });

    if ($cache.orderLineItemsContainer.length) {
        eventMgr.registerAction('checkout.updateLineItems', function() {
            return _updateContainer($cache.orderLineItemsContainer);
        });
    }
    eventMgr.registerAction('checkout.updateSummary', function() {
        if ($cache.orderSummaryContainer.length) {
            return _updateContainer($cache.orderSummaryContainer, true);
        }
    });
    if ($cache.giftWrap.length) {
        $cache.orderSummaryMobile.toggleClass('expanded');
    }

    // store link to active BillingAddress component
    eventMgr.on('BillingAddress.attached', (cmpInstance) => {
        billingAddressCmp = cmpInstance;
    });

    // this fired only upon user-initiated address selection change
    eventMgr.on('BillingAddress.addressSelected', () => {
        billingAddressCmp.submitForm();
    });

    // refresh page when GiftCard redeemed/removed
    eventMgr.on('GiftCardsBlock.redeemed', refreshPayment);
    eventMgr.on('GiftCardsBlock.removed', refreshPayment);

    // refresh page when Loyalty points redeemed/removed
    eventMgr.on('LoyaltyBlock.redeemed', refreshPayment);
    eventMgr.on('LoyaltyBlock.removed', refreshPayment);
}

exports.init = function () {
    initializeCache();
    initializeEvents();
    initializeError();
};
