import Component from '_core_ext/components/Component';
import dialog from '_core_ext/dialog';

export default class ProductPromotions extends Component {

    static get selector() {
        return '.js-product-promotions';
    }

    get configDefault() {
        return {
            dialogClass: 'promotion-dialog side_dialog',
            draggable: false
        };
    }

    init(...args) {
        super.init(...args);

        this.initEvents();
    }
    
    initEvents() {
        this.event('click', '.js-open', this.onViewClick.bind(this));
    }

    onViewClick(el, ev) {
        ev.preventDefault();

        const isDialogOpened = dialog.isOpen();
        let prevDialog;
        if (isDialogOpened) {
            prevDialog = dialog.getCurrent();
        }

        dialog.open({
            html: this.$el.find('.js-content').html(),
            options: {
                dialogClass: this.config.dialogClass,
                title: this.config.popupTitle,
                draggable: this.config.draggable,
                close: function () {
                    if (isDialogOpened) {
                        dialog.restore(prevDialog);
                    }
                },
                open: function () {
                    app.components.initComponent('Expandable');
                }
            }
        });
    }
}

module.exports = ProductPromotions;
