'use strict';

var ajax = require('_core_ext/ajax'),
    scrollProductTile = require('_core_ext/components/scrollprodtiles'),
    util = require('_core_ext/util');

/**
 * @private
 * @function
 * @description Binds the click events to the remove-link and quick-view button
 */
function initializeEvents() {
    $('.remove-link').on('click', function (e) {
        e.preventDefault();
        
        var pidValue = $(this).attr('data-pid');
        var catValue = $(this).attr('data-cat');
        
        var url = util.appendParamsToUrl(Urls.compareRemove, {pid: pidValue, category: catValue});

        ajax.load({
            url: url,
            callback: function (response, textStatus, responseText) {
                var data = JSON.parse(response);
                if (data.success) {
                    var redirectUrl = util.appendParamsToUrl(Urls.compareShow, {category: catValue});
                    window.location.assign(redirectUrl);
                }
            }
        });
    });

    $('#compare-category-list').on('change', function () {
        $(this).closest('form').submit();
    });
}

exports.init = function () {
    scrollProductTile.init();
    initializeEvents();
};
