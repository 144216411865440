import OrderAddressAbstract from '_core_ext/components/address/OrderAddressAbstract';
import dialog from '_core_ext/dialog';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('DialogAddress');

export default class DialogAddress extends OrderAddressAbstract {
    static get selector() {
        return '.js-component-dialog-address';
    }

    /*
     * used for re-initialization of required (current) component
     */
    getComponentName() {
        return 'DialogAddress';
    }

    init(...args) {
        super.init(...args);
    }

    _initCache(...args) {
        super._initCache(...args);
        this.addressComponent = this.$el.data('address-component');
    }

    _initEvents(...args) {
        super._initEvents(...args);
        this.event('click', '.js-cancel-order-address', (element, event) => {
            event.preventDefault();
            dialog.close();
        });

        this.event('click', '.js-save-order-address', (element, event) => {
            event.preventDefault();
            var $this = $(element),
                $currentForm = $this.closest('form');

            if ($currentForm.valid()) {
                dialog.submit($this.attr('name')).done((response) => {
                    dialog.close();
                    emitter.emit('submit', {addressComponent: this.addressComponent, response: response});
                });
            }
        });
    }

    /**
    * @override 
    */
    beforeFieldChanged(elem, event) {
        emitter.emit('beforeUpdate', elem, event, {addressComponent: this.addressComponent});
    }

    updateAddressSelect() {
        if (pageContext.checkoutPage === 'payment') {
            window.location.assign(Urls.payment);
        }
    }
}

module.exports = DialogAddress;
