var formPrepare = require('_core_ext/pages/checkout/formPrepare'),
    dialog = require('_core_ext/dialog'),
    util = require('_core_ext/util'),
    validatorModule = require('_core_ext/components/global/validator');

/**
 * @function
 * @description Initializes gift message box for multiship shipping, the message box starts off as hidden and this will display it if the radio button is checked to yes, also added event handler to listen for when a radio button is pressed to display the message box
 */
function initMultiGiftMessageBox() {
    $.each($('.item-list'), function () {
        var $this = $(this);
        var $giftMessage = $this.find('.gift-message-text');

        //handle initial load
        $giftMessage.toggleClass('hidden', $('input[name$="_isGift"]:checked', this).val() !== 'true');

        //set event listeners
        $this.on('change', function () {
            $giftMessage.toggleClass('hidden', $('input[name$="_isGift"]:checked', this).val() !== 'true');
        });
    });
}


/**
 * @function
 * @description capture add edit address form events
 */
function addEditAddress(target) {
    // form in add/edit dialog
    var $addressForm = $('form[name$="multishipping_editAddress"]'),
        validator = validatorModule.initForm($addressForm),
        $addressDropdown = $addressForm.find('select[name$=_addressList]'),
        $addressList = $addressForm.find('.address-list'),
        selectedAddressUUID = '';

    $addressDropdown.on('change', function (e) {
        e.preventDefault();
        var selectedAddress = $addressList.find('select').val();
        var add = selectedAddress === 'newAddress';
        if (add) {
            //reset the form if the value of the option is not a UUID
            $addressForm.get(0).reset();
            validator.resetForm();
        } else {
            selectedAddress = $.grep($addressList.data('addresses'), function (add) {
                return add.UUID === selectedAddress;
            })[0];
            // proceed to fill the form with the selected address
            util.fillAddressFields(selectedAddress, $addressForm);
            $addressForm.valid();
        }
    });

    $addressForm.on('click', '.cancel', function (e) {
        e.preventDefault();
        dialog.close();
    });

    $addressForm.on('submit', function (e) {
        e.preventDefault();

        if (!$addressForm.valid()) {
            return;
        }

        $.getJSON(Urls.addEditAddress, $addressForm.serialize())
            .done(function(response) {
                if (!response.success) {
                    // @TODO: figure out a way to handle error on the form
                    return;
                }
                var address = response.address,
                    $shippingAddress = $(target).closest('.js-item-shipping-address'),
                    $otherShippingAddress = $('.js-item-shipping-address').not($shippingAddress).find('.js-multi-select-address'),
                    $select = $shippingAddress.find('.js-multi-select-address'),
                    newOption = '<option value="' + address.UUID + '"' + 'data-county="' + (address.stateCode || '') + '">' +
                        (address.address1 ? address.address1 : '') +
                        (address.city ? ', ' + address.city : '') +
                        (address.stateName ? (', ' + address.stateName) : '') +
                        (address.countryName ? ', ' + address.countryName : '') +
                    '</option>';

                dialog.close();

                var $option = $select.find('option[value="' + address.UUID + '"]');
                //check, what option at target exists
                if ($option.length) {
                    //but we need to update all options related to updated address
                    $('.js-multi-select-address option[value="' + address.UUID + '"]').replaceWith(newOption);
                } else {
                    $otherShippingAddress.removeClass('no-option').append(newOption);
                    $select.removeClass('no-option').append(newOption);
                    $('.no-address').hide();
                }

                // select newly created/updated option
                $select.find('option[value="' + address.UUID + '"]').prop('selected', 'selected').trigger('change');
                // this is to clear previous validation error
                $select.valid();
            })
            .fail(function() {
                /*
                 * @ToDo
                 * here will be handling of server side errorrs
                 * if it will be need
                 */
            });
    });

    // if 'edit' clicked - get id of selected address and preselect same address in add/edit form,
    // otherwise - preselect first empty option to add new
    if ($(target).data('action') === 'edit') {
        selectedAddressUUID = $(target).parents('.js-item-shipping-address').find('.js-multi-select-address').val();
    } else {
        selectedAddressUUID = '';
    }
    //preserve the uuid of the option for the pop-up form
    if (selectedAddressUUID) {
        //update the form with selected address
        $addressList.find('option').each(function () {
            //check the values of the options
            if ($(this).attr('value') === selectedAddressUUID) {
                $(this).prop('selected', 'selected');
                $addressDropdown.trigger('change');
            }
        });
    }
}

/**
 * @function
 * @description Initializes edit bag term box
 */
function editBagBox() {
    var $addressSelect = $('.js-multi-select-address'),
        $ageBox = $('.js-input-confirmTerms'),
        $ageBoxChecked = $('.js-input-confirmTerms:checked'),
        $continueBtn = $('[name$="_multishipping_addressSelection_save"]'),
        $emptyOptions = $addressSelect.filter(function(index, el){
            var $el = $(el);
            return !$el.val() || $el.val().length === 0;
        });
    if ($ageBox.length) {
        if ($emptyOptions.length === 0 && $ageBox.length === $ageBoxChecked.length) {
            $continueBtn.toggleClass('disabled', false);
        } else {
            $continueBtn.toggleClass('disabled', true);
        }
        $addressSelect.one('change', editBagBox);
        $ageBox.one('change', editBagBox);
    }
}

/**
 * @function
 * @description shows gift message box in multiship, and if the page is the multi shipping address page it will call initmultishipshipaddress() to initialize the form
 */
exports.init = function () {
    initMultiGiftMessageBox();
    editBagBox();

    // if on shipping methods page (second step)
    if ($('form[id$="multishipping_shippingOptions"]').length > 0) {
        formPrepare.init({
            continueSelector: '[name$="shippingOptions_save"]',
            formSelector: 'form[id$="multishipping_shippingOptions"]',
            disableContinue: true
        });
    }

    // addresses form
    var $multiShippingForm = $('.js-checkout-multi-shipping-form');

    // address dropdown (on page)
    if ($multiShippingForm.find('.js-multi-select-address').length > 0) {
        formPrepare.init({
            continueSelector: '[name$="addressSelection_save"]',
            formSelector: '[id$="multishipping_addressSelection"]'
        });
    }

    // add/edit button
    $multiShippingForm.on('click', '.js-multi-edit-address', function (e) {
        e.preventDefault();
        var $el = $(e.target),
            dialogTitle = $el.data('dialogTitle');
        var dialogsCustomCSSClass = 'add-edit-address-dialog b-checkout_dialog  js-add-edit-address-dialog theme-' + (SitePreferences.CHECKOUT_THEME || 'default');
        dialog.open({
            url: this.href,
            options: {
                title: dialogTitle,
                dialogClass: dialogsCustomCSSClass,
                open: function () {
                    var $container = $('.js-add-edit-address-dialog');
                    addEditAddress(e.target);
                    app.components.initComponent('ShippingAddress', $container);
                }
            }
        });
        return false;
    });

    if (util.isMobile()) {
        $multiShippingForm.on('change', '.js-multi-select-address', function () {
            $(this).valid();
        });
    }

    $('.js-input-confirmTerms:checked').closest('.js-terms-block').addClass('checked');
    $multiShippingForm.on('change', '.js-input-confirmTerms', function () {
        $(this).closest('.js-terms-block').toggleClass('checked', $(this).is(':checked'));
    });
};
