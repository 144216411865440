import Component from '_core_ext/components/Component';

export default class RefinementsRibbon extends Component {
    static get selector() {
        return '.js-refinements-ribbon';
    }

    init(...args) {
        super.init(...args);
        var el = this.$el[0];
        if (el.scrollWidth > el.clientWidth) {
            this.$el.addClass('scrollable at-begin');
        }
        this.initEvents();
    }

    initEvents() {
        this.$el.on('scroll', this.scrollRibbon.bind(this));
        this.event('click', '.js-show-more', this.showMore.bind(this));
    }

    scrollRibbon() {
        var el = this.$el[0];
        if (el.scrollLeft > 0) {
            this.$el.removeClass('at-begin');
        } else if (el.scrollLeft === 0) {
            this.$el.addClass('at-begin');
        }
        // el.scrollLeft can be decimal and so result could vary +/- 1px 
        if (Math.abs(el.scrollWidth - el.scrollLeft - el.clientWidth) <= 1) {
            this.$el.addClass('at-end');
        } else {
            this.$el.removeClass('at-end');
        }
    }

    showMore() {
        this.$el.addClass('show-all');
    }
}

module.exports = RefinementsRibbon;
