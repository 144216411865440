import OrderAddressAbstract from '_core_ext/components/address/OrderAddressAbstract';
import eventMgr from '_core_ext/eventMgr';
import util from '_core_ext/util';

const emitter = eventMgr.getEmitter('BillingAddress');

export default class BillingAddress extends OrderAddressAbstract {
    static get selector() {
        return '#dwfrm_payment';
    }

    getAddressFormContainer() {
        return $('#billing-address-form-container');
    }

    /*
     * used for re-initialization of required (current) component
     */
    getComponentName() {
        return 'BillingAddress';
    }
    
    init(...args) {
        // attach itself to parent page
        emitter.emit('attached', this);
        
        super.init(...args);

        this.updateMiniAddress(this.addressData);
    }

    _initCache(...args) {
        super._initCache(...args);

        this.$addressMiniCont = this.$el.find('.js-address-mini-cont');
        this.$billingAddress = this.$el.find('.js-billing-address');
        this.$useShippingAddress = this.$el.find('.js-input-useShippingAddress');
        this.addressData = this.$el.find('.js-billing-address-data').data('address');
    }
    
    _initEvents(...args) {
        super._initEvents(...args);

        // Billing form can be rendered inside of payment form but with it's own Submit action, which have to validate only address, 
        // and submission must not be interrupted by surrounding form incompleteness.
        // So to achieve this we've marked own submit with 'formnovalidate' attribute (see billingaddress.isml) to not trigger validation on entire surrounding form,
        // and perform only address validity check programmatically.
        this.event('click', '.js-save-order-address', (elem, event) => {
            if (!this.formValidator.form()) {
                event.preventDefault();
                return false;
            }
        });

        this.event('click', '.js-edit-billing-address', (element, e) => {
            e.preventDefault();
            this.$useShippingAddress.prop('checked', false);
            this.$addressMiniCont.addClass('hidden');
            this.$billingAddress.removeClass('hidden');
        });

        this.event('change', '.js-input-useShippingAddress', (element) => {
            var $element = $(element);

            if ($element.is(':checked')) {
                this.$addressMiniCont.removeClass('hidden');
                this.$billingAddress.addClass('hidden');

                this.updateMiniAddress(this.addressData);
                util.fillAddressFields(this.addressData, this.$el);
            }
            
        });
    }
    
    /**
     * @override
     */
    onFieldChanged(elem) {
        emitter.emit('updated', elem);
    }

    /**
     * @override
     */
    onAddressSelectChanged(element, event) {
        // check if change is actually user-initiated 
        if (event.originalEvent) {
            emitter.emit('addressSelected');
        }
    }

    /**
     * @public
     * Check if address is valid 'softly' - without flagging actual errors.  
     */
    isValid() {
        return this.formValidator.checkForm();
    }

    /**
     * @public
     * Trigger submit of the address fields form (if Submit button present).
     * This is used to programmatically submit billing address upon address selector change on Payment page. I'm not sure if this solution will live long.
     */
    submitForm() {
        this.$el.find('.js-save-order-address').click();
    }

    /**
     * Override method to not show phone number in Billing miniaddress
     * @param {Object} address 
     */
    updateMiniAddress(address) {
        super.updateMiniAddress($.extend({}, address, {phone: null}));
    }
}

module.exports = BillingAddress;
