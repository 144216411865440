import {log as logger} from '_core_ext/util';
import EventEmitter from 'events';
import Promise from 'promise';

var emitter = new EventEmitter(),
    emitters = [],
    actions = {};

emitter.setMaxListeners(3000);

const eventMgr = {
    getEmitter: (namespace) => {
        if (emitters.indexOf(namespace) !== -1) {
            logger.log('EventMgr: Given namespace "' + namespace +
                '" already exists. Event emitter creation failed.');
            return {
                emit: () => false
            };
        }
        emitters.push(namespace);
        return {
            emit: function (eventName) {
                try {
                    arguments[0] = namespace + '.' + eventName;
                    /*eslint prefer-spread: "off"*/
                    return emitter.emit.apply(emitter, arguments);
                } catch (e) {
                    logger.error(e);
                }
            }
        };
    },
    on: (eventName, cb) => emitter.on(eventName, cb),
    off: (eventName, cb) => emitter.removeListener(eventName, cb),
    once: (eventName, cb) => emitter.once(eventName, cb),
    execute: (actionName, params) => {
        if (actions[actionName]) {
            /*eslint arrow-body-style: ["off"]*/
            return actions[actionName](params).then((data) => {
                return data;
            }, (reason) => {
                return new Promise((res, rej) => {
                    rej(reason);
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                reject(new Error(`Action is not found for "${actionName}"`));
            });
        }
    },
    registerAction: (actionName, handler) => {
        if (actions[actionName]) {
            logger.warn(`Overriding "${actionName}"!`);
        }
        actions[actionName] = handler;
    }
};
// make it global
window.eventMgr = eventMgr;

module.exports = eventMgr;
