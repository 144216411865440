import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

export default class BeautyIngredientsGlossary extends Component {
    static get selector() {
        return '.js-beauty-ingredients-glossary';
    }

    get configDataAttrName() {
        return 'component-beauty-ingredients-glossary';
    }

    init(...args) {
        super.init(args);

        var $window = $(window),
            $filteringComponent = $('.js-beauty-ingredients-glossary-filtering'),
            enabledLetters = $('input[name="enabled-letters"]').val().split(','),
            $activeLetter = $('#js-beauty-ingredients-glossary-active-letter').text(enabledLetters[0]),
            locationHash = location.hash;

        $filteringComponent.find('a').each(function() {
            var $letter = $(this),
                letterText = $letter.text();
                
            if (enabledLetters.includes(letterText)) {
                $letter.removeClass('disabled');
            }

            if (enabledLetters[0] === letterText) {
                $letter.toggleClass('active', true);
            }
        });
        $filteringComponent.data('active-letter', enabledLetters[0]);
        $('[data-show-on-letter=' + enabledLetters[0] + ']').show();

        if (locationHash) {
            this.navigateToHash(locationHash, enabledLetters[0], $filteringComponent, $activeLetter);

            if (locationHash.length > 2 && history.state && history.state.expanded) {
                $('a[href="' + decodeURIComponent(locationHash) + '"]').closest('.js-beauty_ingredients-item').find('.js-expandable').addClass('expanded');
                history.replaceState(null, '');
            }
        }

        $window.on('hashchange', () => {
            this.navigateToHash(location.hash, enabledLetters[0], $filteringComponent, $activeLetter);
        });

        this.event('click', '.js-beauty-ingredients-glossary-filtering', (el, event) => {
            var $el = $(el),
                $target = $(event.target);

            if (!$target.is('a')) {
                return;
            }

            this.filterIngredients($el, $target, $activeLetter);
        });

        this.event('click', '.js-beauty_ingredients-cta', (el, event) => {
            var $el = $(el),
                $bi = $el.closest('.js-beauty_ingredients-item');

            event.preventDefault();
            history.replaceState({ 
                expanded: $bi.find('.js-expandable').hasClass('expanded') 
            }, '', $bi.find('a[href^="#"]').attr('href'));
            location.assign(el.href);
        });
    }

    filterIngredients($el, $target, $activeLetter) {
        var currentActiveLetter = $el.data('active-letter'),
            newActiveLetter = $target.text();

        if (!$target.hasClass('disabled')) {
            $target.siblings('a:contains(' + currentActiveLetter + ')').toggleClass('active', false);
            $target.toggleClass('active', true);
            $('[data-show-on-letter=' + currentActiveLetter + ']').hide();
            $('[data-show-on-letter=' + newActiveLetter + ']').show();
            $el.data('active-letter', newActiveLetter);
            $activeLetter.text(newActiveLetter);
            return newActiveLetter;
        }
        
        return false;
    }

    navigateToHash(locationHash, firstAvailableLetter, $el, $activeLetter) {
        const self = this;
        $el.find('a[href=#' + locationHash[1].toUpperCase() + ']').each(function() {
            const newActiveLetter = self.filterIngredients($el, $(this), $activeLetter);
            if (!newActiveLetter) {
                location.replace('#' + firstAvailableLetter);
            }
        });
        // navigate to the particular ingredient
        if (locationHash.length > 2) {
            eventMgr.execute('stickyHeader.getHeight').then(function (stickyHeaderHeight) {
                $('html, body').animate({ 
                    scrollTop: $('a[href="' + decodeURIComponent(locationHash) + '"]').offset().top - (stickyHeaderHeight || 255)
                });
            });
        }
    }
}

module.exports = BeautyIngredientsGlossary;
