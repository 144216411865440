import Component from '_core_ext/components/Component';

export default class CustomerMigration extends Component {
    static get selector() {
        return '.js-cmp-customer-migration';
    }

    get configDataAttrName() {
        return 'customer-migration';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this._initCache()
        this._initEvents();
    }

    /**
     * placeholder, for case, if it will be extended
     */
    _initCache() {
        this.$loginForm = this.$el.find('.js-login-form');
        this.$loginFormSubmit = this.$loginForm.find(':submit');
    }

    _initEvents() {}

    getFormLoginValue() {
        return this.$loginForm.find('input:text.email').val() || this.$loginForm.find('input:text.username').val();
    }
}

module.exports = CustomerMigration;
