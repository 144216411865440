import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('TimeslotSelector');

export default class TimeslotSelector extends Component {
    static get selector() {
        return '.js-timeslot-selector:not(.hidden)';
    }

    get configDefault() {
        return {};
    }

    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.initCache();
        this.initEvents();

        this.$el.find('.js-dateslot-carousel').addClass('js-component-slick');
        app.components.initComponent('TimeslotSlick');

        // show selection info if loaded page with saved timeslot
        if (this.$shippingMethodList.find('.checkout_timeslot_selected').length) {
            $('.js-selected_banner').removeClass('hidden');
        }
    }

    initCache() {
        this.$shippingMethodList = $('#shipping-method-list');
    }

    initEvents() {
        // check time slots for selected date and disable unavailable
        this.event('change', '[name="date-slot"]', (element) => {
            var $dateSlot = $(element);
            var date = $dateSlot.val();
            this.setTimeSlotsAvailability(date);
        });

        // time slot selects used for data retrieve
        this.event('change', '[name="date-time-slot"]', (element) => {
            var time = $(element).val();
            var date = this.$el.find('[name="date-slot"]').val();
            var shippingMethodID = this.$el.data('method-id');
            emitter.emit('slotSelected', [shippingMethodID, time, date]);
        });
        this.event('change', '[name="time-slot"]', (element) => {
            var time = $(element).val();
            var date = new Date().toISOString().slice(0, 10);
            var shippingMethodID = this.$el.data('method-id');
            emitter.emit('slotSelected', [shippingMethodID, time, date]);
        });

        /**
         * click on date block in carousel
         * clear previous selected date and time, add indicator class for date block and parent carousel block (to show timeslots)
         * sync with select
         */
        this.event('click', '.js-checkout_dateslot:not(.slot-date-unavailable):not(.checkout_dateslot_selected)', (element, event) => {
            event.stopPropagation()
            $('.checkout_dateslot_selected').removeClass('checkout_dateslot_selected');
            $('.checkout_timeslot_selected').removeClass('checkout_timeslot_selected');
            $(element).closest('.checkout_dateslot').addClass('checkout_dateslot_selected');
            $(element).closest('.js-dateslot-carousel').addClass('carousel_dateslot_selected');
            var dateValue = $(element).data('date');
            $('select[name="date-slot"]').val(dateValue).trigger('change');
        });
        /**
         * click on time block
         * clear previous selected time, add indicator class for time block
         * check for shipping method and sync with select
         */
        this.event('click', '.checkout_timeslot:not(.slot-time-unavailable):not(.checkout_timeslot_selected)', (element, event) => {
            event.stopPropagation()
            $('.checkout_timeslot_selected').removeClass('checkout_timeslot_selected');
            $(element).closest('.checkout_timeslot').addClass('checkout_timeslot_selected');
            var timeValue = $(element).data('time');
            if ($(element).closest('.sdd-method').length) {
                $('select[name="time-slot"]').val(timeValue).trigger('change');
            } else if ($(element).closest('.ndd-method').length) {
                $('select[name="date-time-slot"]').val(timeValue).trigger('change');
            }
        });
    }

    setTimeSlotsAvailability(date) {
        var slotsAvailability = this.$el.find('[name="date-slot"]').data('slot-availability')[date];
        var $selectorTimeSlots = this.$el.find('[name="date-time-slot"]');
        $selectorTimeSlots.val(''); // clear selection
        $selectorTimeSlots.find('option:not([value=""])').each(function () {
            $(this).attr('disabled', !slotsAvailability[$(this).val()])
        })
        var $carouselTimeSlots = this.$el.find('.checkout_timeslot');
        $carouselTimeSlots.each(function () {
            $(this).removeClass('slot-time-unavailable');
            if (!slotsAvailability[$(this).data('time')]) {
                $(this).addClass('slot-time-unavailable');
            }
        })
    }
}

module.exports = TimeslotSelector;
