var validatePostalCode = require('./postalValidation.js');

module.exports = function (value, el) {
    var $el = $(el),
        
        // lookup country in common fieldset and fallback to form if no such
        $form = $el.closest('fieldset');
    if (!$form.length) {
        $form = $el.closest('form');
    }

    var $countryField = $form.find('.js-input-country'),
        
        postal = $el.val().toUpperCase(),
        country = $countryField.val(),
        
        isValid = false,
        isOptional = this.optional(el);
    
    if (!value && isOptional) {
        isValid = true;
    } else {
        isValid = validatePostalCode(country, $.trim(postal));
    }

    if (!isValid) {
        $.validator.messages['js-input-postal'] = (country === 'IE' ? Resources.INVALID_EIRCODE : Resources.INVALID_POSTAL);
    }
    return isValid;
};
