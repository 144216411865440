/**
 * Gift Wrap Options popup
 */
import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const CSRF_TOKEN_SELECTOR = '.js-csrf-token';
const PLI_ROW_SELECTOR = '.js-product-line-item';
const GIFT_OPTION_SELECTOR = '.js-gift-option';
const GIFT_OPTION_MESSAGE_SELECTOR = '.js-gift-msg';

const GW_IMAGE_DEFAULT_SELECTOR = '.js-gift-wrap-img-default';
const GW_IMAGE_CHOSEN_SELECTOR = '.js-gift-wrap-img-chosen';
const GW_TEXT_IMAGE_SELECTOR = '.js-gift-wrap-text-img';
const GW_NOTEXT_IMAGE_SELECTOR = '.js-gift-wrap-notext-img';

const ACTIVE_CLASSNAME = 'active';

const emitter = eventMgr.getEmitter('GiftWrapOptions');

export default class GiftWrapOptions extends Component {
    static get selector() {
        return '.js-gift-wrap-options';
    }

    init(...args) {
        super.init(...args);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.giftOptionChanged = this.giftOptionChanged.bind(this);
        this.giftMessageChanged = this.giftMessageChanged.bind(this);

        this.initEvents();
        this.buildGiftWrapCache();
    }

    initEvents() {
        //Handle submit
        this.$el.find('.js-confirm-gift-options').on('click', this.handleSubmit);

        this.$el.find(GIFT_OPTION_SELECTOR).on('change', this.giftOptionChanged);
        this.$el.find(GIFT_OPTION_MESSAGE_SELECTOR).on('change', this.giftMessageChanged);
    }

    handleSubmit() {
        $.ajax({
            url: this.config.handleUrl,
            method: 'POST',
            data: this.buildData(),
            success: function (response) {
                if (response.error) {
                    alert(response.error);
                }

                emitter.emit('refresh');
            }
        });
    }

    //Builds data object with gift wrap data and csrf
    buildData() {
        const $csrfField = this.$el.find(CSRF_TOKEN_SELECTOR);
        const $selectFields = this.$el.find(GIFT_OPTION_SELECTOR);
        const giftWrapData = [];

        $selectFields.each(function() {
            const $this = $(this);
            const $textArea = $this.closest(PLI_ROW_SELECTOR).find(GIFT_OPTION_MESSAGE_SELECTOR);
            const obj = {
                productId: $this.data('productId'),
                pliUuid: $this.data('pliUuid'),
                isImplicitGiftMsg: $this.data('isImplicitGiftMsg'),
                giftWrapId: $this.val(),
                giftWrapMsg: $textArea.val()
            };

            giftWrapData.push(obj);
        });

        const returnObj = {
            format: 'ajax',
            giftWrapData: JSON.stringify(giftWrapData)
        };
        
        returnObj[$csrfField.attr('name')] = $csrfField.val();

        return returnObj;
    }

    //Sets chosen gift wrap option image and show it or show the default icon
    handleGiftWrapImage($select) {
        const $jsItem = $select.closest(PLI_ROW_SELECTOR);
        const imgSrc = $select.find(':selected').data('img');

        if (imgSrc) {
            $jsItem.find(GW_IMAGE_CHOSEN_SELECTOR).find('.js-gift-wrap-img').attr('src', imgSrc);

            $jsItem.find(GW_IMAGE_DEFAULT_SELECTOR).removeClass(ACTIVE_CLASSNAME);
            $jsItem.find(GW_IMAGE_CHOSEN_SELECTOR).addClass(ACTIVE_CLASSNAME);

            return;
        }

        $jsItem.find(GW_IMAGE_CHOSEN_SELECTOR).removeClass(ACTIVE_CLASSNAME);
        $jsItem.find(GW_IMAGE_DEFAULT_SELECTOR).addClass(ACTIVE_CLASSNAME);
    }

    //Builds a cache which will be used to manipulate the gift wrap options by types
    buildGiftWrapCache() {
        this.giftWrapCache = {};
        this.$pliRows = this.$el.find(PLI_ROW_SELECTOR);

        const giftWrapCache = this.giftWrapCache;

        this.$pliRows.each(function() {
            const $this = $(this);
            const giftWrapId = $this.find(GIFT_OPTION_SELECTOR).val();
            const $giftWrapTxt = $this.find(GIFT_OPTION_MESSAGE_SELECTOR);
            const giftWrapMsg = $giftWrapTxt.val();

            //Used to keep track of previous/new gift wrap value
            $this.data('giftWrapId', giftWrapId);

            //If no gift wrap selected -> disable textarea
            if (!giftWrapId) {
                $giftWrapTxt.prop('disabled', true);
                return;
            }

            //If gift wrap not added to cache add it
            if (!(giftWrapId in giftWrapCache)) {
                giftWrapCache[giftWrapId] = {
                    secondaryIndexes: [],
                    msg: giftWrapMsg,
                    lockedByIndex: $this.index()
                };
                return;
            }

            //If gift wrap type already picked -> disable textarea
            giftWrapCache[giftWrapId].secondaryIndexes.push($this.index());
            $giftWrapTxt.prop('disabled', true);
        });
    }

    giftMessageChanged(e, isManuallyTriggered) {
        const $target = $(e.target);
        this.handleGiftWrapTextImage($target);

        //If changed manually then skip manipulating the cache and updating other textareas
        if (isManuallyTriggered) {
            return;
        }

        const $row = $target.closest(PLI_ROW_SELECTOR);
        const giftWrapId = $row.data('giftWrapId');

        //Empty gift option selection is not processed
        if (!giftWrapId) {
            return;
        }

        const giftWrapCache = this.giftWrapCache;
        const newMsg = $target.val();

        giftWrapCache[giftWrapId].msg = newMsg;
        giftWrapCache[giftWrapId].lockedByIndex = $row.index();

        //Update secondary textareas with the message from the main
        giftWrapCache[giftWrapId].secondaryIndexes.forEach((index) => {
            $(this.$pliRows.get(index))
                .find(GIFT_OPTION_MESSAGE_SELECTOR)
                .val(newMsg)
                .trigger('change', true);
        });
    }

    giftOptionChanged(e) {
        const $target = $(e.target);
        this.handleGiftWrapImage($target);

        const giftWrapCache = this.giftWrapCache;

        const $row = $target.closest(PLI_ROW_SELECTOR);
        const $giftWrapTxt = $row.find(GIFT_OPTION_MESSAGE_SELECTOR);
        //Reset gift wrap message and disable the textarea
        $giftWrapTxt.prop('disabled', true).val('').trigger('change', true);

        const oldGiftWrapId = $row.data('giftWrapId');
        const newGiftWrapId = $target.val();

        //Used to keep track of previous/new gift wrap value
        $row.data('giftWrapId', newGiftWrapId);

        //Remove the index from the old gift option type list
        if (oldGiftWrapId in giftWrapCache) {
            //If not the main textarea -> remove the index from the secondary.
            if (giftWrapCache[oldGiftWrapId].lockedByIndex !== $row.index()) {
                giftWrapCache[oldGiftWrapId].secondaryIndexes = giftWrapCache[oldGiftWrapId].secondaryIndexes.filter((index) => index !== $row.index());
            } else {
                //Sort by number ascending in order to unlock the top position text area
                giftWrapCache[oldGiftWrapId].secondaryIndexes.sort((a, b) => a - b);
                const firstIndexToBeActivated = giftWrapCache[oldGiftWrapId].secondaryIndexes.shift();

                //If we have secondary textarea of the same type -> activate it. Otherwise clean the cache.
                if (typeof firstIndexToBeActivated === 'number') {
                    $(this.$pliRows.get(firstIndexToBeActivated)).find(GIFT_OPTION_MESSAGE_SELECTOR).prop('disabled', false);
                    giftWrapCache[oldGiftWrapId].lockedByIndex = firstIndexToBeActivated;
                } else {
                    delete giftWrapCache[oldGiftWrapId];
                }
            }
        }

        //If no gift option is selected -> keep the textarea disabled
        if (!newGiftWrapId) {
            return;
        }

        //If the gift option type already picked -> set the gift message and keep it disabled
        if (newGiftWrapId in giftWrapCache) {
            giftWrapCache[newGiftWrapId].secondaryIndexes.push($row.index());
            $giftWrapTxt.val(giftWrapCache[newGiftWrapId].msg).trigger('change', true);
        } else {
            //Otherwise -> this is the first input which should be the main and active
            giftWrapCache[newGiftWrapId] = {
                secondaryIndexes: [],
                msg: '',
                lockedByIndex: $row.index()
            };
            $giftWrapTxt.prop('disabled', false);
        }
    }

    //Makes gift wrap text icon active/inactive
    handleGiftWrapTextImage($textarea) {
        const $jsItem = $textarea.closest(PLI_ROW_SELECTOR);

        if ($textarea.val()) {
            $jsItem.find(GW_NOTEXT_IMAGE_SELECTOR).removeClass(ACTIVE_CLASSNAME);
            $jsItem.find(GW_TEXT_IMAGE_SELECTOR).addClass(ACTIVE_CLASSNAME);
            return;
        }

        $jsItem.find(GW_TEXT_IMAGE_SELECTOR).removeClass(ACTIVE_CLASSNAME);
        $jsItem.find(GW_NOTEXT_IMAGE_SELECTOR).addClass(ACTIVE_CLASSNAME);
    }
}

module.exports = GiftWrapOptions;
