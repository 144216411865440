// general extension functions
module.exports = function () {
    //cant be installed via npm
    require('./lib/jquery-ui')();
    require('./lib/jquery/support/orientation')();
    require('./lib/jquery/events/throttledresize')();
    require('./lib/jquery/events/orientationchange')();

    require('jcarousel');
    require('./lib/jcarousel-swipe');
    require('jquery-sticky');
    require('sticky-kit/dist/sticky-kit');
    require('jquery-validation');
    require('jquery-outside-events');
    require('jquery-ui-touch-punch');

    //--- --- --- --- --- --- ---
    require('./lib/modernizr')();
    require('./lib/modernizr.custom')();
    require('./lib/ScrollToFixed')();

    //cant be installed via npm
    require('./lib/Twenty-twenty')();
    require('./lib/EventMove')();

    //removed because causing on performance
    //require('./lib/pinit')();

    require('autofill-event');
    require('lazysizes');
    require('./lib/videojs.disableProgress');
};
