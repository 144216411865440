import Component from '_core_ext/components/Component';
import dialog from '_core_ext/dialog';

export default class EnergyRating extends Component {
    
    static get selector() {
        return '.js-energy-rating';
    }
    
    init(...args) {
        super.init(...args);
        
        this.initEvents();
    }
    
    initEvents() {
        this.event('click', this.onEnergyLabelClick.bind(this));
    }

    onEnergyLabelClick(el, ev) {
        ev.preventDefault();

        var isDialogOpened = dialog.isOpen(),
            energyRatingImage,
            prevDialog;

        if (isDialogOpened) {
            prevDialog = dialog.getCurrent();
        }
        
        energyRatingImage = `
            <div class="energy-rating-wrapper">
                <img src="${this.$el.attr('href')}" class="js-energy-rating-image-large energy-rating-image" />
            </div>`;

        dialog.open({
            html: energyRatingImage,
            options: {
                dialogClass: 'energy-rating-dialog',
                close: function () {
                    if (isDialogOpened) {
                        dialog.restore(prevDialog);
                    }
                }
            }
        });
    }
}

module.exports = EnergyRating;
