import Component from '_core_ext/components/Component';
import util  from '_core_ext/util';
import ajax from '_core_ext/ajax';
import eventMgr  from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('Coupon');

/**
 * Component handling inline coupon code form.
 * Coupon applied via AJAX and 'Coupon.applied' event emitted afterwards.
 */
export default class CouponCode extends Component {
    static get selector() {
        return '.js_couponcode_container';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        super.init(...args);
        this.initCache();
        this.initEvents();
    }

    initCache() {
        this.$addCoupon = this.$el.find('[data-add-coupon]');
        this.$couponCode = this.$el.find('input[name$="couponCode"]');
        this.changeCouponHandler = this.changeCouponHandler.bind(this);
    }

    initEvents() {
        this.event('click', '[data-add-coupon][data-action="apply"]', this.applyCoupon);
        this.event('click', '[data-add-coupon][data-action="edit"]', this.editCoupon);
        this.$couponCode.on('keyup change', this.changeCouponHandler);
        this.$couponCode.on('paste', () => setTimeout(this.changeCouponHandler, 0));   // setTimeout needed to execute check after content actually pasted
        this.$couponCode.on('keydown', (e) => {
            if (e.which === 13) {
                e.preventDefault();
                this.$addCoupon.trigger('click');
            }
        });
    }

    applyCoupon(el, ev) {
        ev.preventDefault();
        var $error = this.$el.find('.js-coupon-error');
        var code = this.$couponCode.val();
        if (code.length === 0) {
            $error.removeClass('hidden');
            $error.html($error.data('msg-required'));
            this.$couponCode.addClass('invalid');
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, (data) => {
            var msg = '';

            if (!data) {
                msg = Resources.BAD_RESPONSE;
            } else if (!data.success) {
                msg = data.message;
            }
            if (!data || !data.success) {
                $error.removeClass('hidden');
                $error.html(msg);
                this.$couponCode.addClass('invalid');
                return;
            }

            if (data.success) {
                emitter.emit('applied');
            }
        });
    }

    editCoupon(el, ev) {
        ev.preventDefault();
        this.$couponCode.prop('disabled', false);
        var $btn = $(ev.currentTarget);
        $btn.text($btn.data('apply-title'));
        $btn.attr('data-action', 'apply');    // switch button handler to "apply"
    }

    changeCouponHandler() {
        if (this.$couponCode.val()) {
            this.$addCoupon.prop('disabled', false);
        } else {
            this.$addCoupon.prop('disabled', true);
        }
    }
}

/**
 * Handles "remove coupon" links as they can be outside of coupon form. Coupon removed via AJAX and 'Coupon.removed' event emitted afterwards.
 * Handler must be attached once on a page, so do it here.
 */
$(document).on('click', '.js-remove-coupon', (e) => {
    e.preventDefault();
    var code = $(e.currentTarget).attr('data-coupon');
    var url = util.appendParamsToUrl(Urls.removeCoupon, {couponCode: code});

    ajax.load({
        url: url,
        callback: (response) => {
            var data = JSON.parse(response);

            if (data.success) {
                emitter.emit('removed');
            }
        }
    });
});

module.exports = CouponCode;
