import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('stickyHeader');

export default class Sticky extends Component {
    static get selector() {
        return '.js-header';
    }
    get configDefault() {
        return {
            topSpacing: 0,
            bottomSpacing: 296,
            className: 'is-sticky',
            wrapperClassName: 'sticky-wrapper',
            center: false,
            getWidthFrom: '',
            widthFromWrapper: true, // works only when .getWidthFrom is empty
            responsiveWidth: true,
            zIndex: 9996
        };
    }
    get configDataAttrName() {
        return 'sticky';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.$html = $('html');
        this.event('sticky-start', () => {
            this.$html.addClass('has-sticky-header');
            emitter.emit('stick');
        });
        this.event('sticky-end', () => {
            this.$html.removeClass('has-sticky-header');
            emitter.emit('unstick');
        });

        this.$el.sticky(this.config);
        eventMgr.on('noScroll.enabled', this.reInit.bind(this));
        eventMgr.on('noScroll.disabled', this.reInit.bind(this));

        // to get sticky header height
        eventMgr.registerAction('stickyHeader.getHeight', () => 
            new Promise((res) => {
                if (this.$el.css('position') === 'fixed') {
                    res(this.$el.outerHeight());
                } else {
                    res(0);
                }
            })
        );
    }
    reInit() {
        this.$el.sticky('update');
    }
    destroy() {
        this.$el.unstick();
    }
}

module.exports = Sticky;
