import 'slick-carousel';

exports.init = function init () {
    $('.js-amp-slider-module').slick({
        dots: false,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        easing: 'swing',
        speed: '650'
    });

    var $slickSlider = $('.js-amp-slider-module');

    $slickSlider.find('.slick-list').append('<div class="slick-shadow-overlay slick-overlay-left"></div>');
    $slickSlider.find('.slick-list').append('<div class="slick-shadow-overlay slick-overlay-right"></div>');

    var $overlayLeft = $slickSlider.find('.slick-overlay-left');
    var $overlayRight = $slickSlider.find('.slick-overlay-right');



    function repositionShadowOverlays(slick, currentSlide) {

        var img_width;
        var caption_width;

        var slickSliderWidth = $slickSlider.width();

        // captions have to fit image's width. Otherwise - they will be in 1 line so much wider
        $slickSlider.find('.slick-slide').each(function() {
            img_width = $(this).find('.amp-caption-panel__img').innerWidth();
            caption_width = $(this).find('.amp-caption-panel__caption').innerWidth();
            if (img_width > slickSliderWidth) {
                img_width = slickSliderWidth; // make sure caption will not be wider than slider width (ot screen width)
            }
            if (img_width !== caption_width) {
                $(this).find('.amp-caption-panel__caption').css({'width' : img_width});
            }
        });

        var currentSlideWidth = slick.$slides[currentSlide].clientWidth;

        var overlayLeftPosX = 0;
        var overlayLeftWidth = 0;
        var overlayRightPosX = slickSliderWidth;
        var overlayRightWidth = 0;

        if (currentSlideWidth < slickSliderWidth) {
            overlayLeftWidth = Math.ceil((slickSliderWidth - currentSlideWidth) / 2);
            overlayRightPosX = overlayLeftWidth + currentSlideWidth;
            overlayRightWidth = slickSliderWidth - overlayRightPosX;
        } // else - show and use zeroed size overlays

        $overlayLeft.css({'left' : overlayLeftPosX, 'width' : overlayLeftWidth});
        $overlayRight.css({'left' : overlayRightPosX, 'width' : overlayRightWidth});
        //slick.$slides[currentSlide].amp-caption-panel__caption

    }


    // On before slide change
    $slickSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        repositionShadowOverlays(slick, nextSlide);
    });

    // On Every time Slick recalculates position (resize and slidechange too (inevitable duplicate...))
    $slickSlider.on('setPosition', function(event, slick) {
        repositionShadowOverlays(slick, slick.currentSlide);
    });

    $('.js-amp-generic-slick-slider').slick({
        dots: true,
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        centerMode: false,
        easing: 'swing',
        speed: '650'
    });
};