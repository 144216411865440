var $document = $(document);

function addFilterToEmailFields($root) {
    $root.on('change keypress', '.js-input-email', function (e) {
        if (event.type === 'change') {
            //spaces are allowed in the e-mail address
            var $this = $(this);
            $this.val($this.val().replace(/\s/g, ''));
        } else if (event.type === 'keypress') {
            //32 - space
            if (e.keyCode === 32) {
                return false;
            }
        }
    });
}

function addAsteriskToRequiredInputs($root) {
    var $inputs = $root.find('form').find('input');
    $inputs.each(function (i, item) {
        var $item = $(item);
        if ($item.hasClass('required') || $item.attr('required') || ($item.attr('aria-required') && $item.attr('aria-required') === 'true')) {
            var placeholder = $item.attr('placeholder');
            if (placeholder && placeholder.slice(-1) !== '*') {
                $item.attr('placeholder', placeholder + ' *');
            }
        }
    });
}
/**
 * @private
 * @function
 * @description Add events
 */
function initializeEvents() {
    addFilterToEmailFields($document);
}

function initializeDom() {
    addAsteriskToRequiredInputs($document);
}

exports.init = function () {
    initializeDom();
    initializeEvents();
};
