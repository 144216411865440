import ProductTile from '_core_ext/components/product/ProductTile';
import util from '_core_ext/util';

export default class ProductLineItem extends ProductTile {
    static get selector() {
        return '.js-component-cart-product-line-item';
    }
    get configDataAttrName() {
        return 'component-cart-product-line-item';
    }

    init() {
        this.source = 'cart';
        this.$tileGridWrapper = this.$el.closest('.js-product-grid');
        this.$tileWrapper = this.$el.closest('.js-product-tile-wrapper');

        //call parent init method with same args
        //super.init(...args);

        this.$document = $(document);
        this.initConfig();

        if (util.isTablet()) {
            this.$el.find('.js-component-cart-product-line-item .item-image img').each((i, el) => {
                var $el = $(el);
                $el.attr('src', $el.data('lgimage'));
                $el.load();
            });
        }


        this.addEvents();
    }
}

module.exports = ProductLineItem;
