/**
 * Script responsible for handling logic specific to ProductSet of type 'skus_only' (containing only SKU items) PDP.
 */

/**
 * Refresh other parts of interface upon quantity selection change
 */
var _refresh = function ($pdpMain) {
    // all quantities
    var $quantities = $pdpMain.find('#product-set-sku-list').find('.js-product-set-sku-item .js-quantity');

    // look for invalid quantity selections
    var invalidQts = $quantities.filter((index, qtyInput) => {
        var $qtyInput = $(qtyInput);
        return (!$qtyInput.valid() || $qtyInput.hasClass('js-quantity-not-available'))
    });

    // check for Non-zero quantities separately
    var nonZeroQts = $quantities.filter((index, qtyInput) => {
        var $qtyInput = $(qtyInput);
        return parseInt($qtyInput.val(), 10) > 0;
    });

    var $addAllToCart = $pdpMain.find('#add-all-skus-to-cart');

    // enable A2C button when there are some quantity selected, disable otherwise (case with invalid quantities handled/described below)
    if (nonZeroQts.length && !$addAllToCart.hasClass('set-disabled')) {
        $addAllToCart.prop('disabled', false);
    } else {
        $addAllToCart.prop('disabled', true);
    }

    // addToCart.js contains handling A2C button and also displaying error
    // here we only notify that error message can be hidden, if qty selections become valid (all zeros also considered as valid - like initial form state)
    if (!invalidQts.length) {
        $addAllToCart.trigger('error.hide');
    }
};

/**
 * Display availability message, after availability fetched (availability.js) upon user selection
 */
function onAvailabilityFetched(ev, response, isBatch) {
    const $qty = $(ev.target);
    var error = '';

    if (!$qty.valid()) {
        // do nothing if selected quantity is not valid - error will be shown by bound validator

    } else if (!response || response.status === 'NOT_AVAILABLE') {
        // edge case - item become unavailable after PDP loaded (otherwise there is no QTY input, but 'Out of stock' message shown instead)
        // or no correct availability response received from server
        $qty.addClass('quantity-not-available js-quantity-not-available');
        error = `<span class="js-quantity-available error-quantity">${Resources.ITEM_STATUS_NOTAVAILABLE}</span>`;
        $qty.closest('.js-sku-details-outer-cont').find('.js-error-place').html(error);

    } else if (response.status === 'ZERO_QTY') {
        // reset item when it set to 0
        $qty.closest('.js-sku-details-inner-cont').removeClass('item-selected');	// uncheck item row
        $qty.removeClass('quantity-not-available js-quantity-not-available');
        $qty.closest('.js-sku-details-outer-cont').find('.js-error-place').empty();

    } else if (response.levels.IN_STOCK > 0 && response.levels.NOT_AVAILABLE > 0) {
        // selected quantity is above stock level
        $qty.addClass('quantity-not-available js-quantity-not-available');
        error = `<span class="js-quantity-available error-quantity">${response.quantityAvailableMsg}</span>`;
        $qty.closest('.js-sku-details-outer-cont').find('.js-error-place').html(error);

    } else {
        // if we here - this means that some quantity selected and it's in stock
        $qty.closest('.js-sku-details-inner-cont').addClass('item-selected');	// check item line
        $qty.removeClass('quantity-not-available js-quantity-not-available');
        $qty.closest('.js-sku-details-outer-cont').find('.js-error-place').empty();
    }

    // refresh another parts of interface only if it's not executed in batch
    if (!isBatch) {
        _refresh();
    }
}

module.exports = function ($container) {
    $container = $container || $(document);
    const $pdpMain = $container.find('.js-pdp-main');
    const $productSetList = $pdpMain.find('#product-set-sku-list');

    // set up _refresh function
    _refresh = _refresh.bind(null, $pdpMain);

    // this will be fired when quantity selection changed and availability fetched for selected value
    $productSetList.on('availability.fetched', '.js-product-set-sku-item .js-quantity', onAvailabilityFetched);
};
