import account from '../account';

const eventMgr = require('_core_ext/eventMgr');

function handleContactPrefs() {
    const $form = $(document).find('.js-registration-form'),
        $passwordconfirm = $form.find('.js-input-passwordconfirm'),
        $loyaltyFields = $form.find('.js-loyalty-fields');

    $form
        .on('click', 'input:checkbox', function (e) {
            account.handleSubscribes(e, $form);
        }).on('submit', function (e) {
            account.validateContactOptions(e, $form);
        }).on('change', '.js-input-passwordcreate', function() {
            $passwordconfirm.val($(this).val());
            $form.validate().element($(this));
        }).on('change', '.js-input-loyaltynumber', function() {
            $form.validate().element($(this));
        }).on('change', '[name$="selectedloyaltytype"]', function() {
            if ($(this).val() === 'NEW_CARD') {
                $loyaltyFields.show();
            } else {
                $loyaltyFields.hide();
            }
        });

}

var orderconfirmation = {
    init: function () {
        eventMgr.execute('components.initialized').then(() => {
            // delay everything until validator module initialized
            handleContactPrefs();
        });
    },
    handleContactPrefs: handleContactPrefs
};

module.exports = orderconfirmation;
