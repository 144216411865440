import util from '../util';
import eventMgr from '../eventMgr';
import $ from 'jquery';
import _debounce from 'lodash/function/debounce';

const emitter = eventMgr.getEmitter('deviceDetector');

const DeviceDetector = {
    init: function() {
        const info = DeviceDetector.getDeviceInfo();

        DeviceDetector.currentDevice = info.device;
        DeviceDetector.currentOrientation = info.orientation;
    },

    startWatchSize: function() {
        // make sure to attach listener only once
        if (DeviceDetector.onResizeBound) {
            return;
        }
        DeviceDetector.onResizeBound = true;
        $(window).on('resize', _debounce(() => DeviceDetector.matchViewport(), 300));
    },

    getDeviceInfo: function() {
        const info = {device: null, orientation: null, hasTouch: false};

        // detect device by screen resolution and agents
        if (util.isMobile()) {
            info.device = DeviceDetector.screen.MOBILE;
        } else if (util.isTablet()) {
            info.device = DeviceDetector.screen.TABLET;
        } else {
            info.device = DeviceDetector.screen.DESKTOP;
        }

        info.orientation = util.isPortrait() ? DeviceDetector.orientation.PORTRAIT : DeviceDetector.orientation.LANDSCAPE;
        info.hasTouch = util.isTouch();

        return info;
    },

    matchViewport: function() {
        var previousDevice = DeviceDetector.currentDevice,
            previousOrientation = DeviceDetector.currentOrientation;

        const info = DeviceDetector.getDeviceInfo();
        DeviceDetector.currentDevice = info.device;
        DeviceDetector.currentOrientation = info.orientation;
        if (previousDevice !== DeviceDetector.currentDevice || DeviceDetector.currentOrientation !== previousOrientation) {
            emitter.emit('deviceChange', DeviceDetector);
        }
    }
};

DeviceDetector.screen = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop'
};

DeviceDetector.orientation = {
    PORTRAIT: 'portrait',
    LANDSCAPE: 'landscape'
};

DeviceDetector.init();

module.exports = DeviceDetector;
