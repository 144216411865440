import Component from '_core_ext/components/Component';


export default class Fullpage extends Component {
    static get selector() {
        return '.js-component-fullpage';
    }
    get configDefault() {
        return {
            navigation: true,
            navigationPosition: 'right',
            navigationTooltips: '',
            responsiveHeight: 600,
            responsiveWidth: 768,
            continuousVertical: false,

            //Custom selectors
            sectionSelector: '.js-fullpage-section',
            slideSelector: '.js-fullpage-slide',

            afterLoad: () => {
                this.updatePosition();
            },

            afterResize: () => {
                this.updatePosition();
            },

            afterRender: () => {
                // on slider full load ...
                // get custom data-attributes
                this.autoscrollMode = $('.amp-vertical-slider').data('autoscrollMode'); // manual, hybrid, automatic
                this.autoscrollInterval = $('.amp-vertical-slider').data('autoscrollInterval'); // in msec
                if (typeof this.autoscrollInterval === 'undefined') {
                    this.autoscrollInterval = 5000; // default
                }
                // start the slideshow according to settings
                if (this.autoscrollMode === 'automatic' || this.autoscrollMode === 'hybrid') {
                    this.slideTimeout = setInterval(function () {
                        $.fn.fullpage.moveSectionDown();
                    }, this.autoscrollInterval);
                }
            },

            onLeave: () => {
                // reset timer in case was hybrid (otherwise can scroll 2 times in a row)
                if (this.autoscrollMode === 'hybrid') {
                    clearInterval(this.slideTimeout);
                    this.slideTimeout = setInterval(function () {
                        $.fn.fullpage.moveSectionDown();
                    }, this.autoscrollInterval);
                }
            }
        };
    }
    get configDataAttrName() {
        return 'component-fullpage';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        this.instance = require('fullpage.js');
        //call parent init method with same args
        super.init(...args);

        this.$el.fullpage(this.config);
    }
    updatePosition() { // custom function to update slide sizes
        var headerheight = $('.header-banner').height() + $('.top-banner').height();
        var footerheight = $('.footer-links').css('position') === 'static' ? 0 : $('.footer-links').outerHeight();
        var windowheight = $(window).height();
        var slideHeight = windowheight - headerheight - footerheight;
        if ($(window).width() >= 768) {
            $('.header-banner').addClass('fixed-by-fullpage-js');
            $('.top-banner').addClass('fixed-by-fullpage-js');
            $('.js-fullpage-section').css('padding-top',headerheight);
            $('.js-fullpage-section').css('padding-bottom',footerheight);
            $('.js-fullpage-section').css('height', slideHeight);
            $('.fp-tableCell').css('height', slideHeight);
        } else {
            $('.header-banner').removeClass('fixed-by-fullpage-js');
            $('.top-banner').removeClass('fixed-by-fullpage-js');
            $('.js-fullpage-section').css('padding-top',headerheight);
            $('.js-fullpage-section:first-child').css('padding-top',0);
            $('.js-fullpage-section').removeAttr('padding-bottom');
            $('.js-fullpage-section').css('height','auto');
            $('.fp-tableCell').css('height','auto');
        }

        $('.fp-tableCell .amp-vertical-slider-slide').css('height', slideHeight); // it has image on bg (covered) so have to setup height explicitly
    }
}

module.exports = Fullpage;
