import eventMgr from '_core_ext/eventMgr';
const dialog = require('_core_ext/dialog');
const page = require('_core_ext/page');
const cookies = require('_core_ext/utils/cookies');

const emitter = eventMgr.getEmitter('TimeslotExpire');

var _idleInterval = null,
    _idleIntervalSeconds = 60,
    _idleCookieKey = 'dwTimeslotExpire',
    _timeslotExpireTime = 10 * 60 * 1000; // 10 minutes


function _getExpireCookie() {
    return cookies.getCookie(_idleCookieKey);
}

function _removeExpireCookie() {
    return cookies.removeCookie(_idleCookieKey);
}

function _idleTimer() {
    if (this.isTimeslotExpired()) {
        dialog.open({
            options: {
                title: Resources.TIMESLOT_EXPIRE_TITLE,
                closeOnEscape: false,
                dialogClass: 'popup timeslot-expire-popup',
                open: () => {
                    _removeExpireCookie();
                    //send special ID to select first available method
                    var specialId = '_FIRST_AVAILABLE_METHOD_';
                    emitter.emit('expiredTimeout', specialId);
                },
                close: () => {
                    page.redirect(Urls.shippingMethods);
                },
                buttons: [{
                    text: Resources.CONTINUE,
                    click: function () {
                        dialog.close();
                    }
                }]
            },
            html: Resources.TIMESLOT_EXPIRE_BODY
        });
        clearInterval(_idleInterval);
    }
}

module.exports = {
    init : function() {
        this.initEvents();

        if (app.page.ns === 'orderconfirmation' ||
            app.page.checkoutPage === 'multipleaddresses') {
            _removeExpireCookie();
        } else if (_getExpireCookie()) {
            this.startTimer();
        }
    },

    startTimer: function () {
        var _idleTimerBound = _idleTimer.bind(this);
        _idleInterval = setInterval(_idleTimerBound, _idleIntervalSeconds * 1000);
    },

    isTimeslotExpired: function() {
        var cookieValue = _getExpireCookie();
        if (cookieValue) {
            var currentTime = new Date().getTime();
            var timeDifference = currentTime - parseInt(cookieValue, 10);
            if (timeDifference > _timeslotExpireTime) {
                return true;
            }
        }
        return false;
    },

    initEvents: function () {
        eventMgr.on('TimeslotSelector.slotSelected', () => {
            var selectionTimestamp = new Date().getTime();
            cookies.setCookie(_idleCookieKey, selectionTimestamp);
            this.startTimer();
        });
        eventMgr.on('shipping.shippingMethodSelected', () => {
            _removeExpireCookie();
        });
    }
};
