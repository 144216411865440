import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';
import DD from '_core_ext/components/deviceDetector';
import layout from '_core_ext/components/global/layout';
import _debounce from 'lodash/function/debounce';

/* globals Modernizr */

export default class Carousel extends Component {
    static get selector() {
        return '.js-carousel';
    }
    get configDefault() {
        return {
            //jcarousel standart attributes
            animation: {
                duration: 1700,
                easing:   'linear',
                complete: function() {}
            },
            transitions: Modernizr.csstransitions ? {
                transforms:   Modernizr.csstransforms,
                transforms3d: Modernizr.csstransforms3d,
                easing:       'ease'
            } : false,
            vertical: false,
            wrap: 'circular',
            scroll: null,
            auto: null,
            rtl: null,
            center: false,
            //non-standart attributes
            autoscroll: null, //msec ot null
            fadeIn: false, //fade effect or swipe (default)
            fadeInDuration: 800, //msec
            responsiveStrategy: 'linear', // Use responsive view strategy. For example 'one-slide', 'linear', 6-3-1, 8-4-2-1
            pageStep: 1
        }
    }
    get configDataAttrName() {
        return 'carousel';
    }

    /**
     * Method called when creating component
     *
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.isCarouselBusy = false;

        this.reInitDebounced = _debounce(() => {
            this.reInit();
        }, 500);

        this.extendConfig();
        this._initEvents();
        DD.startWatchSize();

    }
    _initEvents() {
        var $el = this.$el;
        if (!this.config.vertical && !$el.find('.jcarousel-control').length) {
            $el.append('<div class="jcarousel-control"></div>');
        }
        if (!$el.find('.jcarousel-nav.jcarousel-prev').length) {
            $el.append('<a class="jcarousel-nav jcarousel-prev" data-carousel-control-css="prev-inactive"></a>');
        }
        if (!$el.find('.jcarousel-nav.jcarousel-next').length) {
            $el.append('<a class="jcarousel-nav jcarousel-next" data-carousel-control-css="next-inactive"></a>');
        }

        //*** Hack for safari and android browser to prevent page vertical scroll on carousel swiping
        this.$el.on('touchstart', () => { this.swiping = true; });
        this.$el.on('touchend', () => { this.swiping = false; });
        window.addEventListener('touchstart', (e) => this.touchStart(e));
        window.addEventListener('touchmove', (e) => this.preventTouch(e), {passive: false});
        //***

        // Prevent touch scroll when carousel being animated from control button
        this.$el.on('jcarousel:animate', () => { this.isCarouselBusy = true; });
        this.$el.on('jcarousel:animateend', () => { this.isCarouselBusy = false; });

        /* eslint  complexity: [0] */
        $el.on('jcarousel:create jcarousel:reload', (event) => {
            // responsive slides

            if (!this.config.vertical) {
                var windowWidth,
                    $element = $(event.target),
                    width = $element.innerWidth();

                switch (this.config.responsiveStrategy) {
                    case 'one-slide': // view one slide per page
                        break;
                    case 'two-slides': // Amplicance Carousel in image-panel-with-carousel template
                        windowWidth = $(window).innerWidth();
                        if (windowWidth >= 768) {
                            width = Math.floor(width / 2); // need's rounding - otherwise user will see arrow keys (as artifact) if total  number of products = 2
                        } // else - 1 slide full width
                        break;
                    case 'product-list-may-like': // view recommendations on PLP
                        windowWidth = $(window).innerWidth();

                        if (windowWidth >= 975) {
                            width = width / 4;
                        } else if (windowWidth < 975 && windowWidth >= 801) {
                            width = width / 3;
                        } else if (windowWidth < 801) {
                            width = width / 2;
                        }
                        break;
                    case 'product-list-may-like-cart': // view recommendations on cart
                        windowWidth = $(window).innerWidth();

                        if (windowWidth >= 975) {
                            width = width / 3;
                        } else {
                            width = width / 2;
                        }
                        break;
                    case 'pop-slider':
                        if (width >= 1230) {
                            width = width / 6;
                        } else if (width < 1230 && width >= 990) {
                            width = width / 4;
                        } else if (width < 990 && width >= 738) {
                            width = width / 3;
                        } else if (width < 738 && width >= 481) {
                            width = width / 2;
                        } else if (width < 480) {
                            width = width / 1;
                        }
                        break;
                    case 'category-preview':
                        windowWidth = $(window).innerWidth();
                        if (windowWidth > 1024) {
                            width = width / 3;
                        } else {
                            width = width / 2;
                        }
                        break;
                    default: //'linear' if slides will be more 6, update will be required
                        if (width >= 1230) {
                            width = width / 6;
                        } else if (width < 1230 && width >= 990) {
                            width = width / 4;
                        } else if (width < 990 && width >= 738) {
                            width = width / 3;
                        } else if (width < 738) {
                            width = width / 2;
                        }
                        break;
                }
                $element.jcarousel('items').css('width', width + 'px');
            }
        }).jcarousel(this.config);

        if (this.config.vertical) {
            $el.jcarousel('reload');
        }

        if (this.config.autoscroll) {
            $el.jcarouselAutoscroll({
                interval: this.config.autoscroll
            });

            if ($el.hasClass('who-will-be')) { // special autoscroll functionality according to BT-1035
                // enable autoscroll only on mobile (not  protrait and desktop)
                var updateAutoscrollState = function() {
                    if ($(window).width() > 768) {
                        $el.jcarouselAutoscroll('stop');
                    } else {
                        $el.jcarouselAutoscroll('start');
                    }
                };
                updateAutoscrollState(); // init state on page load
                $(window).resize(function() {
                    updateAutoscrollState();
                });
            }
        }

        if (this.config.fadeIn) {
            // make fadeIn effect - this is unsupported custom effect that imitates fadeIn effect as alternative to the ONLY and default swipe
            this.event('jcarousel:animate', (element) => {
                $(element.context).find('li').hide().fadeIn(this.config.fadeInDuration);
            });
        }
        this._reInitControls();
        $el.find('.jcarousel-control')
        .on('jcarouselpagination:active', 'a', function () {
            $(this).addClass('active');
        })
        .on('jcarouselpagination:inactive', 'a', function () {
            $(this).removeClass('active');
        })
        .jcarouselPagination({
            perPage: 2,
            item: function (page) {
                return '<a>' + page + '</a>';
            }
        });

        eventMgr.on('ProductTile.carouselUpdate', ($carousel) => {
            if (this.$el.is($carousel)) {
                this.reInitDebounced();
            }
        });
        eventMgr.on('deviceDetector.deviceChange', () => {
            this.reInit(this.getExtensionConfig(layout.getMode()));
        });
    }

    _reInitControls(step = this.config.pageStep) {
        var $controllPrev = this.$el.find('.jcarousel-prev');
        var $controllNext = this.$el.find('.jcarousel-next');

        this._attachActiveStatusToControl($controllPrev).jcarouselControl({
            target: '-=' + step
        });
        this._attachActiveStatusToControl($controllNext).jcarouselControl({
            target: '+=' + step
        });
    }
    extendConfig() {
        $.extend(this.config, this.getExtensionConfig(layout.getMode()));
    }
    getExtensionConfig(device) {
        //add options customization by device
        var customOptions = this.config;
        if ('custom' in customOptions && device in customOptions.custom) {
            return customOptions.custom[device];
        } else {
            return {};
        }
    }
    _attachActiveStatusToControl($controll) {
        $controll.on('jcarouselcontrol:active', (e) => {
            var $target = $(e.target);
            $target.removeClass('inactive');
            this.$el.removeClass($target.data('carousel-control-css'));
        })
        .on('jcarouselcontrol:inactive', (e) => {
            var $target = $(e.target);
            $target.addClass('inactive');
            this.$el.addClass($target.data('carousel-control-css'));
        });
        return $controll;
    }
    reInit(options) {
        this.$el.jcarousel('reload', options);
    }
    destroy() {
        this.$el.jcarousel('destroy');
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        if (event.cancelable === true) {
            let cancelEvent = false;
            this.clientX = e.touches[0].clientX - this.firstClientX;
            this.clientY = e.touches[0].clientY - this.firstClientY;

            // Prevent horizontal swipe control when carousel is already animating scroll.
            if (this.isCarouselBusy && Math.abs(this.clientY) < minValue) {
                cancelEvent = true;
            } else if (this.swiping === true) { // Prevent vertical scroll on horizontal swiping.
                // Vertical scrolling does not work when you start swiping horizontally.
                if (Math.abs(this.clientX) > minValue) {
                    cancelEvent = true;
                }
            }

            if (cancelEvent === true) {
                e.preventDefault();
                e.returnValue = false;
                return false;
            }
        }
    }

}

module.exports = Carousel;
