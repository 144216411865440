/**
 * Used for validation of phone number.
 * Please, consider that this helper is used on frontend(/js/validator/phone) as well as on backend
 */

var defaultPhoneRegEx = /^\+?[- \d]{6,}$/,
    phonesRegEx = {};
/*
 * here example for different matching for countries
 *  
    phonesRegEx = {
        IE: /^\+?[- \d]*$/,
        XN: /^\+?[- \d]*$/,
        GB: /^\+?[- \d]*$/
    };
 */

module.exports = function (country, phone) {
    phone = phone || '';
    var rgx = country in phonesRegEx ? phonesRegEx[country] : defaultPhoneRegEx;
    return rgx.test(phone.trim());
};
