'use strict';

var isotope = require('_core_ext/components/global/isotope');

module.exports = {
    runningQuery : null,
    currentQuery : null,
    $resultsContainer : null,
    $searchForm : null,
    /**
     * @function
     * @description Configures parameters and required object instances
     */
    init: function () {
        var $searchContainer = $('.js-be-inspired-story-archive-search');
        var $grid = $('.js-isotope-grid');
        this.$searchForm = $searchContainer.find('form[name="beInspiredSearch"]');
        var $searchField = this.$searchForm.find('input[name="q"]');
        // disable browser auto complete
        $searchField.attr('autocomplete', 'off');

        // on blur listener
        $(document).on('input', '.clearable', function() {
            $(this).toggleClass('x', !!this.value);
        }).on('mousemove', '.x', function(e) {
            var state = (this.offsetWidth - 18 < e.clientX - this.getBoundingClientRect().left);
            $(this).toggleClass('onX', state);
        }).on('touchstart click', '.onX', function(e) {
            e.preventDefault();
            $(this).removeClass('x onX').val('').trigger('change');
            isotope.contentSearch($grid);
        }).on('click', function (e) {
            if (!$searchContainer.is(e.target)) {
                setTimeout(this.clearResults, 200);
            }
        }.bind(this));
        // on key up listener
        $searchField.on('keyup', function (e) {
            // get keyCode (window.event is for IE)
            var keyCode = e.keyCode || window.event.keyCode;

            // check for an ENTER or ESC
            if (keyCode === 13 || keyCode === 27) {
                this.clearResults();
                return;
            }

            isotope.contentSearch($grid, $searchField.val().trim());
        }.bind(this));
    },
    /**
     * @function
     * @description
     */
    clearResults: function () {
        var that = this;
        if (!this.$resultsContainer) { return; }
        this.$resultsContainer.fadeOut(200, function () {that.$resultsContainer.empty();});
    }
};