import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';
import util from '_core_ext/util';
import noScroll from '_core_ext/components/noscroll';
import _debounce from 'lodash/function/debounce';
import ajax from '_core_ext/ajax';
import dialog from '_core_ext/dialog';
import progress from '_core_ext/progress';
import mobileStickyRefinements from './SearchRefinements/mobileStickyRefinements';

const LEVEL_CLASS = 'ref-level-';
const emitter = eventMgr.getEmitter('sticky');

export default class SearchRefinements extends Component {
    static get selector() {
        return '#main';
    }

    get configDataAttrName() {
        return 'search-refinements-options';
    }

    get configDefault() {
        const config = {
            variationAttributes: {
                attributePreffix: 'prefn',
                valuePreffix: 'prefv',
                valueSeparator: '|',
                priceRefinementMin: 'pmin',
                priceRefinementMax: 'pmax'
            },
            hiddenRefinementsWhitelist: ['keyingredients'],
            hash: '#productrefinesearch',
            selectors: {
                container: '.js-search-refinements-container',
                refinementContainer: '.js-search-refinement-item-container',
                refinement: '.js-search-refinement-item',
                refinementValue: '.js-search-refinement-item-value',
                ribbonRefinementValue: '.js-ribbon-refinement-item-value',
                refinementContainerToggle: '.js-search-refinements-toggle',
                refinementFilter: '.js-search-refinements-filter',
                refinementInner: '.search-refinements-inner',

                refinementToolsCounter: '.js-search-refinement-item-tools-counter',
                refinementToolsClean: '.js-search-refinement-item-tools-clean',

                buttonApply: '.js-search-refinements-controls-button-apply',
                buttonClearAll: '.js-search-refinements-controls-button-clearall',
                buttonsContainer: '.js-refinements-control-buttons',

                showMobileRefinements: '.js-search-refinements-mobile-show',
                hideMobileRefinements: '.js-search-refinements-mobile-hide',

                refinementFilterMatched: '.js-matched',
                refinementBrandFilterNoMatched: '.js-search-brand-refinements-nomatch',
                refinementBrandFilterClean: '.js-search-brand-clean',
                priceRangeMinInput: '.js-search-refinement-pricerange-input-min',
                priceRangeMaxInput: '.js-search-refinement-pricerange-input-max',
                priceRangeSelectedRange: '.js-search-refinement-item-tools-selected-pricerange',

                refinementsContainer: '.js-search-refinement-items-container',
                categoryRefinement: '.js-search-category-refinement',
                backButton: '.js-refinements-back',
                applyButtonFirstLevel: '.js-search-refinements-controls-button-level-first-apply',
                viewTypeInput: 'input[name="refinebarHorizontal"]',
                closeRefinementsArea: '.js-search-refinements-mobile-close',
                clearRefinementTag: '.js-refined-clear-tag'
            },
            cssClasses: {
                hidden: 'visually-hidden',
                refinement: 'refinement',
                refinementSelected: 'selected',
                refinementHidden: 'hidden-refinement',
                mobileActiveClass: 'search-refinements-active',
                refinementFilterMatched: 'matched js-matched',
                noresults: 'noresults-refinement-category',
                refinementLevel: 'LEVEL_CLASS',
                refinementHorizontal: 'js-refinements__item',
                filteredRefinement: 'filtered-refinement-item',
                hideElement: 'hidden',
                horizontalView: 'js-search-horizontal-view'
            }
        };
        config.selectors = $.extend(config.selectors, {
            refinementsContainer: '.js-search-refinement-items-container',
            categoryRefinement: '.js-search-category-refinement',
            backButton: '.js-refinements-back',
            applyButtonFirstLevel: '.js-search-refinements-controls-button-level-first-apply',
            viewTypeInput: 'input[name="refinebarHorizontal"]',
            closeRefinementsArea: '.js-search-refinements-mobile-close',
            clearRefinementTag: '.js-refined-clear-tag'
        });
        config.cssClasses = $.extend(config.cssClasses, {
            refinementHorizontal: 'js-refinements__item',
            filteredRefinement: 'filtered-refinement-item',
            hideElement: 'hidden',
            horizontalView: 'js-search-horizontal-view'
        });

        return config;
    }

    isMobileView() {
        return util.isMobile() || (util.isTablet() && util.isPortrait());
    }

    reInitRefinementTools() {
        var $refinementContainers = this.$container.find(this.config.selectors.refinementContainer);
        for (var i = 0, len = $refinementContainers.length; i < len; i++) {
            var $refinementContainer = $($refinementContainers[i]);
            this.processRefinemetContainerItems($refinementContainer);
        }
    }

    initState() {
        // Holds state for refinements popup
        this.state = {
            currentLevel: 0, // current menu level
            changed: false, // refinement change status
            openRefinementContainer: '',
            lastScrollTop: 0,
            filterOffsetTop: 0,
        };
    }

    init(...args) {
        /**
         * initState() is called before super.init to set current default values for state,
         * which are later used in reinit
         */
        this.initState();

        //call parent init method with same args
        super.init(...args);

        this.applyRefinementsDebounced = _debounce(() => {
            this.applyRefinements();
        }, 300);

        this.updateRefinementContainersDebounced = _debounce(() => {
            this.updateRefinementContainers();
        }, 300);

        eventMgr.on('search.productListUpdated', () => {
            this.$container = this.$el.find(this.config.selectors.container);
            this.$filter = this.$container.find(this.config.selectors.refinementInner);

            this.reInitRefinementTools();
            this.initRefinementsForMobile();
            this.initStickyRefinement();
            mobileStickyRefinements()
            app.components.initComponent('PriceRange');
            app.components.initComponent('ProductSlickCarousel');
            if (!this.isMobile && !this.isTablet) {
                app.components.initComponent('stickyKit');
            }
        });

        this.reInit();

        if (this.isMobileView()) {
            this.hideCategoryRefinement();
        }

        this.updateViewportWidth();
    }

    reInit() {
        this.isTablet = util.isTablet();
        this.isMobile = util.isMobile();
        this.isMobileRefinementsShown = false;

        this.$window = $(window);
        this.$container = this.$el.find(this.config.selectors.container);
        this.$filter = this.$container.find(this.config.selectors.refinementInner);

        this.reInitRefinementTools();
        this.initRefinementsForMobile();
        this.initStickyRefinement();
        mobileStickyRefinements()

        this._initEvents();

        if (this.isMobileView()) {
            // buttons initial state
            this.updateControls();
            this.hideCategoryRefinement();
        }

        this.updateViewportWidth();

        this.state.filterOffsetTop = this.$filter[0].offsetTop;
    }

    initRefinementsForMobile() {
        if (this.isMobileView()) {
            const that = this;
            const $newRefinementContainers = this.$container.find(this.config.selectors.refinementContainer);
            $newRefinementContainers.each(function() {
                var $el = $(this);
                if (!$el.hasClass(that.config.cssClasses.refinementHidden)) {
                    $el.toggleClass(that.config.cssClasses.refinementHidden);
                }
            });
        }
    }

    initStickyRefinement() {
        // set height only for desktop sticky (horizontal) refinements 
        if (util.getCSSContentValue(this.$container[0]) === 'horizontal') {
            this.$container.height(this.$container.outerHeight(true));
        }
    }

    applyRefinementURL(url) {
        var qsParams = {
            format: 'ajax',
            formatsource: 'getcountonly'
        };
        ajax.getJson({
            url: util.appendParamsToUrl(url, qsParams),
            callback: function(response) {
                if (!response || !response.success || response.psmCount > 0) {
                    eventMgr.execute('search.updateProductListing', url);
                    return;
                } else {
                    dialog.open({
                        url: Urls.noRefinementHits,
                        options: {
                            title :  Resources.NO_REFINEMENTS_HITS_TITLE,
                            dialogClass: 'js-refinements-dialog',
                            position: {
                                my: 'center',
                                at: 'center',
                                of: window,
                                collision: 'none'
                            },
                            open: function() {
                                var $formContainer = dialog.getCurrent();
                                var $btn = $formContainer.find('.js-no-refinements-hits-btn');
                                $btn.on('click', function(){
                                    dialog.close();
                                });
                            },
                            close: function() {
                                window.location.reload();
                            }
                        }
                    });
                }
            }
        });
    }

    getSelectedRefinements($container) {
        return $container.find(this.config.selectors.refinementValue).filter(function() {
            return $(this).data('refinement-selected');
        });
    }

    applyRefinements() {
        var url = this.getRefinementUrl();
        this.applyRefinementURL(url);
    }

    clickApplyRefinements(element, event) {
        this.applyRefinementsDebounced();

        event.preventDefault();
        return false;
    }

    clickClearAllRefinements(element, event) {
        var url = $(element).attr('href');
        this.updateRefinementContainers(url);
        event.preventDefault();
        return false;
    }

    /**
     * Controlling control buttons states (view all/apply/back)
     *
     * @memberof SearchRefinements
     */
    updateControls() {
        const currentLevel = this.state.currentLevel;
        const $buttonApply = $(this.config.selectors.buttonApply);
        const $buttonApplyFirstLevel = $(this.config.selectors.applyButtonFirstLevel);

        if (currentLevel === 0) {
            if (!this.state.changed) {
                $buttonApply.show();
            }

            $buttonApplyFirstLevel.addClass(this.config.cssClasses.hideElement);

            this.$container.removeClass(LEVEL_CLASS + '1');
        } else if (currentLevel === 1) {
            $buttonApply.hide();
            $buttonApplyFirstLevel.removeClass(this.config.cssClasses.hideElement);

            this.$container.removeClass(LEVEL_CLASS + '0');
        }

        this.$container.addClass(LEVEL_CLASS + currentLevel);
    }

    /**
     * @description Updates refinements container based on ajax response call
     * @param {*} content refinements content retrieved from ajax call
     * @memberof SearchRefinements
     */
    updateRefinementContainersItems(content) {
        if (this.isMobileView()) {
            const $buttonApply = $(content).find(this.config.selectors.buttonApply);
            const $buttonApplyFirstLevel = $(this.config.selectors.applyButtonFirstLevel);

            if ($buttonApply && $buttonApply.length) {
                this.$container.find(this.config.selectors.buttonApply).replaceWith($buttonApply);
                $buttonApplyFirstLevel.addClass(this.config.cssClasses.hideElement);
                this.$container.removeClass(LEVEL_CLASS + '1');
            }
        }

        if (this.isMobileView()) {
            const $buttonApply = $(content).find(this.config.selectors.buttonApply);
            const $buttonApplyFirstLevel = $(this.config.selectors.applyButtonFirstLevel);

            if ($buttonApply && $buttonApply.length) {
                this.$container.find(this.config.selectors.buttonApply).replaceWith($buttonApply);
                $buttonApplyFirstLevel.addClass(this.config.cssClasses.hideElement);
                this.$container.removeClass(this.config.cssClasses.refinementLevel + '1');
            }
        }

        var that = this;
        var $resp = $(content);
        var $cont = this.$container;

        if (!$resp || !$resp.find(this.config.selectors.refinementInner).length || $resp.find(this.config.cssClasses.noresults).length) {
            dialog.open({
                url: Urls.noRefinementHits,
                options: {
                    title :  Resources.NO_REFINEMENTS_HITS_TITLE,
                    dialogClass: 'js-refinements-dialog',
                    position: {
                        my: 'center',
                        at: 'center',
                        of: window,
                        collision: 'none'
                    },
                    open: function() {
                        var $formContainer = dialog.getCurrent();
                        var $btn = $formContainer.find('.js-no-refinements-hits-btn');
                        $btn.on('click', function(){
                            dialog.close();
                        });
                    },
                    close: function() {
                        window.location.reload();
                    }
                }
            });
            return false;
        }

        // need to save containers states
        var $allRefinementContainers = this.$container.find(this.config.selectors.refinementContainer);
        var $openRefinementContainers = $allRefinementContainers.filter(function() {
            return !$(this).hasClass(that.config.cssClasses.refinementHidden);
        });
        var states = [];
        $openRefinementContainers.each(function() {
            var $el = $(this);
            var attrClass = $el.attr('class')
                .replace(that.config.cssClasses.refinement, '')
                .replace(that.config.selectors.refinementContainer.substr(1), '')
                .replace(that.config.cssClasses.refinementHidden, '')
                .replace(/\s+/, '');
            states.push(attrClass);
        });
        var $resHtml = $resp.find(this.config.selectors.refinementInner).html();
        $cont.find(this.config.selectors.refinementInner).empty().html($resHtml);

        this.reInitRefinementTools();
        app.components.initComponent('PriceRange');

        // apply states
        var $newRefinementContainers = this.$container.find(this.config.selectors.refinementContainer);
        $newRefinementContainers.each(function() {
            var $el = $(this);
            var open = false;
            for (var i = 0; i < states.length; i++) {
                if ($el.hasClass(states[i])) {
                    open = true;
                }
            }
            if ((open && $el.hasClass(that.config.cssClasses.refinementHidden)) || (!open && !$el.hasClass(that.config.cssClasses.refinementHidden))) {
                $el.toggleClass(that.config.cssClasses.refinementHidden);
            }

        });
        progress.hide();
    }


    _initEvents() {
        this.event('click', this.config.selectors.buttonClearAll, this.clickClearAllRefinements);
        this.event('click', this.config.selectors.buttonApply, this.clickApplyRefinements);
        this.event('click', this.config.selectors.refinementValue, this.clickRefinementValue);
        this.event('click', this.config.selectors.ribbonRefinementValue, this.clickRibbonRefinementValue);
        this.event('click', this.config.selectors.refinementContainerToggle, this.clickToggleRefinementContainer);
        this.event('click', this.config.selectors.showMobileRefinements, this.clickShowMobileRefinements);
        this.event('click', this.config.selectors.hideMobileRefinements, this.hideMobileRefinements);
        this.event('click', this.config.selectors.refinementToolsClean, this.clickRefinementToolsClean);
        this.event('click touchstart', this.config.selectors.refinementBrandFilterClean, this.clickRefinementFilterClean);
        this.event('keyup click input', this.config.selectors.refinementFilter, this.processRefinementFilterInput);
        this.event('change', this.config.selectors.priceRangeMinInput, this.changePriceRange);
        this.event('change', this.config.selectors.priceRangeMaxInput, this.changePriceRange);
        this.$window.on('orientationchange', this.orientationChangeWindow.bind(this));

        this.event('click', this.config.selectors.backButton, this.eventBackButtonClick);
        this.event('click', this.config.selectors.closeRefinementsArea, this.hideMobileRefinements);
        this.event('click', this.config.selectors.clearRefinementTag, this.clickClearRefinementTag);
        this.event('click', this.config.selectors.categoryRefinement, _debounce(() => this.clickCategoryArea(), 0));

        this.event('click', this.config.selectors.backButton, this.eventBackButtonClick);
        this.event('click', this.config.selectors.closeRefinementsArea, this.clickHideMobileRefinements);
        this.event('click', this.config.selectors.clearRefinementTag, this.clickClearRefinementTag);
        this.event('click', this.config.selectors.categoryRefinement, _debounce(() => this.clickCategoryArea(), 0));

        //added for collapsing when clicking outside the filter area
        $(document).on('click', this.clickOutsideRefinement.bind(this));

        //added only for horizontal refinements when user is scrolling up
        if (this.$container.hasClass(this.config.cssClasses.horizontalView)) {
            this.$window.on('scroll', this.updateStickyClass.bind(this));
        }
    }

    updateStickyClass() {
        if (window.pageYOffset > this.state.filterOffsetTop) {
            if (window.pageYOffset > this.state.lastScrollTop) {
                this.$filter.removeClass('sticky-refinements');
                this.$filter.css('top', '');
            } else {
                this.$filter.addClass('sticky-refinements');
                eventMgr.execute('stickyHeader.getHeight')
                    .then((top) => this.$filter.css('top', top))
                    .catch(() => this.$filter.css('top', 0));
            }
        } else {
            this.$filter.removeClass('sticky-refinements');
            this.$filter.css('top', '');
        }

        this.state.lastScrollTop = window.pageYOffset;
    }

    clickOutsideRefinement(e) {
        const $refinementContainer = $(e.target).closest(this.config.selectors.refinementContainer);

        if (!this.isMobileView() && this.isHorizontalView() && !$refinementContainer.length) {
            const $allRefinementContainers = this.$container.find(this.config.selectors.refinementContainer);
            const refinementHiddenClass = this.config.cssClasses.refinementHidden;
            const refinementContainerToggleSelector = this.config.selectors.refinementContainerToggle;

            this.state.openRefinementContainer = '';

            $allRefinementContainers.each((i, refinementContainer) => {
                const $thisRefinementContainer = $(refinementContainer);

                if (!$thisRefinementContainer.hasClass(refinementHiddenClass)){
                    this.collapseRefinement($thisRefinementContainer, refinementHiddenClass, refinementContainerToggleSelector);
                }
            });
        }
    }

    clickCategoryArea() {
        emitter.emit('recalc');
    }

    /**
     * @description 'Back' button click from level 1 mobile refinements
     * @memberof SearchRefinements
     */
    eventBackButtonClick() {
        this.state.currentLevel = 0;

        // Just a return click, restore menu without additional calls
        const $allRefinementContainers = this.$container.find(this.config.selectors.refinementContainer);

        const refinementHiddenClass = this.config.cssClasses.refinementHidden;
        $allRefinementContainers.each(function() {
            // It was previously hidden on current level incrementation
            $(this).show();

            // but changing visibility
            if (!$(this).hasClass(refinementHiddenClass)) {
                $(this).addClass(refinementHiddenClass);
            }
        });

        if (this.state.changed) {
            // Fully reload refinements with ajax
            const $priceRangeSelectedRange = $(this.config.selectors.priceRangeSelectedRange);

            if ($priceRangeSelectedRange.length && this.isMobileView()) {
                const $refinementContainer = $priceRangeSelectedRange.closest(this.config.selectors.refinementContainer);
                $refinementContainer.addClass(this.config.cssClasses.refinementHidden);
            }

            this.updateRefinementContainers();

        }

        const $categoryRefinement = this.$container.find(this.config.selectors.categoryRefinement);
        $categoryRefinement.show();

        $(this.config.selectors.refinementContainerToggle).addClass('toggle');

        this.updateControls();
        this.state.changed = false;
    }

    /**
     * @description Handles click at refinement tag for mobile view
     * @param {*} element
     * @param {*} event
     * @memberof SearchRefinements
     */
    clickClearRefinementTag(element, event) {
        if (this.isMobileView()) {
            const $refinementValue = $(element).closest(this.config.selectors.refinementValue);
            this.clickRefinementValue(element, event);
            this.updateRefinementContainers(this.prepareRefinementUrl($refinementValue.attr('href')));
        }
    }

    /**
     * @description Handles click at refinement value
     * @param {*} element clicked element
     * @param {*} event
     * @memberof SearchRefinements
     */
    clickRefinementValue(element, event) {
        event.preventDefault();
        const $refinementValue = $(element);
        const url = $refinementValue.attr('href');
        
        if (!url) {
            return;
        }

        if (this.isHorizontalView() && !this.isMobileView()){
            this.recordOpenedRefinementContainer();
        }

        if (this.isMobileView()) {
            const $itemWrapper = $refinementValue.closest(this.config.selectors.refinement);
            $itemWrapper.toggleClass(this.config.cssClasses.refinementSelected);

            const $refinementContainer = $refinementValue.closest(this.config.selectors.refinementContainer);
            this.processRefinemetContainerItems($refinementContainer);
            this.state.changed = true;
        } else {
            this.applyRefinementURL(url + this.config.hash);
        }
    }

    /**
     * @description Handles click at ribbon refinement value
     * @param {*} element clicked element
     * @param {*} event
     * @memberof SearchRefinements
     */
    clickRibbonRefinementValue(element, event) {
        event.preventDefault();
        const $refinementValue = $(element);
        const url = $refinementValue.attr('href');
        
        if (!url) {
            return;
        }
        this.applyRefinementURL(url + this.config.hash);
    }

    /**
     * @description record unique id of refinement to the state.
     */
    recordOpenedRefinementContainer() {
        this.state.openRefinementContainer = this.$container.find(this.config.selectors.refinementContainer)
            .not(`.${this.config.cssClasses.refinementHidden}`)
            .attr('data-refinement-id') || '';
    }

    processRefinemetContainerItems($refinementContainer) {
        var $priceRangeSelectedRange = $refinementContainer.find(this.config.selectors.priceRangeSelectedRange);
        var $refinementToolsClean = $refinementContainer.find(this.config.selectors.refinementToolsClean);
        var $refinements = $refinementContainer.find(this.config.selectors.refinement);
        var $selectedRefinements = $refinements.filter((i, refinement) => $(refinement).hasClass(this.config.cssClasses.refinementSelected));
        let isSelectedRefinement = !!$selectedRefinements.length;
        var isPriceSelected = false;

        if ($priceRangeSelectedRange.length) {
            var currencySymbol = $priceRangeSelectedRange.data('currency-symbol');

            var $priceRangeMinInput = $refinementContainer.find(this.config.selectors.priceRangeMinInput);
            var $priceRangeMaxInput = $refinementContainer.find(this.config.selectors.priceRangeMaxInput);

            isPriceSelected = $priceRangeMinInput.data('is-selected')
                || $priceRangeMinInput.data('is-changed')
                || $priceRangeMaxInput.data('is-selected')
                || $priceRangeMaxInput.data('is-changed');
            isSelectedRefinement = !!isPriceSelected;

            var selectedValue = '';

            if (isPriceSelected) {
                var priceRangeMinValue = $priceRangeMinInput.val();
                var priceRangeMaxValue = $priceRangeMaxInput.val();

                selectedValue = (this.isMobileView() ? '' : '(')
                    + currencySymbol
                    + priceRangeMinValue + ' - ' + currencySymbol
                    + priceRangeMaxValue
                    + (this.isMobileView() ? '' : ')');
            }
            if ($refinementToolsClean.length) {
                $refinementToolsClean.toggleClass(this.config.cssClasses.hidden, !(isPriceSelected && (this.isMobile || this.isTablet)));
                $priceRangeSelectedRange.toggleClass(this.config.cssClasses.hidden, !isPriceSelected);
            }
            $priceRangeSelectedRange.html(selectedValue);
        } else {
            var $refinementToolsCounter = $refinementContainer.find(this.config.selectors.refinementToolsCounter);
            var count = $selectedRefinements.length;
            if ($refinementToolsCounter.length) {
                if (count) {
                    $refinementToolsCounter.text('(' + count + ')');
                }
                $refinementToolsCounter.toggleClass(this.config.cssClasses.hidden, !count);
            }
            if ($refinementToolsClean.length) {
                $refinementToolsClean.toggleClass(this.config.cssClasses.hidden, !(count && (this.isMobile || this.isTablet)));
            }
        }


        if ($priceRangeSelectedRange.length && this.isMobileView()) {
            const $refinementToolsClean = $refinementContainer.find(this.config.selectors.refinementToolsClean);
            const $priceRangeMinInput = $refinementContainer.find(this.config.selectors.priceRangeMinInput);
            const $priceRangeMaxInput = $refinementContainer.find(this.config.selectors.priceRangeMaxInput);
            const isPriceSelected = $priceRangeMinInput.data('is-selected')
                || $priceRangeMinInput.data('is-changed')
                || $priceRangeMaxInput.data('is-selected')
                || $priceRangeMaxInput.data('is-changed');

            isSelectedRefinement = !!isPriceSelected;
            $refinementContainer.addClass(this.config.cssClasses.refinementHidden);
            $refinementToolsClean.addClass(this.config.cssClasses.hidden);
            $(this.config.selectors.refinementContainerToggle).addClass('toggle');
            this.state.currentLevel = 0;
        }

        $refinementContainer.toggleClass(this.config.cssClasses.filteredRefinement, isSelectedRefinement);

        if (this.isHorizontalView() && !this.isMobileView()){
            this.openRefinementContainer($refinementContainer);
        }
    }

    /**
     * @description open refinements which are located in the state.
     */
    openRefinementContainer($refinementContainer) {
        if (this.state.openRefinementContainer.length && ($refinementContainer.attr('data-refinement-id') === this.state.openRefinementContainer)) {
            $refinementContainer.removeClass(this.config.cssClasses.refinementHidden);
            $refinementContainer.find(this.config.selectors.refinementContainerToggle).addClass('expanded');
        }
    }

    updateRefinementContainers(refUrl) {
        var that = this;
        var url = refUrl || this.getRefinementUrl();
        var qsParams = {
            format: 'ajax',
            formatsource: 'refinements'
        };
        progress.show(this.$container, false);
        ajax.load({
            url: util.appendParamsToUrl(url, qsParams),
            callback: function(response){
                that.updateRefinementContainersItems(response);
            }
        });
    }

    /*
     * show refinements for tablet landscape screen orientation
     */
    orientationChangeWindow(event) {
        // if tablet rotated from portrait to landscape and (mobile) refinements were opened - close them
        if (util.isTablet()) {
            if (event.orientation === 'landscape') {
                if (this.isMobileRefinementsShown) {
                    this.hideMobileRefinements();
                }
            }

            // we need this because sticky kit determines screen orientation by screen width/height ratio,
            // but seems that upon orientation change numbers aren't updated yet, so we have to delay it :(
            setTimeout(() => {
                app.components.reInitComponent('stickyKit', this.$el.parent(), {hard: true});
            }, 10);
        }
    }

    /**
     * @description handler for showing and hiding refinement category body
     * @param element refinemenet container
     */
    clickToggleRefinementContainer(element) {
        const $element = $(element);

        if (!this.isMobileView() && this.$container.hasClass(this.config.cssClasses.horizontalView)) {
            this.collapseFilters($element);
            this.state.openRefinementContainer = '';
        }

        const $refinementContainer = $element.closest(this.config.selectors.refinementContainer);

        if (this.state.currentLevel !== 1) {
            $refinementContainer.toggleClass(this.config.cssClasses.refinementHidden);
        }

        if (this.isMobileView()) {
            // Hide all refinements, to make currently opened refinement category to be on the top
            var $allRefinementContainers = this.$container.find(this.config.selectors.refinementContainer);
            $allRefinementContainers.each(function() {
                $(this).hide();
            });

            const $categoryRefinement = this.$container.find(this.config.selectors.categoryRefinement);
            $categoryRefinement.hide();

            // display currently selected refinement container
            $refinementContainer.show();

            this.state.currentLevel = 1;
            this.updateControls();
            $(this.config.selectors.refinementContainerToggle).removeClass('toggle');
        } else if (this.isHorizontalView()) {
            // Horizontal desktop behavior, close all other menus when opened new one
            const $allRefinementContainers = this.$container.find(this.config.selectors.refinementContainer);
            const refinementHiddenClass = this.config.cssClasses.refinementHidden;
            const refinementContainerToggleSelector = this.config.selectors.refinementContainerToggle;

            $allRefinementContainers.each((i, refinementContainer) => {
                const $thisRefinementContainer = $(refinementContainer);

                if (!$thisRefinementContainer.is($refinementContainer)
                    && !$thisRefinementContainer.hasClass(refinementHiddenClass)) {
                    this.collapseRefinement($thisRefinementContainer, refinementHiddenClass, refinementContainerToggleSelector);
                }
            });
        }

        if (!this.isTablet) {
            app.components.reInitComponent('stickyKit', this.$el.parent());
        }
    }

    /**
     * button handler for showing refinements
     * on mobile version
     */
    clickShowMobileRefinements() {
        this.$container
            .addClass(this.config.cssClasses.mobileActiveClass);
        noScroll.enable();
        this.isMobileRefinementsShown = true;
    }

    clickRefinementToolsClean(element, event) {
        var $element = $(element);
        var $refinementContainer = $element.closest(this.config.selectors.refinementContainer);

        var $priceRangeSelectedRange = $refinementContainer.find(this.config.selectors.priceRangeSelectedRange);
        if ($priceRangeSelectedRange.length) {
            var $priceRangeMinInput = $refinementContainer.find(this.config.selectors.priceRangeMinInput);
            var $priceRangeMaxInput = $refinementContainer.find(this.config.selectors.priceRangeMaxInput);

            $priceRangeMinInput.val($priceRangeMinInput.data('min-value'));
            $priceRangeMaxInput.val($priceRangeMaxInput.data('max-value'));
            $priceRangeMinInput.data('is-selected', false);
            $priceRangeMaxInput.data('is-selected', false);
            if (this.isMobileView()) {
                $priceRangeMinInput.trigger('change');
                $priceRangeMaxInput.trigger('change');
            }
            $priceRangeMinInput.data('is-changed', false);
            $priceRangeMaxInput.data('is-changed', false);

            $priceRangeSelectedRange.empty();
            $priceRangeSelectedRange.addClass(this.config.cssClasses.hidden);
        } else {
            var $selectedRefinements = $refinementContainer.find(this.config.selectors.refinementValue);
            for (var i = 0, len = $selectedRefinements.length; i < len; i++) {
                var $refinement = $($selectedRefinements[i]);
                var $itemWrapper = $refinement.closest(this.config.selectors.refinement);
                $itemWrapper.removeClass(this.config.cssClasses.refinementSelected);
            }

            var $refinementToolsCounter = $refinementContainer.find(this.config.selectors.refinementToolsCounter);
            if ($refinementToolsCounter.length) {
                $refinementToolsCounter.empty();
                $refinementToolsCounter.addClass(this.config.cssClasses.hidden);
            }
        }

        $element.addClass(this.config.cssClasses.hidden);
        if (!this.isMobileView()) {
            this.applyRefinementsDebounced();
        } else {
            this.updateRefinementContainers();
        }

        event.preventDefault();

        return false;
    }

    highlightFilterRefinement($filteredRefinements, str) {
        var self = this;
        $filteredRefinements.each(function () {
            var $el = $(this).find(self.config.selectors.refinementValue);
            var origContent = $el.text();
            $el.data('highlighting-orig-content', origContent);
            var matchStart = $el.text().toLowerCase().indexOf(str.toLowerCase());
            var matchEnd = matchStart + str.length - 1;
            var beforeMatch = $el.text().slice(0, matchStart);
            var matchText = $el.text().slice(matchStart, matchEnd + 1);
            var afterMatch = $el.text().slice(matchEnd + 1);
            $el.html(beforeMatch + '<strong class="match">' + matchText + '</strong>' + afterMatch);
        });
    }

    processRefinementFilterInput(element) {
        var $el = $(element);
        var $refinementContainer = $el.closest(this.config.selectors.refinementContainer);
        var $refinements = $refinementContainer.find(this.config.selectors.refinement);
        var $refinementsFilterNoMatched = $refinementContainer.find(this.config.selectors.refinementBrandFilterNoMatched);
        var $refinementBrandFilterClean = $refinementContainer.find(this.config.selectors.refinementBrandFilterClean);
        var self = this;
        var regexp = new RegExp(/\((.*)\)/);

        if ($el.val().length !== 0) {
            $refinements
                .removeClass(this.config.cssClasses.refinementFilterMatched)
                .addClass(this.config.cssClasses.hidden);

            var $filteredRefinements = $refinements.filter(function () {
                return $(this).find(self.config.selectors.refinementValue).text().replace(regexp, '').toLowerCase().indexOf($el.val().toLowerCase()) !== -1;
            });

            $refinementsFilterNoMatched.toggleClass(this.config.cssClasses.hidden, $filteredRefinements.length > 0);
            $refinementBrandFilterClean.removeClass(this.config.cssClasses.hidden);

            $filteredRefinements.addClass(this.config.cssClasses.refinementFilterMatched)
                .removeClass(this.config.cssClasses.hidden);
            this.highlightFilterRefinement($filteredRefinements, $el.val());
        } else {
            $refinements.each(function () {
                var $el = $(this).find(self.config.selectors.refinementValue);
                var origContent = $el.data('highlighting-orig-content');
                if (origContent) {
                    $el.text(origContent);
                }
            });

            $refinements
                .removeClass(this.config.cssClasses.refinementFilterMatched)
                .removeClass(this.config.cssClasses.hidden);
            $refinementBrandFilterClean.addClass(this.config.cssClasses.hidden);
            $refinementsFilterNoMatched.addClass(this.config.cssClasses.hidden);
        }
    }

    clickRefinementFilterClean(element, event) {
        var $el = $(element);
        var $refinementContainer = $el.closest(this.config.selectors.refinementContainer);
        var $refinementBrandFilter = $refinementContainer.find(this.config.selectors.refinementFilter);

        $refinementBrandFilter.val('');
        $refinementBrandFilter.click();

        event.preventDefault();
        return false;
    }

    /**
     * hide mobile refinements and clear not applied items
     */
    hideMobileRefinements() {
        this.$container
            .removeClass(this.config.cssClasses.mobileActiveClass);

        noScroll.disable();
        this.isMobileRefinementsShown = false;

        // we need cleanup not applied items
        var $allRefinementContainers = this.$container.find(this.config.selectors.refinementContainer);
        for (var i = 0, iLen = $allRefinementContainers.length; i < iLen; i++) {
            var $refinementContainer = $($allRefinementContainers[i]);
            var $priceRangeSelectedRange = $refinementContainer.find(this.config.selectors.priceRangeSelectedRange);
            if ($priceRangeSelectedRange.length) {
                var $priceRangeMinInput = $refinementContainer.find(this.config.selectors.priceRangeMinInput);
                var $priceRangeMaxInput = $refinementContainer.find(this.config.selectors.priceRangeMaxInput);

                $priceRangeMinInput.data('is-selected', $priceRangeMinInput.data('is-selected-orig'));
                $priceRangeMaxInput.data('is-selected', $priceRangeMaxInput.data('is-selected-orig'));
                $priceRangeMinInput.val(Math.floor($priceRangeMinInput.data('original-value'))).trigger('change');
                $priceRangeMaxInput.val(Math.ceil($priceRangeMaxInput.data('original-value'))).trigger('change');
                $priceRangeMinInput.data('is-changed', false);
                $priceRangeMaxInput.data('is-changed', false);
            } else {
                var $itemWrappers = $refinementContainer.find(this.config.selectors.refinement);
                for (var j = 0, jLen = $itemWrappers.length; j < jLen; j++) {
                    var $itemWrapper = $($itemWrappers[j]);
                    var $refinement = $itemWrapper.find(this.config.selectors.refinementValue);
                    var isSelected = !!$refinement.data('refinement-selected');
                    $itemWrapper.toggleClass(this.config.cssClasses.refinementSelected, isSelected);
                }
            }
            this.processRefinemetContainerItems($refinementContainer);
        }
    }

    isHorizontalView() {
        return $(this.config.selectors.viewTypeInput).val() === 'true';
    }

    collapseFilters($element) {
        const $refinementsContainer = $element.closest(this.config.selectors.refinementsContainer);
        const $refinementContainer = $element.closest(this.config.selectors.refinementContainer);
        const refinementHiddenClass = this.config.cssClasses.refinementHidden;
        const refinementToggleClass = this.config.selectors.refinementContainerToggle;

        $refinementsContainer.find(this.config.selectors.refinementContainer).each((i, refinementContainer) => {
            const $this = $(refinementContainer);
            if (!$this.hasClass(refinementHiddenClass) && !$this.is($refinementContainer)) {
                this.collapseRefinement($this, refinementHiddenClass, refinementToggleClass);
            }
        });
    }

    collapseRefinement($refinementContainer, refinementHiddenClass, refinementToggleClass) {
        $refinementContainer.addClass(refinementHiddenClass);
        $refinementContainer.find(refinementToggleClass).removeClass('expanded');
    }

    /**
     * @param {*} url
     * @returns {String} url with additional params to pass mobile state(horizontal:true/false)
     * @memberof SearchRefinements
     */
    prepareRefinementUrl(url) {
        return util.appendParamsToUrl(url, {
            horizontal: this.isHorizontalView()
        });
    }

    getRefinementUrl() {
        var params = {};
        var i, len;
        var attributeRefinementsParams = {};

        var $itemWrappers = this.$el.find(this.config.selectors.refinement);
        for (i = 0, len = $itemWrappers.length; i < len; i++) {
            var $itemWrapper = $($itemWrappers[i]);
            var $refinement = $itemWrapper.find(this.config.selectors.refinementValue);
            $refinement.data('refinement-selected', $itemWrapper.hasClass(this.config.cssClasses.refinementSelected));
        }

        var $selectedRefinements = this.getSelectedRefinements(this.$el);
        for (i = 0, len = $selectedRefinements.length; i < len; i++) {
            var $selectedRefinement = $($selectedRefinements[i]);

            // attribute refinements are prefixed with prefn/prefv further
            if ($selectedRefinement.data('attribute-refinement')) {
                var refinementValueID = $selectedRefinement.data('refinement-value-id');
                if (!(refinementValueID in attributeRefinementsParams)) {
                    attributeRefinementsParams[refinementValueID] = [];
                }
                attributeRefinementsParams[refinementValueID].push($selectedRefinement.data('refinement-value-value'));
            } else {
                // and other (promotion for now) added as is
                params[$selectedRefinement.data('refinement-value-id')] = $selectedRefinement.data('refinement-value-value');
            }
        }
        var sLen = 1;
        for (var key in attributeRefinementsParams) {
            params[this.config.variationAttributes.attributePreffix + sLen] = key;
            params[this.config.variationAttributes.valuePreffix + sLen] = attributeRefinementsParams[key].join(this.config.variationAttributes.valueSeparator);
            sLen++;
        }

        var queryParams = util.getQueryStringParams(location.search);
        // check each search parameter if it is refinement from the whitelist and add to params (for hidden refinements)
        Object.keys(queryParams).forEach((key) => {
            if (this.config.variationAttributes.attributePreffix === key.slice(0, -1)) {
                var k = queryParams[key];
                if (!this.config.hiddenRefinementsWhitelist.includes(k)) {
                    return;
                }
                var v = queryParams[this.config.variationAttributes.valuePreffix + key.slice(-1)];
                if (v) {
                    params[this.config.variationAttributes.attributePreffix + sLen] = k;
                    params[this.config.variationAttributes.valuePreffix + sLen] = v;
                    sLen++;
                }
            }
        });

        var $container = this.$el.find(this.config.selectors.container);
        var $priceRangeMinInput = $container.find(this.config.selectors.priceRangeMinInput);
        var $priceRangeMaxInput = $container.find(this.config.selectors.priceRangeMaxInput);

        if ($priceRangeMinInput.length
                && ($priceRangeMinInput.data('is-changed') || $priceRangeMinInput.data('is-selected'))
                && $priceRangeMinInput.val()) {
            params[this.config.variationAttributes.priceRefinementMin] = $priceRangeMinInput.data('value');
        }
        if ($priceRangeMaxInput.length
                && ($priceRangeMaxInput.data('is-changed') || $priceRangeMaxInput.data('is-selected'))
                && $priceRangeMaxInput.val()) {
            params[this.config.variationAttributes.priceRefinementMax] = $priceRangeMaxInput.data('value');
        }

        var url = $container.data('refinement-clear-url');

        url = util.appendParamsToUrl(url, params) + this.config.hash;

        return this.prepareRefinementUrl(url);
    }

    updateViewportWidth() {
        document.documentElement.style.setProperty('--vw', `${window.innerWidth}px`);
    }

    hideCategoryRefinement() {
        const $categoryRefinement = $(this.config.selectors.categoryRefinement);

        if ($categoryRefinement.length && $categoryRefinement.data('hidecategory')) {
            $categoryRefinement.hide();
        }
    }

    changePriceRange(element) {
        var $el = $(element),
            $container = this.$el.find(this.config.selectors.container),
            $priceRangeMinInput = $container.find(this.config.selectors.priceRangeMinInput),
            $priceRangeMaxInput = $container.find(this.config.selectors.priceRangeMaxInput);

        if (parseInt($priceRangeMinInput.val(), 10) > parseInt($priceRangeMaxInput.val(), 10)) {
            $el.val($el.is(this.config.selectors.priceRangeMinInput) ? $priceRangeMaxInput.val() : $priceRangeMinInput.val());
            setTimeout(function () {
                $el.change();
            }, 20);
        }
        if (this.isMobileView()) {
            var $refinementContainer = $el.closest(this.config.selectors.refinementContainer);
            this.processRefinemetContainerItems($refinementContainer);
            this.updateRefinementContainersDebounced()
        } else {
            this.applyRefinementsDebounced();
        }

        const $categoryRefinement = $(this.config.selectors.categoryRefinement);

        if ($categoryRefinement.length && $categoryRefinement.data('hidecategory')) {
            $categoryRefinement.hide();
        }

        if (this.isHorizontalView() && !this.isMobileView()){
            this.recordOpenedRefinementContainer();
        }
    }
}

module.exports = SearchRefinements;
