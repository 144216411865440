var progress = require('_core_ext/progress'),
    util = require('_core_ext/util');

function loadBrands(url) {
    progress.show();

    $.ajax({
        type: 'GET',
        dataType: 'html',
        url: url,
        success: function (response) {
            var $result = $('<div/>').append(response);
            $('.js_brands-info').html($result.find('.js_brands-info').html());
            progress.hide();
        }
    });

}

function initializeEvents() {
    var $main = $('#main');
    $main.on('click', '.js-letter', function() {
        // We need correction due to sticky header
        var correction = 0;
        if (SitePreferences.PROJECT_ID === 'brownthomas' && !$('#header-wrapper').hasClass('is-sticky')) {
            // calculation will be like a:
            // correction = $('#header-wrapper.is-sticky').outerHeight(true) + (($('.js_letter-G').outerHeight(true) - $('.js_letter-G').height()) / 2)
            // but we can't calculate it because is-sticky not always at #header-wrapper element
            // it will be 80-90px and ~100px on mobile
            if (!util.isMobile()) {
                correction = 90;
            } else {
                correction = 115;
            }
        } else {
            correction = $('.js-header').height();
        }

        var scrollTarget = $('.' + $(this).attr('id')).offset().top;
        scrollTarget -= correction;

        $('html, body').animate({
            scrollTop: scrollTarget
        }, 500);
    });

    $main.on('change', '.js-brand-refinement-store', function() {
        var url = util.appendParamToURL(Urls.storeDetail, 'StoreID', $(this).val());

        url += '#brands-by-store';
        window.location = url;
    });

    if (!util.isMobile()) {
        $main.on('change', '.js-brand-refinement-department', function() {
            /*eslint no-redeclare: "off"*/
            var form = $(this).closest('form'),
                wrapper = $('.js_brands-info'),
                url = form.attr('action'),
                url = util.appendParamToURL(url, 'department', $(this).val()),
                urlHash = 'department=' + $(this).val();

            if (!wrapper.hasClass('submitted-mobile')) {
                wrapper.addClass('submitted-mobile');
            }
            window.location.hash = urlHash;
            loadBrands(window.location.pathname + '?' + window.location.hash.substring(1));
        });

        if (window.location.hash.length > 0) {
            loadBrands(window.location.pathname + '?' + window.location.hash.substring(1));
        }
    }
}
var brandIndex = {
    init: initializeEvents
};

module.exports = brandIndex;
