import Component from '_core_ext/components/Component';
import page from '_core_ext/page';
import quickview from '_core_ext/quickview';
import eventMgr from '_core_ext/eventMgr';
import DD from '_core_ext/components/deviceDetector';
import dialog from '_core_ext/dialog';

const quickViewGlobalEmitter = eventMgr.getEmitter('quickViewGlobal'); 

export default class QuickViewGlobal extends Component {
    static get selector() {
        return '.js-quickview-global';
    }

    get configDataAttrName() {
        return 'component-quickview-global';
    }

    init(...args) {
        //call parent init method with same args
        super.init(...args);
        
        // If quick view is opened from DM and mobile device then we change source param
        this.source = this.$el.data('source') || 'quickview';
        
        this.$document = $(document);
        this.addDefaultEvents();

        DD.startWatchSize();
        eventMgr.on('deviceDetector.deviceChange', () => this._handleDeviceChange());
    }

    addDefaultEvents() {
        this.event('click', this._onQuickViewGlobalClick);
        eventMgr.on('addToCart.product.added', this._onProductAddToCart);
    }

    _onQuickViewGlobalClick(element) {
        var $element = $(element),
            url = $element.attr('href');
        if ($element.hasClass('js-product-url')) {
            quickViewGlobalEmitter.emit('productClick', $element);
        }

        quickview.show({
            url: url,
            source: this.source,
            callback: () => {
                app.components.initComponent('input');
                app.components.initComponent('productvideojs');
                app.components.initComponent('EnergyRating');
                app.components.initComponent('PrintableMessage');
                app.components.initComponent('Expandable');
                app.components.initComponent('AddToWishlist');
                this._handleDeviceChange()
            }
        });

        return false;
    }

    _handleDeviceChange() {
        var settings = dialog.settings;
        var info = DD.getDeviceInfo();
        if (info.device === DD.screen.MOBILE) {
            quickview.$container.dialog('option', {
                height: window.innerHeight,
                width: window.innerWidth,
                left: '0'
            });
        } else {
            quickview.$container.dialog('option',{
                position: settings.position,
                width: settings.width,
                height: settings.height
            });
        }
    }

    _onProductAddToCart() {
        var pc = window.pageContext;
        if (pc && (pc.ns === 'cart' || pc.ns === 'checkout')) {
            page.refresh();
        }
    }
}

module.exports = QuickViewGlobal;
