var validatePhone = require('./phoneValidation.js');

module.exports = function (value, el) {
    var $el = $(el),
         // lookup country in common fieldset and fallback to form if no such
        $form = $el.closest('fieldset');
    if (!$form.length) {
        $form = $el.closest('form');
    }

    var $countryField = $form.find('.js-input-country'),
        
        phone = $el.val(),
        country = $countryField.val(),
        
        isValid = false,
        isOptional = this.optional(el);
    
    if (value) {
        isValid = validatePhone(country, phone);
    } else {
        isValid = isOptional;
    }
    
    if (!isValid) {
        //here can be custom logic for custom error messages for phone
        if (pageContext.ns === 'checkout') {
            $.validator.messages['js-input-phone'] = Resources.INVALID_PHONE_REDESIGN;
        } else {
            $.validator.messages['js-input-phone'] = Resources.INVALID_PHONE;
        }
    }
    return isValid;
};
