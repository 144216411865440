const dialog = require('_core_ext/dialog');
const addToCart = require('_core_ext/pages/product/addToCart');
const eventMgr = require('_core_ext/eventMgr');
const page = require('_core_ext/page');
const noscroll = require('_core_ext/components/noscroll');

var configureDialog = function () {
    var $currentDialog = dialog.getCurrent();
    $currentDialog.find('.js-add-to-cart-active').addClass('sub-product-item');
    addToCart.init($currentDialog);

    $currentDialog[0].style.setProperty('--btplusvh', `${window.innerHeight}px`);

    $currentDialog.on('click', '.js-close', function() {
        dialog.close();
        page.refresh();
    });

    $currentDialog.find('.js-btplus-product-accordion').on('scroll', () => {
        $currentDialog[0].style.setProperty('--btplusvh', `${window.innerHeight}px`);
    });

    eventMgr.on('addToCart.product.added', function() {
        $currentDialog.addClass('pt-product-added');
    });
}

var initEvents = function() {
    $(document).on('click', '.js-bt-plus-popup-show', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(this).attr('href'),
            options: {
                title: $(this).data('title') || Resources.TITLE_BT_PLUS,
                width: '50%',
                dialogClass: 'btplus-content',
                close: () => {
                    $(this).find('.js-btplus-product-accordion').off('scroll');
                    noscroll.disable();
                },
                beforeClose: function () {
                    if ($(this).find('.js-close').is(':visible')) {
                        page.refresh();
                    }
                }
            },
            callback: configureDialog
        })
    });
}

module.exports = {
    init: function () {
        initEvents();
    }
}
