'use strict';

/**
 * @function
 * @description Mobile\tablet carousel-like functionality of product tiles on Compare page
 * 
 */
function initProductScrollAndEvents() {
    var $compareWrapper = $('.compare-table-wrapper');
    var $navPrev;
    var $navNext;
    var scrollEnd;
    var animateSpeed = 300;

    function updateCompareState() {
        if ($compareWrapper.width() < $('.compare-table').width()) {
            if (!$compareWrapper.hasClass('js-scroller-active')) {
                $compareWrapper.addClass('js-scroller-active');
            }
        } else {
            if ($compareWrapper.hasClass('js-scroller-active')) {
                $compareWrapper.removeClass('js-scroller-active');
            }
        }
        updateNavState($compareWrapper.scrollLeft());
    }

    function initCompareState() {
        // add navigation buttons
        $compareWrapper.append('<a class="js-scroller-nav js-scroller-prev"></a><a class="js-scroller-nav js-scroller-next"></a>');
        $navPrev = $compareWrapper.find('.js-scroller-prev');
        $navNext = $compareWrapper.find('.js-scroller-next');
        updateCompareState();
    }

    function updateNavState(scrollLeft) {
        scrollEnd = $compareWrapper.find('.compare-table').outerWidth() - $compareWrapper.innerWidth();
        scrollLeft > 0 ? $navPrev.show() : $navPrev.hide();
        scrollLeft < scrollEnd ? $navNext.show() : $navNext.hide();
    }
    
    initCompareState();

    $('.js-scroller-nav').on('click', function(e){
        var tileWidth = $compareWrapper.find('.product-tile:first').outerWidth();
        var scrollLeft = $compareWrapper.scrollLeft();
        scrollEnd = $compareWrapper.find('.compare-table').outerWidth() - $compareWrapper.innerWidth();

        if ($(this).hasClass('js-scroller-next')) {
            if (scrollLeft + tileWidth > scrollEnd) { scrollLeft = scrollEnd; }
                else scrollLeft = scrollLeft + tileWidth;
        }
        if ($(this).hasClass('js-scroller-prev')) {
            if (scrollLeft - tileWidth < 0) { scrollLeft = 0; } 
                else scrollLeft = scrollLeft - tileWidth;
        }

        $compareWrapper.animate({'scrollLeft': scrollLeft}, animateSpeed);

        updateNavState($(this).scrollLeft());
    });

    $(window).resize(function() {
        updateCompareState(); // update classes and nav buttons state on window resize
    });

    $compareWrapper.scroll(function() {
        updateNavState($(this).scrollLeft()); // update nav button states on manual horizontal scroll
    });
}


exports.init = function ($container) {
    initProductScrollAndEvents();
};