import Component from '_core_ext/components/Component';
const addToCart = require('_core_ext/pages/product/addToCart');

export default class PersonalizeSupersocks extends Component {
    static get selector() {
        return '.js-personalize-supersocks';
    }
    get configDefault() {
        return {};
    }
    get configDataAttrName() {
        return 'supersocks';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        this.opened = false;

        //call parent init method with same args
        super.init(...args);
        this.initEvents();
    }

    initEvents() {
        this.$el.on('click', this.onClick.bind(this));
        $(window).on('beforeunload', this.onBeforeUnload.bind(this));
        window.addEventListener('message', this.onA2CMessage.bind(this));
    }

    onClick(e) {
        e.preventDefault();
        this.openIframe();
    }

    openIframe() {
        this.$el.prop('disabled', true);
        var iframe = $(document.getElementById('supersocks-iframe'));
        iframe.attr('src', this.$el.data('iframe-src'))
            .removeClass('hidden');

        // scroll page to iframe considering height of sticky header
        var offsetTop = iframe.offset().top - 130;
        $(document.documentElement).animate({
            scrollTop: offsetTop
        }, 500);
        this.opened = true;
    }

    closeIframe() {
        this.$el.prop('disabled', false);
        var iframe = $(document.getElementById('supersocks-iframe'));
        iframe.addClass('hidden')
            .removeAttr('src');
        this.opened = false;
    }

    onA2CMessage(e) {
        if (e.data.event_type !== 'supersocks') {
            return;
        }
        var result = addToCart.addItemToCartRaw({
            productId: e.data.id,
            qty: e.data.qty,
            custom: {
                customizationId: e.data.cid
            }
        });
        result.then(() => this.closeIframe());
        addToCart.handleAddedToCart(
            this.$el, 
            this.$el.closest('form'), 
            result
        )
        .catch(() => this.closeIframe());
    }

    onBeforeUnload(e) {
        if (this.opened) {
            e.preventDefault();
            e.returnValue = '';
            return '';
        }
    }
}

module.exports = PersonalizeSupersocks;
