import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

export default class LoyaltyCardSelection extends Component {

    /**
     * Returns elements' selector for initialize
     * @returns {string}
     */
    static get selector() {
        return '.js-component-loyalty-selection';
    }

    /**
     * Method called when creating component
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        const loyaltyDisabled = this.$el.data('loyaltyDisabled');   // when disabled for locale - don't do anything
        const loyaltyEnabledCountries = this.$el.data('loyaltyEnabledCountries');
        
        super.init(...args);

        this.event('click', 'input[type="radio"]', (el) => {
            $('.js-account-birthday').toggleClass('hidden', $(el).attr('id') === 'is-REFUSE_CARD');
        });
        // if countries allowed for loyalty are limited and selected by customer does not match, 
        // loyalty block will be hidden (from outside) and we should preselect Refuse option here
        if (!loyaltyDisabled && loyaltyEnabledCountries) {
            eventMgr.on('CustomerAddress.countryChanged', (countryCode) => {
                const enabledCountry = (loyaltyEnabledCountries.indexOf(countryCode) > -1);
                if (enabledCountry) {
                    this.$el.find('#is-NEW_CARD').click();
                } else {
                    this.$el.find('#is-REFUSE_CARD').click();
                }
            });
        }
    }

    
}

module.exports = LoyaltyCardSelection;
