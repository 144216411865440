import VideoComponent from '_core_ext/components/videoComponent';
import eventMgr from '_core_ext/eventMgr';

export default class ProductVideo extends VideoComponent {
    static get selector() {
        return '.js-product-video';
    }

    _extendConfig() {
        this.config.height = 450;
    }

    /**
     * Shows video player
     * @param {Object} atts
     * @param {jQuery} $targetElem
     */
    showVideo(atts, $targetElem) {
        // do nothing if video to play is not of current component
        if (!this.$el.is($targetElem)) {
            return;
        }

        if (this.$el.find('video').length) {
            this.$el.show();
        } else {
            this._extendConfig();
            
            //video id must be unique, so we will use timestamp
            var id = 'product-video_' + Date.now();
            // create video with poster image at once, then add video source from loaded video json data
            var video = `<div class="video-inner"><video class="video-content video-js vjs-default-skin" id="${id}" poster="${atts.url}"
                controls muted autoplay="muted" preload="auto" playsinline src="${atts.srcVideo}-0x480-600k"></div>`;
            this.$el.html(video).show();

            //var videoURL = atts.videoData;
            //this._loadVideoContent(videoURL, atts.url, this.$el);
        }
    }

    init(...args) {
        super.init(...args);

        this.showVideo = this.showVideo.bind(this);
        
        eventMgr.on('productImage.videoShown', this.showVideo);
        eventMgr.on('productImage.imageShown', () => this.player.pause());
        eventMgr.on('dialog.opened', () => this.player.pause());
        eventMgr.on('dialog.closed', () => this.player.pause());
    }
}

module.exports = ProductVideo;
