var account;
var giftcert = require('_core_ext/giftcert'),
    tooltip = require('_core_ext/components/global/tooltip'),
    util = require('_core_ext/util'),
    dialog = require('_core_ext/dialog'),
    page = require('_core_ext/page'),
    orderhistory = require('_core_ext/pages/account/orderhistory'),
    contactUs = require('_core_ext/pages/account/contactus'),
    eventMgr = require('_core_ext/eventMgr');

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    app.components.initComponent('CustomerAddress');

    var $form = $('.js-contactus-form-wrapper');

    $form.find('input[name="format"]').remove();
    tooltip.init();
    //$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);

    $form.on('click', '.apply-button', function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('.apply-button').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (typeof (data) !== 'string') {
                if (data.success) {
                    dialog.close();
                    page.refresh();
                } else {
                    window.alert(data.message); //NOSONAR
                    return false;
                }
            } else {
                $('#dialog-container').html(data);
                account.init();
                tooltip.init();
            }
        });
    })
        .on('click', '.cancel-button, .close-button', function (e) {
            e.preventDefault();
            dialog.close();
        })
        .on('click', '.delete-button', function (e) {
            e.preventDefault();
            if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) { //NOSONAR
                var url = util.appendParamsToUrl(Urls.deleteAddress, {
                    AddressID: $form.find('#addressid').val(),
                    format: 'ajax'
                });
                $.ajax({
                    url: url,
                    method: 'POST',
                    dataType: 'json'
                }).done(function (data) {
                    if (data.status.toLowerCase() === 'ok') {
                        dialog.close();
                        page.refresh();
                    } else if (data.message.length > 0) {
                        window.alert(data.message); //NOSONAR
                        return false;
                    } else {
                        dialog.close();
                        page.refresh();
                    }
                });
            }
        });

    app.components.initComponent('validator');
}

/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses');
    if (addresses.length === 0) { return; }

    addresses.on('click', '.address-edit, .address-create', function (e) {
        e.preventDefault();
        dialog.open({
            url: this.href,
            options: {
                dialogClass: 'address-popup',
                open: initializeAddressForm
            }
        });
    }).on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) { //NOSONAR
            $.ajax({
                url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    page.redirect(Urls.addressesList);
                } else if (data.message.length > 0) {
                    window.alert(data.message); //NOSONAR
                } else {
                    page.refresh();
                }
            });
        }
    });
}

function initCreateAccountEvents() {
    app.components.initComponent('CustomerAddress');
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }

    util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card'
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        })
            .done(function () {
                page.redirect(Urls.paymentsList);
            });
    });
}

function initNavigationEvents() {
    if (document.getElementById('js-wish-list-switch') !== null) {
        $('.js-wish-list-switch').prop('checked', true).attr('checked', 'checked');
    } else if (document.getElementById('js-orderinfo-switch') !== null) {
        $('.js-orderinfo-switch').prop('checked', true).attr('checked', 'checked');
    } else if (document.getElementById('js-loyalty-account-switch') !== null) {
        $('.js-loyalty-account-switch').prop('checked', true).attr('checked', 'checked');
    } else {
        $('.js-my-account-switch').prop('checked', true).attr('checked', 'checked');
    }
    // start page scrolling (on mobile) after tab expanded
    $('#secondary').find('.tab-content').one('transitionend', () => $(document.documentElement).dequeue('scroll-page'));
}

function handleRegistrationPage() {
    const $form = $(document).find('.js-registration-form');
    const validateOptions = {
        submitHandler: (form, e) => {
            // Is form already submitted
            if ($form.data('isSubmiting') === true) {
                e.preventDefault();
                return false;
            }

            validateContactOptions(e, $form);
            if (!e.isDefaultPrevented()) {
                $form.find('button[type=submit]').addClass('inactive');
                $form.data('isSubmiting', true);
                form.submit();
            }
        },
    }
    if (SitePreferences.PROJECT_ID === 'arnotts') {
        validateOptions.errorElement = 'span';
    }

    $form.validate(validateOptions);

    $form
        .on('click', 'input:checkbox', function (e) {
            handleCustomerSubscribes(e, $form);
        });
    
    // if countries allowed for loyalty are limited and selected by customer does not match, 
    // loyalty and birthday blocks should be hidden
    const loyaltyEnabledCountries = $form.data('loyaltyEnabledCountries');
    if (loyaltyEnabledCountries) {
        const loyaltyBlock = $form.find('.js-component-loyalty-selection'),
            birthdayBlock = $form.find('.js-account-birthday'); 
        eventMgr.on('CustomerAddress.countryChanged', (countryCode) => {
            const enabledCountry = (loyaltyEnabledCountries.indexOf(countryCode) > -1);
            if (enabledCountry) {
                loyaltyBlock.removeClass('hidden');
                birthdayBlock.removeClass('hidden');
            } else {
                loyaltyBlock.addClass('hidden');
                birthdayBlock.addClass('hidden');
            }
        });
    }
}

function handleScroll() {
    const secondary = document.getElementById('secondary');

    if (secondary && util.getCSSContentValue(secondary) === 'js-tablet-nav') {
        var headerStuck = false,
            recalculated = false,
            $scrollToElem = $('.js-account-header');

        // observe when header become sticky
        eventMgr.on('stickyHeader.stick', () => {
            headerStuck = true;
        });

        // schedule scrolling page to main content (on mobile)
        $(document.documentElement).animate({scrollTop: $scrollToElem.offset().top}, {
            easing: 'linear',
            queue: 'scroll-page',   // queued animation, to launch after secondary menu group expanded
            step: function(now, fx) {
                // while page scrolling header becomes sticky, so we adjust animation end value mid-way
                if (headerStuck && !recalculated) {
                    eventMgr.execute('stickyHeader.getHeight').then((stickyHeaderHeight) => {
                        fx.end = $scrollToElem.offset().top - stickyHeaderHeight;
                    });
                    recalculated = true;
                }
            }
        });
    }
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    initCreateAccountEvents();
    initAddressEvents();
    initPaymentEvents();
    initNavigationEvents();
    handleRegistrationPage();
    handleScroll();

    const $form = $(document).find('#RegistrationForm');
    util.removeBrowserAutoFill($form.find('input[type=email], input[type=password]'));
}

const CONTACT_CHANNEL_FIELDS = [
    '.js-input-contactemail',
    '.js-input-contactpost',
    '.js-input-contactsms',
    '.js-input-contactphone',
    '.js-input-arncontactemail',
    '.js-input-arncontactpost',
    '.js-input-arncontactsms',
    '.js-input-arncontactphone'
];

function handleCustomerSubscribes(e, $form) {
    const isHaveClearAction = $(e.currentTarget).data('clearOther'),
        $contactError = $form.find('.js-contact-error');

    if (!$contactError.hasClass('hidden')) {
        $contactError.addClass('hidden');
    }


    if (isHaveClearAction) {
        $form
            .find(CONTACT_CHANNEL_FIELDS.join(', '))
            .prop('checked', false);
    } else {
        $form
            .find('.js-input-contactnone')
            .prop('checked', false);
    }
}

function validateContactOptions(e, $form) {
    if (!checkContactOptions($form)) {
        e.preventDefault();
        $form.find('.js-contact-error').removeClass('hidden');
    }
}

/**
 * Check if at least one contact option is checked.
 * @param {jQuery} $form
 * @returns {boolean} True if any option checked, false otherwise.
 */
function checkContactOptions($form) {
    // check that at least one of contact options including None is selected
    const $contactInputs = $form.find(CONTACT_CHANNEL_FIELDS.concat(['.js-input-contactnone']).join(','));

    if (!$contactInputs.is(':checked')) {
        return false;
    }
    return true;
}

account = {
    init: function () {
        initializeEvents();
        giftcert.init();
        orderhistory.init();
        /* globals pageContext*/
        if ('type' in pageContext && pageContext.type === 'contactus') {
            contactUs.init();
        }
    }
};

module.exports = account;
module.exports.initNavigationEvents = initNavigationEvents;
module.exports.handleSubscribes = handleCustomerSubscribes;
module.exports.validateContactOptions = validateContactOptions;
module.exports.checkContactOptions = checkContactOptions;
module.exports.handleScroll = handleScroll;
module.exports.CONTACT_CHANNEL_FIELDS = CONTACT_CHANNEL_FIELDS;
