var page = require('_core_ext/page'),
    utils = require('_core_ext/components-utils'),
    componentsConfig = require('_core_ext/components-config');

var config = componentsConfig.configuration,
    references = componentsConfig.references;

const eventMgr = require('_core_ext/eventMgr');

/**
 * The reference getter for the page module
 * @param {name}
 * @returns {Object}
 */
function getPage(name) {
    return name && references[name] || {init: () => {}};
}

/**
 * Specific page components getter
 * @param {String} name
 * @returns {Object}
 */
function getPageComponents(name) {
    return name && config[name] && config[name].components || {};
}

/**
 * Global components configuration getter
 * @returns {Object}
 */
function getGlobalComponents() {
    return getPageComponents('global');
}

/**
 * Component reference getter
 * @param {String} page
 * @param {name} name
 * @returns {Object}
 */
function getComponent(page, name) {
    //var components = page && config[page] && config[page].components || {};

    if ('undefined' === typeof name) {
        return getPage(page);
    }

    return references[name] || {};
}

function selectAndCreateComponents(Module, configuration, name, $container) {
    var $elements;

    if (typeof Module.selector === 'string') {
        $elements = ($container || $(document)).find(Module.selector);
    } else {
        $elements = $(Module.selector);
    }

    $elements.each(function () {
        var $el = $(this),
            $initedEl;

        if (!$el.data('componentInstance')) {
            var cmp = new Module(this, configuration);
            if (typeof cmp.init === 'function') {
                $initedEl = cmp.init();
            }
            if ($initedEl && $initedEl instanceof $) {
                $el = $initedEl;
            }
            $el.addClass('js-cmp-inited ' + (name ? 'js-cmp-' + name : '')).data('componentInstance', cmp);
        }
    });
}

/**
 * Component module initization helper
 * @param {Object} module The require reference to the component module
 * @param {Object} configuration Inialization config
 * @param {jQuery Object} $container where need init modules
 * @returns {void}
 */
function initializeModule(module, configuration, name, $container) {
    if (!module) {
        throw new Error('[components]: Component module reference missed');
    }
    if ('undefined' !== typeof module.selector) {
        utils.collectExecutionData(selectAndCreateComponents, null, [module, configuration || {}, name, $container]);
        return;
    }

    if ('function' !== typeof module.init) {
        throw new Error('[components]: The mandatory "init" method missed');
    }

    utils.collectExecutionData(module.init, module, [configuration || {}, name, $container]);
}

/**
 * Component inialization helper
 * @param {String} name The key-name of the component
 * @param {jQuery object} $container for initialization
 * @returns {void}
 */
function initializeComponent(name, $container) {
    if (!isComponentEnabled(name)) {
        return;
    }
    var component = references[name],
        configuration = $.extend(true, {}, getGlobalComponents(), getPageComponents(page.ns)),
        options = configuration[name].options || {};

    try {
        initializeModule(component, options, name, $container);
    } catch (e) {
        utils.handleExceptions(e, '"' + name + '" initialization failed');
    }
}

/**
 * Component inialization helper
 * @param {String} name The key-name of the component
 * @param {jQuery object} $container for initialization
 * @param {Object} options Optional parameters to be passed to component's reInit method
 * @returns {void}
 */
function reInitComponent(name, $container, options) {
    if (!isComponentEnabled(name)) {
        return;
    }
    ($container || $(document)).find('.js-cmp-' + name).each(function () {
        var cmp = $(this).data('componentInstance');
        if (cmp && typeof cmp.reInit === 'function') {
            cmp.reInit(options);
        } else if (cmp && typeof cmp.destroy === 'function' && typeof cmp.init === 'function') {
            cmp.destroy();
            cmp.init(cmp.config);
        }
    });
}

/**
 * Validate the component avaliability on current page
 * @param {String} name
 * @returns {void}
 */
function isComponentEnabled(name) {
    var configuration = $.extend(true, {}, getGlobalComponents(), getPageComponents(page.ns));
    return configuration[name] && ('undefined' === typeof configuration[name].enabled || !!configuration[name].enabled);
}

/**
 * Current components configuration extend method. Implements
 * the possibility to customized configuration extension on brand level
 * @param {Object} componentsConfig
 * @returns {Object} this
 */
function extendConfig(componentsConfig) {
    references = $.extend(true, {}, references, componentsConfig.references || {});
    config = $.extend(true, {}, config, componentsConfig.configuration || {});
    return this;
}

/**
 * The main initialization method for all enabled and availiable components
 * on current page
 * @returs {void}
 */
function initializeAll() {
    var currentPage = getPage(page.ns),
        components = $.extend({}, getGlobalComponents(), getPageComponents(page.ns)),
        initializationCompleted,
        initPromise = new Promise((res) => {
            initializationCompleted = res;
        });

    eventMgr.registerAction('components.initialized', () => initPromise);

    try {
        initializeModule(currentPage, config[page.ns].options, page.ns);
        utils.setExecutionData(page.ns);
    } catch (e) {
        utils.handleExceptions(e, '"' + page.ns + '" initialization failed');
    }

    for (var name in components) {
        if ('undefined' !== typeof components[name].enabled && !components[name].enabled) {
            utils.setInitStatus(name, 'disabled');
            continue;
        }

        try {
            initializeModule(references[name], components[name].options, name);
            utils.setInitStatus(name, 'initialized');
        } catch (e) {
            utils.handleExceptions(e, '"' + name + '" initialization failed');
            utils.setInitStatus(name, 'skipped');
        }
    }

    utils.exposeStatuses();

    initializationCompleted();
}

function destroy(name, $container) {
    if (!isComponentEnabled(name)) {
        return;
    }

    ($container || $(document)).find('.js-cmp-' + name).each(function () {
        const cmp = $(this).data('componentInstance');
        cmp.destroy();
    });
}

module.exports = {
    'get' : getComponent,
    'config' : config,
    'initAll' : initializeAll,
    'initComponent' : initializeComponent,
    'reInitComponent' : reInitComponent,
    'isComponentEnabled' : isComponentEnabled,
    'extendConfig' : extendConfig,
    'destroyComponent' : destroy
};
