const _debounce = require('lodash/function/debounce'),
    eventMgr = require('_core_ext/eventMgr');

/**
 * This module serves as Mediator for Click and Collect page (similar to shipping.js)
 */ 

var billingAddressCmp,
    $form,
    $continueBtn;

/**
 * Performs check of page components (BillingAddress atm) to enable or disable Proceed button.
 */
function updateContinueBtn() {
    let disable = false;
    // disable proceed due to address invalidity only for Registered customer (when there are selectors, not open forms)
    if ($form.hasClass('is-authenticated-customer')) {
        if (!disable && billingAddressCmp && !billingAddressCmp.isValid()) {
            disable = true;
        }
    }
    $continueBtn.prop('disabled', disable);
}

/**
 * Handle component(s)
 */
function registerAddressComponent() {
    eventMgr.on('BillingAddress.attached', (cmpInstance) => {
        billingAddressCmp = cmpInstance;
    });
    
    eventMgr.on('BillingAddress.updated', _debounce(updateContinueBtn, 0));
}

/**
 * There is a Billing address form/selector component (on Arnotts atm),
 * so we have to keep an eye on it and update Proceed button depending on validity of selected address
 * same as on Home delivery page.
 */
exports.init = function () {
    $form = $('[id$="clickandcollecthipping_shippingAddress"]');
    $continueBtn = $('[name$="shippingAddress_save"]');

    registerAddressComponent();
};
