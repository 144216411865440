'use strict';

exports.init = function () {
    $(document).on('click', '.js-tab-href', function() {
        var $el = $(this),
        $target = $($el.data('target'));
        if ($target.length) {
            app.components.reInitComponent('ProductCarousel', $target);
        }
    });
    $('.js-tabs').tabs();
};