var util = require('_core_ext/util');
var eventMgr = require('_core_ext/eventMgr');
var _debounce = require('lodash/function/debounce');

var listTotal = -1;
var listCurrent = -1;
var $resultsContainer;

// mock running jqXHR initially
var runningXhr = {
    abort: $.noop
};

/**
 * @function
 * @description Handles keyboard's arrow keys
 * @param keyCode Code of an arrow key to be handled
 */
function handleArrowKeys(keyCode) {
    switch (keyCode) {
        case 38:
            // keyUp
            listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
            break;
        case 40:
            // keyDown
            listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
            break;
        default:
            // reset
            listCurrent = -1;
            return false;
    }

    $resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
    $('input[name="q"]').val($resultsContainer.find('.selected .suggestionterm').first().text());
    return true;
}

var searchsuggest = {
    /**
     * @function
     * @description Configures parameters and required object instances
     */
    init: function ($searchContainer, defaultValue) {
        this.suggest = this.suggest.bind(this);
        this.clearResults = this.clearResults.bind(this);

        var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
        var $searchField = $searchForm.find('input[name="q"]');
        var $clearSuggestion = $searchContainer.find('.js-clear-suggestion');

        this.updateViewportHeight();
        $(window).on('throttledresize',  this.updateViewportHeight);

        $searchField.attr('placeholder', util.isMobile() ? Resources.SEARCH_MOBILE_PLACEHOLDER : Resources.SEARCH_PLACEHOLDER);

        // disable browser auto complete
        $searchField.attr('autocomplete', 'off');

        // on focus listener (clear default value)
        $searchField.on('focus', () => {
            if (!$resultsContainer) {
                // create results container if needed
                $resultsContainer = $('<div/>').attr('id', 'search-suggestions').appendTo($searchContainer);
                $('#header-search-form-wrapper').addClass('expanded');
            }

            if ($searchField.val() === defaultValue) {
                $searchField.val('');
            }

            setTimeout(() => this.suggest($searchField.val()), SitePreferences.SEARCH_SUGGESTIONS_QUERY_DELAY);
        });

        if ($clearSuggestion.length && util.isMobile()) {
            $clearSuggestion.on('click touchstart tap', () => {
                this.clearResults();
                $searchField.val('');
                runningXhr.abort();
            });
        }

        // on blur listener
        $searchField.on('blur', () => {
            this.clearResults();
            runningXhr.abort();
        });

        // on key up listener
        $searchField.on('keyup', _debounce((e) => {

            // get keyCode (window.event is for IE)
            var keyCode = e.keyCode || window.event.keyCode;

            // check and treat up and down arrows
            if (handleArrowKeys(keyCode)) {
                return;
            }
            // check for an ENTER or ESC
            if (keyCode === 13 || keyCode === 27) {
                this.clearResults();
                runningXhr.abort();
                return;
            }

            this.suggest($searchField.val().trim());
        }, SitePreferences.SEARCH_SUGGESTIONS_QUERY_DELAY));

        // position search bar below sticky header as sticky header can have 1 or 2 lines of menu items
        eventMgr.on('stickyHeader.stick', () => {
            if ($searchContainer.css('position') === 'fixed') {
                eventMgr.execute('stickyHeader.getHeight')
                .then((top) => $searchContainer.css('top', top));
            }
        });
        eventMgr.on('stickyHeader.unstick', () => $searchContainer.css('top', ''));
    },

    /**
     * @description trigger suggest action
     * @param {String} query Search query text
     */
    suggest: function (query) {
        this.updateViewportHeight();

        // if we're here - we should abort any previously running query
        runningXhr.abort();

        // if empty query or below threshold is not allowed - clear result and return
        if (SitePreferences.SEARCH_SUGGESTIONS_QUERY_MIN_LENGTH !== 0
            && query.length < SitePreferences.SEARCH_SUGGESTIONS_QUERY_MIN_LENGTH) {
            this.clearResults();
            return;
        }

        // build the request url avoiding having 'null' value in request as a string
        var reqUrl = util.appendParamsToUrl(Urls.searchsuggest, {
            'q': query || '',
            'legacy': 'false'
        });

        // execute server call and store reference to running query
        runningXhr = $.get(reqUrl, (suggestionHTML) => {
            var ansLength = suggestionHTML.trim().length;

            // if there are results populate the results div
            if (ansLength === 0) {
                this.clearResults();
            } else {
                // update the results div
                $resultsContainer.html(suggestionHTML).fadeIn(200);
                var hasProductSuggestionDiv = $resultsContainer.find('.js-has-product-suggestion');
                if (hasProductSuggestionDiv.length && hasProductSuggestionDiv.data('noProductSuggestion') === true) {
                    $resultsContainer.find('.js-product-suggest-container').remove();
                }
            }

            this.hideLeftPanel();
        });
    },
    /**
     * @function
     * @description
     */
    clearResults: function () {
        if (!$resultsContainer) { return; }
        $resultsContainer.fadeOut(200, function () {
            $resultsContainer.empty();
        });
    },
    /**
     * @function
     * @description
     */
    hideLeftPanel: function () {
        //hide left panel if there is only a matching suggested custom phrase
        if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
            $('.search-suggestion-left-panel').hide();
            $('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper expanded');
            $('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
        }
    },

    updateViewportHeight: function () {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
    }
};

module.exports = searchsuggest;
