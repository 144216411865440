var ajax = require('_core_ext/ajax'),
    tooltip = require('_core_ext/components/global/tooltip'),
    util = require('_core_ext/util');

function onVariationChange(e) {
    e.preventDefault();

    var
        /**
         * jQuery object of `.swatchanchor` or `.variation-select` DOM element
         * @type {jQuery|HTMLElement}
         */
        $this = $(this),

        /**
         * Value that need to find: `input.value` or `a.search`
         * Value matches expression `*?param=value`
         * @type {String}
         */
        valueForSplit = $this.is(':input') ? $this.val() : ($this.attr('search') || this.search),

        /**
         * Splitted value by `?` to find substring after `*?`
         * @type {Array}
         */
        splittedValue = valueForSplit.split('?');

    if (splittedValue.length > 1) {
        var url = Urls.getSetItem + '?' + splittedValue[1];
        var $container = $this.closest('.product-set-item');
        var $pdpMain = $this.closest('.js-pdp-main');
        var qty = $container.find('input[name="Quantity"]').val();
        var psPromo = $container.data('ps-promo');
        url = util.appendParamToURL(url, 'psPromo', psPromo ? psPromo : '');

        ajax.load({
            url: util.appendParamToURL(url, 'Quantity', isNaN(qty) ? '1' : qty),
            target: $container,
            callback: function () {
                _refresh($pdpMain);
                tooltip.init();
                app.components.initComponent('input', $container);
                app.components.initComponent('validator', $container);
            }
        });
    }
    return false;
}
function _refresh($pdpMain) {
    var $addAllToCart = $pdpMain.find('#add-all-to-cart'),
        $productSetList = $pdpMain.find('#product-set-list'),
        $disabledButtons = $productSetList.find('.add-to-cart[disabled], .add-to-cart.add-to-cart-disabled');

    if ($pdpMain.hasClass('js-is-product-set')) {
        $disabledButtons = $disabledButtons
            .filter(function() {
                var $el = $(this);
                if ($el.is('.js-is-product-master, .js-is-product-variation-group')) {
                    var $productSetItem = $el.closest('.js-product-set-item');
                    var $productOOS = $productSetItem.find('.js-product-outofstock');
                    return !$productOOS.length;
                }
                return false;
            });
        
        if ($disabledButtons.length > 0 || $addAllToCart.hasClass('set-disabled')) {
            $addAllToCart.attr('disabled', 'disabled');
        } else {
            $addAllToCart.removeAttr('disabled');
        }
    } else {
        var $addToCart = $pdpMain.find('.js-add-to-cart');
        if ($disabledButtons.length > 0) {
            $addAllToCart.attr('disabled', 'disabled');
            // product set does not have an add-to-cart button, but product bundle does
            $addToCart.attr('disabled', 'disabled');
        } else {
            $addAllToCart.removeAttr('disabled');
            $addToCart.removeAttr('disabled');
        }
    }
}

module.exports = function ($container) {
    $container = $container || $(document);
    var $pdpMain = $container.find('.js-pdp-main');

    var $productSetList = $pdpMain.find('#product-set-list');
    if ($productSetList.length > 0) {
        _refresh($pdpMain);
    }
    // click on swatch for product set
    $productSetList.on('click', '.product-set-item .swatchanchor', onVariationChange);
    $productSetList.on('change', '.product-set-item .variation-select', onVariationChange);
};
