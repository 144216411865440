import Component from '_core_ext/components/Component';
import 'slick-carousel';
import 'waypoints';
import Dialog from 'share-dialog';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';
import fullpage from './fullpage_magazine';
import DD from '_core_ext/components/deviceDetector';
import $ from 'jquery';

var $barbaWrapper = $('#barba-wrapper');

export default class Magazine extends Component {
    static get selector() {
        return '.js-magazine';
    }

    init(...args) {
        //call parent init method with same args
        super.init(...args);
        this._initCache();
        this._initEvents();
        this._initUniqEvents();
        this._initMagazinePage();

        $(document).on('magazinereinit', () => {
            if ($('.article-slideout-trigger').length) {
                $('.js-article-slideout').show();
            }
            this._initUniqEvents();
            this._initMagazinePage();
            this._updateShareLinks();

            app.components.initComponent('QuickViewGlobal', this.$el);
            app.components.initComponent('availability', this.$el);
            this.pageEmiter.emit('newView', {
                url : window.location.pathname,
                title: document.title
            });
        });

        // to get 'deviceDetector.deviceChange' fired when device orientation changed
        DD.startWatchSize();
    }

    _initCache() {
        this.$window = $(window);
        this.pageEmiter = eventMgr.getEmitter('page');
    }

    _initEvents() {
        var pageScrollTop = 0;
        //hook for mac device
        var pageBodyScrollTop = 0;

        $('.js-magazine-share').on('click', function () {
            $(this).toggleClass('expanded');
        });

        $('.js-page-feature').on('click', (e) => {
            e.preventDefault();
            var $featuresWrapper = $('.js-features-page-wrapper');
            var pageFeatureBtn = e.currentTarget;
            var $magazineShareBtn = $('.js-magazine-share');

            if ($featuresWrapper.hasClass('invisible')) {
                pageScrollTop = $('html,body').scrollTop();
                pageBodyScrollTop = $('body').scrollTop();
                $magazineShareBtn.removeClass('expanded')
                                      .addClass('menu-close');
                $barbaWrapper.addClass('expanded');
                $barbaWrapper.find('.article-page').scrollTop(Math.max(pageBodyScrollTop, pageScrollTop));

                if ($featuresWrapper.html()) {
                    $featuresWrapper.removeClass('invisible');
                } else {
                    var url = $(pageFeatureBtn).attr('href');
                    $.ajax({
                        type: 'GET',
                        dataType: 'html',
                        url: url,
                        success: function (response) {
                            $featuresWrapper.html(response).removeClass('invisible');
                        }
                    });
                }
            } else {
                $featuresWrapper.addClass('invisible');
                $magazineShareBtn.removeClass('menu-close')
                                      .addClass('expanded');
                $barbaWrapper.removeClass('expanded');
                $('html,body').scrollTop(pageScrollTop);
                //hook for mac device
                $('body').scrollTop(pageBodyScrollTop);
            }
        });

        eventMgr.on('deviceDetector.deviceChange', () => {
            this._coverVideoConfig();
            this._stickyStack();
            this._slideOut();
        });

        $('.js-magazine-share-facebook').on('click', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            var facebook = Dialog.facebook(url);
            facebook.open();
        });

        $('.js-magazine-share-twitter').on('click', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            var text = $(this).data('text');
            var twitter = Dialog.twitter(url, text);
            twitter.open();
        });
    }

    _initUniqEvents() {
        $('.js-hotspot').on('click', (e) => {
            var productHotspotClass = $(e.currentTarget).data('toggle'),
                currentHotspotProduct = this.$el.find('.'+productHotspotClass),
                isCurrentHotspotProductOpen = !currentHotspotProduct.hasClass('invisible'),
                $hotspotProducts = this.$el.find('[class^=js-product-hotspot-]');
            $hotspotProducts.addClass('invisible');
            !isCurrentHotspotProductOpen && currentHotspotProduct.removeClass('invisible');
        });

        $('.js-show-caption').on('click', function(){
            var $imgCaptionText = $(this).parent().parent().find('.js-image-caption-text');
            $imgCaptionText.toggleClass('hidden');
        });
    }

    _initMagazinePage() {
        this._coverVideoConfig();
        this._articleMediaCarouselInit();
        this._fiftyfiftySlide();
        this._stickyStack();
        this._fullPageGallery();
        this._productCarousel();
        this._slideOut();
        this._slideAdvert();
    }

    _coverVideoConfig() {
        var $bgVideo = this.$el.find('.js-bg-video');
        var video = $bgVideo.get(0);
        var windowWidth = this.$window.width();

        if (video) {
            if (windowWidth < 1400) {
                $bgVideo.prop('muted', true);
            } else {
                $bgVideo.prop('volume', 1);
                $bgVideo.prop('muted', false);
            }
            $bgVideo.prop('controls', false);
            var promise = video.play();
            if (promise !== undefined) {
                promise.catch(error => {
                    $bgVideo.prop('muted', true);
                    video.play();
                });
            }
        }
    }

    _articleMediaCarouselInit() {
        var $slickSlider = this.$el.find('.js-article-media-carousel');
        if ($slickSlider.length > 0) {
            var settings1 = {
                dots: true,
                infinite: true,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                arrows: false
            };

            $slickSlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                var i = (currentSlide ? currentSlide : 0) + 1;
                $(this).closest('.article-page-media-inner').find('.media-slider-pagination').text(i + ' / ' + slick.slideCount);
            });

            $slickSlider.slick(settings1);
        }
    }

    //fiftyfifty slider
    _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {value: value, enumerable: true, configurable: true, writable: true});
        } else {
            obj[key] = value;
        }
        return obj;
    }

    _fiftyfiftySlide() {
        if ($('.double-slider-page').length > 0) {
            var _settings2;

            var settings = {
                dots: true,
                infinite: true,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                arrows: false,
                asNavFor: '.js-article-slide-content-part'
            };

            if ($('.js-article-slide-media-part').length > 0) {
                $('.js-article-slide-media-part').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                    var i = (currentSlide ? currentSlide : 0) + 1;
                    $('.double-slider-page').find('.media-slider-pagination').text(i + ' / ' + slick.slideCount);
                });

                $('.js-article-slide-media-part').slick(settings);
                settings = (_settings2 = {
                    dots: false,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
                this._defineProperty(_settings2, 'arrows', false),
                this._defineProperty(_settings2, 'fade', true),
                this._defineProperty(_settings2, 'asNavFor', '.js-article-slide-media-part'),
                _settings2);

                $('.js-article-slide-content-part').slick(settings);
            }
        }
    }

    // Article Media Stick Init
    _makeSticky() {
        if ($('.js-article-page-media-stack').length > 0) {
            $('.js-article-page-media-stack').stick_in_parent({
                spacer: false
            });
        }
    }

    _stickyStack() {
        var windowWidth = this.$window.width();
        if (windowWidth < 1024) {
            $('.js-article-page-media-stack').trigger('sticky_kit:detach');
        } else {
            this._makeSticky();
        }
    }

    _fullPageGallery() {
        if ($('html').hasClass('fp-enabled')) {
            $.fn.fullpage.destroy('all');
        }

        var $status = $('.fullpage-pagination');
        $status.show();
        var count_slide = $('.js-magazine-fullpage .js-magazine-fullpage-section').length;
        if ($('.js-magazine-fullpage').length > 0) {
            $('.js-magazine-fullpage').fullpage({
                verticalCentered: false,
                navigation: true,
                sectionSelector: '.js-magazine-fullpage-section',
                fullPageWrapper: '.fullpage',
                normalScrollElements: '.js-image-caption-text, .js-features-page-wrapper',
                afterLoad: function afterLoad(anchorLink, index) {
                    $status.text(index + ' of ' + count_slide);

                    if (index === count_slide) {
                        $('.fullpage-pagination').addClass('endpag');
                    } else {
                        $('.fullpage-pagination').removeClass('endpag');
                    }
                }
            });
        }
    }

    _productCarousel() {
        var $productcarousel = $('.js-magazine-product-carousel');
        var settings = {
            dots: true,
            infinite: true,
            speed: 600,
            centerMode: true,
            centerPadding: '26%',
            slidesToShow: 1,
            mobileFirst: true,
            arrows: false
        };

        $productcarousel.slick(settings);
    }

    _slideOut() {
        $(".js-close-slideout").on("click", function (e) {
            $('.js-article-slideout').addClass('article-slideout-closed');
        });
        if ($('.article-slideout-trigger').length) {
           var waypoint = new Waypoint({
                element: $('.article-slideout-trigger')[0],
                handler: function(direction) {
                   if (direction === 'down') {
                       $('.js-article-slideout').removeClass('article-slideout-closed');
                    } else {
                        $('.js-article-slideout').addClass('article-slideout-closed');
                    }
                },
                offset: '60%'
           });
        }
    }

    _slideAdvert() {
        if ($('.js-advert-page').length) {
            setTimeout(function() {
                if ($('.js-advert-page').length) { //check if user not change page and still is on Advert one
                    $('a.next')[0].click();
                }
            }, 10000);
        }
    }
    _updateShareLinks() {
        var currentLink = util.getCurrentURL();

        $('.js-magazine-share-list a').each(function(index, element) {
            var $link = $(element);
            if ($link.hasClass('js-magazine-share-google')) {
                $link.attr('href', util.replaceParameterInURL($link.attr('href'), 'url', encodeURIComponent(currentLink)));
            } else {
                $link.attr('href', currentLink);
            }
        });
    }
}

module.exports = Magazine;
