import AddressAbstract from '_core_ext/components/address/AddressAbstract';
import dialog from '_core_ext/dialog';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('CustomerAddress');

export default class CustomerAddress extends AddressAbstract {
    /*
     * used for re-initialization of required (current) component
     */
    getComponentName() {
        return 'CustomerAddress';
    }
    
    _initEvents(...args) {
        //call parent init method with same args
        super._initEvents(...args);

        this.event('click', '.js-cancel-customer-address', (element, event) => {
            event.preventDefault();
            dialog.close();
        });
    }
    
    onFieldChanged(elem) {
        const $el = $(elem); 
        if ($el.hasClass('js-input-country')) {
            emitter.emit('countryChanged', $el.val());
        }
    }
}

module.exports = CustomerAddress;
