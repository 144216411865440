import Component from '_core_ext/components/Component';
import util from '_core_ext/util';

export default class StickyBottom extends Component {
    static get selector() {
        return '.js-sticky-bottom';
    }
    get configDefault() {
        return {
            isMobileOnly: false,
            stickedCSSClass: 'is-sticked-bottom'
        };
    }
    get configDataAttrName() {
        return 'sticky-bottom';
    }
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        if (this.config.isMobileOnly && !util.isMobile()) {
            return;
        }

        this.initCache();
        this.reCalculate();
        this.initEvents();

    }

    initCache() {
        this.$container = this.$el.parent();
        this.$window = $(window);
    }

    initEvents() {
        this.$window.on('scroll', this.reCalculate.bind(this));
    }

    reCalculate() {
        var scrollTop = this.$window.scrollTop(),
            windowHeight = this.$window.height(),
            windowBottom = scrollTop + windowHeight,

            containerBottom = this.$container.height() + this.$container.position().top;

        this.$el.toggleClass(this.config.stickedCSSClass, windowBottom < containerBottom);
    }
}

module.exports = StickyBottom;
