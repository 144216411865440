var util = require('_core_ext/util');

/**
 * @function
 * @description add side bar scroll on container
 */
exports.init = function () {
    // Order summary custom scroll
    $.fn.isVisible = function() {
        var rect = this[0].getBoundingClientRect();
        return (
            (rect.height > 0 || rect.width > 0) &&
            rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.left <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    if ($('.level-1').length === 0) {return false;} // exit as this isn't the case to apply this sidebar js

    var $window = $(window),
        $sidebar = $('.js_sidebar'),
        $header = $('#header-wrapper');

    if (!util.isTouch()) {
        if ($sidebar.length > 0) {
            var initialSidebarRight = $window.width() - ($sidebar.offset().left + $sidebar.outerWidth());
            var sidebarSetup = $sidebar.data('sidebarSetup');
            var marginTop = 50;
            var $bottomTarget;

            if (sidebarSetup && sidebarSetup.bottomTarget) {
                $bottomTarget = $(sidebarSetup.bottomTarget);
            }

            if (!($bottomTarget && $bottomTarget.length)) {
                $bottomTarget = $('.footer');
            }

            if (sidebarSetup && sidebarSetup.calculateTop) {
                var $jsHeader = $header.find('.js-header');
                var margingTopAdd = parseInt(sidebarSetup.topAdd, 10) || 0;
                marginTop = function () {
                    return $jsHeader.height() + margingTopAdd;
                }
            }
            $sidebar.scrollToFixed({
                marginTop: marginTop,
                limit: function () {
                    var limit = $bottomTarget.offset().top - $(this).outerHeight(true) - 10;
                    return limit;
                },
                right : initialSidebarRight,
                zIndex: 19
            });
        }
    }
};
