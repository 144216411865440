'use strict';

function setFocusOnPasswordField() {
    var $form = $('form[id$="_loginintercept"]');
    var email = $form.find('.js-input-email').val();

    if (email) {
        $form.find('.js-input-password').focus();
    }
}

function initializeDom() {
    setFocusOnPasswordField();
}

exports.init = function() {
    initializeDom();
};