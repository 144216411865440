import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import dialog from '_core_ext/dialog';

export default class Dialogify extends Component {
    static get selector() {
        return '.js-dialogify';
    }
    get configDefault() {
        return {
            url : {
                format : 'ajax'
            },
            //see: http://api.jqueryui.com/dialog/
            dialog : {
                title : null,
                width : 'auto',
                height : 'auto'
            },
            innerComponents: [],
            restorePreviousDialog: false,
            closeBtnSelector: null
        };
    }
    get configDataAttrName() {
        return 'dialogify-options';
    }
    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);
        
        if (this.config.restorePreviousDialog && dialog.isOpen()) {
            const prevDialog = dialog.getCurrent();
            this.config.dialog.close = function () {
                dialog.restore(prevDialog);
            }
        }

        this._initEvents();
    }
    _initEvents() {
        this.event('click', (element, event) => {
            var $el = $(element);
            event.preventDefault();
            dialog.open({
                url: util.appendParamsToUrl($el.attr('href'), this.config.url),
                options: this.config.dialog,
                callback: this.callbackEvents.bind(this, dialog)
            });
            return false;
        });
    }
    _initInnerComponents(dialog, components) {
        components.forEach((component) => {
            app.components.initComponent(component, dialog.getCurrent());
        });
    }
    callbackEvents(dialog) {
        if (this.config.closeBtnSelector) {
            dialog.getCurrent().on('click', this.config.closeBtnSelector, () => {
                dialog.close();
            })
        }

        this._initInnerComponents(dialog, this.config.innerComponents);
    }
}

module.exports = Dialogify;
