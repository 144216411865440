import Component from '_core_ext/components/Component';
import Barba from '_core_ext/barba';
import 'gsap';
import 'waypoints';
import '_core_ext/lib/modernizr';
import util from '_core_ext/util';

export default class BarbaMagazine extends Component {
    static get selector() {
        return '.barba-wrapper';
    }

    init(...args) {
        var lastElementClicked;
        var PrevLink = document.querySelector('a.prev');
        var NextLink = document.querySelector('a.next');

        Barba.Pjax.handleClassLink = 'js-barba-link';
        Barba.Pjax.init();
        
        Barba.Prefetch.handleClassLink = 'js-barba-link';
        Barba.Prefetch.init();
        Barba.Utils.xhrTimeout = 0;
        this._initEvents();

        Barba.Dispatcher.on('linkClicked', function(el) {
            lastElementClicked = el;
        });

        //barba page transistion - fade
        var FadeTransition = Barba.BaseTransition.extend({
            start: function() {
                /**
                 * This function is automatically called as soon the Transition starts
                 * this.newContainerLoading is a Promise for the loading of the new container
                 * (Barba.js also comes with an handy Promise polyfill!)
                 */
                // As soon the loading is finished and the old page is faded out, let's fade the new page
                Promise
                  .all([this.newContainerLoading, this.fadeOut()])
                  .then(this.fadeIn.bind(this));
            },

            fadeOut: function() {
                /**
             * this.oldContainer is the HTMLElement of the old Container
             */
                return $(this.oldContainer).animate({ opacity: 0 }).promise();
            },

            fadeIn: function() {
                /**
                * this.newContainer is the HTMLElement of the new Container
                * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
                * Please note, newContainer is available just after newContainerLoading is resolved!
                */
                var _this = this;
                var $el = $(this.newContainer);
    
                $(this.oldContainer).hide();
    
                window.scroll(0, 0);
    
                $el.css({
                    visibility : 'visible',
                    opacity : 0
                });
    
                $el.animate({ opacity: 1 }, 400, function() {
                    _this.done();
                });
                this.closeMenu();
            },


            updateLinks: function() {
                if (this.newContainer.dataset.prev) {
                    PrevLink.href = this.newContainer.dataset.prev;
                    $(PrevLink).removeClass('hidden')
                } else {
                    PrevLink.href = '';
                    $(PrevLink).addClass('hidden')
                }
                NextLink.href = this.newContainer.dataset.next;
            },

            closeMenu: function() {
                $('.js-features-page-wrapper').addClass('invisible');
                $('.js-magazine-share').removeClass('expanded').removeClass('menu-close');
                $('#barba-wrapper').removeClass('expanded');                
            },
            
            done: function() {
                this.updateLinks();
                this.oldContainer.parentNode.removeChild(this.oldContainer);
                this.newContainer.style.visibility = 'visible';
                this.deferred.resolve();
                $(document).trigger('magazinereinit');
            }

        });

        //barba page transistion - slide
        var SlidePage = Barba.BaseTransition.extend({
            start: function() {
                this.originalThumb = lastElementClicked;

            Promise
               .all([this.newContainerLoading, this.scrollTop()])
               .then(this.movePages.bind(this));
            },

            scrollTop: function() {
                var deferred = Barba.Utils.deferred();
                var obj = { y: window.pageYOffset };

                TweenLite.to(obj, 0.4, {
                    y: 0,
                    onUpdate: function() {
                        if (obj.y === 0) {
                            deferred.resolve();
                        }
                        window.scroll(0, obj.y);
                      },
                    onComplete: function() {
                        deferred.resolve();
                    }
                });
                return deferred.promise;
            },

            movePages: function() {
                var _this = this;
                var goingForward = true;
                var dir = 'next';
                Waypoint.disableAll();
                if (this.oldContainer.dataset.prev && this.getNewPageFile() === this.oldContainer.dataset.prev.split('/').pop()) {
                    goingForward = false;
                    dir = 'prev';
                }

                TweenLite.set(this.newContainer, {
                    visibility: 'visible',
                    xPercent: goingForward ? 100 : -100,
                    position: 'fixed',
                    left: 0,
                    top: util.getHeaderHeight(),
                    right: 0
                });

                TweenLite.to(this.oldContainer, 0.6, { xPercent: goingForward ? 0 : 0 });
                TweenLite.to(this.newContainer, 0.6, { xPercent: 0, onComplete: function() {
                    TweenLite.set(_this.newContainer, { clearProps: 'all' });
                    _this.done();
                }});
            },

            updateLinks: function() {
                if (this.newContainer.dataset.prev) {
                    PrevLink.href = this.newContainer.dataset.prev;
                    $(PrevLink).removeClass('hidden')
                } else {
                    PrevLink.href = '';
                    $(PrevLink).addClass('hidden')
                }

              NextLink.href = this.newContainer.dataset.next;
            },

            getNewPageFile: function() {
              return Barba.HistoryManager.currentStatus().url.split('/').pop();
            },
          
            done: function() {
                this.updateLinks();
                this.oldContainer.parentNode.removeChild(this.oldContainer);
                this.newContainer.style.visibility = 'visible';
                this.deferred.resolve();
                $(document).trigger('magazinereinit');
            }
        });


        //barba page transistion - flip
        var FlipPage = Barba.BaseTransition.extend({
            start: function() {
                this.originalThumb = lastElementClicked;

                Promise
                .all([this.newContainerLoading, this.scrollTop()])
                .then(this.movePages.bind(this));
            },

            scrollTop: function() {
                var deferred = Barba.Utils.deferred();
                var obj = { y: window.pageYOffset };

                TweenLite.to(obj, 0.4, {
                  y: 0,
                  onUpdate: function() {
                    if (obj.y === 0) {
                      deferred.resolve();
                    }

                    window.scroll(0, obj.y);
                  },
                  onComplete: function() {
                    deferred.resolve();
                  }
                });

                return deferred.promise;
            },

            movePages: function() {
                var _this = this;
                var goingForward = true;
                var dir = 'next';
                if (this.oldContainer.dataset.prev && this.getNewPageFile() === this.oldContainer.dataset.prev.split('/').pop()) {
                  goingForward = false;
                  dir = 'prev';
                }

                // global
                var $window = $(window),
                    Modernizr = window.Modernizr;

                // https://gist.github.com/edankwan/4389601
                Modernizr.addTest('csstransformspreserve3d', function () {
                    var prop = Modernizr.prefixed('transformStyle');
                    var val = 'preserve-3d';
                    var computedStyle;
                    if(!prop) return false;

                    prop = prop.replace(/([A-Z])/g, function(str,m1){ return '-' + m1.toLowerCase(); }).replace(/^ms-/,'-ms-');

                    Modernizr.testStyles('#modernizr{' + prop + ':' + val + ';}', function (el, rule) {
                        computedStyle = window.getComputedStyle ? getComputedStyle(el, null).getPropertyValue(prop) : '';
                    });
                return (computedStyle === val);
            });


            /* page flip */
            this.$el = $('#barba-wrapper');
            this.elWidth = this.$el.width();
            var transEndEventNames = {
                'WebkitTransition': 'webkitTransitionEnd',
                'MozTransition': 'transitionend',
                'OTransition': 'oTransitionEnd',
                'msTransition': 'MSTransitionEnd',
                'transition': 'transitionend'
            };
            this.transEndEventName = transEndEventNames[Modernizr.prefixed( 'transition' )] + '.bookblock';
            // support css 3d transforms && css transitions && Modernizr.csstransformspreserve3d
            this.support = Modernizr.csstransitions && Modernizr.csstransforms3d && Modernizr.csstransformspreserve3d;


            this.speed = 1000;
            this.easing = 'ease-in-out';
            this.shadows = true;
            this.shadowSides = 0.4;
            this.shadowFlip = 0.4;

            //After trigger stack page, make sure bookblock prespective is ON and transition works as it is
            var $bookblock = $(".bb-bookblock"); 
            $bookblock.css("-webkit-perspective","2000");
            $bookblock.css("perspective","2000");
            $bookblock.css("z-index","100");
            $bookblock.css("position","relative");
            $("body").css("overflow", "hidden");
            //$(".bb-bookblock").css("overflow", "hidden");
            $(".js-article-page-media-stack").css("position","relative");

            // hide some page elements so they don't appear in the flip
            $('.js-article-slideout').hide();
            Waypoint.disableAll();
            $('.fullpage-pagination').hide();
            $('.bgvid').hide();

            this.$items = this.$el.children( '.bb-item' );

            var self = this,
                // basic structure: 1 element for the left side.
                $s_left = this._addSide( 'left', dir ),
                // 1 element for the flipping/middle page
                $s_middle = this._addSide( 'middle', dir ),
                // 1 element for the right side
                $s_right = this._addSide( 'right', dir ),
                // overlays
                $o_left = $s_left.find( 'div.bb-overlay' ),
                $o_middle_f = $s_middle.find( 'div.bb-flipoverlay:first' ),
                $o_middle_b = $s_middle.find( 'div.bb-flipoverlay:last' ),
                $o_right = $s_right.find( 'div.bb-overlay' ),
                speed = this.end ? 400 : this.speed;

            //this.$items.hide();
            this.$el.prepend( $s_left, $s_middle, $s_right );

            $s_middle.css({
              transitionDuration: speed + 'ms',
              transitionTimingFunction : this.easing
            }).on( this.transEndEventName, function( event ) {
                if ( $( event.target ).hasClass( 'bb-page' ) ) {
                    self.$el.children( '.bb-page' ).remove();
                    //self.$nextItem.show();
                    self.end = false;
                    self.isAnimating = false;
                    var isLimit = dir === 'next' && self.current === self.itemsCount - 1 || dir === 'prev' && self.current === 0;
                    // callback trigger
                    _this.done();
               }
            });

            if ( dir === 'prev' ) {
              $s_middle.addClass( 'bb-flip-initial' );
            }

            // overlays
            if (this.shadows && !this.end) {
              var o_left_style = (dir === 'next') ? {
                      transition: 'opacity ' + this.speed / 2 + 'ms ' + 'linear' + ' ' + this.speed / 2 + 'ms'
                } : {
                    transition: 'opacity ' + this.speed / 2 + 'ms ' + 'linear',
                    opacity: this.shadowSides
                },
                o_middle_f_style = (dir === 'next') ? {
                    transition: 'opacity ' + this.speed / 2 + 'ms ' + 'linear'
                } : {
                    transition: 'opacity ' + this.speed / 2 + 'ms ' + 'linear' + ' ' + this.speed / 2 + 'ms',
                      opacity: this.shadowFlip
                },
                o_middle_b_style = (dir === 'next') ? {
                    transition: 'opacity ' + this.speed / 2 + 'ms ' + 'linear' + ' ' + this.speed / 2 + 'ms',
                    opacity: this.shadowFlip
                } : {
                    transition: 'opacity ' + this.speed / 2 + 'ms ' + 'linear'
                },
                o_right_style = (dir === 'next') ? {
                    transition: 'opacity ' + this.speed / 2 + 'ms ' + 'linear',
                    opacity: this.shadowSides
                } : {
                    transition: 'opacity ' + this.speed / 2 + 'ms ' + 'linear' + ' ' + this.speed / 2 + 'ms'
                };

              $o_middle_f.css(o_middle_f_style);
              $o_middle_b.css(o_middle_b_style);
              $o_left.css(o_left_style);
              $o_right.css(o_right_style);

            }

            setTimeout( function() {
              // first && last pages lift slightly up when we can't go further
              $s_middle.addClass( self.end ? 'bb-flip-' + dir + '-end' : 'bb-flip-' + dir );

              // overlays
              if ( self.shadows && !self.end ) {
                  $o_middle_f.css({
                    opacity: dir === 'next' ? self.shadowFlip : 0
                  });

                  $o_middle_b.css({
                      opacity: dir === 'next' ? 0 : self.shadowFlip
                  });

                  $o_left.css({
                      opacity: dir === 'next' ? self.shadowSides : 0
                  });

                  $o_right.css({
                      opacity: dir === 'next' ? 0 : self.shadowSides
                  });
              }

            }, 25 );
            setTimeout( function() {
                var $bookblock = $(".bb-bookblock"); 
                $bookblock.css("-webkit-perspective","");
                $bookblock.css("perspective","");
                $bookblock.css("z-index","");
                $bookblock.css("position","");
                $("body").css("overflow", "");
            }, this.speed);
          },

          // creates the necessary layout for the 3d structure

          // adds the necessary sides (bb-page) to the layout
          _addSide : function( side, dir ) {
            var $side;

            switch (side) {
              case 'left':
                  /*
                  <div class="bb-page" style="z-index:102;">
                    <div class="bb-back">
                      <div class="bb-outer">
                        <div class="bb-content">
                          <div class="bb-inner">
                            dir==='next' ? [content of current page] : [content of next page]
                          </div>
                        </div>
                        <div class="bb-overlay"></div>
                      </div>
                    </div>
                  </div>
                  */
                $side = $('<div class="bb-page"><div class="bb-back"><div class="bb-outer"><div class="bb-content"><div class="bb-inner">' + ( dir === 'next' ? $(this.oldContainer).html() : $(this.newContainer).html() ) + '</div></div><div class="bb-overlay"></div></div></div></div>').css( 'z-index', 102 );
                break;
              case 'middle':
                  /*
                  <div class="bb-page" style="z-index:103;">
                    <div class="bb-front">
                      <div class="bb-outer">
                        <div class="bb-content">
                          <div class="bb-inner">
                            dir==='next' ? [content of current page] : [content of next page]
                          </div>
                        </div>
                        <div class="bb-flipoverlay"></div>
                      </div>
                    </div>
                    <div class="bb-back">
                      <div class="bb-outer">
                        <div class="bb-content">
                          <div class="bb-inner">
                            dir==='next' ? [content of next page] : [content of current page]
                          </div>
                        </div>
                        <div class="bb-flipoverlay"></div>
                      </div>
                    </div>
                  </div>
                  */
                $side = $('<div class="bb-page"><div class="bb-front"><div class="bb-outer"><div class="bb-content"><div class="bb-inner">' + (dir === 'next' ? $(this.oldContainer).html() : $(this.newContainer).html()) + '</div></div><div class="bb-flipoverlay"></div></div></div><div class="bb-back"><div class="bb-outer"><div class="bb-content" style="width:' + this.elWidth + 'px"><div class="bb-inner">' + ( dir === 'next' ? $(this.newContainer).html() : $(this.oldContainer).html() ) + '</div></div><div class="bb-flipoverlay"></div></div></div></div>').css( 'z-index', 103 );
                break;
              case 'right':
                  /*
                  <div class="bb-page" style="z-index:101;">
                    <div class="bb-front">
                      <div class="bb-outer">
                        <div class="bb-content">
                          <div class="bb-inner">
                            dir==='next' ? [content of next page] : [content of current page]
                          </div>
                        </div>
                        <div class="bb-overlay"></div>
                      </div>
                    </div>
                  </div>
                  */
                $side = $('<div class="bb-page"><div class="bb-front"><div class="bb-outer"><div class="bb-content"><div class="bb-inner">' + ( dir === 'next' ? $(this.newContainer).html() : $(this.oldContainer).html() ) + '</div></div><div class="bb-overlay"></div></div></div></div>').css( 'z-index', 101 );
                break;
            }

                return $side;
            },

            updateLinks: function() {
                if (this.newContainer.dataset.prev) {
                    PrevLink.href = this.newContainer.dataset.prev;
                    $(PrevLink).removeClass('hidden')
                } else {
                    PrevLink.href = '';
                    $(PrevLink).addClass('hidden')
                }

                NextLink.href = this.newContainer.dataset.next;
            },

            getNewPageFile: function() {
                return Barba.HistoryManager.currentStatus().url.split('/').pop();
            },
          
            done: function() {
                this.updateLinks();
                this.oldContainer.parentNode.removeChild(this.oldContainer);
                this.newContainer.style.visibility = 'visible';
                this.deferred.resolve();
                $(document).trigger('magazinereinit');
            },
        });

        Barba.Pjax.getTransition = function() {


          // if left right arrow clicked slide/flip page, else fade in
          if($(lastElementClicked).hasClass('pagenav')){
        	$(NextLink).removeAttr("href");
        	$(PrevLink).removeAttr("href");
            // slide on all touch devices and IE, flip on other
            if (util.isTouch() || this.isIE()) {
                return SlidePage;
            }else{
                return FlipPage;
            }

          }else{
             return FadeTransition;
          }
        };
    }

    _initEvents() {
        //Event tracking for main nav clicks
        $('body').on('click', 'div.menu-tracking a', function(){
          var eventLabel = jQuery(this).attr('href');
          dataLayer.push({
            'event' : 'gaEvent',
            'eventCategory' : 'Menu',
            'eventAction' : 'Navigation',
            'eventLabel' : eventLabel
          })
        });
    }
}
module.exports = BarbaMagazine;
