export default class Component {
    /**
     * Method called when creating component
     * 
     * @param {String|Object} selector CSS/jQuery selector/jQuery Object
     * @param {Object} config
     * @example
     * 
     * <pre><code>
     * constructor(...args) {
     *     //call parent constructor with same args
     *     super(...args);
     *     //custom code here
     * }
     * </code></pre>
     */
    constructor (el, config = {}) {
        this.$el = el instanceof $ ? el : $(el);
        this.listeners = [];
        this.initConfig(config);
        
        if (this.config.cmpId) {
            this.id = this.config.cmpId;
        } else {
            if (this.$el.attr('id')) {
                this.id = this.$el.attr('id');
            }
            if (!this.id && this.config.id) {
                this.id = this.config.id;
            }
        }
    }

    initConfig(config = {}) {
        this.config = $.extend(true, {}, this.configDefault, config, this.$el.data(this.configDataAttrName));
    }

    /**
     * for correct work, must be redeclared at extended class,
     * if selector is not document
     * 
     * @example
     * 
     * <pre><code>
     * static selector() {
     *     return document;
     * }
     * static selector() {
     *     return '.js-component-selector';
     * }
     * </code></pre>
     */
    static get selector() {
        return '.js-component';
    }

    /**
     * for correct work, must be redeclared at extended class (if config required)
     * 
     * @example
     * 
     * <pre><code>
     * get configDataAttrName() {
     *     return 'componentCustom';
     * }
     * </code></pre>
     */
    get configDataAttrName() {
        return undefined;
    }

    /**
     * for correct work, must be redeclared at extended class (if config required)
     * 
     * @example
     * 
     * <pre><code>
     * get configDefault() {
     *     return {
     *         'attr1' : 'val1',
     *         'attrN' : 'valN'
     *     };
     * }
     * </code></pre>
     */
    get configDefault() {
        return {};
    }

    /**
     * Method called when component must be destroyed
     */
    destroy () {
        var event, $element;
 
        while ((event = this.listeners.pop())) {
            $element = event.shift();
            /* eslint prefer-spread: "off" */
            $element.off.apply($element, event);
        }
        this.$el = null;
    }

    /**
     * Assign action to event for DOM element
     * 
     * @param {String} eventName ex: 'click', 'change'
     * @param {String} selector CSS/jQuery selector
     * @param {function} cb
     * @param {JQueryElement} $element
     */
    event (eventName, selector, cb, $element) {
        var self = this,
            fn = function () {
                return cb.apply(self, [this].concat(Array.prototype.slice.call(arguments)));
            };
 
        if (typeof selector === 'function') {
            $element = cb || self.$el;
            cb = selector;
            $element.on(eventName, fn);
            self.listeners.push([$element, eventName, fn]);
        } else {
            $element = $element || self.$el;
            $element.on(eventName, selector, fn);
            self.listeners.push([$element, eventName, selector, fn]);
        }
        return self;
    }
    /**
     * Hide component element
     */
    hide() {
        if (this.shown) {
            
            this.$el.addClass('visually-hidden');
            this.shown = false;
        }
    }

    /**
     * Show component element
     */
    show() {
        if (!this.shown) {
            this.$el.removeClass('visually-hidden');
            this.shown = true;
        }
    }

    /**
     * Check what component element is hidden
     */
    isHidden () {
        return !this.shown;
    }

    /**
     * Check what component element is not hidden
     */
    isShown () {
        return this.shown;
    }
    /**
     * Called 
     */
    init() {}
}
