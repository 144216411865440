import Component from '_core_ext/components/Component';

export default class Removable extends Component {
    static get selector() {
        return '.js-removable';
    }

    get configDataAttrName() {
        return 'component-removable';
    }

    init(...args) {
        super.init(args);

        this.event('click', '.js-remove-parent', function () {
            sessionStorage.setItem(this.$el.data('block-removed-key'), 'true');
            this.$el.hide();
        });
    }
}

module.exports = Removable;
