import OrderAddressAbstract from '_core_ext/components/address/OrderAddressAbstract';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('ShippingAddress');

export default class ShippingAddress extends OrderAddressAbstract {
    static get selector() {
        return '#dwfrm_singleshipping_shippingAddress';
    }

    getAddressFormContainer() {
        return $('#shipping-address-form-container');
    }

    /*
     * used for re-initialization of required (current) component
     */
    getComponentName() {
        return 'ShippingAddress';
    }
    
    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        // attach itself to parent page
        emitter.emit('attached', this);
        
        super.init(...args);
    }

    /**
     * @override 
     */
    onFieldChanged(elem) {
        emitter.emit('updated', elem);
    }

    /**
    * @override 
    */
    beforeFieldChanged(elem, event) {
        emitter.emit('beforeUpdate', elem, event);
    }

    /**
     * @public
     * @param {jQuery} warning jQuery-wrapped warning to show in form.
     */
    showWarning(warning) {
        // for mini-address component show warning at the top (when there is an address selector),
        // and for full form - after country field
        const $addressSelect = this.$el.find('.js-address-select-cont');
        if ($addressSelect.length) {
            this.$el.prepend(warning.removeClass('hidden'));
        } else {
            this.$countryField.parents('.js-form-row').after(warning.removeClass('hidden'));
        }
    }
    
    /**
     * @public
     */
    hideWarning() {
        this.$el.find('.js-no-methods-form-warn').remove();
    }
    
    /**
     * @public
     * Returns address part determining location, so not include Name etc.
     * Used to update shipping methods list atm.
     */
    getLocationAddress() {
        return {
            address1: this.$el.find('input[name$="_address1"]').val(),
            address2: this.$el.find('input[name$="_address2"]').val(),
            countryCode: this.$el.find('select[id$="_country"]').val(),
            stateCode: this.$el.find('[id$="_state"]').val(),   // this can be either input or select
            postalCode: this.$el.find('input[name$="_postal"]').val(),
            city: this.$el.find('input[name$="_city"]').val()
        };
    }
    
    /**
     * @public
     * Check if address is valid 'softly' - without flagging actual errors.  
     */
    isValid() {
        return this.formValidator.checkForm();
    }
}

module.exports = ShippingAddress;
