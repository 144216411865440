'use strict';

/**
 * @function cookieprivacy    Used to display/control the scrim containing the cookie privacy code
 **/
module.exports = function () {
    /**
     * If we have not accepted cookies AND we're not on the Privacy Policy page, then show the notification
     * NOTE: You will probably want to adjust the Privacy Page test to match your site's specific privacy / cookie page
     */
    if (SitePreferences.COOKIE_HINT === true && document.cookie.indexOf('dw_cookies_accepted') < 0) {
        // check for privacy policy page
        var $cookiesPrivacyPolicy = $('.js-privacy-policy');
        if ($cookiesPrivacyPolicy.length) {
            $cookiesPrivacyPolicy.addClass('visible');
            $cookiesPrivacyPolicy.on('click', '.js-accept-button', function(e) {
                e.preventDefault();
                $cookiesPrivacyPolicy.removeClass('visible');
                enableCookies();
                return false;
            });
        }
    } else {
        // Otherwise, we don't need to show the asset, just enable the cookies
        enableCookies();
    }

    function enableCookies() {
        if (document.cookie.indexOf('dw=1') < 0) {
            document.cookie = 'dw=1; path=/';
        }
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 365 * 24 * 60 * 60 * 1000; //24 * 60 * 60 * 1000
        now.setTime(expireTime);
        if (document.cookie.indexOf('dw_cookies_accepted') < 0) {
            document.cookie = 'dw_cookies_accepted=1;expires=' + now.toGMTString() + ';path=/';
        }
    }
};
