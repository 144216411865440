'use strict';

var minicart = require('_core_ext/components/global/minicart');

module.exports = function ($pdpMain) {
    if ($pdpMain.hasClass('js-is-btplus-product')) {
        var pid = $pdpMain.find('#pid').val(),
            addedBTPlusProducts = minicart.getAddedBTPlusProducts();

        if (addedBTPlusProducts.length > 0 && addedBTPlusProducts.indexOf(pid) !== -1) {
            $pdpMain.find('.js-add-to-cart').prop('disabled', true);
        }
    }
};
