import AddressAbstract from '_core_ext/components/address/AddressAbstract';
import dialog from '_core_ext/dialog';
import util from '_core_ext/util';
import validator from '_core_ext/components/global/validator';
import eventMgr from '_core_ext/eventMgr';
import _escape from 'lodash/string/escape';

export default class OrderAddressAbstract extends AddressAbstract {
    /*
     * used for re-initialization of required (current) component
     */
    getComponentName() {
        return 'OrderAddress';
    }

    getAddressFormContainer() {
        return $('#address-form-container');
    }

    /**
     * Method called when creating component
     *
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);
        eventMgr.on('DialogAddress.submit', this.updateAddressSelect.bind(this));

        // this needed to flag error on load in case of invalid default address
        var $selectedAddress = this.$el.find('.js-address-radio:checked');
        if ($selectedAddress.length) {
            this._prefill($selectedAddress);
        }
    }
    _initCache(...args) {
        super._initCache(...args);

        this.dialogsCustomCSSClass = 'add-edit-address-dialog b-checkout_dialog  js-add-edit-address-dialog theme-' + (SitePreferences.CHECKOUT_THEME || 'default');
        this.$formContainer = this.getAddressFormContainer();
        this.$addressMini = this.$el.find('.js-address-mini');
    }

    _initEvents(...args) {
        super._initEvents(...args);

        this.event('change', '.js-address-radio', (element, event) => {
            this._prefill($(element));
            this.onAddressSelectChanged(element, event);
        });

        this.event('click', '.js-edit-address', (element, e) => {
            e.preventDefault();
            var $el = $(element);
            var $currentForm = $el.closest('fieldset'),
                $selectedAddress = $currentForm.find('select[name$="_selectaddress"] :selected'),
                addressUUID = $selectedAddress.data('uuid'),
                url = $el.data('url'),
                dialogTitle = $el.data('dialogTitle');

            if (addressUUID) {
                url = util.appendParamToURL(url, 'addressUUID', addressUUID);
            }

            dialog.open({
                url: url,
                target: this.$formContainer,
                options: {
                    title :  dialogTitle,
                    dialogClass: this.dialogsCustomCSSClass,
                    open: () => {
                        var $addressForm = this.$formContainer.find('form');

                        validator.initForm($addressForm);
                        app.components.initComponent('DialogAddress', this.$formContainer);
                        app.components.initComponent('RedesignInput', this.$formContainer);
                        // validate edited address to highlight invalid fields at once
                        $addressForm.valid();
                    }
                }
            });
        });

        this.event('click', '.js-add-address', (element, e) => {
            e.preventDefault();
            var $el = $(element);
            var url = $el.data('url'),
                dublinOnly = $el.data('dublinOnly'),
                dialogTitle = $el.data('dialogTitle');

            dialog.open({
                url: url,
                target: this.$formContainer,
                options: {
                    title : dialogTitle,
                    dialogClass: this.dialogsCustomCSSClass,
                    open: () => {
                        var $addressForm = this.$formContainer.find('form');
                        if (dublinOnly) {
                            $addressForm.find('.js-input-state').addClass('dublin-only');
                        }
                        validator.initForm($addressForm);
                        app.components.initComponent('DialogAddress', this.$formContainer);
                        app.components.initComponent('RedesignInput', this.$formContainer);
                    }
                }
            });
        });

        this.event('click', '.js-change-address', (element, e) => {
            e.preventDefault();
            this.$el.toggleClass('show-address-actions');
        });

        this.event('click', '.js-shipping-address-tile-item', (element) => {
            var $el = $(element);
            var $addressItems = $('.js-shipping-address-tile-item');

            $addressItems.removeClass('selected');
            $addressItems.find('input[type="radio"]').removeAttr('checked');
            $el.addClass('selected');
            $el.find('input[type="radio"]').prop('checked', true).trigger('change');
        });
    }
    destroy() {}

    updateAddressSelect(data) {
        if (data.addressComponent === this.getComponentName()) {
            this.$el.find('.js-address-select-cont').html(data.response);
            this.$el.find('.js-address-radio').trigger('change');
        }
    }

    updateMiniAddress(address) {
        var addrStr = '';
        if (address) {
            var countryConfig;
            if (address.countryCode && address.countryCode in window.Countries) {
                countryConfig = window.Countries[address.countryCode];
            }
            addrStr += '<div class="miniaddr-name">'
            if (address.firstName) {
                addrStr += _escape(address.firstName);
            }
            if (address.lastName) {
                addrStr += ' ' + _escape(address.lastName);
            }
            addrStr += '</div>'

            if (address.address1) {
                addrStr += _escape(address.address1);
            }
            if (address.address2) {
                addrStr += ',<br/>' + _escape(address.address2);
            }

            if (address.city) {
                addrStr += ',<br/>' + _escape(address.city);
                if (countryConfig && address.stateCode) {
                    var regions = Object.keys(countryConfig.regions).map((key) => countryConfig.regions[key]);
                    regions = regions.filter((r) => r.value === address.stateCode);
                    var state = '';
                    if (regions.length) {
                        state = String.format(countryConfig.stateFormat, regions[0].label);
                    } else {
                        state = String.format(countryConfig.stateFormat, address.stateCode);
                    }
                    addrStr += ',<br/>' + state;
                }
            }

            if (countryConfig) {
                addrStr += ',<br/>' + countryConfig.countryName;
            }

            if (address.custom && address.custom.dublinCode) {
                addrStr += ',<br/>' + _escape(address.custom.dublinCode);
            } else if (address.postalCode) {
                addrStr += ',<br/>' + _escape(address.postalCode);
            }

            if (address.phone) {
                addrStr += ',<br/>' + _escape(address.phone);
            }

        }
        this.$addressMini.html(addrStr);
    }

    cleanDialogAddressFields() {
        var $form = this.$el.find('form');

        $form.find(':input[name*=addressFields]').val('');
        $form.find('[name$="country"]').trigger('change');
        $form.find('[name$="state"]').trigger('change');
    }

    _prefill($selectedAddress) {
        var addressData = $selectedAddress.data('address');
        var $selectedAddressError = this.$el.find('.js-selected-address-error');
        $selectedAddressError.addClass('hidden');

        if (addressData) {
            util.fillAddressFields(addressData, this.$el);

            var $confirmTerms = $('.js-input-confirmTerms[type="checkbox"]');
            // remove the required rule for confirmation input to check only address validation
            $confirmTerms.rules('add',  {required: false});
            // re-validate the form
            this.formValidator.form();
            var isInvalidAddress = !this.formValidator.valid();

            // add the required rule for confirmation input
            $confirmTerms.rules('add',  {required: true});

            if (isInvalidAddress) {
                $selectedAddressError.removeClass('hidden');
            }
        } else {
            //in dialog
            if ($selectedAddress.parents('.ui-dialog').length) {
                //clean form
                this.cleanDialogAddressFields();
            }
        }
    }

    /* eslint-disable no-unused-vars */
    /**
     * This method called upon Address selector change.
     * @abstract
     * @param {Element} element
     * @param {jQuery.Event} event
     */
    onAddressSelectChanged(element, event) {}
    /* eslint-enable no-unused-vars */

    /**
     * If any of inputs except Country (as it's always preselected) has value,
     * validate all form to highlight other fields required to fill.
     */
    validateWhenFilled() {
        var someFilled = this.$el.find(':input')
            .not(':hidden, :button, :checkbox, select[id$="_country"]')
            .is((index, elem) => !!$(elem).val());

        if (someFilled) {
            this.formValidator.form();
        }
    }
}

module.exports = OrderAddressAbstract;
