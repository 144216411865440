import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';
import util from '_core_ext/util';

import 'jquery.panzoom';

export default class PdpImage extends Component {
    static get selector() {
        return '.js-pdp-main';
    }

    getDefaultZoomConfig() {
        return {
            minScale: 1,
            maxScale: 4,
            increment: 0.2,
            scrollIncrement: 0.1,
            clickIncrement: 1.25
        }
    }

    initZoom($zoomImg) {
        const isTouch = util.isTouch();
        const zoomSettings = $zoomImg.data('zoomSettings') || {};
        const zoomConfiguration = $.extend(true, {}, this.getDefaultZoomConfig(), zoomSettings);
        this.$panzoom = $zoomImg.panzoom({
            contain: 'invert',
            minScale: zoomConfiguration.minScale,
            maxScale: zoomConfiguration.maxScale,
            increment: zoomConfiguration.increment,
            panOnlyWhenZoomed: isTouch,
        });

        this.$panzoom.on('mousewheel.focal', (e) => {
            this.$panzoom.panzoom('zoom', e.originalEvent.wheelDelta < 0, {
                increment: zoomConfiguration.scrollIncrement,
                animate: !isTouch,
                focal: e
            });

            e.preventDefault();
        });

        let clickCounter = 0;

        const zoomImage = (focalEvent) => {
            if (clickCounter === 2) {
                this.$panzoom.panzoom('reset');
                clickCounter = 0;
                return;
            }

            clickCounter++;
            this.$panzoom.panzoom('zoom', {
                focal: focalEvent,
                animate: !isTouch,
                increment: zoomConfiguration.clickIncrement,
                linearZoom: false,
                disablePan: false,
            });
        };

        const DOUBLETAP_RECOGNITION_DELAY = 300;
        const DOUBLETAP_RECOGNITION_DELTA = 30;
        const processTouch = function (event, focalEvent) {
            const targetData = $.data(event.target);
            const now = new Date().getTime();
            const delta = targetData.lastTouch ? now - targetData.lastTouch : 0;

            if (delta < DOUBLETAP_RECOGNITION_DELAY && delta > DOUBLETAP_RECOGNITION_DELTA) {
                zoomImage(focalEvent);
            } else {
                targetData.lastTouch = now;
            }
        };

        this.$panzoom.on('panzoomend', (event, panzoom, matrix, changed) => {
            if (!changed && !isTouch) {
                // deal with clicks
                zoomImage(event);
                event.stopImmediatePropagation();
            }
        });

        if (isTouch) {
            // Save the touchstart, as touchend event itself has no clientX/clientY, so we get touchstart event stored
            let touchStartEvent = null;

            $zoomImg.on('touchend click', (e) => {
                // touchend event has no clientX/clientY itself, so we grab it from originalEvent/changedTouches
                var touch = touchStartEvent.originalEvent.touches[0]
                    || touchStartEvent.originalEvent.changedTouches[0]
                    || touchStartEvent;

                const focalEvent = {
                    clientX: touch.clientX - e.target.offsetLeft,
                    clientY: touch.clientY - e.target.offsetTop
                }

                processTouch(e, focalEvent);
                return e.preventDefault();
            }).on('touchstart', (e) => {
                touchStartEvent = e;
            });
        }
    }

    /**
     * replaces srcset attributes for provided picture element
     * @param {jQuery} $picture $ wrapped picture tag
     * @param {String} src
     * @memberof PdpImage
     */
    updatePictureSources($picture, src) {
        $picture.find('source').each((i, sourceTag) => {
            const $source = $(sourceTag);
            $source.attr('srcset', src + '&' + $source.data('type-param'));
        });
    }
    /**
     * @description retrieves hi-res image link and adds it to markup after fully loaded, initializing zoom functionality
     * @memberof ProductMainImage
     */
    loadZoomImage() {
        const $primaryPicture = this.$el.find('.js-primary-picture');
        const $zoomPicture = this.$el.find('.js-zoom-picture');

        const hiResSrc = this.$el.find('a.main-image').attr('href');
        const $zoomImg = this.$el.find('.js-zoomImg');

        if ($zoomImg.length && hiResSrc) {
            /*
                jQuery.one used to avoid of multiple initializations for panZoom
            */
            $zoomImg.one('load', () => {
                // once hi-res image is loaded, display it on the page and replace primary one
                $primaryPicture.hide();
                if (this.$panzoom === null) {
                    this.initZoom($zoomImg);
                } else {
                    this.$panzoom.panzoom('reset', {
                        animate: false
                    });
                }

                $zoomPicture.show();
            })

            this.updatePictureSources($zoomPicture, hiResSrc);
            $zoomImg.attr('src', hiResSrc + '&' + $zoomImg.data('type-param'));
        }
    }

    initEvents() {
        this.$el.on('click', '.thumbnail-link', (e) => {
            const $target = $(e.currentTarget);

            if ($target.hasClass('js-thumbnail-video')) {
                this.$el.find('.js-defaultzoom-disabled').hide();
            } else {
                this.$el.find('.js-defaultzoom-disabled').show();
            }

            const lgImg = $target.find('img').data('lgimg');
            const hiresImg = lgImg.hires;
            // After thumbnail clicked, we need to update source tags for current picture tag
            const $primaryPicture = this.$el.find('.js-primary-picture');
            const $zoomPicture = this.$el.find('.js-zoom-picture');
            this.updatePictureSources($primaryPicture, this.$el.find('.primary-image').attr('src'));

            // Update hi-res version when thumbnails clicked
            if (hiresImg.length) {
                this.loadZoomImage();
            } else {
                $primaryPicture.show();
                $zoomPicture.hide();
            }
        });

        // Update hi-res on variant update
        eventMgr.on('variant.updated', () => {
            this.$panzoom = null;
            this.loadZoomImage()
        });

        eventMgr.on('productMainImage.furnImageUpdated', () => {
            this.$panzoom.panzoom('reset', {
                animate: false
            });
        });
    }

    init(...args) {
        super.init(...args);

        this.$panzoom = null;

        this.initEvents();
        this.loadZoomImage();

        return true;
    }
}

module.exports = PdpImage;
