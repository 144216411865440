var validatorModule = require('_core_ext/components/global/validator');
var util = require('_core_ext/util');

var $form, $continue, validator, options;

var validateForm = function () {
    if (!validator) {
        return;
    }
    if (validator.checkForm()) {
        $continue.prop('disabled', false);
    } else if (options.disableContinue) {
        $continue.prop('disabled', true);
    }
};

function isOnScreen(element) {
    var win = $(window);
    var theElement = $(element);

    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = theElement.offset();
    bounds.right = bounds.left + theElement.outerWidth();
    bounds.bottom = bounds.top + theElement.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
}

/**
 * @param {Object} opts
 * @param {String} opts.formSelector Form to validate
 * @param {String} opts.continueSelector Continue button to enable (disable) upon validation result
 * @param {boolean} opts.disableContinue Flag indicating if continue button should be also disabled upon validation result (only enabled by default)
 */
var init = function (opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    $form = $(opts.formSelector);
    $continue = $(opts.continueSelector);
    options = opts;

    validator = validatorModule.initForm($form);
    validateForm();
    $form.on('change', validateForm);
    $continue.on('click', function(event) {
        if (!validator.checkForm()) {
            $form.valid();
            var errorNode = $('.error').first();
            var top = errorNode.offset().top - 5;
            // scroll only when element is not visible
            if (!isOnScreen(errorNode)) {
                util.scrollBrowser(top);
            }
            event.preventDefault();
            return false;
        }
    });
};

exports.init = init;

exports.validateForm = validateForm;
