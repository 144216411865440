import Component from '_core_ext/components/Component';
import 'videojs-youtube';

var videojs = require('video.js'),
    util = require('_core_ext/util'),
    _debounce = require('lodash/function/debounce');

export default class VideoComponent extends Component {
    get configDefault() {
        return {
            autoplay: 'muted',
            preload: 'auto',
            muted: true,
            loop: true,
            techOrder: ['html5', 'flash'],
            fluid: true,
            height: 240,
            nativeControlsForTouch: false,
            setFluid: false,
            controlBar: {
                volumeMenuButton: false,
                volumePanel: false,
                muteToggle: true,
                fullscreenToggle: false,
                remainingTimeDisplay: false,
                progressControl: {
                    seekBar: true
                },   
                playbackRateMenuButton: false
            }
        };
    }

    /**
     * Method called when creating component
     *
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.isPlayed = false;

        // mock player until it's initialized
        this.player = {
            play: $.noop,
            pause: $.noop
        };
    }

    _loadVideoContent(videoURL, poster, videoElement) {
        var video,
            manageNativeControlsClass = '';
        
        if (!this.config.nativeControlsForTouch && util.isIPhone()) {
            manageNativeControlsClass = 'js-component-videojs-hide-native-controls';
        }

        if (videoURL) {
            $.ajax({url: videoURL}).done((videoData) => {
                // The JSON from the API response can be accessed here as videoData
                function sortByQuality(a, b) {
                    function getItemSortingPoints(item) {
                        const isMobile = util.isMobile();
                        if (isMobile && item.profile === 'mp4_360p') {
                            return 4;
                        } else if (!isMobile && item.profile === 'pdpnew') {
                            return 4;
                        } else if (item.profileLabel === 'High') {
                            return 3;
                        } else if (item.profileLabel === 'Medium') {
                            return 2;
                        } else if (item.profileLabel === 'Low') {
                            return 1;
                        }
                        return 0;
                    }
                    var aQ = getItemSortingPoints(a.profileLabel);
                    var bQ = getItemSortingPoints(b.profileLabel);
                    return bQ - aQ;
                }

                var id = videoData.id + (1 * new Date()); // videojs would refuse to initialize player with the same id
                videoData = videoData.media.sort(sortByQuality);

                if (!videoElement.find('video').length) {
                    video = '<video poster="' +
                        poster + '" class="amp-video-panel__video video-js vjs-default-skin vjs-tech ' + manageNativeControlsClass +
                        '" id="' + id + '" controls muted autoplay="muted" preload="auto" playsinline></video>';
                    videoElement.html(video);
                } else {
                    id = videoElement.find('video').attr('id');
                }

                var hasMuteToggle = false;
                var videoSrcs = '';

                for (var i in videoData) {
                    var mediaFile = videoData[i];
                    var videoFormat = mediaFile.format === 'mpeg4' ? 'video/mp4' : 'video/' + mediaFile.format;
                    videoSrcs += '<source src="' +
                        mediaFile.src + '" type="' +
                        videoFormat + '" codecs="\'' +
                        mediaFile['video.codec'] + '\',\'' +
                        mediaFile['audio.codec'] + '\' media="all and (max-width:' +
                        mediaFile.width + 'px)">';
                    if (mediaFile['audio.channels'] > 0) {
                        hasMuteToggle = true;
                    }
                }
                this.config.controlBar.muteToggle = hasMuteToggle;
                videoElement.find('video').html(videoSrcs);
                this.player = videojs(id, this.config, () => {
                    $(window).on('scroll', _debounce(() => {
                        if (!util.isVisible(videoElement[0])) {
                            this.player.pause();
                        }
                    }, 200));

                    // disable seeking progressbar via plugin
                    this.player.disableProgress({
                        autoDisable: true
                    });
                });
            });
        } 
    }

}

module.exports = VideoComponent;
