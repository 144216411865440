/* globals dataLayer, pageContext, currency, console */

var $cache = {},
    promoViews = [],
    productImpressions = [],
    productDetailImpression = [],
    checkoutData = null,
    purchaseData = null,
    removedProductData = null;

const eventMgr = require('_core_ext/eventMgr');
const Promise = require('promise');
const _throttle = require('lodash/function/throttle');
const util = require('_core_ext/util');

window.dataLayer = window.dataLayer || [];

function _initializeCache() {
    $cache.document = $(document);
    $cache.lists = $('.js-tiles-container, .js-products-container');
}

/**
 * @description Single method for pushing data
 * useful for debugging
 * @example console.info('dataLayer.push', data);
 *
 * @param data
 * @returns {Object} dataLayer
 */
function _pushToDataLayer(data) {
    window.dataLayer = window.dataLayer || [];
    return dataLayer.push(data);
}

function _sendEcommerceData() {
    var dataObject = {
        'ecommerce': {}
    };
    
    if (productDetailImpression.length) {
        dataObject.ecommerce.detail = {};
        dataObject.ecommerce.detail.products = productDetailImpression;
        var list = localStorage.getItem('ga4_' + productDetailImpression[0].id || '');
        if (!list) {
            list = productDetailImpression[0].category.split('/').pop();
        }
        dataObject.ecommerce.detail.actionField = {list: list};
        dataObject.event = 'productDetail';
    }

    if (checkoutData) {
        dataObject.ecommerce.checkout = checkoutData.ecommerce.checkout;
        if (!purchaseData) {
            dataObject.ecommerce.checkout.actionField = checkoutData.ecommerce.checkout.actionField;
        }
        dataObject.event = 'checkout';
        dataObject.pageType = 'checkout';
    }

    if (purchaseData) {
        dataObject.ecommerce.purchase = purchaseData.ecommerce.purchase;
        dataObject.ecommerce.purchase.actionField = purchaseData.actionField;
        dataObject.ecommerce.currencyCode = currency;
        dataObject.event = 'transaction';
    }

    if (removedProductData) {
        dataObject.ecommerce.remove = removedProductData.ecommerce.remove;
        dataObject.event = removedProductData.event;
    }
    
    if (Object.keys(dataObject.ecommerce).length) {
        _pushToDataLayer(dataObject);
    }
}

function _listPageIdentifier() {
    var pageType = pageContext.gtm.gtmPageData && pageContext.gtm.gtmPageData.pageType || pageContext.type;
    var result = '';
    switch (pageType) {
        case 'product list':
        case 'category landing':
            result = [pageType, pageContext.gtm.category].join(', ');
            break;
        default:
            result = pageType;
    }
    return result;
}

function _processList($list, counter, listName, isTilesAppend) {
    var pageIdentifier = _listPageIdentifier();
    $list.find('.js-component-product-tile').each(function(index) {
        var $tile = $(this),
            tileData = $tile.data('gtm');
        if (tileData !== null && typeof tileData === 'object') {
            tileData.position = ++index + counter;
            tileData.list = listName ? `${listName} (${pageIdentifier})` : _getProductList($tile);
            $tile.data('gtm', tileData);

            if (!isTilesAppend) {
                var $tileOnPage = $('#product-search-result-items').find('[data-product-id="' + $tile.data('productId') + '"]');
                if ($tileOnPage.length) {
                    $tileOnPage.data('gtm', tileData);
                }
            }
        }
    });
}

function _findPromoContent($container) {
    $container = $container || $(document); 
    const promos = $container.find('[data-asset-gtm]');

    promos.each((i, el) => {
        const $promo = $(el),
            $container = $promo.closest('.js-gtm-asset-container'),
            promoData = $promo.data('asset-gtm');

        // same asset can be rendered in different places but should be tracked separately,
        // so we will maintain collection of all of them, but mark visited and viewed with respective data-* attributes
        // to omit during checks
        if ($container.data('gtm-visited')) {
            return;
        }
        
        if (!$container.length) { 
            console.debug(`GTM: No .js-gtm-asset-container wrapper for asset "${promoData.id}", content will not be tracked!`);    // eslint-disable-line no-console
            return;
        }
        
        $container.data('gtm-visited', true);
        promoViews.push({
            data: promoData,
            $el: $container
        });
    });
}

function _trackVisiblePromos() {
    var track = [];
    
    for (let i = 0, len = promoViews.length; i <  len; i++) {
        const promoView = promoViews[i];
        if (promoView.$el.data('gtm-viewed')) {
            // skip already viewed elements
            continue;
        }
        
        if (util.isVisible(promoView.$el[0])) {
            promoView.$el.data('gtm-viewed', true);
            track.push(promoView.data);
        }
    }
    
    if (track.length > 0) {
        _pushToDataLayer({
            'event': 'promotionView',
            'ecommerce': {
                'promoView': {
                    'promotions': track
                }
            }
        })
    }
}

function _insertGtmScript() {
    var gtmContainerID = SitePreferences.GTM_CONTAINER_ID || '';
    var gtmScript = document.createElement('script');
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	}
	)(window,document,'script','dataLayer','${gtmContainerID}');`;
    document.head.appendChild(gtmScript);
}

var _throttledTrackVisiblePromos = _throttle(_trackVisiblePromos, 200);

function _initialRequests() {
    if (typeof pageContext === 'undefined' || typeof pageContext.gtm !== 'object') {
        return;
    }

    for (var key in pageContext.gtm) {
        if (pageContext.gtm[key].ecommerce) {
            if (pageContext.gtm[key].ecommerce.purchase) {
                purchaseData = pageContext.gtm[key];
                continue;
            }

            if (pageContext.gtm[key].ecommerce.checkout) {
                checkoutData = pageContext.gtm[key];
                var initialPageData = _enrichData(pageContext.gtm, key, true);
                _pushToDataLayer(initialPageData);
                continue;
            }

            if (pageContext.gtm[key].ecommerce.remove) {
                removedProductData = pageContext.gtm[key];
                continue;
            }
        }

        var enrichedGtmData = _enrichData(pageContext.gtm, key);
        _pushToDataLayer(enrichedGtmData);
    }

    // gtm script should be loaded after initial request
    _insertGtmScript();

    _findPromoContent($(document));
    _throttledTrackVisiblePromos();

    $cache.lists.each(function() {
        var $list = $(this),
            counter = $list.find('.pagination').data('gtm-start') || 0;
        _processList($list, counter, $list.data('list-name'));
    });
    _proceedProductTilesImpressions();

    if (('gtmProductDetailView' in pageContext) && pageContext.gtmProductDetailView !== null
            && $cache.document.find('.product-detail') && $cache.document.find('.js-product-tile-wrapper')) {
        productDetailImpression.push(pageContext.gtmProductDetailView);
    }

    _sendEcommerceData();

    var $miniCartContent = $('.js-mini-cart-content');
    if ($miniCartContent.length) {
        var gtmData = $miniCartContent.data('gtm');
        if (gtmData) {
            _pushToDataLayer(gtmData);
        }
    }

    _pushToDataLayer({'event': 'pageDataReady'});

}

function _enrichData(gtm, key, createFromBlank) {
    var gtmData = createFromBlank ? {} : gtm[key];
    if ((key === 'gtmPageData' || createFromBlank) && gtm.gtmUserData) {
        gtmData.language = gtm.gtmUserData.language || '';
        gtmData.country = gtm.gtmUserData.country || '';
        gtmData.visitorType = gtm.gtmUserData.visitorType || '';
        gtmData.visitorId = gtm.gtmUserData.visitorId || '';
        gtmData.loyaltyPoints = gtm.gtmUserData.loyaltyPoints || 0;
        gtmData.crmNumber = gtm.gtmUserData.crmNumber || '';
        gtmData.accountType = gtm.gtmUserData.tier || '';
    }
    if ((key === 'gtmUserData' || createFromBlank) && gtm.gtmPageData) {
        gtmData.pageType = gtm.gtmPageData.pageType || '';
        gtmData.department = gtm.gtmPageData.department || '';
    }
    return gtmData;
}

function _productRequest() {
    var productData = this.productData,
        event = this.event,
        action = this.action,
        actionField = this.actionField,
        eventCallback = this.eventCallback,
        currencyCode = this.currencyCode,
        productRequest = {};

    for (let i = 0; i < productData.length; i++) {
        if (productData[i].hasOwnProperty('list')) {
            delete productData[i].list;
        }
    }
    productRequest.event = event;
    productRequest.ecommerce = {};
    if (currencyCode) {
        productRequest.ecommerce.currencyCode = currencyCode;
    }
    productRequest.ecommerce[action] = {};

    if (productData.list) {
        productRequest.ecommerce[action].list = productData.list;
    }

    if (this.list) {
        productRequest.ecommerce[action] = productRequest.ecommerce[action] || {};
        productRequest.ecommerce[action].actionField = productRequest.ecommerce[action].actionField || {};
        productRequest.ecommerce[action].actionField.list = this.list;
    }

    if (actionField) {
        productRequest.ecommerce[action].actionField = actionField;
    }

    productRequest.ecommerce[action].products = productData;

    if (typeof eventCallback === 'function') {
        productRequest.eventCallback = eventCallback;
    }

    return _pushToDataLayer(productRequest);
}


function _addToCartRequest(productsData) {
    var list = '';
    var productID = productsData[0].id;
    // check if quickview or reqular pdp
    if ($('#pdpMain[data-master-product-id="' + productID + '"]').closest('#QuickViewDialog').length) {
        var productTile = $('.js-component-product-tile[data-master-product-id="' + productID + '"]')[0];
        list = _getProductList($(productTile));
    } else {
        list = localStorage.getItem('ga4_' + productID || '');
    }

    return _productRequest.call({
        'productData': productsData,
        'event': 'addToCart',
        'action': 'add',
        'currencyCode': currency,
        'list': list
    });
}

function _onQuickViewShow(sender) {
    var productData = $(sender).closest('.js-component-product-tile').data('gtm');

    // Use the list from product impression list attribute according to documentation
    var list = productData && productData.list;

    _productRequest.call({
        productData: [productData],
        event: 'productClick',
        action: 'click', 
        list: list
    });

    var qvProductData = {
        productData: [productData],
        event: 'productView',
        action: 'detail',
        list: list
    };
    _productRequest.call(qvProductData);
}

function _proceedProductTilesImpressions() {
    var productTiles = $cache.document.find('.js-component-product-tile:not([data-gtm-viewed=true])');

    productTiles.filter(function (i, el) {
        // filter out tiles in carousels
        return !$(el).closest('.js-product-carousel, .js-slick-product-carousel').length;
    }).each(function (i, el) {
        var $productTile = $(el);
        // send all PLP tiles once on page, but mark them as viewed so they don't sent again
        $productTile.attr('data-gtm-viewed', true);
        productImpressions.push($productTile.data('gtm'));
    });
    _sendProductImpressions();
}

// as carousels have cloned elements - need to have specific search to make sure info is not doubled
function _proceedCarouselProductTilesImpressions($carousel) {
    var tiles = $carousel.find('.js-component-product-tile:not([data-gtm-viewed=true])');
    tiles.each(function (i, el) {
        var $productTile = $(el);

        // mark all copies as viewed not to be selected for push by any other selector
        var isVievedCopyPresent = $carousel.find('[data-product-id="' + $productTile.data('product-id') + '"][data-gtm-viewed=true]').length;
        if (isVievedCopyPresent) {
            $carousel.find('[data-product-id="' + $productTile.data('product-id') + '"]').attr('data-gtm-viewed', true);
        }

        if (!isVievedCopyPresent && util.isVisible($productTile[0])) {
            $carousel.find('[data-product-id="' + $productTile.data('product-id') + '"]').attr('data-gtm-viewed', true);
            productImpressions.push($productTile.data('gtm'));
        }
    })
    _sendProductImpressions();
}

/**
 * send vieved products
 * if user see more that chunk maximum - send full chunk and the rest with another event
 */
function _sendProductImpressions() {
    // send products in chunks by 30
    var maxProducts = SitePreferences.PAYLOAD_MAXSIZE || 30;
    while (productImpressions.length) {
        var productImpressionsChunk = productImpressions.splice(0, maxProducts); 

        if (productImpressionsChunk.length) {
            _pushToDataLayer({
                'ecommerce': {
                    'currencyCode': currency,
                    'impressions': productImpressionsChunk
                },
                'event': 'productListLoaded'
            });
        }
    }
}

function _saveProductList($tile) {
    var productData = $tile.data('gtm');
    var listWithPage = _getProductList($tile);

    localStorage.setItem('ga4_' + productData.id, listWithPage); // set data to localStorage because sessionStorage is empty in new tabs so can't save data
}

function _getProductList($tile) {
    var pageIdentifier = _listPageIdentifier();
    // get list name from parent container
    var list = $tile.closest('.grid-tile, .js-tiles-container, .js-products-container').data('gtm')
        || $tile.closest('[data-gtm-list]').data('gtm-list')
        || $tile.closest('[data-list-name]').data('list-name');
    return `${list} (${pageIdentifier})`;
}

function _initializeEvents() {
    eventMgr.on('search.productTilesAppend', function(data, itemsCount, listName) {
        _processList(data, itemsCount, listName, true);
        _proceedProductTilesImpressions();
    });
    
    eventMgr.on('page.newView', function(data) {
        _pushToDataLayer({
            'event': 'pageView',
            'pageUrl': data.url,
            'pageTitle': data.title
        });
        $('.js-products-container').each(function() {
            var $container = $(this);
            _processList($container, 0, $container.data('list-name'));
        });
        _sendEcommerceData();
    });

    eventMgr.on('search.productListUpdated', function(data, itemsCount, listName) {
        //specific for productListUpdated
        itemsCount = 0;

        _processList(data, itemsCount, listName);
        _proceedProductTilesImpressions();
    });
    
    $cache.document.on('recommendations.initPredictiveRecommendations', (event, slotID) => {
        const $recommSlot = $(`[data-slot-id="${slotID}"]`);
        _processList($recommSlot, 0, $recommSlot.data('gtm') || $recommSlot.closest('[data-gtm-list]').data('gtm-list'));
        _proceedCarouselProductTilesImpressions($recommSlot);
    });
    
    eventMgr.on('product.quickViewShow', _onQuickViewShow);
    eventMgr.on('quickViewGlobal.productClick', _onQuickViewShow);
    eventMgr.registerAction('gtm.addBonusProducts', function(bonusProductsGTMData) {
        return Promise.resolve(_addToCartRequest(bonusProductsGTMData));
    });
    
    $cache.document.on('click', '.js-component-product-tile a.js-product-url', function(event) {
        event.preventDefault();
        var $this = $(this),
            productData = $this.closest('.js-component-product-tile').data('gtm') || {},
            url = $this.attr('href'),
            navStarted = false; // flag to prevent double page requests

        _saveProductList($this.closest('.js-component-product-tile'));
        _productRequest.call({
            productData: [productData],
            event: 'productClick',
            action: 'click',
            list: productData && productData.list || '',
            eventCallback: function() {
                if (navStarted) {
                    return;
                }
                navStarted = true;
                document.location = url;
            }
        });

        setTimeout(function () {
            if (navStarted) {
                return;
            }
            navStarted = true;
            document.location = url;
        }, 500);
    });

    $cache.document.on('click', '.add-to-cart, .js-add-to-cart', function() {
        var productsData = [];
        var $form = $(this).closest('form'),
            productData = $form.data('gtm');
        if (productData) {
            productData.quantity = + $form.find('.js-quantity, input[name="Quantity"]').val();
            productsData.push(productData);
        }
        _addToCartRequest(productsData);
    });

    $cache.document.on('click', '.add-all-to-cart', function() {
        var productsData = [];
        var forms = $('.product-add-to-cart').find('form');

        $(forms).each(function() {
            var $form = $(this);
            if ($form.data('gtm')) {
                var productData = $form.data('gtm');
                productData.quantity = +$form.find('.js-quantity, input[name="Quantity"]').val();
                productsData.push(productData);
            }
        });

        _productRequest.call({
            'productData': productsData,
            'event': 'addToCart',
            'action': 'add',
            'currencyCode': currency,
            'list': productsData.length > 0 ? productsData[0].list : ''
        });
    });
    
    eventMgr.on('productPage.BuyWithApplePay.blocked', function(apBtn) {
        var productsData = [];
        var $form = $(apBtn).closest('form'),
            productData = $form.data('gtm');
        if (productData) {
            productData.quantity = 1;
            productData.price = parseFloat($form.find('.js-input-giftprice').val() || 0) || productData.price;
            productsData.push(productData);
        }

        _productRequest.call({
            'productData': productsData,
            'event': 'BuyWithApplePay.blocked',
            'action': 'click',
            'currencyCode': currency,
            'list': productsData.length > 0 ? productsData[0].list : ''
        });
    });

    eventMgr.on('productPage.BuyWithApplePay', function(apBtn) {
        var productsData = [];
        var $form = $(apBtn).closest('form'),
            productData = $form.data('gtm');
        if (productData) {
            productData.quantity = 1;
            productData.price = parseFloat($form.find('.js-input-giftprice').val() || 0) || productData.price;
            productsData.push(productData);
        }

        _productRequest.call({
            'productData': productsData,
            'event': 'BuyWithApplePay',
            'action': 'add',
            'currencyCode': currency,
            'list': productsData.length > 0 ? productsData[0].list : ''
        });
    });

    $cache.document.on('click', '[data-gtmpromotions] ~ * a', function(event) {
        var $this = $(this);
        var $target = $this.closest('.js-gtm-asset-container').find('[data-asset-gtm]');

        if ($target.length) {
            var contentData = $target.data('asset-gtm'),
                href = $this.attr('href'),
                navStarted = false; // flag to prevent double page requests
            _pushToDataLayer({
                'event': 'promotionClick',
                'ecommerce': {
                    'promoClick': {
                        'promotions': [contentData]
                    }
                },
                'eventCallback': function() {
                    if (navStarted) {
                        return;
                    }
                    navStarted = true;
                    document.location = href;
                }
            });
            event.preventDefault();
            //Fallback when for some reason GTM eventCallback doesn't called
            setTimeout(function () {
                if (navStarted) {
                    return;
                }
                navStarted = true;
                document.location = href;
            }, 500);
        }
    });

    if ('gtmNextStepData' in pageContext && pageContext.gtmNextStepData) {
        if (pageContext.checkoutPage === 'payment') {
            $cache.document.on('click', 'form[class*="checkout"] [type="submit"], [name="dwfrm_cart_checkoutCart"], [name="dwfrm_accountvalidator_submit"]', function() {
                _pushToDataLayer(pageContext.gtmNextStepData);
            });
        }
        $cache.document.on('click', '.js-applepay-cart', function() {
            _pushToDataLayer(pageContext.gtmNextStepData);
        });
    }

    $cache.document.on('click', '.js-minicart-submit', function() {
        _pushToDataLayer($(this).data('checkout-gtm'));
    });

    // Track promoView.promotions and products in carousels upon actual content visibility
    var _throttledCarouselImpressions = _throttle(_proceedCarouselProductTilesImpressions, 200);
    $(window).on('scroll resize', function () {
        _throttledTrackVisiblePromos();
        _throttledCarouselImpressions($('.js-product-carousel, .js-slick-product-carousel'));
    });
    
    function promoObserverCb(mutations) {
        for (let i = 0; i < mutations.length; i++) {
            if (mutations[i].type === 'childList' && mutations[i].addedNodes.length > 0) {
                _findPromoContent($(mutations[i].target));
            }
        }
        _throttledTrackVisiblePromos();
    } 
    if (window.MutationObserver) {
        new MutationObserver(promoObserverCb).observe(document.body, {
            childList: true, 
            subtree: true, 
            attributes: true
        });
    } else {
        setInterval(() => {
            _findPromoContent($cache.document);
            _throttledTrackVisiblePromos();
        }, 1000);
    }

    // send products that became visible
    $(document).on('afterChange', '.js-slick-product-carousel', function (e) {
        _proceedCarouselProductTilesImpressions($(e.target));
    })

    // remove item from localStorage (used for actionField) on tab close
    $(window).on('beforeunload', function() {
        if (pageContext && pageContext.gtmProductDetailView) {
            var key = 'ga4_' + pageContext.gtmProductDetailView.id || '';
            localStorage.removeItem(key);
        }
    });
}

module.exports = {
    init: function () {
        if (!SitePreferences.GTM_ENABLED) {
            return;
        }
        
        // Delay analytics initialization after all other components.
        // This needed to for carousels initialized and so initially visible slide (only) tracked on page load
        eventMgr.execute('components.initialized').then(() => {
            _initializeCache();
            _initialRequests();
            _initializeEvents();
        });
    }
};
