import Component from '_core_ext/components/Component';
import validator from '_core_ext/components/global/validator';

export default class AddressAbstract extends Component {
    static get selector() {
        return '.address, .js-address';
    }

    /*
     * used for re-initialization of required (current) component
     */
    getComponentName() {
        return 'AddressAbstract';
    }

    get configDefault() {
        return {};
    }

    get configDataAttrName() {
        return 'address';
    }

    /**
     * Method called when creating component
     *
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this._initCache()
        this._initEvents();
    }

    _initCache() {
        this.$countryField = this.$el.find('.js-input-country');
        this.$statesField = this.$el.find('.js-input-state');
        this.$postalField = this.$el.find('.js-input-postal');
        this.$dublinCodeField = this.$el.find('.js-input-dublinCode');
        this.userUnCheckUseAsShipping = false;
        this.formValidator = validator.initForm(this.$el);
        this.$countryField.data('oldval', this.$countryField.val());    // remember initial value for artificial 'preventDefault'
    }

    _initEvents() {
        this.event('mousedown', '.js-input-country', () => {
            this.$countryField.data('oldval', this.$countryField.val());

        }).event('change', '.js-input-country', (elem, event) => {
            this.beforeFieldChanged(elem, event);
            
            // outside code could stand against country change, so revert selection 
            if (event.isDefaultPrevented()) {
                this.$countryField.val(this.$countryField.data('oldval'));
                return;
            }

            this._statesOnCountryChange();
            this._postalOnCountryChange();

            if (this.$postalField.val()) {
                this.$statesField.trigger('change');
            } else {
                this._dublinCodeAdjust();
            }

            this.formValidator = validator.initForm(this.$el);

            // there must be no other 'change' event handlers attached to Country field,
            // but this method overridden/extended in descendant classes for additional logic
            this.onFieldChanged(elem);

        }).event('change propertychange', '.js-input-state', (elem, event) => {
            this.beforeFieldChanged(elem, event);

            this._dublinCodeAdjust();

            // there must be no other 'change' event handlers attached to State field,
            // but this method overridden/extended in descendant classes for additional logic
            this.onFieldChanged(elem);
        }).event('change', ':input[name*=addressFields]', (elem, event) => {
            this.beforeFieldChanged(elem, event);

            // Handle change on all other fields beside Country and State
            const $el = $(elem);
            if ($el.hasClass('js-input-country') || $el.hasClass('js-input-state')) {
                return;
            }
            this.onFieldChanged(elem);
        });

        this.$el.find('.js-input-country').trigger('change');
    }

    getCountryConfig(country) {
        return window.Countries[country];
    }

    getFormCountryConfig() {
        var country = this.getSelectedCountry();
        return this.getCountryConfig(country);
    }

    getSelectedCountry() {
        return this.$countryField.val();
    }

    getSelectedState() {
        return this.$statesField.val();
    }

    /**
     * This method must be overridden in descendant classes for additional logic upon fields' changes.
     * Keep in mind - it may (and does) run several times in same loop (especially when selecting saved address for registered customer)
     * @abstract
     * @param {Element} el
     */
    /* eslint-disable no-unused-vars */
    onFieldChanged(el) {}

    /**
     * It's actually called when selection already changed, but we can do ev.preventDefault() to roll-back change.
     * Implemented for country field only atm.
     */ 
    beforeFieldChanged(el, ev) {}
    /* eslint-enable no-unused-vars */

    destroy() {}

    _statesOnCountryChange() {
        this.$statesField.prop('disabled', true);

        const countryConfig = this.getFormCountryConfig();
        if (!SitePreferences.HIDE_OPTIONAL_STATE_FIELD) {
            if (countryConfig) {
                this._buildStatesField(countryConfig);
            }
        } else {
            var $formRow = this.$statesField.closest('.js-form-row');
            if (countryConfig) {
                // when we hide State input it's got disabled and so not submitted,
                // but we need clear previous value on backe-end and so need to submit empty value explicitly,
                // so we do this via same-named hidden input, when actual one hidden/disabled
                if (countryConfig.isRegionHidden) {
                    $formRow.addClass('hidden');
                    $formRow.append(`<input type="hidden" name="${this.$statesField.attr('name')}" value=""/>`);
                } else {
                    $formRow.removeClass('hidden');
                    $formRow.find(`input[type="hidden"][name="${this.$statesField.attr('name')}"]`).remove();
                    this._buildStatesField(countryConfig);
                }
            } else {
                $formRow.addClass('hidden');
            }
        }
    }

    _buildStatesField(countryConfig) {
        if (this.$statesField.is('select')) {
            this.$statesField.get(0).selectedIndex = 0;
        }

        var labelSpanSelector = 'label[for=\'' + this.$statesField.attr('id') + '\']',
            $labelSpan = this.$el.find(labelSpanSelector),
            stateValueOriginal = this.$statesField.val() || this.$statesField.attr('value');

        $labelSpan.html(
            '<span>' + countryConfig.regionLabel + '</span>' +
                (!countryConfig.isRegionMandatory ? '<span>' + Resources.FORM_FIELD_OPTIONAL + '</span>' : '')
        );
        if (this.$statesField.is('select') && !countryConfig.isRegionSelect) {
            /*eslint block-scoped-var: "off"*/
            var $newStateField = $('<input type="text"/>'),
                attributes = this.$statesField.prop('attributes');
            // loop through <select> attributes and apply them on <input>
            $.each(attributes, function() {
                $newStateField.attr(this.name, this.value);
            });
            this.$statesField.replaceWith($newStateField);
            this.$statesField = $newStateField;
            this.$statesField.prop('disabled', false);
            this.$statesField.removeClass('required');
        } else if (this.$statesField.is('input') && !countryConfig.isRegionSelect) {
            this.$statesField.prop('disabled', false);
        } else {
            var arrHtml = [],
                isSelectedOptionAvailible = false;
            if (this.$statesField.is('input') && countryConfig.isRegionSelect) {
                /*eslint no-redeclare: "off"*/
                var $newStateField = $('<select/>'),
                    attributes = this.$statesField.prop('attributes');
                // loop through <input> attributes and apply them on <select>
                $.each(attributes, function() {
                    $newStateField.attr(this.name, this.value);
                });
                this.$statesField.replaceWith($newStateField);
                this.$statesField = $newStateField;
            }
            for (var stateKey in countryConfig.regions) {
                var state = countryConfig.regions[stateKey];
                var stateSelected = '';
                if (stateValueOriginal && state.value === stateValueOriginal) {
                    stateSelected = ' selected="selected"';
                    isSelectedOptionAvailible = true;
                }

                arrHtml.push(
                    '<option value="' + state.value + '"' + stateSelected + '>' +
                        state.label +
                    '</option>'
                );
            }
            this.$statesField.html(arrHtml.join(''));

            // in case if originally selected value is missed in updated options list,
            // then mark as selected the first option
            if (!isSelectedOptionAvailible) {
                this.$statesField.get(0).selectedIndex = 0;
            }
        }

        if (countryConfig.isRegionMandatory) {
            this.$statesField.addClass('required');
            this.$statesField.parents('.form-row').addClass('required');
        } else {
            this.$statesField.removeClass('required');
            this.$statesField.parents('.form-row').removeClass('required');
        }
        this.$statesField.prop('disabled', false);
        // Label in properties has no asterisk, as in CO field name rendered via label and asterisk added via CSS when 'required' class added to container
        // but this is needed for MA, where native placeholders rendered using same properties
        this.$statesField.attr('placeholder', countryConfig.isRegionMandatory ? addAsteriskIfAbsent(countryConfig.regionLabel) : countryConfig.regionLabel);
    }

    _postalOnCountryChange() {
        this.$postalField.prop('disabled', true);
        var $formRow = this.$postalField.closest('.js-form-row');

        const countryConfig = this.getFormCountryConfig();
        if (countryConfig) {
            if (countryConfig.isPostalHidden) {
                $formRow.addClass('hidden');
                this.$postalField.val('');
            } else {
                $formRow.removeClass('hidden');
                var labelSpanSelector = 'label[for=\'' + this.$postalField.attr('id') + '\']',
                    $labelSpan = this.$el.find(labelSpanSelector);

                $labelSpan.html(
                    '<span>' + countryConfig.postalLabel + '</span>' +
                        (!countryConfig.isPostalMandatory ? '<span>' + Resources.FORM_FIELD_OPTIONAL + '</span>' : '')
                );
                if (countryConfig.isPostalMandatory) {
                    this.$postalField.addClass('required');
                    $formRow.addClass('required');
                } else {
                    this.$postalField.removeClass('required');
                    $formRow.removeClass('required');
                }

                // Label in properties has no asterisk, as in CO field name rendered via label and asterisk added via CSS when 'required' class added to container
                // but this is needed for MA, where native placeholders rendered using same properties
                this.$postalField.attr('placeholder', countryConfig.isPostalMandatory ? addAsteriskIfAbsent(countryConfig.postalLabel) : countryConfig.postalLabel);
            }
        } else {
            $formRow.removeClass('hidden');
        }
        this.$postalField.prop('disabled', false);
    }
    
    /**
     * Handle Dublin code (IE Postcode/Eircode) presentation upon Country/State selection
     */
    _dublinCodeAdjust() {
        const countryConfig = this.getFormCountryConfig();
        const formRow = this.$dublinCodeField.closest('.js-form-row');
        var placeholder;
        
        if (this.getSelectedCountry() === 'IE') {
            formRow.show();
        } else {
            formRow.hide();
            this.$dublinCodeField.val('');
        }

        if (this.getSelectedCountry() === 'IE' && this.getSelectedState() === 'D') {
            this.$dublinCodeField.addClass('required');
            formRow.addClass('required');
            placeholder = addAsteriskIfAbsent(countryConfig.postalLabel);
        } else {
            this.$dublinCodeField.removeClass('required');
            formRow.removeClass('required');
            placeholder = countryConfig.postalLabel;
        }

        if (this.$dublinCodeField.attr('placeholder')) {
            this.$dublinCodeField.attr('placeholder', placeholder);
        }
    }
}

/**
 * Add '*' to the end of string if it's absent.
 * @param {String} str
 * @returns String appended with '*'
 */
function addAsteriskIfAbsent(str) {
    if (str && str.slice(-1) !== '*') {
        return str + '*';
    }
    return str;
}

module.exports = AddressAbstract;
