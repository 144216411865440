'use strict';

var ajax = require('_core_ext/ajax'),
    _once = require('lodash/function/once');

var $cache = {};
/**
 * @exmple:
    var result = groupBy(list, function(element) {
      return $element.data('attr');
    });
 * 
 * @param $elements
 * @param f
 * @returns
 */
function _groupBy( array , f, skipEmptyGroups) {
    var groups = {};
    
    for(var i=0; i < array.length; i++) {
        var o = array[i];
        var group = String(f(o)).trim();
        
        if(skipEmptyGroups) {
            if(group.length) {
                groups[group] = groups[group] || [];
                groups[group].push(o);
            }
        } else {
            groups[group] = groups[group] || [];
            groups[group].push(o);
        }
    }
    return Object.keys(groups).map( function( group ) {
        return groups[group]; 
    });
}

function _getColumnNextSortingDerection($currentElement) {
    var nextSortingDirection;
    nextSortingDirection = $currentElement.hasClass('js-is-sorting-direction-asc') ? 'desc' : 'asc';
    return nextSortingDirection;
}
/**
 * @private
 * @function
 * @description
 */
function _getProductItemsSortingRule(sortingRuleName, sortingDirection) {
    return function (a, b) {
        var retVal = 0,
            $a, $b;
        
        $a = $(a);
        $b = $(b);
        
        //show gift wrap groups at top
        if($a.hasClass('js-gift-wrap') && $b.hasClass('js-gift-wrap') ) {
            retVal = 0;
        } else if ($a.hasClass('js-gift-wrap') && !$b.hasClass('js-gift-wrap') ) {
            retVal = -1;
        } else if(!$a.hasClass('js-gift-wrap') && $b.hasClass('js-gift-wrap') ) {
            retVal = 1;
        } else {
            // gift wrap must be always at bottom of gift wrap group
            if($a.data('is-gift-wrap-product') && $a.parent().hasClass('js-gift-wrap')) {
                retVal = 1;
            } else if ($b.data('is-gift-wrap-product')&& $b.parent().hasClass('js-gift-wrap')) {
                retVal = -1;
            } else {
                var valueDataSelector, valueA, valueB,
                    $sortedCellA, $sortedCellB;
                
                valueDataSelector = '[data-sort-attr="' + sortingRuleName + '"]';
                
                $sortedCellA = $a.find(valueDataSelector);
                $sortedCellB = $b.find(valueDataSelector);
                
                valueA = $sortedCellA.data('sort-value');
                valueB = $sortedCellB.data('sort-value');
                
                // for some attribute can be custom sorting rule.
                // so, we use "switch case" for example, how it can be used
                // for now will be used default
                switch (sortingRuleName) {
        //            case 'nr':
        //                break;
        //            case 'productName':
        //                break;
        //            case 'quantity':
        //                break;
        //            case 'WorkFlowStatusDesc':
        //                break;
        //            case 'price':
        //                break;
        //            case 'trackingNumber':
        //                break;
                    default:
                        if (valueA < valueB) {
                            retVal = sortingDirection == 'desc' ? 1 : -1;
                        } else if (valueA > valueB) {
                            retVal = sortingDirection == 'desc' ? -1 : 1;
                        }
                }
            }
        }
        return retVal;
    };
};
function _sortProductItems($wrapper, callback) {
    var $children = $wrapper.children();
    // @see: http://jsfiddle.net/JtsRz/
    
    $children.sort(callback).prependTo($wrapper);
    
    for(var i=0; i < $children.length; i++) {
        var $el = $($children[i]);
        if ($el.hasClass('js-gift-wrap')) {
            _sortProductItems($el, callback);
        }
    }
}
/**
 * @private
 * @function
 * @description
 */
function _initProductItemsSortingForItemsWrapper($wrapper) {
    
    $wrapper.find('.js-orderhistory-orderdetails-show-giftwraps-together').on('change', function(e) {
        var sortingRuleName, sortingRule, nextSortingDirection,
            $this, $firstColumn;
        $this = $(this);
        var showGiftwrapsTogether = $this.prop('checked');
        
        if(showGiftwrapsTogether) {
            var $itemsRecords = $wrapper.find('.js-orderhistory-orderdetails-product-items-record');
            
            var groupedRecords = _groupBy($itemsRecords, function(element) {
                return $(element).data('gift-wrap-group-id');
            }, true);
            
            for(var i=0; i < groupedRecords.length; i++) {
                $(groupedRecords[i]).wrapAll('<div class="gift-wrap js-gift-wrap"></div>');
            }
        } else {
            var $gw = $wrapper.find('.js-gift-wrap');
            for(var i=0; i < $gw.length; i++) {
                var $el = $($gw[i]);
                $el.replaceWith($el.html());
            }
        }
        
        nextSortingDirection = 'asc';
        
        $firstColumn =  $wrapper.find('.js-items-apply-sorting-rule').first();
        
        sortingRuleName = $firstColumn.data('sorting-rule');
        
        sortingRule = _getProductItemsSortingRule(sortingRuleName, nextSortingDirection);
        $wrapper.find('.js-items-apply-sorting-rule').removeClass('js-is-sorting-direction-asc js-is-sorting-direction-desc');
        $firstColumn.addClass('js-is-sorting-direction-' + nextSortingDirection);
        
        _sortProductItems($wrapper.find('.js-orderhistory-orderdetails-product-items-list'), sortingRule);
    }).trigger('change');
    
    $wrapper.find('.js-items-apply-sorting-rule').on('click', function(e) {
        var sortingRuleName, sortingRule, nextSortingDirection,
            $this;
        
        $this = $(this);
        
        var showGiftwrapsTogether = $wrapper.find('.js-orderhistory-orderdetails-show-giftwraps-together').prop('checked');
        
        nextSortingDirection = _getColumnNextSortingDerection($this);
        sortingRuleName = $this.data('sorting-rule');
        
        sortingRule = _getProductItemsSortingRule(sortingRuleName, nextSortingDirection);
        
        $wrapper.find('.js-items-apply-sorting-rule').removeClass('js-is-sorting-direction-asc js-is-sorting-direction-desc');
        $this.addClass('js-is-sorting-direction-' + nextSortingDirection);
        
        _sortProductItems($wrapper.find('.js-orderhistory-orderdetails-product-items-list'), sortingRule);
    });
}

function _initShowStoreOrders() {
    $('#show-more-store-orders').on('click', (e) => {
        // there is no ability to make server-side pagination as we get all orders from service at once,
        // so we just reveal them on client by chunks
        var btn = $(e.target),
            pageSize = btn.data('pageSize'),
            hiddenOrders = $('.js-store-order.hidden');

        hiddenOrders.slice(0, pageSize).removeClass('hidden');
        app.components.reInitComponent('isotope');  // to align shown receipts correctly
        if (hiddenOrders.length <= pageSize) {
            btn.hide();
        }
    });
}

/**
 * @private
 * @function
 * @description
 */
function _initializeCache($wrapper) {
    $cache = {
        wrapper : $wrapper,
        productItemsWrapper : $wrapper.find('.js-orderhistory-orderdetails-product-items-wrapper')
    };
}

function _initLoadOnlineOrders() {
    // bind live event handler as "Load" button will be replaced with loaded chunk
    $(document).on('click', '#load-more-online-orders', (e) => {
        var $el = $(e.currentTarget);
        ajax.load({
            url: $el.data('url')
        }).then((response) => {
            $el.replaceWith(response);
        });
    });
}

function _initOrdersTabs() {
    const ONLINE_ORDERS_TAB = 0;
    const STORE_ORDERS_TAB = 1;

    var $tabs = $('.js-tabs');
    var initIsotope = _once(() => app.components.reInitComponent('isotope'));

    $tabs.tabs({
        active: ONLINE_ORDERS_TAB,
        activate: function (event, ui) {
            var $this = $(this);
            var selectedField = $this.find('.js-tabs-selected').first();
            var selectedTabText = ui.newTab.text();
            selectedField.text(selectedTabText);
            if (ui.newPanel.find('.js-isotope-component').length) {
                // init isotope once when Store orders tab opened, so calculations are correct
                initIsotope();
            }
        }
    });

    // recalculate store orders layout upon device orientation change
    $(window).on('orientationchange', () => {
        if ($tabs.tabs('option', 'active') === STORE_ORDERS_TAB) {
            // recalculate at once if store orders tab opened
            app.components.reInitComponent('isotope');
        } else {
            // recalculate when tab will be opened
            initIsotope = _once(() => app.components.reInitComponent('isotope'));
        }
    });
}

/**
 * @private
 * @function
 * @description Binds the events at page
 */
function _initializeEvents($wrapper) {
    for(var i=0; i < $cache.productItemsWrapper.length; i++) {
        _initProductItemsSortingForItemsWrapper($($cache.productItemsWrapper[i]));
    }
    _initLoadOnlineOrders();
    _initShowStoreOrders();
    _initOrdersTabs();
}

module.exports = {
    init: function () {
        var $wrapper = $('#main');
        if ($wrapper.length && $wrapper.hasClass('js-is-page-orderhistory')) {
            _initializeCache($wrapper);
            _initializeEvents($wrapper);
        }
    }
};