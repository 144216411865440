import Component from '_core_ext/components/Component';
import dialog from '_core_ext/dialog';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('ColorPicker');

export default class ColorPicker extends Component {
    
    static get selector() {
        return '.js-color-picker';
    }
    
    init(...args) {
        super.init(...args);
        this._initEvents(this.$el);
    }

    _initEvents($el) {
        $el.find('.js-color-search').on('input', function() {
            const searchPhrase = $(this).val().toLocaleLowerCase();
            $el.find('.js-colorselector-item').each(function() {
                $(this).toggleClass('hidden', !~$(this).text().toLocaleLowerCase().search(searchPhrase));
            });
        });

        $el.find('.js-colorselector-item').on('click', function() {
            dialog.close();
            if (!$(this).hasClass('selected')) {
                emitter.emit('selected', $(this).data('select-url'));
            }
        });
    }
}

module.exports = ColorPicker;
