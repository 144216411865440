import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import Instafeed from 'instafeed.js';

export default class Instagram extends Component {
    static get selector() {
        return '.js-component-instagram';
    }
    get configDefault() {
        return {
            get: 'user',
            limit: 1
        };
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.runUserFeed();
    }

    runUserFeed() {
        //check, what instagram is enabled
        
        if (Instafeed) {

            this._accessToken = SitePreferences.INSTAGRAM_ACCESS_TOKEN;
            this.$el.html('');
            var tplName = this.config.instagramFeedTemplate || 'default';
            var resolution = this.config.instagramFeedResolution || 'standard_resolution';

            /*globals Instafeed*/
            var userFeed = new Instafeed({
                target: this.id,
                accessToken: this._accessToken,
                template: this._getTemplate(tplName),
                resolution : resolution,
                after: () => {
                    var $parent = this.$el.parent();
                    if ($parent.hasClass('js-carousel')) {
                        app.components.reInitComponent('carousel', $parent.parent());
                    }
                }
            });
            userFeed.run();

            $.ajax({
                //this is "hack" for cross-domain request
                url : util.appendParamsToUrl(Urls.instagramFeed, this.config),
                success : (json) => {
                    var callbackName = 'instafeedCache' + userFeed.unique;
                    if (!(callbackName in window)) {
                        throw new Error('callbackName: \'' + callbackName + '\' not exists. Please, check Instafeed options');
                    }
                    this.$el.html('');
                    window[callbackName].parse(json);
                }
            });
        }
    }
    _getTemplate(tplName) {
        if (!tplName || !(tplName in this._templates)) {
            tplName = 'default';
        }
        return this._templates[tplName];
    }
    get _templates() {
        return {
            'default' : '<div class="instagram-item">' +
                '<div class="photo-box">' +
                    '<div class="image-wrap">' +
                        '<a href="{{link}}" target="_blank">' +
                            '<img src="{{image}}">' +
                        '</a>' +
                        '<div class="likes">{{likes}} Likes</div>' +
                    '</div>' +
                    '<div class="description">' +
                        '{{caption}}' +
                        '<div class="date">{{model.date}}</div>' +
                    '</div>' +
                '</div>' +
            '</div>',

            'carousel-items' : '<li class="slide instagram-item">' +
                '<div class="photo-box">' +
                    '<div class="image-wrap">' +
                        '<a href="{{link}}" target="_blank"><img src="{{image}}"></a>' +
                        '<div class="likes">{{likes}} Likes</div>' +
                    '</div>' +
                    '<div class="description">' +
                        '{{caption}}' +
                        '<div class="date">{{model.date}}</div>' +
                    '</div>' +
                '</div>' +
            '</li>'
        }
    }
}

module.exports = Instagram;
