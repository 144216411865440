

var billing = require('_core_ext/pages/checkout/billing'),
    multiship = require('_core_ext/pages/checkout/multiship'),
    payment = require('_core_ext/pages/checkout/payment'),
    customer = require('_core_ext/utils/customer'),
    shipping = require('_core_ext/pages/checkout/shipping'),
    shippingmethods = require('_core_ext/pages/checkout/shippingmethods'),
    clickandcollect = require('_core_ext/pages/checkout/clickandcollect'),
    personaldetails = require('_core_ext/pages/checkout/personaldetails'),
    checkoutlogin = require('_core_ext/pages/checkout/checkoutlogin'),
    registration = require('_core_ext/pages/checkout/registration'),
    noScroll = require('_core_ext/components/noscroll'),
    page = require('_core_ext/page');
const eventMgr = require('_core_ext/eventMgr');

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */

exports.init = function () {
    function getComponents(checkoutPage) {
        var dict = {
            'checkoutlogin' : [checkoutlogin],
            'personaldetails' : [personaldetails],
            'singleshipping' : [shipping],
            'shippingmethods' : [shippingmethods],
            'clickandcollect' : [clickandcollect],
            'multipleaddresses' : [multiship],
            'multishippment' : [multiship],
            'billing' : [billing],
            'payment' : [billing, payment],
            'registration' : [registration]
        };
        return dict[checkoutPage] || [];
    }

    /* globals pageContext */
    var components = getComponents(pageContext.checkoutPage);
    for (var i = 0; i < components.length; i++) {
        var component = components[i];
        if ('init' in component && typeof component.init === 'function') {
            component.init();
        }
    }

    //if on the order review page and there are products that are not available diable the submit order button
    if ($('.order-summary-footer').length > 0) {
        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        }
    }

    eventMgr.on('session.expiredAndLogout', () => {
        //replace content js-checkout-timeout-loggedin-content
        var content = $('#js-checkout-timeout-content').html();
        if (customer.isAuthenticated()) {
          //logout
            $.ajax({
                url: Urls.loginLogout
            });

            content = $('#js-checkout-timeout-loggedin-content').html();
        }
        noScroll.disable();     // to reset negative top on body if dialog was opened on scrolled page
        $(SitePreferences.CHECKOUT_SESSION_WRAPPER || '#wrapper').html(content);
    });

    eventMgr.on('TimeslotExpire.expiredTimeout', (id) => {
        shippingmethods.selectShippingMethod(id);
    });

    eventMgr.on('dialog.opened', () => noScroll.enable());
    eventMgr.on('dialog.closed', () => noScroll.disable());

    eventMgr.on('Coupon.applied', () => page.refresh());
    eventMgr.on('Coupon.removed', () => page.refresh());
};
