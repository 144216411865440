import VideoComponent from '_core_ext/components/videoComponent';
import 'videojs-youtube';

var videojs = require('video.js'),
    util = require('_core_ext/util'),
    _debounce = require('lodash/function/debounce');

export default class VideoJS extends VideoComponent {
    static get selector() {
        return '.js-component-videojs';
    }
    
    get configDataAttrName() {
        return 'component-videojs';
    }

    /**
     * Method called when creating component
     *
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);
        this._initVideo();

        if (!this.isVideoData && !util.isDesktop()) {
            return this.$videoContainer.find('video');
        }
    }

    _extendConfig() {
        // turn fluid mode off if width or height is passed in attributes to video-tag
        if (this.$el.attr('width') || this.$el.attr('height')) {
            this.config.fluid = false;
        }
        // switch to Youtube tech plugin if it's youtube source
        if (this.$el.hasClass('youtube')) {
            this.config.techOrder = ['youtube'];
        }

        this.config.width = 320;
        this.config.height = 240;
        this.config.autoplay = util.isVisible(this.$el[0]);
    }

    _initVideo() {
        var $videoElement = this.$el,
            videoURL,
            lgimg,
            video,
            id;

        var manageNativeControlsClass = '';
        if (!this.config.nativeControlsForTouch && util.isIPhone()) {
            manageNativeControlsClass = 'js-component-videojs-hide-native-controls';
        }

        this._extendConfig();

        lgimg = $videoElement.data('lgimg');

        if (lgimg) {
            if (!this.config.setFluid) {
                this.config.fluid = false;
            }
            this.isVideoData = true;
            videoURL = lgimg.videoData;
            
            if (videoURL) {
                //making this temp changes as new Adobe DAM will not have json format details for videos
                var video = `<video class="video-js vjs-default-skin vjs-tech" id="${id}" poster="${lgimg.url}"
                controls muted  preload="auto" playsinline src="${lgimg.srcVideo}-0x480-600k">`;
                $videoElement.html(video);
            } else {
                if (lgimg.video) {
                    id = lgimg.title.replace(/\s/g, '-') + (1 * new Date());  // videojs would refuse to initialize player with the same id
                    video = '<video poster="' + lgimg.url + '" class="amp-video-panel__video video-js vjs-default-skin vjs-tech ' + manageNativeControlsClass +
                    '" id="' + id + '" controls muted autoplay="muted" preload="auto" playsinline>';

                    video = video + '<source src="' + lgimg.video + '" media="all">';
                    video = video + '</video>';
                    $videoElement.html(video);
                    this.player = videojs(id, this.config, () => {
                        this._initScrollEvent();

                        // disable seeking progressbar via plugin
                        this.player.disableProgress({
                            autoDisable: true
                        });
                    });
                }
            }
        } else {
            this.$videoContainer = $videoElement.parent();
            var isUseAsBackground = this.$videoContainer.hasClass('use-as-background');

            $videoElement.addClass(manageNativeControlsClass);
            if (!isUseAsBackground) {
                this.player = videojs($videoElement[0], this.config, () => {
                    var $playerWrapper = this.$videoContainer.find('.video-js'),
                        cmpSelector = VideoJS.selector;

                    if (cmpSelector.charAt(0) === '.' || cmpSelector.charAt(0) === '#') {
                        cmpSelector = cmpSelector.substr(1);
                    }
                    $playerWrapper.removeClass(cmpSelector).find('video').addClass(cmpSelector);

                    this.player.on('loadedmetadata', () => {
                        // metadata is loaded so we can query original video's width and set max-width to parent container of video
                        // (ONLY if container has "standard" class!)
                        if (!isUseAsBackground && this.$videoContainer.hasClass('standard')) {
                            this.$videoContainer.css('max-width', this.player.videoWidth() + 'px');
                        }
                    });

                    this._initScrollEvent();

                    // disable seeking progressbar via plugin
                    this.player.disableProgress({
                        autoDisable: true
                    });
                });

                if (!util.isDesktop()) {
                    this.$el = this.$videoContainer.find('video');
                }
            } // if it's useAsBackground - we will use only native html5 player without controls, autolooped and CSS3 transformed to cover full bg
        }
    }

    _initScrollEvent() {
        $(window).on('scroll', _debounce(() => {
            if (!util.isVisible(this.$el[0])) {
                this.player.pause();
            } else if (!this.isPlayed) {
                this.player.play();
                this.isPlayed = true;
            }
        }, 200));
    }

    reInit() {
        this.getVideoData();
    }
}

module.exports = VideoJS;
