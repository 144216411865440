var cache = {};

function _initializeCache($container) {
    cache.$pdpMain = $container.find('.js-pdp-main');
    cache.$productSetList = cache.$pdpMain.find('.js-product-bundle-list');
    cache.$hiddenElements = cache.$productSetList.find('.js-hidden-item');
}

function _initializeEvents() {
    cache.$productSetList.on('click', '.js-show_more_bundled_products', function() {
        var $this = $(this);
        var isVisible = $this.data('isVisible');
        var buttonMsg = $this.data('inverse-msg');
        
        if (isVisible) {
            cache.$hiddenElements.addClass('visually-hidden');
        } else {
            cache.$hiddenElements.removeClass('visually-hidden');
        }
        $this.data('inverse-msg', $this.text());
        $this.text(buttonMsg);
        $this.data('isVisible', !isVisible);
    });
}

module.exports = function ($container) {
    _initializeCache($container || $(document));
    _initializeEvents();
};
