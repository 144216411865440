/*eslint no-use-before-define: "off"*/
/*eslint eqeqeq: "off" */
/*eslint complexity: "off"*/

var compareWidget = require('_core_ext/compare-widget'),
    progress = require('_core_ext/progress'),
    page = require('_core_ext/page'),
    util = require('_core_ext/util'),
    ajax = require('_core_ext/ajax'),
    imagesLoaded = require('imagesloaded'),
    noScroll = require('_core_ext/components/noscroll'),
    _throttle = require('lodash/function/throttle');

const eventMgr = require('_core_ext/eventMgr');
const searchEmitter = eventMgr.getEmitter('search');
const outfitViewEmitter = eventMgr.getEmitter('OutfitView');

var currentUrl = '';
var loadMoreLastPressed = null;

/**
 * @private
 * @function
 * @description Utilitary methid that returns one of the 3 PDP tabs, the one selected<br/>
 */
function getSelectedTab() {
    if ($('.js-product-search-container').is(':visible')) {
        return 'product';
    }
    if ($('.js-content-search-container').is(':visible')) {
        return 'feature';
    }
    if ($('.js-video-search-container').is(':visible')) {
        return 'video';
    }
}

function infiniteScroll() {
    var $searchResultItems = $('#product-search-result-items');
    var offSetContainer;
    if ($searchResultItems.is(':visible')) {
        offSetContainer = $searchResultItems;
    } else {
        $searchResultItems = $('#feature-search-result-items');
        if ($searchResultItems.is(':visible')) {
            offSetContainer = $searchResultItems;
        } else {
            offSetContainer = $('#video-search-result-items');
        }
    }
    // getting the hidden div, which is the placeholder for the next page
    var itemsCount = offSetContainer.find('li.grid-tile').length;
    var loadingPlaceHolder = $('.' + getSelectedTab() + '-infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    // getting the variables necessary for infinite scroll. Page will be loaded once 75% is loaded.
    var scrollTop = $(document).scrollTop();
    var offSetContainerHeight = offSetContainer.height();
    var scrollPercentage = (scrollTop / offSetContainerHeight);

    // if the scroll is more than 60% from the top, load more content.
    if (loadingPlaceHolder.length === 1 && scrollPercentage > 0.6) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');

        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            var $html = $(html);
            loadingPlaceHolder.removeClass('infinite-scroll-loading bticons-loading bticons-spin');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            offSetContainer.append($html);
            app.components.initComponent('availability', offSetContainer);
            app.components.initComponent('ProductTile', offSetContainer);
            app.components.initComponent('OutfitView');

            setTimeout(() => {
                searchEmitter.emit('productTilesAppend', $html, itemsCount, offSetContainer.closest('.js-product-grid').data('gtm'));
            }, 0);

            if (!util.isMobile()) {
                $(document.body).trigger('sticky_kit:recalc');
            }
            if ($('#feature-search-result-items').is(':visible') || $('#video-search-result-items').is(':visible')) {
                app.components.initComponent('videojs');
            }
        };

        if (SitePreferences.LISTING_INFINITE_SCROLL) {
            // if we hit the cache
            //fillEndlessScrollChunk(sessionStorage[scrollCacheKey]);
            ajax.load({
                url: gridUrl,
                callback: function (response) {
                // put response into cache, filtering out the main ul
                    var elementResponse = $(response).filter('ul').html();
                // update UI
                    fillEndlessScrollChunk(elementResponse);
                }
            });
        }
    }
}

function initComponentsAfterUpdate() {
    var offSetContainer = $('#product-search-result-items');

    app.components.initComponent('availability', offSetContainer);
    app.components.initComponent('ProductTile', offSetContainer);
    app.components.initComponent('OutfitView');

    if (!util.isMobile()) {
        $(document.body).trigger('sticky_kit:recalc');
    }
    if ($('#feature-search-result-items').is(':visible') || $('#video-search-result-items').is(':visible')) {
        app.components.initComponent('videojs');
    }
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(refreshHash, initPageLoad) {
    if (refreshHash == false) {
        app.components.initComponent('availability');
        app.components.initComponent('ProductTile');
        app.components.initComponent('OutfitView');
        return;
    }

    if (refreshHash.indexOf('viewall') != -1) {
        //toggle infinite scroll on
        SitePreferences.LISTING_INFINITE_SCROLL = true;
        $(window).on('scroll', _throttle(infiniteScroll, 150));
    }

    var hashSplit = refreshHash.split('#'),
        hash = hashSplit[1],
        url = hashSplit[0],
        query = url.split('?');

    if (hash === 'results-products') { return; }
    var refineUrl;
    var queryParams = query.length > 1 ? query[1] : '';

    if ('undefined' !== typeof hash && hash.length > 0) { // added query parameter inclusion into ajax call
        queryParams = queryParams.length > 1 ? queryParams + '&' + hash : hash;
        refineUrl = window.location.pathname + '?' + queryParams;
        window.location.hash = queryParams;
    } else {
        if (queryParams.indexOf('productsearch') == -1 && queryParams.indexOf('productrefinesearch') == -1 && queryParams.indexOf('contentsearch') == -1 && queryParams.indexOf('videosearch') == -1) {
            app.components.initComponent('availability');
            app.components.initComponent('ProductTile');
            app.components.initComponent('OutfitView');
            return;
        }
    }

    function _reLoadProducts($container, url, format) {
        var $productsGrid = $container.hasClass('js-product-grid') ? $container : $container.find('.js-product-grid:first'),
            backupGridViewType = $productsGrid.data('product-tiles-view-type') || 'standard',
            isScrollToContent = SitePreferences.REFINEMENTS_SCROLL_TO_CONTENT,
            progressContainerClass = isScrollToContent ? 'js-product-search-container' : 'js-product-search-result-main';

        //if INFINITE_SCROLL disabled but VIEW ALL, this takes precedence
        progress.show($container.hasClass(progressContainerClass) ? $container : $container.find('.' + progressContainerClass), true);
        var options = {
            url: util.appendParamToURL(url, 'format', format || 'ajax')
        };
        $.ajax(options).done(function (response) {
            var $result = $('<div/>').append(response),
                $newProductsGrid = $result.find('.js-product-grid:first');

            if ($newProductsGrid.length) {
                $newProductsGrid.attr('data-product-tiles-view-type', backupGridViewType);
                var $toggleButton = $result.find('.js-toggle-grid:first');
                if (backupGridViewType === 'wide') {
                    $newProductsGrid.addClass('wide-tiles');
                    $toggleButton.addClass('wide');
                } else {
                    $newProductsGrid.removeClass('wide-tiles');
                    $toggleButton.removeClass('wide');
                }
            }

            app.components.destroyComponent('OutfitView');

            $container.html($result.html());

            compareWidget.init();
            app.components.initComponent('availability', $container);
            app.components.initComponent('ProductTile', $container);
            app.components.initComponent('OutfitView');
            progress.hide();
            app.components.initComponent('sticky');
            app.components.initComponent('bannerCarousel', $container);
            app.components.initComponent('ProductCarousel', $container);
            changeBrowserState(null, null, url);
            addClickEventsToTabs();
            //refinements imply starting over
            searchEmitter.emit('productListUpdated', $container, 0, $container.data('gtm'));
            noScroll.disable();
            app.components.initComponent('videojs');
            app.components.initComponent('breadcrumbs');
            app.components.initComponent('slick');
            app.components.initComponent('RefinementsRibbon');
            scrollToTop();
        });
    }
    if (queryParams.indexOf('productsearch') > -1 && !initPageLoad) {
        _reLoadProducts($('#main'), url, 'ajax');
    } else {//if it is a init then the full block must be searched and attached to body
        if (queryParams.indexOf('productrefinesearch') > -1 || (queryParams.indexOf('productsearch') > -1 && initPageLoad)) {
            _reLoadProducts($('#main'), url, 'ajax');

        } else  {
            if (queryParams.indexOf('contentsearch') > -1) {
                progress.show($('#content-search-result-main'));
                $('#content-search-result-main').load(util.appendParamToURL(refineUrl, 'format', 'ajax'), function () {
                    app.components.initComponent('availability');
                    app.components.initComponent('ProductTile');
                    app.components.initComponent('OutfitView');
                    progress.hide();
                    changeBrowserState(null, null, refineUrl);
                    app.components.initComponent('videojs');
                });
            } else {
                if (queryParams.indexOf('videosearch') > -1) {
                    progress.show($('#video-search-result-main'));
                    $('#video-search-result-main').load(util.appendParamToURL(refineUrl, 'format', 'ajax'), function () {
                        app.components.initComponent('availability');
                        app.components.initComponent('ProductTile');
                        app.components.initComponent('OutfitView');
                        progress.hide();
                        changeBrowserState(null, null, refineUrl);
                        app.components.initComponent('videojs');
                    });
                }
            }
        }
    }
}

function changeBrowserState(data, title, url) {
    title = title || '';
    data = data || {};
    if (supportsHistoryAPI()) {
        //removes stateObject from history replacing its state
        history.replaceState(data, title, url);
        //store current url for future comparision
        currentUrl = url;
    }
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $('#main');
    // compare checked

    var tileAddFunc = function () {
        var cb = $(this);
        var tile = cb.closest('.js-component-product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('product-id'),
            uuid: tile.data('product-uuid'),
            img: itemImg,
            cb: cb
        });
    };
    $main.on('click', 'input[type="checkbox"].compare-check', tileAddFunc);

    eventMgr.registerAction('search.updateProductListing', function(url) {
        return new Promise((res) => {
            updateProductListing(url);
            res();
        });
    });

    $('#header-search-form-wrapper').find('form').on('submit', function(e) {
        var searchTerm = e.target.querySelector('#q').value;
        if (searchTerm == Resources.SEARCH_PLACEHOLDER || searchTerm == '') {
            e.preventDefault();
        }
    });
    $main.on('change', '.js-browse-categories', function(event) {
        event.preventDefault();
        updateProductListing($(this).val());
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not(.js-quickview, [data-action="wishlist"]), .js-grid-tile a', function () {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};


        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile,.js-grid-tile');
        var idx = tile.data('idx') ? + tile.data('idx') : 0;

        if (!a.parent().hasClass('content-item-link') && qsParams.crefn1 && qsParams.crefn1 == 'content_type') {
            //this means you have clicked on product but view all page refresh was done in content
            //solution is to have view all loading by ajax
            //for now do nothing
        } else {
            // convert params.start to integer and add index
            params.start = (+params.start) + (idx + 1);
            // set the hash and allow normal action to continue
            a[0].hash = $.param(params);
        }

        var selectedTab = getSelectedTab();
        var query = '';
        var $el = null;

        if (SitePreferences.LISTING_INFINITE_SCROLL && supportsHistoryAPI()) {
            // loads last loaded chunk url from dom
            query = '.' + selectedTab + '-infinite-scroll-placeholder[data-loading-state="loaded"]:last';
            $el = $(query);

            updateHistoryState('infinite-scroll', $el, selectedTab);
        } else if (SitePreferences.LISTING_LOAD_MORE && supportsHistoryAPI()) {
            updateHistoryState('load-more', $(loadMoreLastPressed), selectedTab);
        }

        function updateHistoryState(type, $el, selectedTab){
            if (!$el.length) {
                return false;
            }

            var lastLoadedChunk = $el.attr('data-grid-url');
            var uri = util.getUri(lastLoadedChunk);
            //stores the window scroll so it can be restored afterwards
            var scrollTop = $(window).scrollTop();
            //stateObject created, adding to the history state
            var stateObj = {
                'type': type,
                'dataitemid': tile.attr('data-itemid'),
                'scrollcachekey': lastLoadedChunk,
                'selectedtab': selectedTab,
                'scrolltop': scrollTop
            };
            if (uri) {
                stateObj.size = uri.queryParams.sz;
                stateObj.start = uri.queryParams.start;
            }
            //added stateObj to history
            changeBrowserState(stateObj, null, window.location.href);
        }
    });

    // handle sorting change
    $main.on('change', '.sort-by select', function (e) {
        e.preventDefault();
        updateProductListing($(this).find('option:selected').val());
    })
    .on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl);
        }
    }).on('click', '.sizes-view-all .items-per-page-number', function () {
        $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
    });

    addClickEventsToTabs();

    $(document).on('click', '#toggle > li > div', function() {
        if (false == $(this).next().is(':visible')) {
            $('#toggle ul').slideUp();
        }
        var $currIcon = $(this).find('span.the-btn > i');
        $('span.the-btn > i').not($currIcon).addClass('fa-plus').removeClass('fa-minus');
        $currIcon.toggleClass('fa-minus fa-plus');
        $(this).next().slideToggle();
        $('#toggle > li > div').removeClass('active');
        $(this).addClass('active');
    });

    //loads the latest back click
    $(document).on('click', 'div.back', function () {
        if (supportsHistoryAPI()) {
            window.history.back();
        }
    });

    //check, what stored url is changed
    //if changed - refresh page
    $(window).on('popstate', function(e) {
        if (e.originalEvent && e.originalEvent.state && currentUrl && currentUrl != window.location.href) {
            page.refresh();
        }
    });

    $main.on('click', '.js-product-load-more', function(e){
        e.preventDefault();

        const $target = $(e.target);
        loadMoreLastPressed = $target.clone();  // remember last pressed Load More button instance to pass into updateHistoryState
        const url = $target.attr('data-grid-url');

        const offSetContainer = $('#product-search-result-items');
        const itemsCount = offSetContainer.find('li.grid-tile').length;

        progress.show($('.js-search-result-content'));

        ajax.load({
            url: url,
            callback: function (response) {
                const $response = $(response);
                const productGridResponse = $response.find('#product-search-result-items').html();
                offSetContainer.append(productGridResponse);

                initComponentsAfterUpdate();

                // replace 'Load more' nav elements with received from loaded grid portion
                const $productGrid = offSetContainer.closest('.js-product-grid');
                $productGrid.find('.js-product-load-more').replaceWith($response.find('.js-product-load-more'));
                $productGrid.find('.js-products-counting').replaceWith($response.find('.js-products-counting'));
                $productGrid.find('.js-number-of-products').text($productGrid.find('.js-product-grid-tile').length);

                setTimeout(() => {
                    searchEmitter.emit('productTilesAppend', $(productGridResponse), itemsCount, $productGrid.data('gtm'));
                }, 0);

                progress.hide();
            }
        });
    });

    //prevent bfcache safari
    $(window).on('unload', function() {});

    gridViewToggle($main);
    gridTilesResize();
}

function addClickEventsToTabs() {

    var productSearchContainer = $('.js-product-search-container');
    var contentSearchContainer = $('.js-content-search-container');
    var videoSearchContainer = $('.js-video-search-container');

    if ($('.js-product-search-tab').hasClass('no-click') == false) {
        $(document).on('click', '.js-product-search-tab', function() {
            productSearchContainer.removeClass('hide');
            contentSearchContainer.addClass('hide');
            videoSearchContainer.addClass('hide');
        });
    }

    if ($('.js-content-search-tab').hasClass('no-click') == false) {
        $(document).on('click', '.js-content-search-tab', function() {
            contentSearchContainer.removeClass('hide');
            productSearchContainer.addClass('hide');
            videoSearchContainer.addClass('hide');
        });
    }

    if ($('.js-video-search-tab').hasClass('no-click') == false) {
        $(document).on('click', '.js-video-search-tab', function() {
            videoSearchContainer.removeClass('hide');
            productSearchContainer.addClass('hide');
            contentSearchContainer.addClass('hide');
        });
    }
}


function selectTab (contentType) {

    var productSearchContainer = $('.js-product-search-container');
    var contentSearchContainer = $('.js-content-search-container');
    var videoSearchContainer = $('.js-video-search-container');

    if (contentType == 'product') {

        productSearchContainer.removeClass('hide');
        contentSearchContainer.addClass('hide');
        videoSearchContainer.addClass('hide');

    } else {
        if (contentType == 'feature') {

            contentSearchContainer.removeClass('hide');
            productSearchContainer.addClass('hide');
            videoSearchContainer.addClass('hide');

        } else {

            videoSearchContainer.removeClass('hide');
            productSearchContainer.addClass('hide');
            contentSearchContainer.addClass('hide');

        }
    }
}

/**
 * @private
 * @function
 * @description Having a history state object loads the appropriate page portions from sessionStorage and moves the scroll to the right position<br/>
 * <p>sessionStorage loading</p>
 * <p>ajax loading page portions</p>
 * <p>historyState replacement</p>
 */
function loadInfiniteScrollFromHistoryState(historyState) {
    var deffereds = [];

    if (!historyState) {
        $(window).on('scroll', _throttle(infiniteScroll, 150));
        return;
    }

    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        // 24 / 12 = 2 iterations
        var it = historyState.start / historyState.size;
        var responses = [];
        for (var i = 1; i <= it; i++) {
            var start = i * historyState.size;
            var uri = util.getUri(historyState.scrollcachekey);
            var url = historyState.scrollcachekey.split('?')[0];// cannot use uri url due to different urls for different browsers (IE) causing key issues

            uri.queryParams.start = start;

            for (var param in uri.queryParams) {
                url = util.appendParamToURL(url, param,uri.queryParams[param]);
            }

            deffereds.push(ajax.load({
                type: 'GET',
                url: url,
                callback: (function(i) {
                    return function (response) {
                        var elementResponse = $(response).filter('ul').html();
                        responses[i-1] = elementResponse;
                    }
                })(i)
            }))
        }

        $.when(...deffereds).then(function(){
            responses.every(function(response){
                fillInfiniteScrollChunk(response);
                return true;
            });
            $(window).on('scroll', _throttle(infiniteScroll, 150));

            initComponentsAfterUpdate();

            $('html, body').animate({
                scrollTop: historyState.scrolltop
            }, 1000);
        })
    } else {
        $('html, body').animate({
            scrollTop: historyState.scrolltop
        }, 1000);
    }

    changeBrowserState(null, null, window.location.href);
}

/**
 * @private
 * @function
 * @description Selects an unloaded placeholder and pushes the html<br/>
 * <p>page portion loading</p>
 */
function fillInfiniteScrollChunk (html) {//pass the selector
    var selectedTab = getSelectedTab();
    var placeHolder = '.' + selectedTab + '-infinite-scroll-placeholder[data-loading-state="unloaded"]';
    var loadingPlaceHolder = $(placeHolder);
    loadingPlaceHolder.attr('data-loading-state', 'loaded');
    $(html).appendTo('#' + selectedTab + '-search-result-items');
}

/**
 * @private
 * @function
 * @description Having a history state object loads the appropriate page portions and moves the scroll to the right position<br/>
 * <p>sessionStorage loading</p>
 * <p>ajax loading page portions</p>
 * <p>historyState replacement</p>
 */
function loadMoreFromHistoryState(historyState) {
    if (!historyState || !historyState.scrollcachekey) {
        return;
    }

    // number of iterations
    var it = Math.floor(historyState.start / historyState.size);

    var deffereds = [];
    var responses = [];

    for (var i = 1; i <= it; i++) {
        var start = i * historyState.size;
        var uri = util.getUri(historyState.scrollcachekey);
        var url = historyState.scrollcachekey.split('?')[0];

        uri.queryParams.start = start;

        for (var param in uri.queryParams) {
            url = util.appendParamToURL(url, param,uri.queryParams[param]);
        }

        deffereds.push(ajax.load({
            type: 'GET',
            url: url,
            callback: (function(index) {
                return function (response) {
                    responses[index - 1] = response;
                }
            })(i)
        }));
    }

    $.when(...deffereds).then(function() {
        var $searchResultItems = $('#product-search-result-items');

        $.each(responses, function(ind, response) {
            $searchResultItems.append($(response).find('#product-search-result-items').html());
        });

        var lastChunk = $(responses[responses.length - 1]);
        const $productGrid = $searchResultItems.closest('.js-product-grid');

        // remember Load more button holding url to last loaded chunk - the one from the penultimate loaded chunk or from PLP itself
        if (responses.length > 1) {
            loadMoreLastPressed = $(responses[responses.length - 2]).find('.js-product-load-more').clone();
        } else {
            loadMoreLastPressed = $productGrid.find('.js-product-load-more').clone();
        }

        // replace 'Load more' nav elements with received from last loaded grid portion
        $productGrid.find('.js-product-load-more').replaceWith(lastChunk.find('.js-product-load-more'));
        $productGrid.find('.js-products-counting').replaceWith(lastChunk.find('.js-products-counting'));

        initComponentsAfterUpdate();

        // scroll to last visited position after all chunks loaded
        $('html, body').animate({
            scrollTop: historyState.scrolltop
        }, 1000);
    })

    changeBrowserState(null, null, window.location.href);
}

/**
 * @private
 * @function
 * @description Checks for History API availability<br/>
 *
 */
function supportsHistoryAPI() {
    return !!(window.history && history.pushState);
}

function gridTilesResize() {
    var $tiles = $('.js-product-tiles-wrapper');
    if ($tiles.length === 0) { return; }
    imagesLoaded('.tiles-container').on('done', function () {
        $tiles.syncHeight()
            .each(function (idx) {
                $(this).data('idx', idx);
            });
    });
}

function gridViewToggle($container) {
    $container.on('click', '.js-toggle-grid:first', function () {
        var $this = $(this),
            $gridWrapper = $container.find('.js-product-grid:first'),
            viewType = $this.hasClass('wide') ? 'standard' : 'wide';
        if (viewType === 'wide') {
            $gridWrapper.addClass('wide-tiles');
            $this.addClass('wide');
        } else {
            $gridWrapper.removeClass('wide-tiles');
            $this.removeClass('wide');
        }

        $gridWrapper.trigger('tiles.repaint', {viewType : viewType});
        $gridWrapper.data('product-tiles-view-type', viewType);

        outfitViewEmitter.emit('resetLinkText');
    });
}

function initTabSelection() {
    //selects tab even in pagination mode via history state or hash
    if (supportsHistoryAPI() && history.state !== null && history.state.selectedtab) {
        selectTab(history.state.selectedtab);
    } else {
        if (window.location.hash.indexOf('productsearch') > -1) {
            selectTab('product');
        } else {
            if (window.location.hash.indexOf('contentsearch') > -1) {
                selectTab('feature');
            } else {
                if (window.location.hash.indexOf('videosearch') > -1) {
                    selectTab('video');
                }
            }
        }
    }
}

function scrollToTop() {
    if (util.isDesktop()) {
        var $searchResultContainer = $('.js-search-result-content'),
            containerTop = $searchResultContainer.offset().top,
            containerHeight = $searchResultContainer.height(),
            windowTop = $(window).scrollTop(),
            headerHeightOffset = 50,
            containerHeightOffset = 106;

        if ((containerTop + containerHeight - windowTop + containerHeightOffset) < 0) {
            var scrollTo = $('.js-product-search-container').offset().top - headerHeightOffset;
            var $headerWrapper = $('#header-wrapper');
            if ($headerWrapper.hasClass('is-sticky')) {
                scrollTo -= $headerWrapper.find('.js-header').height();
            } else {
                scrollTo -= $headerWrapper.find('.is-sticky > .js-header').height();
            }

            $('html, body').animate({
                scrollTop: scrollTo
            }, 150);
        }
    }

}

exports.init = function () {
    compareWidget.init();
    initTabSelection();

    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        //if history has state object, load selected tab and loads infinite scroll chunks
        if (supportsHistoryAPI()) {
            loadInfiniteScrollFromHistoryState(history.state);
        } else {
            $(window).on('scroll', _throttle(infiniteScroll, 150));
        }
    } else if (SitePreferences.LISTING_LOAD_MORE && supportsHistoryAPI()) {
        loadMoreFromHistoryState(history.state);
    } else if (window.location.hash.length) {
        updateProductListing(window.location.pathname + '?' + window.location.hash.substring(1) + '#', true);
    }

    initializeEvents();
};
