import Component from '_core_ext/components/Component';
import dialog from '_core_ext/dialog';
import validator from '_core_ext/components/global/validator';

export default class AddressUpdateLinks extends Component {
    static get selector() {
        return '.js-address-update-link';
    }

    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this._initCache();
        this._initEvents();
    }
    _initCache() {
        this.url = this.$el.data('url');
    }
    _initEvents() {
        var that = this;
        this.event('click', (element, e) => {
            e.preventDefault();
            var isHampers = $(element).hasClass('hampers');
            var dialogsCustomCSSClass = 'add-edit-address-dialog b-checkout_dialog  js-add-edit-address-dialog theme-' + (SitePreferences.CHECKOUT_THEME || 'default');
            dialog.open({
                url: this.url,
                options: {
                    title :  Resources.EDIT_ADDRESS_TITLE,
                    dialogClass: dialogsCustomCSSClass,
                    open: () => {
                        var $formContainer = dialog.getCurrent();
                        var $addressForm = $formContainer.find('form');

                        validator.initForm($addressForm);
                        app.components.initComponent('DialogAddress', $formContainer);
                        app.components.initComponent('RedesignInput', $formContainer);
                        if (isHampers) {
                            that.initUseAsShipingAddress($formContainer);
                        }

                    }
                }
            });

            return false;
        });
    }
    initUseAsShipingAddress($formContainer) {
        var $stateSelect = $formContainer.find('.js-input-state');
        var $useAsShip = $formContainer.find('.js-input-useAsShippingAddress');

        if ($stateSelect.val() !== Resources.ALLOWED_HAMPERS_COUNTY) {
            $useAsShip.prop('checked', false);
            $useAsShip.closest('.js-form-row').hide();
        }

        $stateSelect.on('change', () => {
            if ($stateSelect.val() !== Resources.ALLOWED_HAMPERS_COUNTY) {
                $useAsShip.prop('checked', false);
                $useAsShip.closest('.js-form-row').hide();
            } else {
                $useAsShip.closest('.js-form-row').show();
            }
        });
    }

}

module.exports = AddressUpdateLinks;
