import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

/**
  * Holds text entered by user to persist message upon variation changes on PDP.
  * This works only for component re-init without complete page reload.
 */
var _typedMessage = '';

/**
 * Component for handling Gift message boxes (gift wrap and gift card),
 * wraps input (usually textarea), sanitize input to allow only printable text and shows message with characters left.
 * Controlled input must define 'maxlength' attribute!
 */
export default class PrintableMessage extends Component {

    static get selector() {
        return '.js-printable-msg';
    }

    static get typedMessage() {
        return _typedMessage;
    }

    static set typedMessage(value) {
        _typedMessage = value;
        return _typedMessage;
    }

    get configDefault() {
        return {
            input: 'textarea',
            limitMsgContainer: 'label',
            limitMsgPattern: '{0} characters left out of {1}'
        };
    }

    get configDataAttrName() {
        return 'printable-msg';
    }

    /**
     * Method called when creating component
     *
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.attributes = {};
        this.$cache = {};

        this._initCache()
        this._initEvents();

        if (!this.config.doNotPersist && PrintableMessage.typedMessage) {
            this.$cache.input.val(PrintableMessage.typedMessage);
        }

        this._update();
    }

    _initCache() {
        this.$cache = {
            input: this.$el.find(this.config.input),
            limitMsg: this.$el.find(this.config.limitMsgContainer)
        };

        this.attributes.maxlength = parseInt(this.$cache.input.attr('maxlength'), 10);
    }

    _initEvents() {
        this.event('keyup change', this.config.input, this._update);

        // clear entered message when added to cart
        eventMgr.on('addToCart.product.added', () => {
            this.$cache.input.val('');
            this._update();
        });
    }

    _update() {
        // sanitize entered text first
        util.removeInputNotAcceptableCharacters(this.$cache.input);
        if (!this.config.doNotPersist) {
            PrintableMessage.typedMessage = this.$cache.input.val();
        }

        // update 'chars left' message
        let charsLeft = this.attributes.maxlength - this.$cache.input.val().length;
        charsLeft = Math.max(0, charsLeft);
        const msg = String.format(this.config.limitMsgPattern, charsLeft, this.attributes.maxlength);
        this.$cache.limitMsg.html(msg);
    }
}

module.exports = PrintableMessage;
