
/**
 * @function
 * @description Initializes component
 *
 */

var ajax = require('_core_ext/ajax');

function _loadFeeds() {
    var $feedsContainer = $('.js-social-feeds'),
        $feedsBlocks = $feedsContainer.find('[data-url]');
    $feedsBlocks.each(function() {
        var $feedsContainer = $(this);
        ajax.load({
            url: $feedsContainer.data('url'),
            callback: function(response) {
                $feedsContainer.empty().html(response);
                app.components.initComponent('carousel', $feedsContainer);
            }
        });
    });

    app.components.initComponent('instagram');

    if ($feedsBlocks.length) {
        $feedsContainer.tabs();
    }
}

exports.init = function () {
    _loadFeeds();
};
