const eventMgr = require('_core_ext/eventMgr');
const noScrollEmitter = eventMgr.getEmitter('noScroll');

var $body = $(document.body),
    $window = $(window),
    prevScrollTop;

exports.enable = function () {
    prevScrollTop = $window.scrollTop();

    $body.css('top', - prevScrollTop).addClass('noscroll');
    noScrollEmitter.emit('enabled');
};

exports.disable = function () {
    $body.css('top', 'auto').removeClass('noscroll');
    noScrollEmitter.emit('disabled');

    if (prevScrollTop) {
        $window.scrollTop(prevScrollTop);
    }
};
