'use strict';

var dialog = require('_core_ext/dialog');
var SessionAttributes = window.SessionAttributes;

/**
 * @function captcha Used to display/control the scrim containing the simulated captcha code
 **/
module.exports = function () {
    /**
     * if the session.privacy.ratelimited element is present then show the notification
     * NOTE: You will probably want to replace this with a call to an actual CAPTCHA system to replace the simple one here
     */
    if (SessionAttributes.SHOW_CAPTCHA) {
        dialog.open({
            html: '<div class="description">' + Resources.ARE_YOU_HUMAN + '</div>',
            options: {
                closeOnEscape: false,
                dialogClass: 'dialog-human',
                minHeight: 125,
                close: function() {
                    // refactored https://brownthomas.atlassian.net/browse/BT-934
                    $.ajax({
                        url: Urls.rateLimiterReset
                    });
                }
            }
        });
    }
};
