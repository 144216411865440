/* globals google */
import Component from '_core_ext/components/Component';
import styles from '_core_ext/components/map/markerMapStyle';

export default class Marker extends Component {
    static get selector() {
        return '.js-map-marker';
    }
    get configDefault() {
        return {
            position : {lat: 53.349805, lng: -6.260320},
            zoom : 14
        };
    }
    get configDataAttrName() {
        return 'map-marker-options';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.createMarker();
    }
    createMarker() {
        $.when(window.GoogleMapDeffered).then(() => {
            var map = new google.maps.Map(this.$el.get(0), {
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: styles,
                zoom: this.config.zoom,
                disableDefaultUI: true,
                center: this.config.position
            });

            new google.maps.Marker({ // eslint-disable-line no-new
                map: map,
                position: this.config.position,
                icon: Urls.storeMapMarkerPath
            });
        });
    }
}

module.exports = Marker;
