var dialog = require('_core_ext/dialog');
var util = require('_core_ext/util');

var login = {
    /**
     * @private
     * @function
     * @description init events for the loginPage
     */
    init: function () {
        //o-auth binding for which icon is clicked
        $('.oAuthIcon').on('click', function () {
            $('#OAuthProvider').val(this.id);
        });


        //toggle the value of the rememberme checkbox
        $('#dwfrm_login_rememberme').on('change', function () {
            if ($('#dwfrm_login_rememberme').attr('checked')) {
                $('#rememberme').val('true');
            } else {
                $('#rememberme').val('false');
            }
        });

        var isRedesign = window.pageContext && window.pageContext.ns === 'checkout';

        $(document).on('click', '.js-password-reset-link, #password-reset', function (e) {
            e.preventDefault();
            var $link = $(e.target);
            dialog.open({
                url: util.appendParamToURL($link.attr('href'), 'source', pageContext.ns),
                options: {
                    title : $link.data('dialogTitle') || Resources.RESET_PASSWORD,
                    dialogClass: isRedesign ? 'b-checkout_dialog_password' : '',
                    open: function () {
                        var $dialog = dialog.getCurrent();

                        app.components.initComponent('validator', $dialog);
                        app.components.initComponent('RedesignInput', $dialog);

                        $dialog.find('.js-input-email').val($('input:text.username').first().val());
                        var $requestPasswordForm = $dialog.find('[name$="_requestpassword"]'),
                            $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
                        $($submit).on('click', function (e) {
                            if (!$requestPasswordForm.valid()) {
                                return;
                            }
                            e.preventDefault();
                            dialog.submit($submit.attr('name'));
                        });
                    }
                }
            });
        });

        $(document).on('click mousedown', '.js-dialog-close', function() {
            dialog.close();
        });

        var $contactusFormWrapper = $('.js-login-form-wrapper');
        initializeCaptcha($contactusFormWrapper);
    }
}

function initializeCaptcha($wrapper) {
    if (SitePreferences.LOGIN_CAPTCHA_ENABLED) {
        // Explicit rendering reCAPTCHA
        window.grecaptcha.ready(() => {
            window.grecaptcha.render('g-recaptcha', {
                'sitekey' : SitePreferences.CONTACTUS_CAPTCHA_SITE_KEY
            });
        });

        // Show reCAPTCHA inside asset
        $('.js-recaptcha2').show();

        // Bind reCAPTCHA validation
        $wrapper.click('form button[type=submit]', () => {
            const reCaptchaResponse = window.grecaptcha.getResponse();
            const reCaptchaIsNotValid = reCaptchaResponse.length === 0;
            $('#g-recaptcha + input').prop('required', reCaptchaIsNotValid);
        });
    }
}


module.exports = login;
