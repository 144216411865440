import Component from '_core_ext/components/Component';

export default class ProductInfo extends Component {
    static get selector() {
        return '.js-product-info';
    }

    initCache() {
        this.$loyaltyPoints = this.$el.find('.js-loyalty-points');
    }

    _updateLoyaltyPoints() {
        if (!this.$loyaltyPoints.length) {
            return;
        }
        const bitrhdayLoyaltyMultiplier = +SitePreferences.BIRTHDAY_LOYALTY_MULTIPLIER;
        const price = this.$loyaltyPoints.data('price');
        const isDoubleLoyalty = this.$loyaltyPoints.data('isdoubleloyalty');
        let customerLoyaltyTier = window.User.customerLoyaltyTier;
        var loyaltyUser = this.$loyaltyPoints.data('loyaltyuser');
        //loyalty points in the category do not double if they are already tripled on the birthday
        if (customerLoyaltyTier < bitrhdayLoyaltyMultiplier) {
            customerLoyaltyTier *= isDoubleLoyalty ? 2 : 1;
        }

        const loyaltyPoints = Math.floor(customerLoyaltyTier * Math.floor(price));
        var msgTpl = "";
        if(loyaltyUser){
            msgTpl = this.$loyaltyPoints.attr(`data-stringpoints-${User.loyaltyTierName}`) || this.$loyaltyPoints.attr('data-stringpoints');
        }else{
            msgTpl = this.$loyaltyPoints.attr('data-strngpoints-noloyalty');
        }
         
        this.$loyaltyPoints.html(String.format(msgTpl, loyaltyPoints));
    }

    init(...args) {
        super.init(...args);

        this.initCache();
        this._updateLoyaltyPoints();

        return true;
    }
}

module.exports = ProductInfo;
