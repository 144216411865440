import Component from '_core_ext/components/Component';

export default class DeliveryInfo extends Component {
    static get selector() {
        return '.js-delivery-info';
    }

    init(...args) {
        //call parent init method with same args
        super.init(...args);
        this._initEvents();
        this.initTimer();
    }

    _initEvents() {
    }

    initTimer() {
        var $timer = this.$el.find('.js-delivery-timer');
        if ($timer.length) {
            var $deliverySame = this.$el.find('.js-delivery-wrapper-same');
            var $deliveryNominated = this.$el.find('.js-delivery-wrapper-nominated');
            var targetTime = new Date();
            // Same day delivery is available till hours specified in asset
            var targetHours = $timer.data('order-by-hours') || 15;
            targetTime.setHours(targetHours, 0, 0, 0); // set hours, minutes, seconds and miliseconds
            targetTime = targetTime.getTime();

            (function countdown() {
                var now = new Date();
                now.setHours(now.toLocaleString('en-GB', {hour: '2-digit', hour12: false, timeZone: 'Europe/London'}))
                now = now.getTime();
                var timeleft = targetTime - now;

                // Calculating the hours and minutes left
                var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));

                var countdownString = '';
                if (hours) {
                    countdownString += hours + (hours > 1 ? 'hrs' : 'hr') + ' and ';
                }
                countdownString += minutes + (minutes > 1 ? 'mins' : 'min');
                $timer.html(countdownString);
                
                // Change content asset when countdown is over
                if (timeleft < 0) {
                    $deliverySame.addClass('hidden');
                    $deliveryNominated.removeClass('hidden');
                } else {
                    setTimeout(countdown, 60000);
                }
            })();
        }
    }
}

module.exports = DeliveryInfo;
