import $ from 'jquery';

const CSS_STICKY_TOP = 119; // this value is a copy of the `top` css property for `.sticky` class

const STICKY_WRAPPER_CLASS = 'search-result-options';
const STICKY_REFERENCE_CLASS = 'js-sticky';
const STICKY_DEFINING_CLASS = 'sticky';

const stickyElement = () => $(`.${STICKY_REFERENCE_CLASS}`);

const getTopOffset = () => $(`.${STICKY_WRAPPER_CLASS}`).offset().top - CSS_STICKY_TOP;
const toggleStickiness = (condition) => stickyElement().toggleClass(STICKY_DEFINING_CLASS, condition);
const onOffStickiness = (currentScrollTop, lastScrollTop) => {
    const isScrollUp = currentScrollTop < lastScrollTop;
    const isTopOffsetNotReached = currentScrollTop > getTopOffset();
    return toggleStickiness(isScrollUp && isTopOffsetNotReached);
}

const debounceScroll = (fn) => (currentScrollTop, lastScrollTop) => {
    const DEBOUNCE_THRESHOLD = 15;
    const scrollTopDiff = Math.abs(currentScrollTop - lastScrollTop);
    if (scrollTopDiff > DEBOUNCE_THRESHOLD) {
        return fn();
    }
}

const onScroll = (fn) => {
    const $window = $(window);
    let lastScrollTop = 0;
    $window.on('scroll', () => {
        const currentScrollTop = $window.scrollTop();
        debounceScroll(() => {
            fn(currentScrollTop, lastScrollTop);
            lastScrollTop = currentScrollTop;
        })(currentScrollTop, lastScrollTop)
    });
}

const setParentHeightEqualToStickyElement = () => stickyElement().parent().height(stickyElement().outerHeight(true));
const setOnScrollHandler = () => onScroll(onOffStickiness)

const mobileStickyRefinements = () => {
    setParentHeightEqualToStickyElement();
    setOnScrollHandler();
}

export default mobileStickyRefinements;
