import Component from '_core_ext/components/Component';
import ajaxService from '_core_ext/ajax';
import progress from '_core_ext/progress';
import util from '_core_ext/util';
import account from '../../pages/account';

var
    /**
     * Loaders counter
     * @type {number}
     */
    initCounter = 0,

    /**
     * Update buttons selector
     * @type {string}
     */
    containerUpdateButtons = '.js_preferences-centre-update',

    /**
     * Buttons inactive class
     * @type {string}
     */
    btnClassInactive = 'inactive';

export default class PreferenceGroup extends Component {

    /**
     * Returns elements' selector for initialize
     * @returns {string}
     */
    static get selector() {
        return '.js-component-preferencegroup';
    }

    /**
     * Method called when creating component
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {

        // Call parent init method with same args
        super.init(...args);

        this.showProgress();
        this.searchInputs();
        this.initFieldsMap();
        this.foundUniqueGroupCodes();
        this.initializeButtonsEvents();
        this.initializeInputsEvents();
        this.getProfilePreferences();
    }

    /**
     * Shows loader
     */
    showProgress() {
        if (initCounter === 0) {
            progress.show(this.$el.parents('.preferences-centre'));
        }
        initCounter++;
    }

    /**
     * Hides loader
     */
    hideProgress() {
        initCounter--;
        if (initCounter <= 0) {
            progress.hide();
        }
    }

    /**
     * Search inputs in container
     */
    searchInputs() {
        this.$inputArray = this.$el.find('input[type="checkbox"], input[type="radio"]');
    }

    /**
     * Initializes fields map by group code and preferences codes
     */
    initFieldsMap() {
        this.fieldsMap = {};
        this.$inputArray.each((inputIndex, inputEl) => {
            var $input = $(inputEl),
                groupCode = $input.data('groupCode'),
                attributeCode = $input.data('attributeCode'),
                attributeCustomer = $input.data('customerAttribute');

            // Group attributes
            if (groupCode) {
                this.fieldsMap[groupCode] = this.fieldsMap[groupCode] || {};
                this.fieldsMap[groupCode][attributeCode] = {
                    el: $input,
                    value: $input.prop('checked')
                };
            }

            // Customer attributes
            if (attributeCustomer) {
                this.fieldsMap._customer = this.fieldsMap._customer || {};
                this.fieldsMap._customer[attributeCustomer] = {
                    el: $input,
                    value: $input.prop('checked')
                };
            }
        });
    }

    /**
     * Founds all unique group codes
     */
    foundUniqueGroupCodes() {
        this.uniqueGroupCodes = Object.keys(this.fieldsMap);
    }

    /**
     * Initializes buttons events
     */
    initializeButtonsEvents() {
        this.$el.on('click', containerUpdateButtons, (event) => {
            const $contactError = this.$el.find('.js-contact-error'),
                $contactInputs = this.$el.find('input[type="checkbox"]'),
                $preferencesBtn = $(event.currentTarget);

            event.preventDefault();

            if (!$contactInputs.is(':checked')) {
                $contactError.removeClass('hidden');

                return;
            }

            if (!$preferencesBtn.hasClass(btnClassInactive)) {

                // WARNING: Input options will change by pairs
                var changedAttributesPacks = [],
                    changedAttributes = [];

                for (var groupCode in this.fieldsMap) {

                    if (!this.fieldsMap.hasOwnProperty(groupCode)) {
                        continue;
                    }

                    for (var attributeCode in this.fieldsMap[groupCode]) {

                        if (!this.fieldsMap[groupCode].hasOwnProperty(attributeCode)) {
                            continue;
                        }

                        var
                            $input = this.fieldsMap[groupCode][attributeCode].el,
                            inputValue = $input.prop('checked'),
                            oldValue = this.fieldsMap[groupCode][attributeCode].value,
                            attributeCodeUnchecked = $input.data('attributeCodeUnchecked');

                        if (inputValue === oldValue) {
                            continue;
                        }

                        if (!inputValue && attributeCodeUnchecked) {
                            changedAttributes.push({
                                groupCode: groupCode,
                                attributeCode: attributeCodeUnchecked,
                                attributeCustomer: $input.data('customerAttribute'),
                                attributeValue: true
                            });
                        } else {
                            changedAttributes.push({
                                groupCode: groupCode,
                                attributeCode: attributeCode,
                                attributeCustomer: $input.data('customerAttribute'),
                                attributeValue: inputValue
                            });
                        }

                        // DW allows to send maximum 6 requests to service because of server's quota
                        if (changedAttributes.length === 6) {
                            changedAttributesPacks.push(changedAttributes);
                            changedAttributes = [];
                        }
                    }
                }

                if (changedAttributes.length > 0) {
                    changedAttributesPacks.push(changedAttributes);
                }

                if (changedAttributesPacks.length > 0) {
                    this.sendPreferences(changedAttributesPacks)
                        .then(() => {
                            this.initFieldsMap();
                            $preferencesBtn.text($preferencesBtn.attr('data-updated-msg'))
                                .addClass(btnClassInactive);
                            this.hideProgress();
                        });
                } else {
                    $preferencesBtn.text($preferencesBtn.attr('data-updated-msg'))
                        .addClass(btnClassInactive);
                }
            }
        });
    }

    /**
     * Sends preferences packs for save by promise queue
     * @param {array} packs
     */
    sendPreferences(packs) {
        this.showProgress();
        var sendPreferencesPromise = $.Deferred();
        this.sendRequest(packs, sendPreferencesPromise);
        return sendPreferencesPromise.promise();
    }

    /**
     * Send request pack, starting from zero-index
     * @param {array} packs
     * @param {promise} sendPreferencesPromise
     * @param {number} [index]
     */
    sendRequest(packs, sendPreferencesPromise, index) {
        index = index || 0;
        return $.ajax({
            url: this.$el.attr('action'),
            data: {
                attributes: JSON.stringify(packs[index])
            },
            type: 'POST'
        }).always(() => {
            if (packs[index + 1]) {
                this.sendRequest(packs, sendPreferencesPromise, index + 1);
            } else {
                sendPreferencesPromise.resolve();
            }
        });
    }

    /**
     * Initializes inputs events
     */
    initializeInputsEvents() {
        this.$el.on('click', 'input', (e) => {
            const $contactError = this.$el.find('.js-contact-error');

            account.handleSubscribes(e, this.$el);

            if (!$contactError.hasClass('hidden')) {
                $contactError.addClass('hidden');
            }

            var $preferencesBtn = this.$el.find(containerUpdateButtons);
            $preferencesBtn.text($preferencesBtn.attr('data-default-msg'))
                .removeClass(btnClassInactive);
        });
    }

    /**
     * Loads profile preferences from service
     */
    getProfilePreferences() {
        if (!SitePreferences.ACCOUNT_PREFERENCES_LIVECALLS_ENABLED) {
            this.initContactChannels();
            this.hideProgress();
            return;
        }
        var
            /**
             * Groups request array
             * @type {Array}
             */
            groupCodeRequestArray = [];
        $.each(this.uniqueGroupCodes, (groupIndex, groupCode) => {
            /**
             * AJAX query for current preference group code
             * @type {promise}
             */
            var newAjax = ajaxService.getJson({
                url: util.appendParamToURL(Urls.getAttributesCollection, 'groupCode', $.trim(groupCode))
            }).then((ajaxRequestJson) => {

                // Group attributes
                if (ajaxRequestJson && ajaxRequestJson.Attributes) {
                    $.each(ajaxRequestJson.Attributes,
                        /**
                         * Iterates attributes from CRM
                         * @param {number} attributeIndex
                         * @param {object} attribute
                         * @param {string} attribute.GroupCode
                         * @param {string} attribute.Code
                         * @param {number} attribute.Value
                         */
                        (attributeIndex, attribute) => {
                            if (this.fieldsMap[attribute.GroupCode] && this.fieldsMap[attribute.GroupCode][attribute.Code]) {
                                this.fieldsMap[attribute.GroupCode][attribute.Code].el.prop('checked', !!attribute.Value);
                            }
                        });
                }

                // Customer attributes
                if (groupCode === '_customer') {
                    for (var attributeName in ajaxRequestJson) {
                        if (this.fieldsMap._customer && this.fieldsMap._customer[attributeName]) {
                            this.fieldsMap._customer[attributeName].el.prop('checked', ajaxRequestJson[attributeName] === '1');
                        }
                    }
                }
            });
            groupCodeRequestArray.push(newAjax);
        });

        if (groupCodeRequestArray.length > 0) {
            $.when.apply(this, groupCodeRequestArray).always(() => {
                this.initFieldsMap();
                this.initContactChannels();
                this.hideProgress();
            });
        } else {
            this.hideProgress();
        }
    }

    /**
     * Preselect None option, if no communication options selected, or uncheck it otherwise
     */
    initContactChannels() {
        var someContactChannelSelected = this.$el
            .find(account.CONTACT_CHANNEL_FIELDS.join(', '))
            .is(':checked');
        this.$el.find('.js-input-contactnone')
            .prop('checked', !someContactChannelSelected);
    }
}

module.exports = PreferenceGroup;
