import Component from '_core_ext/components/Component';

export default class RedesignInput extends Component {
    static get selector() {
        return '.js-field-wrapper-redesign input';
    }

    get configDefault() {
        return {};
    }

    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.initCache();
        this.initEvents();

        this.checkOnValue();
        this.fixForLoginFormFieldsAutofill();
    }

    initCache() {
        this.$wrapper = this.$el.closest('.js-field-wrapper-redesign');
        this.$label = this.$wrapper.find('label');
    }

    initEvents() {
        this.event('change', this.checkOnValue.bind(this));
    }

    checkOnValue() {
        this.$label.toggleClass('active', !!this.$el.val());
    }

    fixForLoginFormFieldsAutofill() {
        if (this.$el.hasClass('js-input-username')) {
            this.event('change', (element, event) => {
                this.$el.off(event);
                if (this.$el.val()) {
                    var $form = this.$el.closest('form');
                    $form.find('.js-form-row-field-password label').addClass('active');
                }
            });
        }
    }
}

module.exports = RedesignInput;
