import eventMgr from '_core_ext/eventMgr';

/**
 * Salesfloor widget wrapper. 
 * Purpose of this is to hide widget upon certain elements such as minicart/dialog/footer shown on page. 
 */
const SalesfloorWidget = {
    init: function() {
        this.$body = $('body');
        this.$footer = $('.footer-links');

        this._initEvents();
    },
    
    _initEvents: function() {
        eventMgr.on('footer.inviewport', this._toggleWidget.bind(this));
        eventMgr.on('noScroll.enabled', this._toggleWidget.bind(this, true));
        eventMgr.on('noScroll.disabled', this._toggleWidget.bind(this, false));
        eventMgr.on('dialog.opened', this._toggleWidget.bind(this, true));
        eventMgr.on('dialog.closed', this._toggleWidget.bind(this, false));
        eventMgr.on('mainmenu.opened', this._toggleWidget.bind(this, true));
        eventMgr.on('mainmenu.closed', this._toggleWidget.bind(this, false));
        eventMgr.on('minicart.desktop.opened', this._toggleWidget.bind(this, true));
        eventMgr.on('minicart.desktop.closed', this._toggleWidget.bind(this, false));
        
        eventMgr.on('footer.expanded', (expanded) => {
            if (this.$footer.css('position') === 'fixed') {
                this._toggleWidget(expanded);
            }
        });
    },
    
    _toggleWidget: function(doAdd) { 
        this.$body.toggleClass('sf-widget-hide-all', doAdd); 
    }
}

module.exports = SalesfloorWidget;
