var regex = require('_core_ext/validator/patterns');

// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    onkeyup: false,
    onfocusout: false,
    ignore: ':hidden, .js-validator-ignore, input[class*="adyen-checkout"]',     // :hidden is default, and .js-validator-ignore is additional mark to get field ignored by validator. 
                                                                                 // input[class*="adyen-checkout"] is for Owner Name input on Adyed CC form, generated and validated by Adyen component so can't add our class to it
    errorPlacement: function (error, element) {
        var $element = $(element);
        if ($element.hasClass('js-birthday')) {
            $element.closest('fieldset').find('.js-error-place').html(error);
        } else if ($element.data('errorPlacement')) {
            // data-error-placement should contain simple config to find element which error should be placed into
            // 'parent' should be a selector of closest element's parent, must be inside form containing element!,
            // 'child' is a selector of the container inside of 'parent'
            var errorPlacement = $element.data('error-placement');
            $element.closest(errorPlacement.parent).find(errorPlacement.child).html(error);
        } else if (
            SitePreferences.PROJECT_ID === 'arnotts'
            && $element.attr('type') === 'number'
            && $element.next().hasClass('qchange')
        ) {
            error.insertAfter($element.next());
        } else {
            error.insertAfter(element);
        }
    },

    // default submit handler which just prevents double-submit of the form 
    // triggered only when all fields are valid, see https://jqueryvalidation.org/validate/#submithandler
    submitHandler: function (form, e) {
        // Is form already submitted
        var $form = $(form);
        if ($form.data('ajaxForm') === true) {
            return true;
        }

        if ($form.data('isSubmiting') === true) {
            e.preventDefault();
            return false;
        }

        $form.data('isSubmiting', true);
        form.submit();
    }
};

function extendSettings($form, deafaultSettings) {
    var formSettings = $form.data('validation-settings');
    if (formSettings && formSettings.onkeyup === 'hideError') {
        formSettings.onkeyup = function(element) {
            if ($form.validate().check($(element))) {
                $(element).valid();
            }
        };
    }
    return $.extend({}, deafaultSettings, formSettings);
}

$.extend($.validator.messages, {
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    required: Resources.VALIDATE_REQUIRED,
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

const VALIDATE_CHARS = $.validator.format(Resources.VALIDATE_CHARS);
/**
 * Generates error message for passed field using it's data-field-label.
 * @param {Object} params
 * @param {HTMLInputElement} el Element to generate error message for
 */
function invalidCharsMsg(params, el) {
    return VALIDATE_CHARS($(el).attr('data-field-label').replace('*', ''));
}

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test(value.trim());
    return isValid;
};

var validatePassword = function (value) {
    return !value || regex.password.test(value);
};

function validateElementConfirmation(value, el) {
    var confirmId = $(el).data('confirmid'),
        confirmValue = $('#' + confirmId).val();
    /*eslint eqeqeq: "off" */
    return !confirmValue || confirmValue == value;
}

function validateEmailConfirmation(value, el) {
    var confirmId = $(el).data('confirmid'),
        confirmValue = $('#' + confirmId).val();
    /*eslint eqeqeq: "off" */
    return !confirmValue || confirmValue.toLowerCase() == value.toLowerCase();
}

var validateLoyaltyNumber = function (value) {
    return regex.loyaltynumber.test(value) || !value;
};

var validateMoney = function(value) {
    return regex.money.test(value.trim());
};

var validateGroup = function(value, element) {
    var otherInputsValues = $(element).closest('fieldset').find('input, select').filter(function() {
        return !!this.value;
    }).length;
    return !value == !otherInputsValues;
};

var validateBirthday = function(value, element) {
    var $wrapper = $(element).closest('fieldset'),
        day = $wrapper.find('[id*="day"]').val(),
        month = $wrapper.find('[id*="month"]').val(),
        year = $wrapper.find('[id*="year"]').val();
    if (day && month && year) {
        var birthday = new Date(year, --month, day),
            now = new Date();
        return birthday.getDate() == day && birthday < now;
    }
    return validateGroup(value, element);

};

var validateAddress = function(value) {
    return value && value.length > 3;
};

var validateAddress2 = function(value) {
    return ((value && value.length > 3) || !value);
};

var validateEmail = function(value) {
    return !value || regex.email.test(value.trim());
};

var validateLatinChars = function(value) {
    //Fix for iOS11 smart punctuation: Replacing all of the “curly” quotes in a string with "straight" quotes
    const updatedValue = value.replace(/[\u2018\u2019\u201C\u201D]/g, (c) => '\'\'""'.substr('\u2018\u2019\u201C\u201D'.indexOf(c), 1));

    return !updatedValue || regex.latinChars.test(updatedValue.trim());
}

var validateNumbers = function(value) {
    return !value || regex.numbers.test(value.trim());
};

$.validator.addMethod('js-input-money', validateMoney, Resources.VALIDATE_MONEY);

$.validator.addMethod('js-input-postal', require('_core_ext/validator/postal'), '');

$.validator.addMethod('js-input-username', validateEmail, Resources.VALIDATE_EMAIL);

$.validator.addMethod('js-input-email', validateEmail, Resources.VALIDATE_EMAIL);

$.validator.addMethod('js-input-emailAddress', validateEmail, Resources.VALIDATE_EMAIL);

$.validator.addMethod('js-input-youremail', validateEmail, Resources.VALIDATE_EMAIL);

$.validator.addMethod('js-input-friendemail', validateEmail, Resources.VALIDATE_EMAIL);

$.validator.addMethod('js-emailconfirm', validateEmailConfirmation, Resources.VALIDATE_EQUALTO);

$.validator.addMethod('js-password-validate', validatePassword, Resources.VALIDATE_PASSWORD);

$.validator.addMethod('js-input-passwordcreate', validatePassword, Resources.VALIDATE_PASSWORD);

$.validator.addMethod('js-input-passwordconfirm', validateElementConfirmation, Resources.VALIDATE_PASSWORDNOMATCH);

$.validator.addMethod('js-input-loyaltynumber', validateLoyaltyNumber, Resources.VALIDATE_LOYALTYNUMBER);

//$.validator.addMethod('js-input-giftnumber', validateLoyaltyNumber, Resources.VALIDATE_GIFTNUMBER);

$.validator.addMethod('js-birthday', validateBirthday, Resources.VALIDATE_DATE);

$.validator.addMethod('js-group-required', validateGroup, Resources.VALIDATE_REQUIRED);

$.validator.addMethod('js-input-address1', validateAddress, Resources.VALIDATE_INVALID);

$.validator.addMethod('js-input-address2', validateAddress2, Resources.VALIDATE_INVALID);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('js-input-phone', require('_core_ext/validator/phone'), '');

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add hampers multisipping delivery method to jQuery validation plugin.
 * Text fields must have 'hampers' css class to be validated
 */
$.validator.addMethod('hampers', function (value, el) {
    return ($(el).find('option:selected').length && $(el).find('option:selected').data('county') === Resources.ALLOWED_HAMPERS_COUNTY);
}, Resources.INVALID_HAMPERS_COUNTY);

/**
 * Add hampers shipping dublin only delivery method to jQuery validation plugin.
 * Text fields must have 'dublin-only' css class to be validated
 */
$.validator.addMethod('dublin-only', function (value, el) {
    return $(el).val() === Resources.ALLOWED_HAMPERS_COUNTY;
}, Resources.INVALID_HAMPERS_COUNTY_ADDR);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if (value.trim().length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

if (SitePreferences.PROJECT_ID === 'arnotts') {
    $.validator.addMethod('js-input-ordernumber', validateNumbers, Resources.VALIDATE_NUMBER);
}

// this supposed to be applied to all free text inputs
$.validator.addMethod('latin-charset', validateLatinChars, invalidCharsMsg);

if (window.pageContext.ns !== 'checkout' && SitePreferences.PROJECT_ID === 'arnotts') {
    $.extend(settings, {
        onfocusout: function (element) {
            if (!this.checkable(element)) {
                this.element(element);
            }
        },
        focusInvalid: false,
        invalidHandler: function(event, validator) {
            if (!validator.numberOfInvalids()) {
                return;
            }

            var invalidElTop = getInvalidElTop(validator) - $('.js-top-banner-wrapper').height();
            var headerHeight = $('.js-top-banner-wrapper').height();
            if (!headerHeight) {
                var $headerWrapper = $('#header-wrapper');
                if ($headerWrapper.hasClass('is-sticky')) {
                    headerHeight = $headerWrapper.find('.js-header').height();
                } else {
                    headerHeight = $headerWrapper.find('.is-sticky > .js-header').height();
                }
            }
            invalidElTop -= headerHeight;
            var windowTop = $(window).scrollTop();

            if (windowTop > invalidElTop) {
                setTimeout(function () {
                    $('html, body').animate({
                        scrollTop: invalidElTop
                    }, 500);
                }, 0);
            }
        }
    });
}

if (window.pageContext.ns === 'checkout' ||
    window.pageContext.ns === 'orderconfirmation') {
    $.extend(settings, {
        focusInvalid: false,
        validClass: 'valid',
        errorClass: 'error invalid',
        onfocusout: function (element) {
            if (!this.checkable(element)) {
                this.element(element);
            }
        },
        errorPlacement: function (error, element) {
            var $el = $(element);
            if ($el.is(':checkbox') || $el.is('select') || $el.is(':radio')) {
                if ($el.hasClass('js-birthday')) {
                    $el.closest('fieldset').find('.js-error-place').html(error);
                } else {
                    error.insertAfter(element);
                }
            } else {
                var $wrapper = $el.closest('.js-form-row');
                if ($wrapper.length) {
                    var $error = $(error);
                    var $label = $wrapper.find('label');
                    //here must be .attr method for correct CSS behavior
                    $label.attr('data-error', $error.text());
                } else {
                    error.insertAfter(element);
                }
            }
        },
        invalidHandler: function(event, validator) {
            if (!validator.numberOfInvalids()) {
                return;
            }

            // In checkout we have validation on page and in slide-in dialog, which is fixed and has scrollable content,
            // so we have to scroll to first element with error differently,
            // as Element.scrollIntoView with {behavior: smooth} is not supported in all browsers unfortunately
            var invalidElTop,
                containerTop,
                container,
                dialogContent = $(validator.errorList[0].element).closest('.ui-dialog-content');

            if (dialogContent.length) {
                // we are in dialog, so have to scroll it's content
                invalidElTop = getDialogInvalidElTop(validator, dialogContent[0]);
                containerTop = dialogContent[0].scrollTop;
                container = dialogContent;
            } else {
                // we are on page, so have to scroll entire page
                invalidElTop = getInvalidElTop(validator);
                containerTop = document.documentElement.scrollTop;
                container = document.documentElement;
            }            

            if (containerTop > invalidElTop) {
                $(container).animate({
                    scrollTop: invalidElTop - 10
                }, 500);
            }
        }
    });
}

function getInvalidElTop (validator) {
    var $invalidEl = $(validator.errorList[0].element);
    var $invalidElWrapper = $invalidEl.parents('.js-form-row');
    var isWrapperHidden = $invalidElWrapper.closest('.visually-hidden').length;
    var $invalidFieldset = $invalidEl.closest('.js-address-container-scrollto');
    var invalidElTop;
    if (isWrapperHidden && $invalidFieldset.length) { //in case when address displayed in print mode and form element is hidden
        invalidElTop = $invalidFieldset.offset().top - parseInt($invalidElWrapper.css('margin-top'), 10);
    } else if ($invalidElWrapper.length) {
        invalidElTop = $invalidElWrapper.offset().top - parseInt($invalidElWrapper.css('margin-top'), 10);
    } else {
        invalidElTop = $invalidEl.offset().top;
    }

    return invalidElTop;
}

/**
 * Calculates element offset relative to Dialog content element which is scrollable  
 * @param validator
 * @param {Element} container Scrollable dialog content, must have position: relative.
 * @returns
 */
function getDialogInvalidElTop(validator, container) {
    var invalidElWrapper = $(validator.errorList[0].element).closest('.js-form-row')[0];
    var offset = invalidElWrapper.offsetTop;
    var parent = invalidElWrapper.offsetParent;
    while (parent !== container) {
        offset += parent.offsetTop;
        parent = parent.offsetParent;
    }
    return offset;
}

var validator = {
    regex: regex,
    settings: settings,
    init: function (config, name, $container) {
        var $element = $container || $(document);
        var self = this;
        $element.find('form:not(.suppress)').each(function () {
            var $form = $(this),
                settings = extendSettings($form, self.settings);
            $form.validate(settings);
        }).on('reset', function() {
            var $form = $(this),
                validator = $form.validate(extendSettings($form, self.settings));
            validator.elements().each(function(index, element) {
                validator.settings.unhighlight.call(validator, element);
            });
        });
    },
    initForm: function (f, extraSettings = {}) {
        var $form = $(f),
            settings = extendSettings($form, $.extend({}, this.settings, extraSettings));
        return $form.validate(settings);
    }
};

module.exports = validator;
