import Component from '_core_ext/components/Component';
import dialog from '_core_ext/dialog';


/**
 * @class AccountWarningPopup Used to display pop-up on login screen if account being locked or password has to be reset etc.
 **/
export default class AccountWarningPopup extends Component {

    /**
     * Returns elements' selector for initialize
     * @returns {string}
     */
    static get selector() {
        return '[data-account-warning]';
    }

    /**
     * Method called when creating component
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        super.init(...args);

        dialog.open({
            html: '<div class="description">' + this.$el.html() + '</div>', // message should be the same as on form
            options: {
                closeOnEscape: false,
                dialogClass: 'dialog-human popup account-locked',
                minHeight: 125,
                title: this.$el.data('title')
            }
        });
    }
}

module.exports = AccountWarningPopup;
