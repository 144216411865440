var ajax =  require('_core_ext/ajax'),
    util = require('_core_ext/util'),
    dialog = require('_core_ext/dialog');

var getAvailability = function (event, isBatch) {
    if (event && event.keyCode === 13) {
        event.preventDefault();
    }

    var isQuickView = false;
    var isDialogOpened = dialog.isOpen();
    if (isDialogOpened) {
        var $currentDialog = dialog.getCurrent();
        if ($currentDialog.length && $currentDialog.attr('id') === 'QuickViewDialog') {
            isQuickView = true;
        }
    }

    var $quantityField = $(this);
    var isPLIAvailability = $quantityField.hasClass('js-is-pli-availability');
    var $form = $quantityField.closest('form');
    var productId;
    var $container;

    if (isPLIAvailability) {
        $container = $quantityField.closest('.js-component-cart-product-line-item');
        productId = $quantityField.data('pid');
    } else {
        $container = $form.closest('#product-content-wrapper, .product-set-item, .js-component-product-tile');
        var $pidInput = $form.find('#pid, .js_pid_set, [name="pid"]');
        if ($pidInput.length) {
            productId = $pidInput.val();
        } else if ($quantityField.data('pid')) {
            productId = $quantityField.data('pid');
        } else {
            var $productWrapper = $quantityField.closest('.giftwrap-product-wrapper');
            productId = $productWrapper.data('productId');
        }
    }

    var $availabilityMsg = $container.find('.availability .availability-msg'),
        $addToCartButton = $container.find('.js-add-to-cart:not(.js-add-to-cart-disabled), .js-add-to-cart-active'),
        $addToCartButtonWrapper = $container.find('.add-to-cart-wrapper'),
        isAddToCartButtonExists = $addToCartButton.length,
        minValue = $quantityField.attr('min') ? parseInt($quantityField.attr('min'), 10) : 1,
        message;

    if ($quantityField.val() <= minValue) {
        $quantityField.val(minValue);
    }

    if (isAddToCartButtonExists && !$quantityField.valid()) {
        $addToCartButton.prop('disabled', true);
    }
    
    // if quantity set to zero, we don't want to do availability fetching, so we return mock deferred,
    // resolved immediately with specific status: 'ZERO_QTY', and triggering same event as for live call
    // This result has to be handled different explicitly by outer code!
    var qty = parseInt($quantityField.val(), 10);
    // we return ZERO_QTY only if qty was set explicitly to 0, to preserve previous behavior for other situations (e.g. empty input)
    if (qty === 0) {
        return $.Deferred().resolve({
            status: 'ZERO_QTY'
        }).then(function(result) {
            $quantityField.trigger('availability.fetched', [result, isBatch]);
            return result;
        });
    }

    return ajax.getJson({
        url: util.appendParamsToUrl(Urls.getAvailability, {
            pid: productId,
            Quantity: $quantityField.val() || 1,
            source: isPLIAvailability ? pageContext.ns : ''
        }),
        callback: function(response) {
            if (!response) {
                $availabilityMsg.html(Resources.ITEM_STATUS_NOTAVAILABLE);
                return;
            }
            if ($availabilityMsg.length) {
                if (response.levels.IN_STOCK > 0) {
                    if (!response.levels.PREORDER && !response.levels.BACKORDER && !response.levels.NOT_AVAILABLE) {
                        message = Resources.IN_STOCK;
                    } else {
                        message = response.inStockMsg;
                    }
                    $availabilityMsg.html('<p class="in-stock-msg">' + message + '</p>');
                }
                if (response.levels.PREORDER > 0) {
                    if (!response.levels.IN_STOCK && !response.levels.BACKORDER && !response.levels.NOT_AVAILABLE) {
                        message = Resources.PREORDER;
                    } else {
                        message = response.preOrderMsg;
                    }
                    $availabilityMsg.html('<p class="preorder-msg">' + message + '</p>');
                }
                if (response.levels.BACKORDER > 0) {
                    if (!response.levels.IN_STOCK && !response.levels.PREORDER && !response.levels.NOT_AVAILABLE) {
                        message = Resources.BACKORDER;
                    } else {
                        message = response.backOrderMsg;
                    }
                    $availabilityMsg.html('<p class="backorder-msg">' + message + '</p>');
                }
                if (response.inStockDate !== '') {
                    $availabilityMsg.html('<p class="in-stock-date-msg">' + String.format(Resources.IN_STOCK_DATE, response.inStockDate) + '</p>');
                }
                if (response.levels.NOT_AVAILABLE > 0) {
                    if (response.levels.PREORDER === 0 && response.levels.BACKORDER === 0 && response.levels.IN_STOCK === 0) {
                        message = Resources.NOT_AVAILABLE;
                    } else if (window.pageContext.ns === 'wishlist') {
                        message = response.quantityAvailableMsg;
                    } else {
                        message = Resources.REMAIN_NOT_AVAILABLE;
                    }
                    $availabilityMsg.html('<p class="not-available-msg">' + message + '</p>');
                }
            }
            if (response.levels.IN_STOCK > 0 && response.levels.NOT_AVAILABLE > 0) {
                $quantityField.addClass('quantity-not-available');
                var $quantityAvailableMsg = (isPLIAvailability || isQuickView) ? $container.find('.js-quantity-available') : $form.find('.js-quantity-available');

                if ($quantityAvailableMsg.length) {
                    $quantityAvailableMsg.replaceWith('<div class="js-quantity-available error-quantity">' + response.quantityAvailableMsg + '</div>');
                } else {
                    var content = '<div class="js-quantity-available error-quantity">' + response.quantityAvailableMsg + '</div>';
                    if (isPLIAvailability || window.pageContext.ns === 'wishlist' && window.SitePreferences.PROJECT_ID === 'arnotts') {
                        $quantityField.closest('.js-quantity-wrapper').append(content);
                    } else {
                        $addToCartButtonWrapper.before(content);
                    }
                }
                $addToCartButton.prop('disabled', true);
            } else if (response.status === 'NOT_AVAILABLE') {
                $addToCartButton.prop('disabled', true);
            } else {
                $quantityField.removeClass('quantity-not-available');
                if (isPLIAvailability || isQuickView) {
                    $container.find('.js-quantity-available').remove();
                } else {
                    $form.find('.js-quantity-available').remove();
                }
                $addToCartButton.prop('disabled', !$quantityField.valid());
            }
            
            // trigger to add custom handling depending on context outside of this component
            $quantityField.trigger('availability.fetched', [response, isBatch]);
        }
    });
};

var getAvailabilityRaw = function (productId, qty) {
    return ajax.getJson({
        url: util.appendParamsToUrl(Urls.getAvailability, {
            pid: productId,
            Quantity: qty || 1
        })
    }).then((response) => {
        if (!response){
            return {
                isAvailable: false
            };
        } else if (response.levels.NOT_AVAILABLE > 0 || response.status === 'NOT_AVAILABLE') {
            response.isAvailable = false;
        } else {
            response.isAvailable = true;
        }
        return response;
    });
}

var changeQuantity = function(e) {
    e.preventDefault();
    var $this = $(this), $quantity;
    
    var $productSetQtyInput = $this.closest('.quantity').find('input.js_quantyty-ps');
    if ($productSetQtyInput.length) {
        $quantity = $productSetQtyInput;
    } else {
        $quantity = $this.siblings('.js-quantity, input[name="Quantity"]');
    }
    if ($quantity.is(':disabled')) {
        return;
    }
    var minValue = $quantity.attr('min') ? parseInt($quantity.attr('min'), 10) : 1;
    var quantity = parseInt($quantity.val(), 10);

    if ($this.hasClass('plus')) {
        quantity++;
    } else if ($this.hasClass('minus')) {
        quantity--;
    }
    if (quantity >= minValue) {
        $quantity.val(quantity);
    }
    $quantity.trigger('change').valid();
}

module.exports = {
    init: function (config, name, $container) {
        $container = $container || $(document);
        if (!$container.data('is-availability-component-attached')) {
            $container.on('keydown', '.js-quantity, input[name="Quantity"]', util.preventEnterKey);
            $container.on('change', '.js-quantity, input[name="Quantity"]', getAvailability);
            $container.on('click', '.qchange', changeQuantity);
            $container.data('is-availability-component-attached', true);
        }
    },
    getAvailability: getAvailability,
    getAvailabilityRaw: getAvailabilityRaw
}
