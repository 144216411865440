import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';
import util from '_core_ext/util';

/**
 * @description Handles product tile image (flip on hover and outfit view)
 * As there's specific picture tags used over images for PLP product images (on BT),
 * this component handles supporting expected behavior for picture tags and also when single <img/> tag used
 * @export PictureTag
 * @class PictureTag
 * @extends {Component}
 */
export default class PictureTag extends Component {
    static get selector() {
        return '.js-product-tile-image-container[data-second-image]';
    }

    initState() {
        this.state = {
            isOutfitView: false
        };
    }

    /**
     * Replaces srcset attributes for nested picture tag sources (on BT only atm).
     * @param {String} src
     * @param {String} srcAttribute Src attribute to update - srcset or data-srcset for not yet lazyloaded sources
     */
    _updateSources(src, attributeName) {
        attributeName = attributeName || 'srcset';
        if (this.$el) {
            this.$el.find('source').each((i, sourceTag) => {
                const $source = $(sourceTag);
                const prevSourceSet = $source.attr(attributeName);
                // We only need to replace url, leaving parameters of previous srcset values. Note that split function always returns array, so we don't need to check if there is '?' character
                $source.attr(attributeName, prevSourceSet.replace(prevSourceSet.split('?')[0], src.split('?')[0]));
            });
        }
    }

    /**
     * Switch main image src and secondary one.
     */
    _flipImageSrc() {
        const $image = this.$el.find('img.js-product-image-img');
        // if image not lazyloaded yet, we update data-* attributes
        const SRC_ATTR_PREFIX = $image.hasClass('lazyload') ? 'data-' : '';
        
        const firstImageSource = $image.attr(`${SRC_ATTR_PREFIX}src`);
        const secondImagesrc = this.$el.data('second-image');

        if (secondImagesrc) {
            this.$el.data('second-image', firstImageSource);

            this._updateSources(secondImagesrc, `${SRC_ATTR_PREFIX}srcset`);
            $image.attr(`${SRC_ATTR_PREFIX}src`, secondImagesrc);
        }
    }

    initEvents() {
        if (!util.isTouch()) {
            this.$el.on('mouseenter', () => {
                // flip src
                var $image = this.$el.find('img.js-product-image-img'),
                    SRC_ATTR_PREFIX = $image.hasClass('lazyload') ? 'data-' : '',   // for not yet lazyloaded image we should refer to data-* attributes
                    firstImageSource = $image.attr(`${SRC_ATTR_PREFIX}src`),
                    secondImageSource = this.$el.data('second-image');
                this.$el.data('first-image', firstImageSource);
                
                // on sources first
                this._updateSources(secondImageSource, `${SRC_ATTR_PREFIX}srcset`);
                // and on img tag
                $image.attr(`${SRC_ATTR_PREFIX}src`, secondImageSource);
            }).on('mouseleave', () => {
                var $image = this.$el.find('img.js-product-image-img'),
                    SRC_ATTR_PREFIX = $image.hasClass('lazyload') ? 'data-' : '',   // for not yet lazyloaded image we should refer to data-* attributes
                    firstImageSource = this.$el.data('first-image');

                this._updateSources(firstImageSource, `${SRC_ATTR_PREFIX}srcset`);
                $image.attr(`${SRC_ATTR_PREFIX}src`, firstImageSource);
            });
        }

        /**
         * Flip images upon "Outfit view" link
         */
        eventMgr.on('OutfitImage.change', (isOutfitView) => {
            if (isOutfitView && this.state.isOutfitView) {
                return false;
            }
            
            this.state.isOutfitView = isOutfitView;
            this._flipImageSrc();
        });
    }

    init(...args) {
        super.init(...args);
        this.initState();
        this.initEvents();

        return true;
    }
}

module.exports = PictureTag;
