/* globals pageContext */

/** The references object of all modules needed for components initialization */
var references = {
    /** Page module references */
    'storelocator'      : require('_core/pages/storelocator'),

    /** Custom Page modules from extension cartridge */
    'account'           : require('_core_ext/pages/account'),
    'brandindex'        : require('_core_ext/pages/brands'),
    'cart'              : require('_core_ext/pages/cart'),
    'checkout'          : require('_core_ext/pages/checkout'),
    'clp'               : require('_core_ext/pages/clp'),
    'compare'           : require('_core_ext/pages/compare'),
    'contentpage'       : require('_core_ext/pages/contentpage'),
    'orderconfirmation' : require('_core_ext/pages/checkout/orderconfirmation'),
    'product'           : require('_core_ext/pages/product'),
    'search'            : require('_core_ext/pages/search'),
    'storefront'        : require('_core_ext/pages/storefront'),
    'wishlist'          : require('_core_ext/pages/wishlist'),

    /** Components from extension cartridge */
    'AddressUpdateLinks'    : require('_core_ext/components/address/AddressUpdateLinks'),
    'searchplaceholder'     : require('_core_ext/searchplaceholder'),
    'BillingAddress'        : require('_core_ext/components/address/BillingAddress'),
    'DialogAddress'         : require('_core_ext/components/address/DialogAddress'),
    'CustomerAddress'       : require('_core_ext/components/address/CustomerAddress'),
    'GTM'                   : require('_core_ext/components/global/analytics/GTM'),
    'PriceRange'            : require('_core_ext/components/search/SearchRefinements/PriceRange'),
    'RefinementsRibbon'     : require('_core_ext/components/search/SearchRefinements/RefinementsRibbon'),
    'ProductTile'           : require('_core_ext/components/product/ProductTile'),
    'PictureTag'            : require('_core_ext/components/product/PictureTag'),
    'DeliveryInfo'          : require('_core_ext/components/product/DeliveryInfo'),
    'OutfitView'            : require('_core_ext/components/search/OutfitView'),
    'ProductImageCarousel'  : require('_core_ext/components/product/ProductImageCarousel'),
    'ProductCarousel'       : require('_core_ext/components/global/ProductCarousel'),
    'ProductLineItem'       : require('_core_ext/components/cart/ProductLineItem'),
    'QuickViewGlobal'       : require('_core_ext/components/global/QuickViewGlobal'),
    'RedesignInput'         : require('_core_ext/components/checkout/RedesignInput'),
    'TimeslotSelector'      : require('_core_ext/components/checkout/TimeslotSelector'),
    'TimeslotExpire'        : require('_core_ext/components/checkout/TimeslotExpire'),
    'TimeslotSlick'         : require('_core_ext/components/checkout/TimeslotSlick'),
    'SearchRefinements'     : require('_core_ext/components/search/SearchRefinements'),
    'ShippingAddress'       : require('_core_ext/components/address/ShippingAddress'),
    'availability'          : require('_core_ext/components/product/availability'),
    'commonElements'        : require('_core_ext/common-elements'),
    'carousel'              : require('_core_ext/components/global/carousel'),
    'dialogify'             : require('_core_ext/components/global/dialogify'),
    'ecomBridge'            : require('_core_ext/components/global/ecom-bridge'),
    'formsCustomization'    : require('_core_ext/components/global/forms/customization'),
    'PreferenceGroup'       : require('_core_ext/components/account/PreferenceGroup'),
    'input'                 : require('_core_ext/components/global/forms/input'),
    'instagram'             : require('_core_ext/components/social/instagram'),
    'isotope'               : require('_core_ext/components/global/isotope'),
    'jqueryFallback'        : require('_core_ext/components/global/jqueryfallback'),
    'layout'                : require('_core_ext/components/global/layout'),
    'login'                 : require('_core_ext/components/global/login'),
    'marker'                : require('_core_ext/components/map/marker'),
    'minicart'              : require('_core_ext/components/global/minicart'),
    'password'              : require('_core_ext/components/global/password'),
    'scrollTo'              : require('_core_ext/components/scrollto'),
    'session'               : require('_core_ext/components/global/session'),
    'sidebarScroll'         : require('_core_ext/components/global/sidebarscroll'),
    'slick'                 : require('_core_ext/components/global/slick'),
    'slickSliderModule'     : require('_core_ext/components/global/slick-slider-module'),
    'sticky'                : require('_core_ext/components/global/sticky'),
    'stickyKit'             : require('_core_ext/components/global/sticky-kit'),
    'stickyBottom'          : require('_core_ext/components/global/stickyBottom'),
    'tls'                   : require('_core_ext/components/global/tls'),
    'tooltip'               : require('_core_ext/components/global/tooltip'),
    'validator'             : require('_core_ext/components/global/validator'),
    'videojs'               : require('_core_ext/components/global/videojs'),
    'productvideojs'        : require('_core_ext/components/global/ProductVideo'),
    'scrolltotop'           : require('_core_ext/components/search/scrolltotop'),
    'LoyaltyCardSelection'  : require('_core_ext/components/account/LoyaltyCardSelection'),
    'PrintableMessage'      : require('_core_ext/components/global/forms/PrintableMessage'),
    'AccountWarningPopup'   : require('_core_ext/components/account/AccountWarningPopup'),
    'btPlus'                : require('_core_ext/components/checkout/btPlus'),
    'PredictiveRecommendations' : require('_core_ext/components/product/PredictiveRecommendations'),
    'Salesfloor'            : require('_core_ext/components/global/analytics/Salesfloor'),
    'SalesfloorWidget'      : require('_core_ext/components/global/SalesfloorWidget'),
    'countrySelector'       : require('_core_ext/components/global/countrySelector'),
    'Smartzer'              : require('_core_ext/components/global/Smartzer'),
    'EnergyRating'          : require('_core_ext/components/product/EnergyRating'),
    'virtualTryOn'          : require('_core_ext/components/product/virtualTryOn'),
    'PersonalizeSupersocks' : require('_core_ext/components/product/PersonalizeSupersocks'),
    'Expandable'            : require('_core_ext/components/global/Expandable'),
    'Filterable'            : require('_core_ext/components/global/Filterable'),
    'Removable'             : require('_core_ext/components/global/Removable'),
    'pdpImage'              : require('_core_ext/components/product/pdpImage'),
    'ProductSlickCarousel'  : require('_core_ext/components/global/ProductSlickCarousel'),
    'GlobalSlot'            : require('_core_ext/components/global/GlobalSlot'),
    'Timer'                 : require('_core_ext/components/global/Timer'),
    'BeautyIngredientsGlossary' : require('_core_ext/components/search/BeautyIngredientsGlossary'),
    'AddToWishlist'         : require('_core_ext/components/product/AddToWishlist'),
    'ProductHotspot'         : require('_core_ext/components/product/ProductHotspot'),
    'AlgonomyTracker'       : require('_core_ext/components/global/AlgonomyTracker'),
    'ProductPromotions'     : require('_core_ext/components/product/ProductPromotions'),
    'ColorPicker'           : require('_core_ext/components/product/ColorPicker'),
    'CouponCode'            : require('_core_ext/components/checkout/CouponCode'),
    'GiftCardsBlock'        : require('_core_ext/components/checkout/GiftCardsBlock'),
    'LoyaltyBlock'          : require('_core_ext/components/checkout/LoyaltyBlock'),
    'GiftWrapOptions'       : require('_core_ext/components/checkout/GiftWrapOptions'),

    /** 3rd-party integration modules */
    'qubit'                 : require('./../../../int_qubit/cartridge/js/qubit')
};

/**
 * The components initialization configuration object
 *
 * @example New "Page" configuration
 *  var configuration = {
 *      //...
 *      'newpage' : {
 *          'enabled' : true,
 *          'options' : {},
 *          'components' : {
 *              'pagination' : {
 *                  'enabled' : false,
 *                  'options' : {}
 *              }
 *          }
 *      }
 *      //...
 *  }
 */
var configuration = {
    'global' : {
        'components': {
            'carousel' : {
                'enabled' : ('jcarousel' in $.fn)
            },
            'ProductCarousel' : {
                'enabled' : ('jcarousel' in $.fn)
            },
            'ProductSlickCarousel' : {
                'enabled' : ('slick' in $.fn)
            },
            'PredictiveRecommendations' : {},
            'commonElements' : {},
            'dialogify' : {},
            'ecomBridge' : {},
            'formsCustomization' : {},
            'jqueryFallback' : {},
            'input' : {},
            'GTM' : {
                'enabled' : SitePreferences.GTM_ENABLED
            },
            'layout' : {},
            'minicart' : {},
            'searchplaceholder' : {
                'enabled' : ('placeholder' in document.createElement('input'))
            },
            'password' : {},
            'productvideojs' : {},
            'scrollTo' : {},
            'sidebarScroll' : {},
            'slick' : {
                'enabled' : ('slick' in $.fn)
            },
            'slickSliderModule' : {},
            'sticky' : {
                'enabled' : ('sticky' in $.fn)
            },
            'stickyKit' : {
                'enabled' : ('stick_in_parent' in $.fn)
            },
            'tls' : {
                'enabled' : ('placeholder' in document.createElement('input'))
            },
            'tooltip' : {},
            'videojs' : {},
            'validator' : {},
            'qubit': {
                enabled: SitePreferences.QUBIT_ENABLE
            },
            'countrySelector': {
                'enabled': !!SitePreferences.COUNTRY_SELECTOR
            },
            'Smartzer': {},
            'SalesfloorWidget' : {
                'enabled' : SitePreferences.SALESFLOOR_ENABLED
            },
            'Expandable': {},
            'Filterable': {},
            'Removable': {},
            'GlobalSlot': {},
            'Timer': {},
            'ProductImageCarousel' : {
                'enabled' : ('jcarousel' in $.fn)
            },
            'pdpImage': {},
            'AddToWishlist': {},
            'AlgonomyTracker': {
                options: {
                    pageType: pageContext && pageContext.type
                }
            },
            'ProductPromotions': {},
            'ColorPicker': {}
        }
    },
    'account' : {
        'components': {
            'CustomerAddress' : {},
            'PreferenceGroup' : {
                'enabled': pageContext && pageContext.customPageType === 'contactpreferences'
            },
            'LoyaltyCardSelection' : {},
            'AccountWarningPopup' : {},
            'login': {},
            'isotope' : {}
        }
    },
    'brandindex' : {},
    'cart' : {
        'components' : {
            'ProductTile' : {},
            'EnergyRating': {},
            'ProductLineItem' : {},
            'QuickViewGlobal' : {},
            'availability' : {},
            'PrintableMessage': {},
            'carousel' : {},
            'sidebarScroll' : {},
            'RedesignInput': {},
            'CouponCode': {}
        }
    },
    'checkout' : {
        'components': {
            'CustomerAddress' : {
                'enabled' : pageContext && pageContext.checkoutPage === 'registration'
            },
            'LoyaltyCardSelection' : {
                'enabled' : pageContext && pageContext.checkoutPage === 'registration'
            },
            'btPlus': {},
            'AddressUpdateLinks': {},
            'BillingAddress': {},
            'ProductTile' : {},
            'EnergyRating': {},
            'ShippingAddress': {
                'enabled' : pageContext &&
                    (
                        pageContext.checkoutPage  === 'singleshipping' ||
                        pageContext.checkoutPage  === 'multipleaddresses' ||
                        pageContext.checkoutPage  === 'clickandcollect'
                    )
            },
            'DialogAddress': {},
            'stickyBottom': {},
            'RedesignInput': {},
            'TimeslotSelector': {},
            'TimeslotSlick': {
                'enabled' : ('slick' in $.fn)
            },
            'TimeslotExpire': {},
            'availability': {},
            'login': {},
            'session': {},
            'PrintableMessage': {},
            'AccountWarningPopup' : {},
            'CouponCode': {},
            'GiftCardsBlock': {
                'enabled': pageContext && pageContext.checkoutPage === 'payment'
            },
            'LoyaltyBlock': {
                'enabled': pageContext && pageContext.checkoutPage === 'payment'
            },
            'GiftWrapOptions': {}
        }
    },
    'clp' : {
        'components': {
            'PictureTag' : {},
            'ProductTile' : {},
            'EnergyRating': {},
            'availability' : {},
            'PrintableMessage': {},
            'carousel' : {},
            'videojs' : {}
        }
    },
    'compare' : {
        'components' : {
            'ProductTile' : {},
            'EnergyRating': {},
            'availability' : {},
            'PrintableMessage': {}
        }
    },
    'contentpage' : {
        'components' : {
            'instagram' : {
                'enabled' : SitePreferences.INSTAGRAM_ENABLED
            },
            'isotope' : {},
            'marker' : {}
        }
    },
    'error': {
        'components': {
            'ProductTile' : {}
        }
    },
    'product' : {
        'components' : {
            'ProductTile' : {},
            'PictureTag': {},
            'EnergyRating': {},
            'availability' : {},
            'PrintableMessage': {},
            'DeliveryInfo': {},
            'virtualTryOn': {},
            'PersonalizeSupersocks': {},
            'productvideojs': {},
            'ProductHotspot': {},
            'ColorPicker': {}
        }
    },
    'orderconfirmation' : {
        'components' : {
            'LoyaltyCardSelection' : {},
            'Salesfloor' : {
                'enabled' : SitePreferences.SALESFLOOR_ENABLED
            },
            'RedesignInput': {}
        }
    },
    'search' : {
        'components': {
            'PictureTag': {},
            'OutfitView': {},
            'PriceRange' : {},
            'ProductTile' : {},
            'EnergyRating': {},
            'SearchRefinements' : {},
            'availability' : {},
            'PrintableMessage': {},
            'isotope' : {},
            'scrolltotop' : {},
            'RefinementsRibbon': {},
            'videojs': {}
        }
    },
    'storefront' : {
        'components' : {
            'ProductTile' : {},
            'PictureTag': {},
            'EnergyRating': {},
            'availability' : {},
            'PrintableMessage': {},
            'isotope' : {},
            'BeautyIngredientsGlossary': {}
        }
    },
    'wishlist' : {
        'components' : {
            'ProductTile' : {},
            'PictureTag': {},
            'EnergyRating': {},
            'availability' : {},
            'PrintableMessage': {},
            'login': {}
        }
    },
    'storelocator' : {
        'components' : {
            'marker' : {}
        }
    }
};

module.exports = {
    'configuration' : configuration,
    'references' : references
};
