import Component from '_core_ext/components/Component';
import 'slick-carousel';
import 'jquery-touchswipe';

export default class Slick extends Component {
    static get selector() {
        return '.js-component-slick';
    }
    get configDefault() {
        return {
            'dots': false,
            'infinite': true,
            'autoplay': true,
            'autoplaySpeed': 7000,
            'pauseOnHover': true,
            'speed': 1000,
            'fade': true,
            'cssEase': 'ease-in-out',
            'prevArrow': '<button type="button" class="slick-prev"></button>',
            'nextArrow': '<button type="button" class="slick-next"></button>'
        };
    }
    get configDataAttrName() {
        return 'component-slick';
    }
    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        this.$window = $(window);
        //fallback, for direct initialization
        if (!('slick' in $.fn)) {
            return false;
        }
        //call parent init method with same args
        super.init(...args);
        
        this.unslickWidth = parseInt(this.$el.data('unslickWidth'), 10);
        
        if (isNaN(this.unslickWidth)) {
            this.$el.slick(this.config);
        } else {
            this.unSlickHandler()
            this.$window.on('resize', this.unSlickHandler.bind(this));
        }

        // Hack for safari and android browser to prevent page vertical scroll on carousel swiping
        this.$el.on('touchstart', () => { this.swiping = true; });
        this.$el.on('touchend', () => { this.swiping = false; });
        window.addEventListener('touchstart', (e) => this.touchStart(e));
        window.addEventListener('touchmove', (e) => this.preventTouch(e), {passive: false});
    }

    unSlickHandler () {
        if ((this.unslickWidth > 0 && window.innerWidth > this.unslickWidth) ||
                (this.unslickWidth < 0 && -window.innerWidth > this.unslickWidth)) {
            if (this.$el.hasClass('slick-initialized')) {
                this.$el.slick('unslick');
            }
            return;
        }
        if (!this.$el.hasClass('slick-initialized')) {
            this.$el.slick(this.config);
        }
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        if (this.swiping === true) {
            const minValue = 5; // threshold

            this.clientX = e.touches[0].clientX - this.firstClientX;
            this.clientY = e.touches[0].clientY - this.firstClientY;

            // Vertical scrolling does not work when you start swiping horizontally.
            if (Math.abs(this.clientX) > minValue) {
                e.preventDefault();
                e.returnValue = false;
                return false;
            }
        }
    }
}

module.exports = Slick;
