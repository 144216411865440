'use strict';

var orderconfirmation = require('_core_ext/pages/checkout/orderconfirmation');

var registration = {
    init: function () {
        orderconfirmation.handleContactPrefs();
    }
};

module.exports = registration;
