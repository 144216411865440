/*eslint no-use-before-define: "off"*/

var dialog = require('_core_ext/dialog'),
    productStoreInventory = require('_core_ext/storeinventory/product'),
    tooltip = require('_core_ext/components/global/tooltip'),
    validator = require('_core_ext/components/global/validator'),
    util = require('_core_ext/util'),
    addToCart = require('_core_ext/pages/product/addToCart'),
    image = require('_core_ext/pages/product/image'),
    productNav = require('_core_ext/pages/product/productNav'),
    productSet = require('_core_ext/pages/product/productSet'),
    productSetSkus = require('_core_ext/pages/product/productSetSkus'),
    productBundle = require('_core_ext/pages/product/productBundle'),
    btPlus = require('_core_ext/pages/product/btPlus'),
    recommendations = require('_core_ext/pages/product/recommendations'),
    variant = require('_core_ext/pages/product/variant'),
    _debounce = require('lodash/function/debounce'),
    cookies = require('_core_ext/utils/cookies'),
    APOnClick = require('_cartridge/int_applepay/applepay.onclick');

const eventMgr = require('_core_ext/eventMgr');
const productEmitter = eventMgr.getEmitter('productPage');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
    recommendations();
    tooltip.init();
    flixmediaContent();
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents($container) {
    $container = $container || $(document);
    var $pdpMain = $container.find('.js-pdp-main');
    var $document = $(document);

    addToCart.init($container);
    variant($container);
    image($container);
    btPlus($pdpMain);
    updateRecentlyViewedProducts($pdpMain);

    $pdpMain.find('#yotpo-bottomline-top-div').clone().appendTo($pdpMain.find('.reviews-mini-copy'));

    $pdpMain.on('click', '.main-image', function (e) {
        e.preventDefault();
    });

    if ($pdpMain.hasClass('js-is-product-set')) {
        productSet($container);
    } else if ($pdpMain.hasClass('js-is-product-sku-set')) {
        productSetSkus($container);
    } else if ($pdpMain.hasClass('js-is-product-bundle')) {
        productBundle();
    }

    productStoreInventory.init($container);

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a' , function (e) {
        e.preventDefault();
    });

    $pdpMain.on('click', '.sizeselector-link, .js-size-chart-link a', function (e) {
        e.preventDefault();

        var isDialogOpened = dialog.isOpen(),
            $el = $(e.target),
            $variationSelect = $el.parent('.sizeselector-link').siblings('.variation-select'),
            currentDialog;
        if (isDialogOpened) {
            currentDialog = dialog.getCurrent();

            // workaround to avoid opening size variation dropdown on QV after size chart popup closed (on iPad)
            if (util.isTablet()) {
                $variationSelect.hide();
            }
        }

        dialog.open({
            url: $el.attr('href'),
            options: {
                dialogClass: 'sizeselector-dialog side_dialog',
                close: function () {
                    dialog.close();

                    if (isDialogOpened) {
                        dialog.restore(currentDialog);

                        // workaround to avoid opening size variation dropdown on QV after size chart popup closed (on iPad)
                        if (util.isTablet()) {
                            $variationSelect.show();
                        }
                    }
                }
            },
            callback : function () {
                var $dialogContainer = $('#dialog-container');
                var content = $dialogContainer.html();
                $dialogContainer.html('<div class="size-guide-wrapper ">' + content + '</div>');
            }
        });
    });

    $pdpMain.on('click', '.js-product-actions-notify-me', function (e) {
        e.preventDefault();

        const options = {
            url: $(this).attr('href'),
            options: {
                title: Resources.TITLE_WHEN_BACK_IN_STOCK,
                dialogClass: 'notify-me-dialog'
            },
            callback: function () {
                var $dialogContainer = $('#dialog-container');
                var notifyMeForm = $dialogContainer.find('form.js-notifyme_form');
                notifyMeForm.on('submit', function(e) {
                    e.preventDefault();
                    validator.init();
                    if (notifyMeForm.valid()) {
                        dialog.submit();
                        $dialogContainer.addClass('notifyme-submitted');
                    }
                    return false;
                });
            }
        };

        if (dialog.isOpen()) {
            const prevDialog = dialog.getCurrent();
            options.close = function () {
                dialog.restore(prevDialog);
            }
        }

        dialog.open(options);
    });


    // product options
    $pdpMain.on('change', '.js-select-gift-price', function() {
        var giftInput = $pdpMain.find('.js-input-giftprice');
        giftInput.val($(this).val());
        giftInput.trigger('change');
    }).on('keypress', '.js-input-giftprice', function() {
        $pdpMain.find('.js-select-gift-price').filter(':checked').prop('checked',false);
    });

    $pdpMain.on('change', '.js-input-giftprice', function(e) {
        var addToCart = $pdpMain.find('.js-add-to-cart:not(.js-disabled-permanently)');
        var $this = $(this);

        //fix for autofill-event polyfill (it triggered change event which affected validation)
        if (e.cancelable && '$$currentValue' in this) {
            return;
        }

        if ($this.valid()) {
            addToCart.removeAttr('disabled');
            addToCart.removeClass('button-fancy-large add-to-cart-disabled');
            addToCart.addClass('coloured large full-width');
            $this.closest('form').removeAttr('novalidate');
        } else {
            addToCart.attr('disabled', 'disabled');
            addToCart.removeClass('coloured large full-width');
            addToCart.addClass('button-fancy-large add-to-cart-disabled');
        }
    });

    // adjust sticky imagery position on CSS tabs open-close
    // there are several properties animated at same time and event will be triggered for each one, so we debounce it to handle once
    $pdpMain.on('transitionend', '.js-tab-content', _debounce(
        () => app.components.reInitComponent('stickyKit', $pdpMain),
        100));

    eventMgr.on('addToCart.product.added', (form) => {
        // clear selected prices and typed messages for next possible gift card
        const $form = $(form);
        if ($form.find('.product-add-giftcard').length) {
            $form[0].reset();
        }
    });

    $document
        .on('reset', 'form', function() {
            var $form = $(this);
            var $buttons = $form.find(':button');
            for (var  i = 0, length = $buttons.length; length > i; i++) {
                var $button = $($buttons[i]);
                if ($button.hasClass('js-add-to-cart')) {
                    var state = $button.data('is-disabled-by-default');
                    $button.toggleClass('add-to-cart-disabled', state);
                }
            }
        });
    var $dialog = dialog.getCurrent();
    if ($dialog && $dialog.length) {
        app.components.initComponent('validator', $dialog);
    }
    
    // Buy with ApplePay for regular products
    APOnClick('js-applepay-pdp', function() {
        if (!$(this).hasClass('js-orderable')) {
            productEmitter.emit('notselected');

            productEmitter.emit('BuyWithApplePay.blocked', this);
            return false;
        }
        productEmitter.emit('BuyWithApplePay', this);
        return true;
    });

    // Buy with ApplePay for Gift card
    APOnClick('js-applepay-giftcard', function() {
        var gcPrice = $('.js-input-giftprice');
        if (!gcPrice.valid()) {
            productEmitter.emit('BuyWithApplePay.blocked', this);
            return false;
        }
        // we can't pass entered price and message to AP hook directly, so pass them through cookies
        var gcData = {
            price: parseFloat(gcPrice.val()),
            message: $('.js-input-giftmessage').val()
        };
        cookies.setCookie('ap_giftcard_data', JSON.stringify(gcData), 5);
        productEmitter.emit('BuyWithApplePay', this);
        return true;
    });

    eventMgr.on('dialog.opened', () => {
        if ($('.beauty_ingredients-dialog').length) {
            app.components.initComponent('Expandable');
        }
    });

    eventMgr.on('dialog.closed', () => {
        $('.beauty_ingredients-dialog').removeClass('expanded');
    });

    app.components.initComponent('Expandable', $pdpMain);
    app.components.initComponent('dialogify', $pdpMain);
    app.components.initComponent('AddToWishlist', $pdpMain);
    app.components.initComponent('ProductPromotions', $pdpMain);

    $pdpMain.on('click', '.js-check-stock-in-store', function (e) {
        e.preventDefault();
        if ($pdpMain.find('.js-add-to-cart-active').data('is-orderable')) {
            const checkStockInStoreDialogOptions = {
                dialogClass: 'side_dialog',
                title: Resources.CHECK_STOCK_IN_STORE
            }
            if (dialog.isOpen()) {
                const prevDialog = dialog.getCurrent();
                checkStockInStoreDialogOptions.close = function () {
                    dialog.restore(prevDialog);
                }
            }

            dialog.open({
                url: $(e.currentTarget).attr('href'),
                options: checkStockInStoreDialogOptions,
                callback: function() {
                    app.components.initComponent('Expandable');
                    app.components.initComponent('Filterable');
                }
            });
        } else {
            productEmitter.emit('notselected');
        }
    });
}

/**
 * Handle Flixmedia content loading
 */
function flixmediaContent() {
    if (!SitePreferences.FLIXMEDIA_ENABLED) {
        return;
    }

    const fmScript = $('script[data-flix-inpage="flix-inpage"]');
    if (!fmScript.length) {
        return;
    }

    // catch the moment when flixmedia script actually loaded as it's async
    fmScript.on('load', () => {
        if (!window.flixJsCallbacks) {
            return;
        }
        // attach inpage content loaded callback
        window.flixJsCallbacks.setLoadCallback(() => {
            // show header only when and if content actually loaded
            $('#flix-inpage-header').removeClass('hidden');
        }, 'inpage');
    });

    // start loading script
    fmScript.attr('src', fmScript.attr('data-src'));
    fmScript.removeAttr('data-src');
}

function updateRecentlyViewedProducts($pdpMain) {
    const cookieName = 'recently_viewed_products';
    const recentlyViewedProducts = JSON.parse(cookies.getCookie(cookieName)) || [];
    const masterProductID = $pdpMain.attr('data-master-product-id');    // use .attr() to prevent implicit converion to number
    const data = {
        id: masterProductID,
        timeStamp: Date.now(),
    };
    let isProductInRecentlyViewed = false;

    const updatedRecentlyViewedProducts = recentlyViewedProducts.map((product) => {
        if (product.id.toString() === masterProductID) {    // call .toString() for those that were stored as numbers before fix
            isProductInRecentlyViewed = true;
            return data;
        }

        return product;
    });

    if (!isProductInRecentlyViewed && masterProductID) {
        const maxNumberProducts = 5;

        if (updatedRecentlyViewedProducts.length === maxNumberProducts) {
            updatedRecentlyViewedProducts.pop();
        }
        updatedRecentlyViewedProducts.unshift(data);
    }

    //setting cookie 'recently_viewed_products' for 1 year (525600 min)
    cookies.setCookie(cookieName, JSON.stringify(updatedRecentlyViewedProducts), 525600);
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
        app.components.reInitComponent('ProductCarousel');
        app.components.reInitComponent('ProductImageCarousel');
    }
};

module.exports = product;
