var dialog = require('_core_ext/dialog'),
    util = require('_core_ext/util'),
    eventMgr = require('_core_ext/eventMgr');

var isTablet = util.isTablet();

var $cache = {};

const emitter = eventMgr.getEmitter('productImage');

/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
var setMainImage = function (atts, event) {
    var $mainVideo = $cache.pdpMain.find('.js-product-video');
    var $primaryImage = $cache.pdpMain.find('.primary-image');
    var $zoomImg = $cache.pdpMain.find('.js-primary-picture');
    if (event) {
        var $pdpMain = $(event.target).parents('.js-pdp-main');
        $primaryImage = $pdpMain.find('.primary-image');
        $mainVideo = $pdpMain.find('.js-product-video');
        $zoomImg = $pdpMain.find('.js-primary-picture');
    }
    var $mainImage = $primaryImage.closest('.main-image');
    
    if (atts.isVideo) {
        $mainImage.hide();
        emitter.emit('videoShown', atts, $mainVideo);
    } else {
        var xlargeImg = isTablet && atts.xlarge && (JSON.stringify(atts.xlarge) !== JSON.stringify({})) ? atts.xlarge : atts.url;
        $primaryImage.attr({
            src: xlargeImg,
            alt: atts.alt,
            title: atts.title
        });

        $zoomImg.find('source').attr('srcset', xlargeImg);
        $zoomImg.find('img').attr('src', xlargeImg);

        $mainImage.attr('href', atts.hires !== '' ? atts.hires : atts.url);
        emitter.emit('imageShown');
        $mainVideo.hide();
        $mainImage.show();
    }
};


var setMainImageLarge = function (atts) {
    var $dialogContainer = dialog.getCurrent();
    var $primaryImageLarge = $dialogContainer.find('.js_product-primary-image-large');
    var $mainVideo = $dialogContainer.find('.js-product-video');

    if (atts.isVideo) {
        $primaryImageLarge.hide();
        emitter.emit('videoShown', atts, $mainVideo);
    } else {
        emitter.emit('imageShown');
        $primaryImageLarge.show();
        $mainVideo.hide();
        $primaryImageLarge.find('.primary-image').show();
        $primaryImageLarge.attr({
            src: atts.hires !== '' ? atts.hires : atts.url,
            alt: atts.alt,
            title: atts.title
        });
    }
};

/**
* @description Initialize Amplience Dynamic Images.
*/
var initAmpDI = function () {
    var amp = window.amp = window.amp || {};
    if ('dwInit' in amp && typeof amp.dwInit === 'function') {
        amp.dwInit.call(amp); // eslint-disable-line no-useless-call
    }
};

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
var replaceImages = function () {
    var $newImages = $cache.pdpMain.find('#update-images');
    var $imageContainer = $cache.pdpMain.find('.product-image-container');

    if ($newImages.length === 0) {
        return;
    }
    $imageContainer.html($newImages.html());

    $newImages.remove();
    initAmpDI();
    app.components.initComponent('ProductImageCarousel', $imageContainer);
};

var showImageZoomDialog = function(event) {
    var pdpMain = $cache.pdpMain;
    if (event) {
        pdpMain = $(event.target).parents('#pdpMain');
    }
    var imageContainer = pdpMain.clone(false).find('.js-product-image-container'),
        primaryImageContainer = imageContainer.find('.product-primary-image'),
        primaryImageURL = imageContainer.find('a.product-image').attr('href');
    // reset possible inline styles applied by sticky kit
    imageContainer.removeAttr('style');
    if (!primaryImageURL) {
        primaryImageURL = imageContainer.find('a.product-image img').attr('src');
    }
    primaryImageContainer.html(
        '<div class="product-image main-video js-product-video"></div>' +
        '<img src="' + primaryImageURL + '" class="js_product-primary-image-large" />'
    );

    dialog.open({
        html : $(imageContainer),
        options: {
            dialogClass : 'ui-popup-imagezoom'
        },
        callback : function () {
            var $content = dialog.getCurrent().find('.js-product-image-container');
            app.components.initComponent('ProductImageCarousel', $content);
            app.components.initComponent('productvideojs', $content);
            $content.on('click', '.productthumbnail', function (e) {
                e.preventDefault();
                var $this = $(this);
                $this.closest('#thumbnails').find('.thumb.selected').removeClass('selected');
                $this.closest('.thumb').addClass('selected');
                setMainImageLarge($this.data('lgimg'));
                $this.closest('.product-image-container').find('.js_product-primary-image-large').find('.zoomImg').remove();
            });
            // set selected image or video
            setMainImageLarge($content.find('#thumbnails .thumb.selected .productthumbnail').data('lgimg'));
        }
    });
}

/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function ($container) {
    $cache.container = $container || $(document);
    $cache.pdpMain = $cache.container.find('.js-pdp-main');
    var $primaryImage = $cache.pdpMain.find('.primary-image');
    var $mainImage = $cache.pdpMain.find('.main-image');

    // handle product thumbnail click event
    $cache.pdpMain.on('click', '.thumbnail-link', function (event) {
        // switch indicator
        event.preventDefault();
        $(this).closest('.product-thumbnails').find('.thumb.selected').removeClass('selected');
        $(this).closest('.thumb').addClass('selected');
        setMainImage($(this).find('.productthumbnail').data('lgimg'), event);
    });

    if (isTablet) {
        //loading the xlarge image for tablets
        var data = $mainImage.find('img').data('xlgimg');
        if (data !== '') {
            $primaryImage.attr({
                src: data
            });
            $primaryImage.load();
        }
    }
};
module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
module.exports.setMainImageLarge = setMainImageLarge;
module.exports.showImageZoomDialog = showImageZoomDialog;
