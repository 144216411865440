var _isString = require('lodash/lang/isString'),
    progress = require('_core_ext/progress'),
    Promise = require('promise');

var util = {
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var splitedUrl = url.split('#');
        var separator = splitedUrl[0].indexOf('?') !== -1 ? '&' : '?';
        return splitedUrl[0] + separator + name + '=' + encodeURIComponent(value) + (splitedUrl.length > 1 ? '#' + splitedUrl[1] : '');
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },
    /**
     * Returns the current url
     *
     * @return {String} currentUrl
     */
    getCurrentURL: function () {
        return window.location.protocol + '//' +
          window.location.host +
          window.location.pathname +
          window.location.search;
    },
    /**
     * Returns url with replaced parameter
     * @param {String} url the url in which the parameter will be replaced
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    replaceParameterInURL: function(url, name, value) {
        var regExp = new RegExp(name + '=[^&]*');
        
        return url.replace(regExp, name + '=' + value);
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        $.each(params, function (name, value) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (!_isString(url)) { return; }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description replace prodtocol to current page protocol
     * @param {String} url
     **/
    getCurrentProtocolUrl: function (url) {
        var protocolRegEx = /^(https?:)\/\//,
            protocolMatch = url.match(protocolRegEx);

        if (protocolMatch && protocolMatch[1] !== window.location.protocol) {
            url = url.replace(protocolRegEx, window.location.protocol + '//');
        }
        return url;
    },
    /**
     * @function
     * @description
     * @param {Element} el
     * @param {Number} offsetToTop
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },

    getParameterValueFromUrl: function(parameterName, url) {
        var currentQueryString = url || window.location.search;
        var currentQueryStringParams = this.getQueryStringParams(currentQueryString);

        return currentQueryStringParams[parameterName];
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="_' + field.replace('Code', '') + '"]').val(address[field]).trigger('change');
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                var $stateField = $form.find('[name$="state"]');
                $stateField.val(address.stateCode);

                if ('custom' in address && 'dublinCode' in address.custom) {
                    var $dublinCodeField = $form.find('[name$="_dublinCode"]');
                    $dublinCodeField.val(address.custom.dublinCode).trigger('change');
                }
                $stateField.trigger('change');
            }
        }
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on('click', '.delete', function () {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation, duration) {
        duration = typeof(duration) !== 'undefined' ? duration : 500;
        $('html, body').animate({scrollTop: xLocation}, duration);
    },

    setWindowScrollTop: function(offset) {
        if (!offset) {
            return;
        }

        var fixedHeaderHeight = $('.js-sticky_header').height();
        var positionToScroll = offset - fixedHeaderHeight;
        if (positionToScroll <= 0) {
            return;
        }

        $(window).scrollTop(positionToScroll);
    },

    isEmpty : function(value) {
        return (value === null || value === undefined || value.length === 0);
    },

    isMobile: function() {
        var mobileAgentHash = ['mobile', 'phone', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var    idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        if (!this.isTablet()) {
            while (mobileAgentHash[idx] && !isMobile) {
                isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
                idx++;
            }
        }
        return isMobile;
    },

    isPortrait: function() {
        return window.innerHeight >= window.innerWidth;
    },

    isLandscape: function() {
        return window.innerHeight < window.innerWidth;
    },

    isTouch: function() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    },

    isMobileScreen: function() {
        return window.innerWidth < 768;
    },

    isTablet: function() {
        // Modern tablets (iPads) have "request desktop version of site" pref turned On by default
        // and so send desktop user agent string.
        // So we'll assume a tablet once it's bigger than mobile and support touches
        return !this.isMobileScreen() && this.isTouch();
    },
    isDesktop: function() {
        return !this.isMobile() && !this.isTablet();
    },
    isIPhone: function() {
        var mobileAgentHash = ['iphone', 'ipod'];
        var idx = 0;
        var isIPhone = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        if (!this.isTablet()) {
            while (mobileAgentHash[idx] && !isIPhone) {
                isIPhone = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
                idx++;
            }
        }
        return isIPhone;
    },

    'eventDelay': function(callback, threshhold, scope, skipInitialCall) {
        threshhold = threshhold ? threshhold : 250;
        var last,
            deferTimer;

        /**
         * @todo  Add description
         */
        return function() {
            var context = scope || this,
                now = (new Date()).getTime(),
                args = arguments;

            if (last && now < last + threshhold) {
                clearTimeout(deferTimer);
                deferTimer = setTimeout(function() {
                    last = now;
                    callback.apply(context, args);
                }, threshhold);
            } else {
                last = now;

                if (!skipInitialCall) {
                    callback.apply(context, args);
                }
            }
        };
    },

    /**
     * Throttling Function Calls
     *
     * @see http://www.nczonline.net/blog/2007/11/30/the-throttle-function/
     * @param {Function} callback Callback function to call
     * @param {Number} delay Delay before callback fire
     * @param {Object} scope The context to for callback fire
     */
    'throttle': function(callback, delay, scope) {
        clearTimeout(callback._tId);
        callback._tId = setTimeout(function() {
            callback.call(scope);
        }, delay || 100);
    },

    getTimer: function() {
        return {
            id: null,
            clear: function () {
                if (this.id) {
                    window.clearTimeout(this.id);
                    delete this.id;
                }
            },
            start: function (duration, callback) {
                this.id = setTimeout(callback, duration);
            }
        };
    },

    /**
     * Helps execute code after some condition met at some point in time.
     * E.g. to run code depending on some 3rd-party script, loading/initializing asynchronously without notifying outside world anyhow.
     * Execute 'predicate' function every 'checkEvery' ms until it returns true or 'timeout' passed.
     * Returns Promise which will be resolved when 'predicate' returns true, or rejected with no result if 'timeout' passed, or error thrown from predicate (error passed to reject).   
     * @param {Function} predicate Function to check
     * @param {Number} interval Interval to check predicate in milliseconds
     * @param {Number} timeout Time after which polling should be stopped, in milliseconds
     * @returns {Promise}   
     */
    wait: function(predicate, checkEvery, timeout) {
        checkEvery = checkEvery || 250;
        timeout = timeout || 5000;
        const start = Date.now();

        return new Promise(function(resolve, reject) {
            if (predicate()) {
                resolve();
                return;
            }
            const intervalId = setInterval(function() {
                if (predicate()) {
                    clearInterval(intervalId);
                    resolve();
                    return;
                }
                if ((Date.now() - start) > timeout) {
                    clearInterval(intervalId);
                    reject();
                }
            }, checkEvery);
        });
    },

    /**
     * Add/remove value from array depending on its existing in array
     * @param inArr
     * @param value
     * @returns {Array}
     */
    toggleFromArray: function(inArr, value) {
        var arr = inArr.slice();
        var valueIndex = arr.indexOf(value);
        if (valueIndex >= 0) {
            arr.splice(valueIndex, 1);
        } else {
            arr.push(value);
        }

        return arr;
    },

    /**
     * Helper function.
     * Checks if two arrays have the same values. It doesn't depend from order
     * @param arr1
     * @param arr2
     * @returns {Boolean}
     */
    arraysEqualByValues: function(arr1, arr2) {
        if (!arr1 || !arr2) {
            return arr1 === arr2;
        }

        var len1 = arr1.length,
            len2 = arr2.length;

        if (len1 !== len2) {
            return false;
        }
        for (var i = 0; i < len1; i++) {
            if (arr2.indexOf(arr1[i]) < 0) {
                return false;
            }
        }

        return true;
    },

    /**
     * Helper function.
     * Construct :nth-child selector with given element and start position
     * @param {String} elem
     * @param {Number} from
     * @returns {String}
     */
    fromNthSelector: function(elem, from) {
        var selector = elem + ':nth-child(n+';
        selector += (from + 1);
        selector += ')';

        return selector;
    },

    getKeyByValue: function(HashMapCollection, value) {
        for (var prop in HashMapCollection) {
            if (HashMapCollection.hasOwnProperty(prop)) {
                if (HashMapCollection[prop] === value) {
                    return prop;
                }
            }
        }
    },

    /**
     * [getUri description]
     * @param  {[type]} o [description]
     * @return {[type]}   [description]
     */
    getUri : function(o) {
        var a;

        if (o.tagName && $(o).attr('href')) {
            a = o;
        } else if (typeof o === 'string') {
            a = document.createElement('a');
            a.href = o;
        } else {
            return null;
        }

        // overcome some stupid ie behaviour
        if (a.host === '') {
            a.href = a.href;
        }

        // all actual version of IE not so smart to correctly process
        // protocol independent locations, so wee need to help them
        if (a.protocol === ':') {
            a.protocol = window.location.protocol;
        }

        // fix for some IE browsers
        if (a.pathname.indexOf('/') !== 0) {
            a.pathname = '/' + a.pathname;
        }

        return Object.create({
            'protocol' : a.protocol, //http:
            'host' : a.host, //www.myexample.com
            'hostname' : a.hostname, //www.myexample.com'
            'port' : a.port, //:80
            'path' : a.pathname, // /sub1/sub2
            'query' : a.search, // ?param1=val1&param2=val2
            'queryParams' : a.search.length > 1 ? this.getQueryStringParams(a.search.substr(1)) : {},
            'hash' : a.hash, // #OU812,5150
            'url' : a.protocol + '//' + a.host + a.pathname,
            'toString' : function () {
                return this.protocol + '//' + this.host + this.port + this.pathname + this.search + this.hash;
            }
        });
    },

    progressShow: function ($container) {
        $container.addClass('js-loading');
        progress.show($container);
    },

    progressHide: function ($container) {
        $container.removeClass('js-loading');
        progress.hide();
    },

    contentShow: function (target) {
        var dialog = require('_core/dialog');
        var contentId = $(target).data('contentid');

        if (!contentId) {
            return;
        }

        $.ajax({
            url: Urls.pageShow,
            data: {
                format: 'ajax',
                cid: contentId,
                type: 'POST'
            }
        }).done(function(response) {
            dialog.open({
                html: response
            });
        });
    },

    /**
     * @function
     * @description filtering all characters, but the printable ASCII ones/Basic Latin + new line character
     * @param {Object} jQuery object
     */
    removeInputNotAcceptableCharacters : function ($input) {
        $input.val($input.val().replace(/[^ -~\n]+/g,''));
    },

    getDecimalSeparator: function() {
        var n = 1.1;
        return n.toLocaleString().substring(1, 2);
    },

    getHeaderHeight: function() {
        var headerWrapperHeigth = $('#header-wrapper').height();
        var headerBanner = $('.header-banner').height();
        return headerWrapperHeigth + headerBanner;
    },

    /**
     * @description Used to prevent browsers (email, password, etc) autofill of field
     * @function
     * @param {JQuery} $fields
     */
    removeBrowserAutoFill: function($fields) {
        $fields.each((key, val) => {
            const $field = $(val);

            $field.on('focus', () => {
                if ($field.attr('readonly')) {
                    $field.removeAttr('readonly');
                    // fix for mobile safari to show virtual keyboard
                    $field.blur();
                    $field.focus();
                }
            });
        });
    },
    
    /**
     * Bind it as 'keydown' event handler on input to prevent submit by hitting Enter on it.
     * @param {jQuery.Event} event
     */
    preventEnterKey: function(event) {
        const ENTER_KEY = 13;
        if (event.which === ENTER_KEY) {
            event.preventDefault();
            return false;
        }
    },

    /**
     * Returns CSS :before/:after pseude-elements content.
     * Useful for sharing media break-points between CSS and JS.
     * Idea: https://gomakethings.com/the-easy-way-to-manage-css-breakpoints-in-javascript/
     * @param {DOMElement} elem 
     * @param {String} contentType ':before' (default if not passed) or ':after'
     * @returns {String} 
     */
    getCSSContentValue: function (elem, contentType) {
        contentType = contentType || ':before';
        return window.getComputedStyle(elem, contentType).content.replace(/\"/g, '');
    },

    /**
     * Checks DOM element visibility on the page
     * @param {DOMElement} elem 
     * @returns {boolean} 
     */
    isVisible: function (el) {
        var elRect = el.getBoundingClientRect(),
            style = getComputedStyle(el);
    
        // means element hidden via display:none
        if (elRect.height === 0) {
            return false;
        }
    
        // as we don't allow horizontal scroll bar on site normally,
        // check that element fully in viewport horizontally (so not moved out of viewport in e.g. carousel), 
        // but intersects at needed ratio vertically
        var visibleVertically = false;
        var top = Math.max(elRect.top, 0);
        var bottom = Math.min(elRect.bottom, window.innerHeight);
        var intersectionHeight = bottom - top;
        var elemToViewportHeightRatio = elRect.height / window.innerHeight;
        if (elemToViewportHeightRatio <= 0.9 && (intersectionHeight / elRect.height) === 1) {
            // if element height less than 90% of viewport (most cases)
            // consider visible when fully in viewport
            visibleVertically = true;
        } else if (elemToViewportHeightRatio > 0.9 && elemToViewportHeightRatio <= 1 && (intersectionHeight / elRect.height) >= 0.9) {
            // if element height bigger than 90% of viewport height but less than viewport,
            // consider visible when it visible by 90%
            visibleVertically = true;
        } else if (elemToViewportHeightRatio > 1 && (intersectionHeight / window.innerHeight) > 0.9) {
            // if element height bigger than viewport height 
            // consider visible when it covers 90% of viewport
            visibleVertically = true;
        }
    
        var inViewport = visibleVertically && 
            elRect.left > -1 &&     // there are some cases, when full-width element has elRect.left = -0.5 (w/o horizontal scroll)
            elRect.right < window.innerWidth + 1;   
        
        // check that element visible (visibility inherited from parents), 
        // and not transparent        
        var visible = inViewport && (style.visibility !== 'hidden') && (style.opacity || 1) > 0,
            parent = el;
    
        // and check that parents are not transparent
        while (visible && (parent = parent.offsetParent) && parent !== document.body) {
            visible = (getComputedStyle(parent).opacity || 1) > 0;
        }
    
        return visible;
    },    

    log : console
};

if (!('util' in window)) {
    window.util = util;
}
module.exports = util;
