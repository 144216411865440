'use strict';

exports.init = function init () {
    if ('_jqfb' in window) {
        $(document).ready(function() {
            if ('domReadyEvents' in window._jqfb) {
                window._jqfb.domReadyEvents.forEach(function(func) {
                    if ('function' === typeof func) {
                        func();
                    }
                });
            }
        });
        $(window).on('load', function() {
            if ('windowLoadEvents' in window._jqfb) {
                window._jqfb.windowLoadEvents.forEach(function(func) {
                    if ('function' === typeof func) {
                        func();
                    }
                });
            }
        });
    }
};
