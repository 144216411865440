import Slick from '_core_ext/components/global/slick';
import eventMgr from '_core_ext/eventMgr';

export default class GlobalSlot extends Slick {
    static get selector() {
        return '.js-global-slot';
    }

    get configDefault() {
        var contentAssetsCount = this.$el.find('.content-asset').length;
        // see Slick slider for full list of options
        return {
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            arrows: false,
            infinite: false,
            responsive:
            [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    initialSlide: contentAssetsCount > 2 ? contentAssetsCount - 2 : 0,
                    arrows: true
                }
            }]
        };
    }

    get configDataAttrName() {
        return 'component-slick';
    }

    init(...args) {
        super.init(args);

        function setAssetsTitle() {
            var asset = $(this),
                overflowHandler = asset.find('.overflow-handler'),
                overflowHandlerTooltip = asset.find('.overflow-handler-tooltip'),
                overflowHandlerContent = asset.find('.header-slide-inner'),
                overflowPresent = overflowHandlerContent.prop('scrollWidth') > overflowHandlerContent.outerWidth();

            if (overflowPresent) {
                overflowHandlerTooltip.text(overflowHandlerContent.text());
                overflowHandler.show();
            } else {
                overflowHandler.hide();
            }
        }

        function resetAllAssetsTitles() {
            this.$el.find('.content-asset').each(setAssetsTitle);
        }

        resetAllAssetsTitles.apply(this);

        eventMgr.on('timer.removed', (c) => {
            this.$el.slick('slickGoTo', 0);
            this.filter(c, function (index, element) {
                return !$(element).find('.hidden').length;
            });
            resetAllAssetsTitles.apply(this);
        });

        eventMgr.on('timer.updated', (tc) => {
            tc.closest('.content-asset').each(setAssetsTitle);
        });

        eventMgr.on('timer.replaced', (tc) => {
            tc.each(setAssetsTitle);
        });
    }

    filter($container, selector) {
        if (!this.$el.is($container)) {
            return;
        }

        this.$el.slick('slickFilter', selector);
    }
}

module.exports = GlobalSlot;
