var util = require('_core_ext/util'),
    socialhubfeeds = require('_core_ext/components/social/socialhub'),
    beInspiredStoryArchiveSearch = require('_core_ext/components/beinspired/storyarchivesearch');

exports.init = function () {
    app.components.initComponent('scrollTo');
    socialhubfeeds.init();
    beInspiredStoryArchiveSearch.init();
    app.components.initComponent('marker');

    var tabsSelector = '.js-tabs' + (util.isMobile() ? ', .js-tabs-mobile' : '');
    var $tabs = $(tabsSelector);

    $tabs.tabs({
        active: 0,
        activate: function (event, ui) {
            var $this = $(this);
            var selectedField = $this.find('.js-tabs-selected').first();
            var selectedTabText = ui.newTab.text();
            selectedField.text(selectedTabText);
        }
    });

    $(document).on('click', 'div.back', function () {
        if (window.history) {
            window.history.back();
        }
    });

    $('.amp-personal-shop__tab-close').on('click', function() {
        $('.amp-personal-shop__cta').prop('checked', false);
    });

    $('.whatson-store').on('click', function() {
        if ($(this).data('filter') !== '*') {
            location.hash = 'store=' + $(this).text();
        } else {
            location.hash = '';
        }
    });

    var hashArray = location.hash.substring(1).split('=');
    if (hashArray[0] === 'store') {
        $('.whatson-store:contains(' + hashArray[1] + ')').trigger('click');
        $('.js-isotope-filter-wrapper .js-toggle-collapse').removeClass('expanded');
    }
};
