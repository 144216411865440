const eventMgr = require('_core_ext/eventMgr');
const sessionEmitter = eventMgr.getEmitter('session');

var cookies = require('_core_ext/utils/cookies');
var _idleTime = 0,
    _idleInterval = null,
    _idleIntervalSeconds = 60,
    _expiredCookieMinutes = 30,
    _idleCookieKey = 'dwExpire';


function _getExpireCookie() {
    return cookies.getCookie(_idleCookieKey);
}

function _resetExpireCookie() {
    return cookies.setCookie(_idleCookieKey, true, _expiredCookieMinutes);
}

function _idleTimer() {
    if (!_idleTime++) {
        _resetExpireCookie();
    }

    if (this.isSessionExpired()) {
        sessionEmitter.emit('expired');
        clearInterval(_idleInterval);
        sessionEmitter.emit('expiredAndLogout');
    }
}

module.exports = {
    init : function() {
        var $document = $(document);

        var _idleTimerBound = _idleTimer.bind(this);
        //To set cookie at once
        _idleTimerBound();
        //Increment the idle time counter every minute.
        _idleInterval = setInterval(_idleTimerBound, _idleIntervalSeconds * 1000);

        //Zero the idle timer on mouse movement.
        $document.on('mousemove', function () {
            _idleTime = 0;
        }).on('keypress', function () {
            _idleTime = 0;
        });
    },

    isSessionExpired  : function() {
        return !_getExpireCookie();
    }
};
