import Component from '_core_ext/components/Component';
import TPromise from 'promise';
import addProductToCart from '_core_ext/pages/product/addToCart';
import quickview from '_core_ext/quickview';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';
const productEmitter = eventMgr.getEmitter('product');

export default class ProductAbstract extends Component {
    get configDefault() {
        return {
            product: {
                ID: '',
                flags: {
                    isBundle: false,
                    isMaster: false,
                    isProductSet: false,
                    isVariationGroup: false,
                    isAvailable: false,
                    isAddToBagDisabled: false,
                    isBTPlusProduct: false,
                    isNoBTPlusProduct: false
                }
            }
        };
    }

    get configDataAttrName() {
        return 'component-product';
    }

    init(...args) {
        this.source = 'default';
        
        //call parent init method with same args
        super.init(...args);

        //make all links of the product lead to quickview but not to the PDP
        if (this.$el.closest('.js-quickview-all').length) {
            this.$el.find('.js-product-url').addClass('js-quickview');
        }

        this.addDefaultEvents();
    }

    getProductID() {
        return this.config.product.ID;
    }

    getProductUUID() {
        return this.config.product.UUID;
    }

    getQty() {
        var $qty = this.$el.find('.js-quantity');
        if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
            $qty.val('1');
        }
        return $qty.val();
    }

    addItemToCart(data) {
        data = $.extend({
            cartAction: 'add',
            Quantity : 1,
            pid: ''
        }, data);
        return TPromise.resolve($.ajax({
            type: 'POST',
            url: util.ajaxUrl(Urls.addProduct),
            data: $.param(data)
        }));
    }

    addDefaultEvents() {
        this.event('click', '.js-quickview', this._onQuickViewClick);
        addProductToCart.attach(this.$el);
        app.components.initComponent('PictureTag', this.$el);
        app.components.initComponent('EnergyRating', this.$el);
        app.components.initComponent('AddToWishlist', this.$el);
    }

    _onQuickViewClick(element) {
        const $el = $(element);
        const url = $el.attr('href');
        const senderSource = $el.data('source');
        quickview.show({
            url: url,
            source: senderSource || this.source,
            callback: function() {
                productEmitter.emit('quickViewShow', $el);

                if (senderSource === 'wishlistadd') {
                    eventMgr.execute('wishlist.quickViewOpen', $el);
                }

                app.components.initComponent('input');
                app.components.initComponent('productvideojs');
                app.components.initComponent('pdpImage');
                app.components.initComponent('ProductImageCarousel');
                app.components.initComponent('EnergyRating');
                app.components.initComponent('PrintableMessage');
                app.components.initComponent('Expandable');
                app.components.initComponent('AddToWishlist');
                app.components.initComponent('ProductPromotions');
                app.components.initComponent('dialogify');
            }
        });
        return false;
    }
}

module.exports = ProductAbstract;
