import Component from '_core_ext/components/Component';
import page from '_core_ext/page';
const addToCart = require('_core_ext/pages/product/addToCart');
const dialog = require('_core_ext/dialog');

/**
 * Smartzer video player actions (add to cart)
 * Player can be added via content slot 
 */

export default class Smartzer extends Component {
    static get selector() {
        return '.js-smartzer';
    }

    get configDataAttrName() {
        return 'component-smartzer';
    }

    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this._initEvents();
    }

    _initEvents() {
        window.removeEventListener('message', this._onSmartzerPostMessage, '*');
        window.addEventListener('message', this._onSmartzerPostMessage, '*');
    }

    _onSmartzerPostMessage(message) {
        if (message.data.event_type !== 'smrtzr_cta') {
            return;
        }
        addToCart.addItemToCartRaw({
            productId: message.data.cta,
            qty: 1
        }).then((response) => {
            if (!response.success && response.url) {
                page.redirect(response.url);
                return;
            }
            const minicart = app.components.get('minicart');
            minicart.show(response);
        }).catch(() => {
            dialog.open({
                url: Urls.getSmartzerError,
                options: {
                    title: dialog.TITLE_FROM_ASSET_NAME,
                    dialogClass: 'smartzer-error-popup',
                    buttons: [{
                        text: Resources.CONTINUE_SHOPPING,
                        class : 'smartzer-error-close-btn',
                        click: function () {
                            $(this).dialog('close');
                        }
                    }]
                }
            });
        });
    }
}

module.exports = Smartzer;
