import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('OutfitImage');

export default class OutfitView extends Component {
    static get selector() {
        return '.js-change-image-view';
    }

    get configDefault() {
        return {
            outfitViewText: this.$el.data('outfitview'),
            itemViewText: this.$el.data('itemview'),
        };
    }

    updateText() {
        this.$el.text(window.pageData.isOutfitView ? this.config.itemViewText : this.config.outfitViewText);
    }

    initEvents() {
        this.$el.on('click', () => {
            window.pageData.isOutfitView = !window.pageData.isOutfitView;

            this.updateText();
            emitter.emit('change', window.pageData.isOutfitView);
        });
    }

    changeOutfitView() {
        if (!window.pageData.isOutfitView) {
            return false;
        }

        emitter.emit('change', window.pageData.isOutfitView);
    }

    init(...args) {
        super.init(...args);

        this.updateText();
        this.initEvents();

        if (window.pageData.isOutfitView) {
            emitter.emit('change', window.pageData.isOutfitView);
        }

        eventMgr.on('search.productTilesAppend', this.changeOutfitView);

        eventMgr.on('OutfitView.resetLinkText', () => {
            window.pageData.isOutfitView = false;
            this.updateText();
        });

        return true;
    }

    destroy(){
        eventMgr.off('search.productTilesAppend', this.changeOutfitView);
    }
}

module.exports = OutfitView;
