import CustomerMigrationBase from '_core_ext/components/customer/CustomerMigration';

export default class CustomerMigration extends CustomerMigrationBase {
    _initCache(...args) {
        super._initCache(...args);
        this.$loginForm = this.$el.find('#dwfrm_login, #dwfrm_loginintercept');
        this.$loginFormSubmit = this.$loginForm.find(':submit');
    }

    _initEvents() {
        this.$loginForm.on('submit', () => {
            //load the username and password
            var username = this.getFormLoginValue();
            var password = this.$loginForm.find('input.js-input-password').val();
            if (username && username.length > 0 && password && password.length > 0) {
                $.ajax({
                    url:Urls.migratedCustomerSalt,
                    method: 'POST',
                    context: this.$loginForm,
                    async: false,
                    data: {
                        username: username
                    }
                }).done((response) => {
                    if (response && response.success && response.salt) {
                        var bcrypt = require('_core_ext/components/bcrypt');
                        bcrypt.hashpw(password, response.salt, (newhash) => {
                            var match = newhash;
                            this.$loginForm.find('input.js-input-encryptedPassword').val(match);
                        }, () => {});
                    }
                });
            }
        });
    }
}

module.exports = CustomerMigration;
