var ajax = require('_core_ext/ajax');

module.exports = {
    /**
     * Get csrf token for cached pages. Returned Promise resolved with structure containing csrf token info
     * @returns {Promise<{csrf_token_name: String, csrf_token_value: String}>}
     */
    getCSRFToken: function() {
        return ajax.getJson({
            url: Urls.getCSRFToken
        });
    },

    /**
     * Appends csrf token name/value pair to passed object.
     * @param {Object<{csrf_token_name: String, csrf_token_value: String}>} tokenData 
     * @param {Object} targetObj Object to add csrf token parameter to
     * @returns {Object} Modified targetObj
     */
    addToObject: function(tokenData, targetObj) {
        targetObj[tokenData.csrf_token_name] = tokenData.csrf_token_value;
        return targetObj;
    }
};
