import ProductAbstract from '_core_ext/components/product/ProductAbstract';
import TPL from '_core_ext/utils/templating';
import util from '_core_ext/util';
import progress from '_core_ext/progress';
import rating from '_core/rating';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('ProductTile');

export default class ProductTile extends ProductAbstract {
    static get selector() {
        return '.js-component-product-tile';
    }

    get configDefault() {
        return {
            templates: {
                bonus: 'product-tile-bonus',
                compare: 'product-tile-compare',
                standard: 'product-tile-standard',
                wide: 'product-tile-wide',
                wishlist: 'product-tile-wishlist',
                vipwishlist: 'product-tile-vipwishlist',
                'clp-bestsellers': 'product-tile-clp-bestsellers'
            }
        };
    }

    get configDataAttrName() {
        return 'componentProductTile';
    }

    // Here we need to use custom component parameters initialization
    initProductTileData(data) {
        this.productTileData = {
            isPreRendered: false,
            product: {},
            contextOptions: {}
        };

        data = data || this.config;

        if (data.isPreRendered) {
            this.productTileData.isPreRendered = data.isPreRendered;
        }
        if (data.product) {
            this.productTileData.product = data.product;
        }
        if (data.contextOptions) {
            this.productTileData.contextOptions = data.contextOptions;
        }

        if (this.productTileData.contextOptions.showRating) {
            this.productTileData.product.rating = this._getRatingStarsContent();
        }

        if ('$tileWrapper' in this && this.$tileWrapper.length) {
            var contextOptions = this.$tileWrapper.data('product-hit-context-options') || {};
            Object.assign(this.productTileData.contextOptions, contextOptions);
        }
        this.productTileData.contextOptions.isMobile = util.isMobile();
    }

    init(...args) {
        this.$tileGridWrapper = this.$el.closest('.js-product-grid');
        this.$tileWrapper = this.$el.closest('.js-product-tile-wrapper');

        //call parent init method with same args
        super.init(...args);
        this.initProductTileData();

        this.source = 'quickview';
        this.viewType = this.$tileGridWrapper.data('product-tiles-view-type') || 'standard';

        if (!this.productTileData.isPreRendered || this.viewType !== 'standard') {
            this.repaint();
        } else {
            if (this.viewType === 'standard') {
                if (this.productTileData.contextOptions.showRating && this.productTileData.product.rating) {
                    this.$el.find('.js-tile-rating-wrapper').html(this.productTileData.product.rating);
                }
                this.checkPreRenderedElements();
            }
        }

        this.addWrapperEvents();
        this.addEvents();
        this.checkAndTriggerCarouselUpdate();
    }

    _getTemplateName() {
        return this.config.templates[this.viewType];
    }

    checkPreRenderedElements() {
        //show required part or remove it
        if (this.productTileData.contextOptions.showPricing) {
            this.$el.find('.js-tile-pricing-wrapper').removeClass('visually-hidden');
        } else {
            this.$el.find('.js-tile-pricing-wrapper').remove();
        }
        if (this.productTileData.contextOptions.showAddToWishList) {
            this.$el.find('.js-tile-wishlist-wrapper').removeClass('visually-hidden');
        } else {
            this.$el.find('.js-tile-wishlist-wrapper').remove();
        }
        if (this.productTileData.contextOptions.showSwatches) {
            this.$el.find('.js-tile-swaches-wrapper').removeClass('visually-hidden');
        } else {
            this.$el.find('.js-tile-swaches-wrapper').remove();
        }
        if (this.productTileData.contextOptions.showRating) {
            this.$el.find('.js-tile-rating-wrapper').removeClass('visually-hidden');
        } else {
            this.$el.find('.js-tile-rating-wrapper').remove();
        }
        if (this.productTileData.contextOptions.showCompare) {
            this.$el.find('.js-tile-compare-wrapper').removeClass('visually-hidden');
        } else {
            this.$el.find('.js-tile-compare-wrapper').remove();
        }
        if (this.productTileData.contextOptions.showProductDetails) {
            this.$el.find('.js-product-details-wrapper').removeClass('visually-hidden');
        } else {
            this.$el.find('.js-product-details-wrapper').remove();
        }
    }

    addWrapperEvents() {
        /**
         * example for manual triggering:
         * $('.js-product-grid').trigger('tiles.repaint', {viewType : 'standard'});
         * $('.js-product-grid').trigger('tiles.repaint', {viewType : 'wide'});
         */
        this.$tileGridWrapper.on('tiles.repaint', (e, data) => {
            if (data && 'viewType' in data) {
                this.viewType = data.viewType;
            }
            this.repaint();
        });
    }

    addEvents() {
        this
            .event('click', '.js-swatch-list .js-swatch', this._onSwatchListSwatchClick)
            .event('mouseleave', '.js-swatch-list', this._onSwatchListMouseLeave)
            .event('mouseenter', '.js-swatch-list .js-swatch', this._onSwatchListSwatchMouseEnter)

            //wide tile
            .event('click', '.js-toggle-details', this._detailsToggleClick)

            .event('click', '.js-tile-variation-attr-value', this._onTileVariationAttrValueClick);
    }

    repaint() {
        var html = TPL.render(this._getTemplateName(), this.productTileData);
        this.$el.html(html);
        if (this.productTileData.contextOptions.showRating && this.productTileData.product.rating) {
            this.$el.find('.js-tile-rating-wrapper').html(this.productTileData.product.rating);
        }
        this.reInitSubComponents();
        this.checkPreRenderedElements();
    }

    _onTileVariationAttrValueClick(element, event) {
        var $element = $(element);
        if ($element.hasClass('js-is-selectable')) {
            if ($element.hasClass('js-selected')) {
                event.preventDefault();
                return;
            }
            var elementDataLink = $element.data('link'),
                url = util.appendParamsToUrl($element.attr('href'), {
                    'format': 'tile-json',
                    'showcompare': elementDataLink ? elementDataLink.showcompare : 'false',
                    'productPrice': this.productTileData.product.promotionPrice,
                    'expanded': $element.closest('.product-variations').hasClass('expanded'),
                    'isMobile': util.isMobile(),
                    'markSelected': this.$el.find('.js-add-bonus-chk').prop('checked')
                }),
                options = {
                    url: url,
                    type: 'GET'
                };
            progress.show(this.$el);

            $.ajax(options).done((data) => {
                if (typeof(data) !== 'string') {
                    var contextOptionsCustom = this.productTileData.contextOptions.custom;

                    this.initProductTileData(data);

                    if ($.isEmptyObject(this.productTileData.contextOptions.custom)) {
                        this.productTileData.contextOptions.custom = contextOptionsCustom;
                    }

                    //set id and uuid for external scripts
                    this.$el.data('product-id', this.productTileData.product.ID);
                    this.$el.data('product-uuid', this.productTileData.product.UUID);

                    this.repaint();

                    var selectedAttributes = [];
                    var variationAttributes = data.product.variationAttributes;
                    for (var index in variationAttributes) {
                        if (variationAttributes[index].selected && ((index === 'color' && variationAttributes[index].list.length > 1) || 'size' === index)) {
                            selectedAttributes.push(variationAttributes[index].selected.value);
                        }
                    }
                    var $messageContainer = this.$el.closest('.grid-tile').find('.prod-feedback-txt');
                    if (selectedAttributes.length > 0) {
                        var message = $messageContainer.html();
                        message += ' ' + selectedAttributes.toString().replace(',', ', ');
                        $messageContainer.html(message);
                        $messageContainer.removeClass('hidden');
                    } else {
                        $messageContainer.addClass('hidden');
                    }
                }
                emitter.emit('VariationChanged', this);
            });

        }
        return false;
    }

    _detailsToggleClick(element) {
        var $element = $(element);
        if (!$element.hasClass('expanded')) {
            $element.html(Resources.HIDE_DETAILS);
        } else {
            $element.html(Resources.SHOW_DETAILS);
        }
    }


    /**
     * EVENTS HANDLERS:
     */
    _onSwatchListMouseLeave() {
        // Restore current thumb image
        var $thumb = this.$el.find('.js-product-image-img').eq(0),
            data = $thumb.data('current');
        if (data && data.src) {
            $thumb.attr({
                src: data.src,
                alt: data.alt,
                title: data.title
            });
        }
    }

    _onSwatchListSwatchClick(element, event) {
        event.preventDefault();
        var $element = $(element);
        if ($element.hasClass('selected')) {
            return;
        }
        $element.closest('.js-swatch-list').find('.js-swatch.selected').removeClass('selected');
        $element.addClass('selected');
        this.$el.find('.js-thumb-link').attr('href', $element.attr('href'));
        this.$el.find('.js-product-url').attr('href', $element.attr('href'));

        var data = $element.children('img').filter(':first').data('thumb');
        var $thumb = this.$el.find('.product-image .thumb-link img').eq(0);
        var currentAttrs = {
            src: data.src,
            alt: data.alt,
            title: data.title
        };
        $thumb.attr(currentAttrs);
        $thumb.data('current', currentAttrs);
        return false;
    }

    _onSwatchListSwatchMouseEnter(element) {
        // get current thumb details
        var $thumb = this.$el.find('.js-product-image-img').eq(0),
            data = $(element).children('img').filter(':first').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    }

    _getRatingStarsContent() {
        var ratingNumber = this.productTileData.product.ratingNumber;
        if (ratingNumber > 0) {
            // rating range from 2 - 5
            var rating = ratingNumber;
            var baseRating = Math.floor(rating);
            var starsCount = 0;
            var html = '';
            for (var i = 0; i < baseRating; i++) {
                html += '<i class="fa fa-star"></i>';
                starsCount++;
            }
            // give half star for anything in between
            if (rating > baseRating) {
                html += '<i class="fa fa-star-half-o"></i>';
                starsCount++;
            }
            if (starsCount < 5) {
                for (var j = 0; j < 5 - starsCount; j++) {
                    html += '<i class="fa fa-star-o"></i>';
                }
            }
            return html;
        }
        return null;
    }

    /**
     * Get product Rating
     * @param pid
     */
    getRating(pid) {
        return rating.getRating(pid);
    }
    checkAndTriggerCarouselUpdate() {
        setTimeout(() => {
            var $carousel = this.$el.parents('.js-carousel');
            if ($carousel.length) {
                emitter.emit('carouselUpdate', $carousel);
            }
        }, 100);
    }
    reInitSubComponents() {
        app.components.initComponent('input', this.$el);
        app.components.initComponent('validator', this.$el);
        app.components.initComponent('PictureTag', this.$el);
        app.components.initComponent('EnergyRating', this.$el);
        app.components.initComponent('AddToWishlist', this.$el);
        app.components.initComponent('ProductInfo', this.$el);
    }
}

module.exports = ProductTile;
