/*global $*/

var util = require('_core_ext/util');
var logger = util.log;
var Handlebars = require('handlebars');

/**
 * {@link http://handlebarsjs.com/}
 */


var _tplScriptIDPreffix = 'js-tpl-',
    _cachedTemplates = {};

//check only one helper, if exists all additional helpers already registered
if (!('debug' in Handlebars.helpers)) {
    /**
     * helpers must be same as in ~/cartridge/scripts/util/templating/handlebars
     */
    var helpers = {
        /**
         * {@link http://stackoverflow.com/a/31632215}
         * 
         * 
         * @example
         *   {{#if (or 
         *         (eq section1 "foo")
         *         (ne section2 "bar")
         *       )}}
         *       .. content
         *   {{/if}}
         */
        eq: function (v1, v2) {
            /*eslint eqeqeq: "off"*/
            return v1 == v2;
        },
        sEq: function (v1, v2) {
            return v1 === v2;
        },
        nEq: function (v1, v2) {
            /*eslint eqeqeq: "off"*/
            return v1 != v2;
        },
        sNEq: function (v1, v2) {
            return v1 !== v2;
        },
        lt: function (v1, v2) {
            return v1 < v2;
        },
        gt: function (v1, v2) {
            return v1 > v2;
        },
        lte: function (v1, v2) {
            return v1 <= v2;
        },
        gte: function (v1, v2) {
            return v1 >= v2;
        },
        and: function (v1, v2) {
            return v1 && v2;
        },
        or: function (v1, v2) {
            return v1 || v2;
        },
        not: function (v1) {
            return !v1;
        },
        
        /**
         * 
         */
        debug: function (optionalValue) {
            logger.log('Current Context');
            logger.log('====================');
            logger.log(this);
            if (optionalValue) {
                logger.log('Value');
                logger.log('====================');
                logger.log(optionalValue);
            }
        },
        'ifAll': function() {
            var args = [].slice.apply(arguments);
            var opts = args.pop();
    
            var fn = opts.fn;
            for (var i = 0; i < args.length; ++i) {
                if (args[i]) {
                    continue;
                }
                fn = opts.inverse;
                break;
            }
            return fn(this);
        },
        'block-params' :  function() {
            var args = [],
                options = arguments[arguments.length - 1];
            for (var i = 0; i < arguments.length - 1; i++) {
                args.push(arguments[i]);
            }
            return options.fn(this, {data: options.data, blockParams: args});
        }
    };
    for (var h in helpers) {
        Handlebars.registerHelper(h, helpers[h]);
    }
}
function _getTemplate(tplID) {
    if (!(tplID in _cachedTemplates)) {
        let source;
        const $tplEl = $('#' + _tplScriptIDPreffix + tplID);
        if ($tplEl.length) {
            source = $tplEl.html();
        } else {
            //Do synchronous AJAX call, as it is fallback
            $.ajax({
                url: util.appendParamsToUrl(Urls.tplGetTemplate, {format: 'ajax', tplID : tplID}),
                //AJAX must be synchronous
                async: false,
                success: function (result) {
                    source = result;
                }
            });
        }
        _cachedTemplates[tplID] = Handlebars.compile(source);
    }
    return _cachedTemplates[tplID];
}

/**
 * @function
 * @description Attach carousel plugin for elements
 */
exports.render = function (tplID, data) {
    var tpl = _getTemplate(tplID);
    return tpl(data || {});
};
