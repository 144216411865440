var dialog = require('_core_ext/dialog'),
    util = require('_core_ext/util'),
    noScroll = require('_core_ext/components/noscroll'),
    ajax = require('_core_ext/ajax');

const emitter = require('_core_ext/eventMgr').getEmitter('minicart');

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var minicart = {
    /**
     * @public
     */
    getAddedBTPlusProducts : function() {
        this.$el = $('#mini-cart');
        var $dataEl = this.$el.find('.js-mini-cart-total'),
            btPlusProducts = [];

        if ($dataEl.length > 0) {
            btPlusProducts = $dataEl.data('added-btplus-products');
        }
        return btPlusProducts;
    },

    /**
     * @public
     */
    getMiniCartData: function() {
        return this.$el.find('.js-minicart-inner').data('minicartData');
    },

    /**
     * @private (but called from outside during page initialization by components manager)
     */
    init: function (options) {
        // if 'disabled' passed from components-config it should not slide in,
        // but still need to be initialized for interaction from code
        this.disabled = (options || {}).disabled || this.disabled;

        var _this = this;
        this.$window = $(window);
        this.$el = $('#mini-cart');
        this.$bodyWrapper = $('#wrapper');
        this.$cartWrapper = $('#cart-wrapper');
        this.$stickyWrapper = $('#sticky-wrapper');
        this.$closeCartButton = $('.js-close-minicart');
        this.$content = this.$el.find('.js-mini-cart-content');

        // styling of minicart tied to no-desktop class, added by head.core.js script, which determines mobile/desktop based on userAgent string
        // but modern iPads send desktop user agent and so recognized as desktop devices (while supporting touch),
        // so align presentation with this class, but behavior with actual touch support
        this.mobileMinicart = $(document.documentElement).hasClass('no-desktop');
        
        if (this.disabled) {
            return;
        } 

        // events
        if (util.isTouch()) {
            // .js-close-minicart element is not reloaded with minicart content,
            // but minicart is re-initialized each time product added to cart, so we need to make sure
            // that click handler bound only once for close button
            this.$closeCartButton.off('click.close').on('click.close', function (e) {
                e.preventDefault();
                _this.close();
            });
            this.$el.find('.js-mini-cart-total').on('click', function (e) {
                // if minicart opened - navigate to cart page, open minicart otherwise 
                if (_this.$content.length > 0 && !util.isVisible(_this.$content[0])) {
                    e.preventDefault();
                    _this.slide();
                }
            });
        } else {
            this.$el.find('.js-mini-cart-total')
                .on('mouseenter', function () {
                    timer.clear();
                    _this.slide();
                }).on('mouseleave', function () {
                    timer.clear();
                    _this.$content.on('mouseenter', function () {
                        _this.slide();
                    });
                    timer.start(SitePreferences.MINICART_RENDER_PREFS.slideoutDelay, _this.close.bind(_this));
                });

            this.$content
                .on('mouseenter', function () {
                    timer.clear();
                    _this.slide();
                }).on('mouseleave', function () {
                    _this.$content.on('mouseenter', function () {
                        timer.clear();
                        _this.slide();
                    });
                    timer.clear();
                    timer.start(SitePreferences.MINICART_RENDER_PREFS.slideoutDelay, _this.close.bind(_this));
                });
        }
    },
    /**
     * @public
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        var _this = this;

        function showCartDOM() {
            _this.slide();
            timer.start(_this.mobileMinicart ? SitePreferences.MINICART_RENDER_PREFS.mobileRenderDelay : SitePreferences.MINICART_RENDER_PREFS.desktopRenderDelay, _this.close.bind(_this));
        }

        if (html) {
            this.update(html);
        }
        
        if (this.disabled) {
            return;
        }

        if (!this.mobileMinicart) {
            showCartDOM();
        } else {
            // immediate call to this.init() + this.slide() caused visual problems (not enough time for js to process received cart DOM?..)
            // so now we delay it a bit - 0 is enough.
            setTimeout(function() {showCartDOM();}, 0);
        }
    },
    /**
     * @private
     * @description Slides down and show the contents of the mini cart
     */
    slide: function () {
        var _this = this;

        // show the item
        if (this.mobileMinicart) {
            noScroll.enable();
            _this.$closeCartButton.css('top', $('.header-cookies').height());
            _this.$content.css('top', -$('.header-banner').height());
            _this.$content.css('height', _this.$window.innerHeight());
            _this.$content.toggleClass('mini-cart-active');
            _this.$bodyWrapper.toggleClass('mini-cart-active');
            if (_this.$bodyWrapper.find('.is-sticky').length) {
                _this.$bodyWrapper.find('#header-search-form-wrapper').css('opacity', '0');
            }
            app.components.initComponent('carousel', _this.$content);
        } else {
            timer.clear();
            this.$content.slideDown('slow', function() {
                app.components.initComponent('carousel', _this.$content);
                emitter.emit('desktop.opened');
            });

            $(document).on('click', function(event) {
                if (!$(event.target).closest(_this.$content).length &&
                   !$(event.target).is(_this.$content)) {
                    if (_this.$content.css('display') === 'block') {
                        timer.start(SitePreferences.MINICART_RENDER_PREFS.desktopRenderDelay, _this.close.bind(_this));
                    }
                }
            });
        }
    },
    /**
     * @private
     * @description Closes the mini cart with given delay
     */
    close: function () {
        if (this.mobileMinicart) {
            noScroll.disable();
            this.$content.removeClass('mini-cart-active');
            this.$bodyWrapper.removeClass('mini-cart-active');
            this.$bodyWrapper.find('#header-search-form-wrapper').css('opacity', '');

            dialog.recalculatePosition();
        } else {
            this.$content.slideUp('slow', () => emitter.emit('desktop.closed'));
        }
        timer.clear();
    },

    /**
     * @public
     * (Re)Loads and initializes minicart but don't show it 
     */
    load: function () {
        return ajax.load({
            url: Urls.minicart,
            target: '#mini-cart'
        }).then(() => this.init());
    },
    
    /**
     * @public
     * Updates and init-s minicart with provided content.
     * Introduced to separate from show()
     */
    update: function (html) {
        this.$el.html(html);
        this.init();
        emitter.emit('updated');
    }
};

module.exports = minicart;
