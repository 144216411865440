const eventMgr = require('_core_ext/eventMgr');
var dialog = require('_core_ext/dialog'),
    page = require('_core_ext/page'),
    util = require('_core_ext/util'),
    TPromise = require('promise');

var maxItems = 1;
var bliUUID = '';

// callback to pass control further from bonus dialog
// stub, to be overridden in promise constructor
var next = function() {};

/**
 * @private
 * @function
 * description Gets a list of bonus products related to a promoted product
 */
function getSelectedBonusProducts($container) {
    var o = {
            bonusproducts: []
        },
        $checkedCheckboxes = $container.find('.js-add-bonus-chk:checked');

    for (var i = 0, len = $checkedCheckboxes.length; i < len; i++) {
        var $checkBox = $($checkedCheckboxes[i]),
            $bonusProductWrapper = $checkBox.closest('.js-bonus-product-tile'),
            $bonusProductInnerTile = $bonusProductWrapper.find('.js-component-product-tile'),
            pid = $bonusProductInnerTile.data('product-id');

        o.bonusproducts.push({
            product: {
                pid: pid.toString(),
                qty: 1,
                options: {}
            }
        });
    }
    return o;
}

function processAddBonusChkVisibility($container, selectedBonusProducts) {
    var selectedBonusProductsCount = selectedBonusProducts.bonusproducts.length;
    var $addBonusChk = $container.find('.js-add-bonus-chk');
    var isMaxReached = selectedBonusProductsCount >= maxItems;
    for (var i = 0, len = $addBonusChk.length; i < len; i++) {
        var $chk = $addBonusChk.eq(i),
            $chkWrap = $chk.closest('.product-compare');
        $chkWrap.toggleClass('visually-hidden', isMaxReached && !$chk.is(':checked'))
    }
}

// Adjust visibility of 'Select bonus' button and 'Select' checkboxes (for arnotts) 
function processElemsVisibility($container, selectedBonusProducts) {
    var productsCount = selectedBonusProducts.bonusproducts.length,
        $submitButton = $container.find('.js-add-to-cart-bonus');

    $submitButton.prop('disabled', !productsCount);

    // update 'X items left to select' message
    var max = $container.data('promo-detail').maxItems,
        itemsLeft = max - productsCount,
        msgCont = $container.find('.js-select-more-msg'),
        msg = '';
    if (!itemsLeft  || itemsLeft === max) {
        msg = '';
    } else if (itemsLeft === 1) {
        msg = msgCont.data('msg-single');
    } else if (itemsLeft > 0) {
        msg = String.format(msgCont.data('pattern-multi'), itemsLeft);
    }
    msgCont.text(msg);

    if (SitePreferences.PROJECT_ID === 'arnotts') {
        processAddBonusChkVisibility($container, selectedBonusProducts);
    }
}

function initializeEvents($container) {
    var promoData = $container.data('promo-detail');

    maxItems = promoData.maxItems;
    bliUUID = promoData.uuid;

    //please, don't move it to head,
    //because, it can broke minicart, or other functionality
    app.components.initComponent('availability', $container);
    app.components.initComponent('ProductTile', $container);
    
    // adjust controls state on dialog load for case when it's shown to Update bonus gift (with products preselected) 
    processElemsVisibility($container, getSelectedBonusProducts($container));
    
    
    eventMgr.on('ProductTile.VariationChanged', () => {
        var selectedBonusProducts = getSelectedBonusProducts($container);
        processElemsVisibility($container, selectedBonusProducts);
    });

    eventMgr.once('dialog.closed', () => {
        // by default prev instance is left in DOM and it's causing issue with selecting items when dialog closed and opened again
        dialog.destroy();
        next(false);
    });
    
    $container
        .on('change', '.js-add-bonus-chk', function() {
            var $checkBox = $(this),
                $maxcountError = $container.find('.js-bonus-maxcount-error'),

                selectedBonusProducts = getSelectedBonusProducts($container),
                selectedBonusProductsCount = selectedBonusProducts.bonusproducts.length;
            
            if (selectedBonusProductsCount > maxItems) {
                $checkBox.prop('checked', false);
                $maxcountError.removeClass('visually-hidden');
            } else {
                $maxcountError.addClass('visually-hidden');
            }

            processElemsVisibility($container, selectedBonusProducts);

        })
        .on('click', '.js-add-to-cart-bonus', function (e) {
            e.preventDefault();

            var url = util.appendParamsToUrl(Urls.addBonusProduct, {bonusDiscountLineItemUUID: bliUUID});
            var bonusProducts = getSelectedBonusProducts($container);
            // make the server call
            $.ajax({
                type: 'POST',
                cache: false,
                contentType: 'application/json',
                url: url,
                data: JSON.stringify(bonusProducts)
            })
            .done(function (response) {
                // success, response is the minicart content
                if (response) {
                    var minicart = app.components.get('minicart');
                    minicart.update(response);
                    eventMgr.execute('gtm.addBonusProducts', minicart.getMiniCartData().bonusProductsGTMData);
                }

                if (window.pageContext.ns === 'cart') {
                    page.redirect(Urls.cartShow);
                    return;
                }
                
                next(true);  // some bonus product(s) was added
            })
            .fail(function (xhr, textStatus) {
                // failed
                if (textStatus === 'parsererror') {
                    window.alert(Resources.BAD_RESPONSE);
                } else {
                    window.alert(Resources.SERVER_CONNECTION_ERROR);
                }
                next(false);
            })
            .always(function () {
                dialog.close();
            });
        })
        .on('click', '.js-add-bonus-cancel', function () {
            dialog.close();
        });
}

var bonusProductsView = {
    /**
     * @function
     * @description Open the list of bonus products selection dialog
     */
    show: function (url) {
        var $bonusProduct = $('#bonus-product-dialog');
        // create the dialog
        dialog.open({
            target: $bonusProduct,
            url: url,
            options: {
                dialogClass : 'bonus-product-dialog js-bonus-product-dialog',
                width: 'auto'
            },
            callback: function () {
                var $container = $('.js-choice-of-bonus-products');
                initializeEvents($container);
            }
        });
    },
    /**
     * @function
     * @description Open bonus product promo prompt dialog
     */
    loadBonusOption: function () {
        var self = this,
            bonusDiscountContainer = document.querySelector('.bonus-discount-container'),
            promise = new TPromise((resolve) => {
                // store reference to callback, to call out of this function
                next = resolve;
            });
        
        if (!bonusDiscountContainer) {
            next(false);
            return promise;
        }

        var bonusProductPromo = $(bonusDiscountContainer).find('.bonus-product-promo');

        // get the html from minicart, then trash it
        bonusDiscountContainer.parentNode.removeChild(bonusDiscountContainer);

        var uuid = bonusProductPromo.data('lineitemid'),
            url = util.appendParamsToUrl(Urls.getBonusProducts, {
                bonusDiscountLineItemUUID: uuid,
                source: 'bonus',
                format: 'ajax',
                lazyLoad: 'false',
                pageStart: 0,
                pageSize: 10,
                bonusProductsTotal: -1
            });
        self.show(url);
        return promise;
    }
};

module.exports = bonusProductsView;
