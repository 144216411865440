import Component from '_core_ext/components/Component';
import util from '_core_ext/util';

export default class Input extends Component {
    static get selector() {
        return '.js-cmp-input:input';
    }

    get configDefault() {
        return {
            preventInputRegexp: null,
            serviceKeys: [8, 9, 16, 37, 38, 39, 40, 46] //workaround to handle backspace, arrow keys etc in Firefox.
        };
    }

    get configDataAttrName() {
        return 'input';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        if (this.config.preventInputRegexp) {
            this.config.preventInputRegexp = new RegExp(this.config.preventInputRegexp);
        }

        this.attributes = {};

        this._initCache()
        this._initEvents();
    }
    /**
     * placeholder, for case, if it will be extended
     */
    _initCache() {}

    _initEvents() {
        this._maxLengthWorkAround();
        if (this.config.preventInputRegexp) {
            this.event('keydown', this._preventInputEvent);
        }
    }

    /*
     * maxlength attribute workaround for number field types
     */
    _maxLengthWorkAround() {
        if (this.$el.attr('type') === 'number') {
            //cache maxlength attribute value
            this.attributes.maxlength = this.$el.attr('maxlength');
            this.event('keypress', () => {
                if (this.$el.val().length >= this.attributes.maxlength) {
                    this.$el.val(this.$el.val().substr(0, this.attributes.maxlength - 1));
                }
            });
        }
    }

    _preventInputEvent(element, event) {
        var key = !event.charCode ? event.which : event.charCode;
        var separator = util.getDecimalSeparator();

        // workaround dor num pad keyboard
        if (key >= 96 && key <=105) { // fix for numpad numbers
            key -= 48;
        } else if (key === 110 || key === 190) { //fix for numpad decimal separator
            // 46 dot, 44 comma
            key = separator === '.' ? 46 : 44;
        }

        var keyValue = String.fromCharCode(key);
        var elValue = this.$el.val();
        if (this.$el.attr('type') === 'number' && this.$el.hasClass('js-input-money')) {
            if (keyValue === separator && (elValue.indexOf(separator) !== -1 || elValue.length === 0)) {
                event.preventDefault();
                return false;
            } 
            
            if (elValue.indexOf(separator) !== -1 && elValue.substr(elValue.indexOf(separator)).length > 2 && this.config.serviceKeys.indexOf(key) === -1) {
                event.preventDefault();
                return false;
            }
        }
        
        if (!(this.config.preventInputRegexp.test(keyValue) || this.config.serviceKeys.indexOf(key) !== -1)) {
            event.preventDefault();
            return false;
        }
    }
}

module.exports = Input;
