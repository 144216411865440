var dialog = require('_core_ext/dialog'),
    availability = require('_core_ext/components/product/availability'),
    page = require('_core_ext/page'),
    util = require('_core_ext/util'),
    customer = require('_core_ext/utils/customer'),
    bonusProductsView = require('_core_ext/bonus-products-view'),
    crossSellView = require('_core_ext/components/product/crossSellView'),
    sizeSelectorView = require('_core_ext/components/product/sizeSelectorView'),
    csrfutil = require('_core_ext/utils/csrf'),
    TPromise = require('promise');

const eventMgr = require('_core_ext/eventMgr');
const addToCartEmitter = eventMgr.getEmitter('addToCart');

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @param {[Object]} csrfToken CSRF token data, passed when set of products added altogether (optional)
 * @returns {Promise}
 */
var addItemToCart = function (form, availableQty, csrfToken) {
    var $form = $(form),
        $qty = $form.find('.js-quantity,input[name="Quantity"]'),
        minicart = app.components.get('minicart');

    var qtyVal = 1;
    if (!($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0)) {
        qtyVal = parseInt($qty.val(), 10);
    }
    if (availableQty && qtyVal > availableQty) {
        qtyVal = availableQty;
    }
    $qty.val(qtyVal);

    var pid = $form.find('input[name="pid"]').val(),
        addedBTPlusProducts = minicart.getAddedBTPlusProducts();
    if (addedBTPlusProducts.length > 0 && addedBTPlusProducts.indexOf(pid) !== -1) {
        $form.find('.js-add-to-cart, .add-to-cart').prop('disabled', true);
    }

    // add csrf token data to A2C request
    return (csrfToken ? TPromise.resolve(csrfToken) : csrfutil.getCSRFToken())
        .then((tokenData) => $.ajax({
            type: 'POST',
            url: util.ajaxUrl(Urls.addProduct),
            data: csrfutil.addToObject(tokenData, $form.serializeObject())
        }));
};

var addItemToCartRaw = function (data) {
    var params = {
        'Quantity': data.qty,
        'cartAction': 'add',
        'pid': data.productId,
        'format': 'ajax'
    };
    $.extend(params, data.custom);
    return TPromise.resolve(availability.getAvailabilityRaw(data.productId, data.qty).then((availability) => {
        if (availability.isAvailable) {
            return csrfutil.getCSRFToken().then((tokenData) => $.ajax({
                type: 'POST',
                url: util.ajaxUrl(Urls.addProduct),
                data: csrfutil.addToObject(tokenData, params)
            }));
        } else {
            // Jquery Promise does not support throwing error from callback (does not caught by surrounding catch callback)
            return TPromise.reject(availability.quantityAvailableMsg);
        }
    })).then(function (response) {
        if (response.error) {
            throw new Error(response.error);
        } else {
            return response;
        }
    });
}

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    e.preventDefault();

    var $el = $(e.currentTarget);
    var $form = $el.closest('form');
    var isBTPlusProduct = $form.hasClass('js-is-btplus-product');
    var isNoBTPlusProduct = $form.hasClass('js-is-no-btplus-product');

    if (isBTPlusProduct && !customer.isAuthenticated()) {
        var pid = $form.find('input[name="pid"]').val();
        var url = util.appendParamsToUrl(Urls.loginShow, {special : 'btplus', pid: pid});

        page.redirect(url);

        return;
    } else {
        if (window.User.btPlusEnabled && isNoBTPlusProduct && !confirm(Resources.NO_BTPLUS_WARNING)) {  //NOSONAR
            return false;
        }

        availability.getAvailability.call($form.find('.js-quantity, input[name="Quantity"]'), e).done(function(response) {
            if (!$el.prop('disabled') && !$el.hasClass('add-to-cart-disabled')) {
                var result = addItemToCart($form, response.availableQty);
                handleAddedToCart($el, $form, result);
            }
        });
    }
};

/**
 * Handles Active A2C button - which enabled proir to variation selected
 * @param {jQuery.Event} e 
 */
function addToCartActive(e) {
    e.preventDefault();
    var $el = $(e.currentTarget);
    
    // regular scenario when variation selected
    if ($el.data('is-orderable')) {
        addToCart(e);
        return;
    }

    // if variation not selected and on destktop - just highlight not selected size
    var showSizeSelector = (util.getCSSContentValue(e.currentTarget) === 'js-size-selector-popup');
    if (!showSizeSelector) {
        addToCartEmitter.emit('notselected');
        return;
    }

    var $form = $el.closest('form');
    var pid = $form.find('input[name="pid"]').val();
    var qty = $form.find('.js-quantity, input[name="Quantity"]').val();
    // when on mobile and variation not selected - show size selector popup and handle it's selection
    sizeSelectorView.show({
        pid: pid,
        qty: qty
    }).then((data) => {
        if (!data) {
            // means size not selected, popup closed - do nothing
            return;
        }
        // add selected size to cart
        var result = addItemToCartRaw({
            productId: data.productId,
            qty: qty
        });
        return handleAddedToCart($el, $form, result);
    });
}

/**
 * Performs actions needed after product added to cart: show minicart / cross-sell popup / bonus choise view.
 * @param {jQuery} $el 
 * @param {jQuery} $form 
 * @param {TPromise} a2cResultPromise 
 * @returns {TPromise}
 */
function handleAddedToCart($el, $form, a2cResultPromise) {
    var minicart = app.components.get('minicart');

    return a2cResultPromise.then((response) => {
        if (!response.success && response.url) {
            page.redirect(response.url);
            
            return TPromise.reject();
        }

        $('body').trigger('ecBasketItemAction', [$form, 'add']);

        var $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
            return TPromise.reject();
        }
            
        addToCartEmitter.emit('product.added', $form);
        // do not close quickview if adding individual item that is part of product set
        // @TODO should notify the user some other way that the add action has completed successfully
        if (!$el.hasClass('sub-product-item')) {
            dialog.close();
        }
        minicart.update(response);
        
        return minicart.getMiniCartData().addedItems || [];                    

    // This code shows Bonus of choise dialog on PDP after adding to cart
    }).then((addedItems) => 
        // show bonus choice dialog (if available), and pass through initially added item(s)
        TPromise.all([addedItems, bonusProductsView.loadBonusOption()])

    ).then(([addedItems, bonusAdded]) => {
        // if bonus choice shown and bonus product selected, 
        // minicart will be updated and addedItems will contain added bonus items
        if (bonusAdded) {
            var addedBonuses = minicart.getMiniCartData().addedItems || [];
            addedItems = addedItems.concat(addedBonuses);
        }
        return addedItems;

    }).then((addedItems) => {
        // suppress cross-sell dialog if product added from cross-sell list
        if ($el.data('disableCrosssellPopup')) {
            return TPromise.resolve(false);
        }
        // show cross-sell dialog (if available) passing all added items to it
        return crossSellView.load($form.closest('#pdpMain'), addedItems);

    }).then((crossSellShown) => {
        // if cross-sell dialog was shown, we should not show minicart
        if (crossSellShown) {
            addToCartEmitter.emit('shown');
            return;
        }
        minicart.show();

        addToCartEmitter.emit('shown');
    })
    .catch((e) => {
        util.log.error(e);
        throw e;
    });
}

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $pdpMain = $(e.target).closest('.js-pdp-main');
    var $productForms = $pdpMain.find('#product-set-list form');
    var minicart = app.components.get('minicart');

    if ($pdpMain.hasClass('.js-is-product-set')) {
        $productForms = $productForms.filter(function() {
            var $setItemForm = $(this);
            var $submit = $setItemForm.find(':submit');
            return !$submit.prop('disabled');
        });
    }

    if ($productForms.length) {
        csrfutil.getCSRFToken().then((tokenData) => TPromise.all(
            $.map($productForms.toArray(), function (form) {
                var $form = $(form);
                return availability.getAvailability.call($form.find('.js-quantity, input[name="Quantity"]')).then(function(response) {
                    var $el = $form.find('.js-add-to-cart, .add-to-cart');
                    if (!$el.prop('disabled') && !$el.hasClass('add-to-cart-disabled')) {
                        return addItemToCart($form, response.availableQty, tokenData);
                    }
                });
            })))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            addToCartEmitter.emit('product.added', $productForms);
            responses.sort(function (a, b) { return b.length - a.length; });
            minicart.show(responses[0]);
            addToCartEmitter.emit('shown');
        });
    }
};

// show error above A2C button on SKUs ProductSet page
var showAddAllSkusError = function () {
    $('#add-all-skus-to-cart-error').html(Resources.ENTER_VALID_QTY);
};

// hide error above A2C button on SKUs ProductSet page
var hideAddAllSkusError = function () {
    $('#add-all-skus-to-cart-error').empty();
};

/**
 * @description Handler the add all SKU items to cart event
 */
var addAllSkusToCart = function (e) {
    e.preventDefault();

    hideAddAllSkusError();

    var $pdpMain = $(e.target).closest('.js-pdp-main'),
        productQtsArr = $pdpMain.find('#product-set-sku-list .js-quantity').toArray(),
        minicart = app.components.get('minicart');

    if (!productQtsArr.length) {
        return;
    }

    // we need this to be available in different steps (then-callbacks) of A2C process
    let nonZeroQtsAndAvails;

    TPromise.all($.map(productQtsArr, (qtyInput) => {
        // get availabilities for all QTY inputs
        var $qty = $(qtyInput);
        // mark request as batch
        return availability.getAvailability.call($qty, null, true);
    }))
    .then((avResponses) => {
        // filter out zero quantities and associate availability responses with their QTY inputs
        nonZeroQtsAndAvails = $.map(avResponses, (avResponse, index) => {
            if (avResponse && avResponse.status !== 'ZERO_QTY') {
                return {
                    qtyInput: productQtsArr[index],
                    avResponse
                };
            }
            // if null or undefined returned from $.map callback - item will be skipped from resulting array
            return;
        });

        // if no quantities selected - stop the process
        if (!nonZeroQtsAndAvails.length) {
            return TPromise.reject();
        }

        // if some selected quantities not valid or unavailable in requested amount - stop the process
        var wrongItems = $.grep(nonZeroQtsAndAvails, (item) =>
            (!$(item.qtyInput).valid() || 
            !item.avResponse || 
            item.avResponse.status === 'NOT_AVAILABLE' || 
            (item.avResponse.levels.IN_STOCK > 0 && item.avResponse.levels.NOT_AVAILABLE > 0))
        );
        if (wrongItems.length) {
            return TPromise.reject();
        }

        // get csrf token and add all to cart one by one
        return csrfutil.getCSRFToken().then((tokenData) => TPromise.all(
            $.map(nonZeroQtsAndAvails, (item) => {
                var $form = $(item.qtyInput).closest('form');
                return addItemToCart($form, item.avResponse.availableQty, tokenData);
            }
        )));
    })
    .then((responses) => {
        dialog.close();
        // collect all qty forms as jQuery collection to pass to emitter
        var $productForms = $($.map(nonZeroQtsAndAvails, (item) => $(item.qtyInput).closest('form').get()));
        addToCartEmitter.emit('product.added', $productForms);
        // show the final response only, which would include all the other items
        responses.sort(function (a, b) { return b.length - a.length; });
        minicart.show(responses[0]);
    })
    .catch(() => {
        showAddAllSkusError();
    });
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = {
    init: function($container) {
        $container = $container || $(document);
        var $pdpMain = $container.find('.js-pdp-main');

        $pdpMain.find('.add-to-cart[disabled]').attr('title', $pdpMain.find('.availability-msg').text());
        $pdpMain.on('click', '.js-add-to-cart', addToCart)
            .on('click', '.js-add-to-cart-active', addToCartActive)
            .on('click', '#add-all-to-cart', addAllToCart)
            .on('click', '#add-all-skus-to-cart', addAllSkusToCart)
            .on('error.hide', '#add-all-skus-to-cart', hideAddAllSkusError);

        $pdpMain.find('.product-detail')
        .on('keypress',':input:not(textarea)', function(e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                return false;
            }
        });
    },
    attach: function($container) {
        $container.on('click', '.js-add-to-cart', addToCart);
    },
    addItemToCartRaw: addItemToCartRaw,
    handleAddedToCart: handleAddedToCart
};
