var dialog = require('_core_ext/dialog');

function showPhoneNumberInstructions() {
    var $form = $('.checkout-personal-details');
    var $phoneHelpContainer = $('.js-phone-help-container');
    
    $form.on('click', '.js-show-phone-help', function(e) {
        e.preventDefault();
        
        dialog.open({
            html: $phoneHelpContainer,
            options: {
                title :  Resources.PHONE_HELPER,
                dialogClass: 'js-show-phone-help-popup',
                open: function () {
                    $phoneHelpContainer.removeClass('hidden');
                }
            }
        });
    });
}

function useAsDeliveryBox() {
    var $billingAdr = $('.billing-address');
    var isHampers = $billingAdr.hasClass('hampers');
    var $stateSelect = $billingAdr.find('.js-input-state');
    var $countrySelect = $billingAdr.find('.js-input-country');
    var $useAsShippingField = $billingAdr.find('.js-input-useAsShippingAddress');
    if (isHampers) {
        $useAsShippingField.closest('.js-form-row').toggleClass('visually-hidden', $stateSelect.val() !== Resources.ALLOWED_HAMPERS_COUNTY);
        $useAsShippingField.prop('checked', $stateSelect.val() === Resources.ALLOWED_HAMPERS_COUNTY);
        $stateSelect.one('change', useAsDeliveryBox);
        $countrySelect.one('change', function(){
            setTimeout(useAsDeliveryBox, 100);
        });
    }
}

function initializeEvents() {
    showPhoneNumberInstructions();
    setTimeout(useAsDeliveryBox, 200);
}

exports.init = function () {
    initializeEvents();
};
