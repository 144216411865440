/* eslint-disable no-undef */
import Component from '_core_ext/components/Component';
import util from '_core_ext/util';

const _unique = require('lodash/array/unique');
const eventMgr = require('_core_ext/eventMgr');

const ADD_TO_CART_PAGE = 'add_to_cart_page';
const PAGETYPES = {
    storefront: 'home_page',
    product: 'item_page',
    Cart: 'cart_page',
    add_to_cart_page: ADD_TO_CART_PAGE,
    clp: 'category_page',
    search: 'search_page',
    Wishlist: 'wish_list_page',
    orderconfirmation: 'purchase_complete_page',
    error: 'error_page'
};

/**
 * This is a description of the AlgonomyTracker constructor function.
 * @class
 * @classdesc This is a description of the AlgonomyTracker class. (must be edited)
 * @extends Component
 */

class AlgonomyTracker extends Component {

    static get selector() {
        return '.js-algonomy-tracker';
    }

    get configDataAttrName() {
        return 'algonomy-tracker';
    }

    init(...args) {
        super.init(...args);
        this.config.customerNo = window.User.customerNo || '';
        this.config.sessionID = window.User.sessionID;
        this.initListeners();
        this.checkPageType();
        this.loadAlgonomyScript();
    }

    /**
     *  Define event listeners
     */
    initListeners() {
        eventMgr.on('addToCart.shown', () => {
            this.config.pageType = ADD_TO_CART_PAGE;
            this.onScriptLoaded();
        });
        eventMgr.on('cart.reloaded', () => {
            this.onScriptLoaded();
        });
        eventMgr.on('product.quickViewShow', ($el) => {
            this.onProductTileAction($el);
        });
        eventMgr.on('wishlist.addToWishlist', ($el) => {
            this.onProductTileAction($el);
        });
    }

    loadAlgonomyScript() {
        if (!PAGETYPES[this.config.pageType]) {
            return;
        }
        $.ajax({
            url: this.config.algonomyUrl,
            cache: true,
            dataType: 'script',
            success: () => {
                this.onScriptLoaded();
            }
        });
    }

    get placements() {
        return {
            add_to_cart_page: (R3_COMMON) => {
                const R3_ADDTOCART = new r3_addtocart();
                const cartItems = this.getCartItems();
                if (cartItems && cartItems.addedItems) {
                    cartItems.addedItems.forEach((item) => {
                        R3_ADDTOCART.addItemIdToCart(item.masterProductId, item.productId);
                        R3_COMMON.addCategoryHintId(item.primaryCategoryId);
                    });
                }
            },
            cart_page: () => {
                const R3_CART = new r3_cart();
                const cartItems = this.getCartItems();
                if (cartItems && cartItems.items) {
                    cartItems.items.forEach((item) => {
                        R3_CART.addItemId(item.masterProductId, item.productId);
                    });
                }
            },
            category_page: () => {
                const R3_CATEGORY = new r3_category();
                this.addProductListProductIds(R3_CATEGORY);
                if (pageContext.algonomyData) {
                    R3_CATEGORY.setId(pageContext.algonomyData.categoryId);
                }
            },
            error_page: () => {
                // eslint-disable-next-line no-unused-vars
                const R3_ERROR = new r3_error();               
            },
            home_page: () => {
                // eslint-disable-next-line no-unused-vars
                const R3_HOME = new r3_home();
            },
            item_page: (R3_COMMON) => {
                const R3_ITEM = new r3_item();
                R3_ITEM.setId(pageContext.algonomyData ? pageContext.algonomyData.productId : '');
                R3_ITEM.setName(pageContext.algonomyData ? pageContext.algonomyData.productName : '');
                R3_COMMON.addCategoryHintId(this.getCategoryID());
            },
            search_page: () => {
                const R3_SEARCH = new r3_search();
                R3_SEARCH.setTerms(this.getQueryParam('q'));
                this.addProductListProductIds(R3_SEARCH);
            },
            wish_list_page: () => {
                const R3_WISHLIST = new r3_wishlist();
                const wishListProducts = this.getWishlistProducts();
                wishListProducts.forEach((item) => {
                    R3_WISHLIST.addItemId(item.product.ID);
                });
            },
            purchase_complete_page: () => {
                var R3_PURCHASED = new r3_purchased();
                if (pageContext.algonomyData) {
                    R3_PURCHASED.setOrderNumber(pageContext.algonomyData.details.orderNumber);
                    pageContext.algonomyData.items.forEach((item) => {
                        R3_PURCHASED.addItemIdPriceQuantity(item.productID, item.price, item.quantity, item.SKU);
                    });
                }
            }
        };
    }
    /**
     * On script load handler, called when the rich relevance script is loaded
     * @param {Object} data - Response of the provider
     */
    onScriptLoaded() {
        RR.jsonCallback = this.onRecommendationsLoaded.bind(this);
        this.fetchRecommendations();
    }
    
    /**
     * Fetch recommendations for placement type
     * @param {Object} placementData placement data
     */
    fetchRecommendations() {
        const algonomyPageType = PAGETYPES[this.config.pageType];
        const R3_COMMON = new r3_common();
        R3_COMMON.setApiKey(this.config.apiKey);
        R3_COMMON.setBaseUrl(`${window.location.protocol}${this.config.baseUrl}`);
        R3_COMMON.setClickthruServer(`${window.location.protocol}//${window.location.host}`);
        R3_COMMON.setSessionId(this.config.sessionID);
        R3_COMMON.setUserId(this.config.customerNo);
        R3_COMMON.addPlacementType(algonomyPageType);
        this.placements[algonomyPageType](R3_COMMON, {});
        if (this.config.algonomyPlacements && this.config.algonomyPlacements[algonomyPageType]) {
            this.config.algonomyPlacements[algonomyPageType].forEach((placement) => {
                const slotcontentObj = $('[data-slot-placement="' + algonomyPageType + '.' + placement + '"]');
                if (slotcontentObj.length) {
                    R3_COMMON.addPlacementType(algonomyPageType + '.' + placement);
                }
            });
        }
        rr_flush_onload();
        r3();
    }

    /**
     * JSON callback function
     */
    onRecommendationsLoaded() {
        RR.result = RR.result || {};
        RR.data.JSON.placements.forEach((placement) => {
            placement.timestamp = (new Date()).getTime();
            const slotcontentObj = $('[data-slot-placement="' + placement.placement_name + '"]').data('algonomy-slot');
            if (slotcontentObj && slotcontentObj.slotcontent && 
                slotcontentObj.slotcontent.custom.algonomyPlacement === placement.placement_name) {
                if (slotcontentObj.slotcontent.custom.algonomyPlacement === 'add_to_cart_page.recs_1' && this.howToWearRecs) {
                    this.onPlacementUpdatedTilesLoad(slotcontentObj.slotcontent.slotID, placement.items, this.howToWearRecs);
                } else {
                    this.getPlacementUpdatedTiles(slotcontentObj, placement.items, placement);
                }
            }
        });
    }

    /**
     * Request updated slot content 
     * @param {object} - slot content object
     * @param {Array} - products
     * @param {Object} - placement
     */
    getPlacementUpdatedTiles(slotcontentObj, items, placement) {
        RR.result[placement.placement_name] = placement;
        slotcontentObj.pids = placement.items.map((item) => item.id);
        if (placement.strategy_message) {
            slotcontentObj.slotcontent.calloutMsg = placement.strategy_message;
        }
        $.ajax({
            method: 'POST',
            url: this.config.algonomySlotURL,
            data: JSON.stringify(slotcontentObj),
            contentType: 'application/json',
            dataType: 'html'
        }).then(this.onPlacementUpdatedTilesLoad.bind(this, slotcontentObj.slotcontent.slotID, items));
    }

    /**
     * Paint the HTML into the slot element with the updated placement tiles
     * @param {string} slotID
     * @param {Array} items
     * @param {HTML} resp
     */
    onPlacementUpdatedTilesLoad(slotID, items, resp) {
        if (resp) {
            this.saveHowToWearResponse(slotID, resp);
            const $newEl = $('[data-slot-id=' + slotID + ']');
            $newEl.replaceWith(resp);
            this._replaceLinks(slotID, items);
        }
    }

    /**
     * Replace links in product tiles with that received from Algonomy.
     * In future all product tile`s clicks tracking should be implemented.
     * @param {slotID}
     * @param {Array} - products
     * @returns {void}
     */
    _replaceLinks(slotID, items) {
        $('[data-slot-id=' + slotID + ']').find('.js-component-product-tile').each(function() {
            const $productTile = $(this),
                pid = $productTile.attr('data-master-product-id'),
                $item = items.find((item) => item.id === pid);

            if ($item) {
                const linkUrlNoRedirect = util.removeParamFromURL($item.link_url, 'ct');
                $productTile.find('.js-product-url').attr('href', $item.link_url);
                $productTile.attr('data-link-url', linkUrlNoRedirect);
            }
        });
    }

    /**
     * Get query or primary category
     * @returns {string} categoryID
     */
    getCategoryID() {
        if (this.getQueryParam('cgid') && this.getQueryParam('cgid') !== 'root') {
            return this.getQueryParam('cgid');
        }
        return pageContext.algonomyData && pageContext.algonomyData.primaryCategoryId;
    }

    /**
     * Get items in cart and recently added ones 
     * @returns {object} cartItems
     */
    getCartItems() {
        return app.components.get('minicart').getMiniCartData();
    }

    /**
     * Check if we are on category or search page and set the pagetype accordingly
     */
    checkPageType() {
        if (this.config.pageType === 'search' && !this.getQueryParam('q')) {
            this.config.pageType = 'clp';
        }
    }

    /**
     * Get the requested query parameter
     * @returns {string} queryParam
     */
    getQueryParam(param) {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop)
        });
        return params[param];
    }
    
    /**
     * Get all product IDs on category or search page and add to alognomy
     */
    addProductListProductIds(R3_INSTANCE) {
        const maxItems = 15;
        let index = 0;
        var productIds = _unique($('.js-component-product-tile').map(function() {
            if (index < maxItems) {
                index++;
                return $(this).attr('data-master-product-id');
            }
        }).get());
        productIds.forEach((productId) => {
            if (productId.length) {
                R3_INSTANCE.addItemId(productId);
            }
        });
    }

    /**
     * Get wishlist item data
     * @returns {object} wishlistData
     */
    getWishlistProducts() {
        return $('[data-component-product-tile]').map(function() {
            return $(this).data('component-product-tile');
        }).get();
    }

    /**
     * Simulate a click to algonomy provided link_url. Used for tracking.
     * @param {$Object} $el 
     */
    onProductTileAction($el) {
        const algonomyLinkUrl = $el.closest('.js-component-product-tile').attr('data-link-url');
        if (!algonomyLinkUrl) return;

        $.ajax({
            method: 'GET',
            url: algonomyLinkUrl
        });
    }

    /**
     * Save the recs for the how to weark/also in range algonomy recs
     * @param {string} slotId 
     * @param {HTML} resp 
     */
    saveHowToWearResponse(slotId, resp) {
        if (slotId === 'recomm-howtowear') {
            this.howToWearRecs = resp;
        }
    }
    /**
     * Destroy is called automatically after the component is being removed from the DOM
     * You must always destroy the listeners attached to an element to avoid any memory leaks
     */
    destroy() {
        //TODO
    }
}

module.exports = AlgonomyTracker
