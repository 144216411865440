import Component from '_core_ext/components/Component';

export default class PriceRange extends Component {
    static get selector() {
        return '.js-component-search-refinement-pricerange';
    }

    get configDefault() {
        return {
            min: 0,
            max: 1000,
            defaultValues: [0, 1000],
            selectors: {
                inputMin: '.js-search-refinement-pricerange-input-min',
                inputMax: '.js-search-refinement-pricerange-input-max'
            }
        };
    }

    get configDataAttrName() {
        return 'search-refinement-pricerange-options';
    }

    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this._initCache();
        this._initSlider();
        this._initEvents();
    }

    _initCache() {
        this.$inputMin = this.$el.find(this.config.selectors.inputMin);
        this.$inputMax = this.$el.find(this.config.selectors.inputMax);
        this.$inputMin.data('prev-value', this.$inputMin.val());
        this.$inputMax.data('prev-value', this.$inputMax.val());

        this.$slider = this.$el.find('.js-search-refinement-pricerange-slider');
    }

    _initSlider() {
        this.$slider.slider({
            range: true,
            min: this.config.min,
            max: this.config.max,
            values: this.config.defaultValues,
            slide: (event, ui) => {
                var minNewValue = ui.values[0];
                this.$inputMin.val(minNewValue);
                
                var maxNewValue = ui.values[1];
                this.$inputMax.val(maxNewValue);
            },
            stop: (event, ui) => {
                var minNewValue = ui.values[0];
                var minPrevValue = Number(this.$inputMin.data('prev-value'));
                if (minNewValue !== minPrevValue) {
                    this.$inputMin.data('is-changed', true);
                    this.$inputMin.data('prev-value', minNewValue);
                    this.$inputMin.val(minNewValue).trigger('change');
                }

                var maxNewValue = ui.values[1];
                var maxPrevValue = Number(this.$inputMax.data('prev-value'));
                if (maxNewValue !== maxPrevValue) {
                    this.$inputMax.data('is-changed', true);
                    this.$inputMax.data('prev-value', maxPrevValue);
                    this.$inputMax.val(maxNewValue).trigger('change');
                }
            }
        });
    }

    setMinValue(value) {
        this.$slider.slider('values', 0, value);
    }

    setMaxValue(value) {
        this.$slider.slider('values', 1, value);
    }

    _initEvents() {
        this.event('change', this.config.selectors.inputMin, this.changeInputMin);
        this.event('change', this.config.selectors.inputMax, this.changeInputMax);
    }

    changeInputMin(element) {
        var $input = $(element);
        var value = $input.val();
        if (!value || value < this.config.realMin) {
            value = this.config.realMin;
            $input.val(value);
        }

        this.$inputMin.data('value', $input.val());
        this.$inputMin.data('is-changed', true);
        this.setMinValue($input.val());
    }

    changeInputMax(element) {
        var $input = $(element);
        var value = $input.val();
        if (!value || value > this.config.realMax) {
            value = this.config.realMax;
            $input.val(value);
        }
        this.$inputMax.data('value', $input.val());
        this.$inputMax.data('is-changed', true);
        this.setMaxValue($input.val());
    }
}

module.exports = PriceRange;
