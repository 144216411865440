/*
 * disableProgress
 * Adapted version of https://github.com/SadwickR/videojs-disable-ui
 */
var videojs = require('video.js');
module.exports = (function() {
    // define some reasonable defaults for this sweet plugin
    var defaults = {
        autoDisable: false
    };

    // plugin initializer
    function disableProgress(options) {
        var
            // save a reference to the player instance
            player = this,
            state = false,

            // merge options and defaults
            settings = Object.assign({}, defaults, options || {});

        // disable / enable methods
        player.disableProgress = {
            disable: function(){
                state = true;
                player.controlBar.progressControl.seekBar.off("mousedown");
                player.controlBar.progressControl.seekBar.off("mouseup");
                player.controlBar.progressControl.seekBar.off("touchstart");
                player.controlBar.progressControl.seekBar.off("touchend");
                player.controlBar.progressControl.seekBar.off("click");
            },
            enable: function(){
                state = false;
                player.controlBar.progressControl.seekBar.on("mousedown",  player.controlBar.progressControl.seekBar.handleMouseDown);
                player.controlBar.progressControl.seekBar.on("mouseup",  player.controlBar.progressControl.seekBar.handleMouseUp);
                player.controlBar.progressControl.seekBar.on("touchstart", player.controlBar.progressControl.seekBar.handleMouseDown);
                player.controlBar.progressControl.seekBar.on("touchend", player.controlBar.progressControl.seekBar.handleMouseUp);
                player.controlBar.progressControl.seekBar.on("click", player.controlBar.progressControl.seekBar.handleClick);
            },
            getState: function(){
                return state;
            }
        };

        if (settings.autoDisable) {
            player.disableProgress.disable();
        }
    };
  
    // register the plugin with video.js
    videojs.plugin('disableProgress', disableProgress);
  }());
  