import Component from '_core_ext/components/Component';
import ajax from '_core_ext/ajax';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';
import Promise from 'promise';

const emitter = eventMgr.getEmitter('timer');

const MINUTE_IN_MILLISECONDS = 60000;
const HOUR_IN_MILLISECONDS = 3600000;
const DAY_IN_MILLISECONDS = 86400000;

export default class Timer extends Component {
    static get selector() {
        return '.js-timer-container';
    }

    get configDataAttrName() {
        return 'component-timer';
    }

    init(...args) {
        super.init(args);
        this.startTimer();
    }

    startTimer() {
        var $timerContainter = this.$el,
            // support utilizing this component in Slick
            $slickOwner = $('[data-slick-container=' + $timerContainter.closest('[data-slick-owner]').data('slick-owner') + ']'),
            $timer = $timerContainter.find('.js-timer'),
            $replacement = $timer.data('replace-with'),
            now = Date.now(),
            till = new Date($timer.data('end-date-time')),
            timeDifference = till - now,
            twoDaysLeft,
            timerObject = getTimerObjectFromTimeDifference(timeDifference),
            interval = null;

        if (timeDifference <= 0) {
            replaceTimer();
            return;
        }

        // When 2 days left to start the main countdown
        new Promise(function (resolve) {
            twoDaysLeft = resolve;
        }).then(startCountdown);

        // Not starting the main timer untill 2 days left
        if (timerObject.days > 1) {
            var displayDaysLeft = timerObject.days + 1;
            $timer.html(displayDaysLeft + ' days');
            emitter.emit('updated', $timerContainter);

            // For days left switching while the main countdown is not started
            var daysInterval = null;
            setTimeout(function () {
                $timer.html(--displayDaysLeft + ' days');
                if (displayDaysLeft > 2) {
                    daysInterval = setInterval(function decrementDays() {
                        $timer.html(--displayDaysLeft + ' days');
                        if (displayDaysLeft === 2) {
                            clearInterval(daysInterval);
                            daysInterval = null;
                            twoDaysLeft();
                        }
                        return decrementDays;
                    }, DAY_IN_MILLISECONDS)
                } else {
                    twoDaysLeft();
                }
            }, timeDifference - timerObject.days * DAY_IN_MILLISECONDS); // time till the next day starts
        } else {
            // Resolving the promise because <2 days left
            twoDaysLeft();
            $timer.addClass('timer-hours');
        }

        function getTimerObjectFromTimeDifference(difference) {
            return {
                seconds: Math.floor(difference / 1000) % 60,
                minutes: Math.floor(difference / MINUTE_IN_MILLISECONDS) % 60,
                hours: Math.floor(difference / HOUR_IN_MILLISECONDS) % 24,
                days: Math.floor(difference / DAY_IN_MILLISECONDS)
            }
        }

        function startCountdown() {
            interval = setInterval(function () {
                var updatedTimeDifference = till - Date.now();
                if (updatedTimeDifference <= 0) {
                    clearInterval(interval);
                    interval = null;
                    replaceTimer();
                    return;
                }
                timerObject = getTimerObjectFromTimeDifference(updatedTimeDifference);
                $timer.html((timerObject.hours + 24 * timerObject.days).toLocaleString(undefined, {minimumIntegerDigits: 2}) + ':' + timerObject.minutes.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ':' + timerObject.seconds.toLocaleString(undefined, {minimumIntegerDigits: 2}));
                emitter.emit('updated', $timerContainter);
            }, 1000);
        }

        function replaceTimer() {
            if ($replacement) {
                ajax.load({url: util.appendParamToURL(Urls.contentAsset, 'cid', $replacement)}).done(function (response) {
                    if ($slickOwner.length) {
                        var $slickSlideContainter = $timerContainter.closest('.header-slide-wrapper').parent();
                        
                        $slickSlideContainter.find('.content-asset').replaceWith(response).data('slick-owner', $slickOwner.data('slick-container'));
                        
                        app.components.initComponent('Timer', $slickSlideContainter);
                        emitter.emit('replaced', $slickSlideContainter);
                    } else {
                        var $newTimerContainter = $(response);
                        $timerContainter.replaceWith($newTimerContainter);
                        app.components.initComponent('Timer', $newTimerContainter);
                    }
                });
            } else {
                $timerContainter.addClass('hidden');
                if ($slickOwner.length) {
                    emitter.emit('removed', $slickOwner);
                }
            }
        }
    }
}

module.exports = Timer;
