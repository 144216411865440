'use strict';
var util = require('_core_ext/util');
var isTouch = util.isTouch();

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
    $(document).tooltip({
        items: '.tooltip',
        track: false,
        tooltipClass: "tooltip-" + $(document).find('#wrapper').attr('class') + (isTouch ? ' is-touch' : ''),
        position: {
            my: "left bottom",
            at: "top",
            using: function(position, feedback) {
                $(this).css(position);
                $("<div>").addClass("tooltip-arrow").appendTo(this);
                $(this).addClass(feedback.vertical).addClass(feedback.horizontal);
            }
        },
        content: function () {
            return $(this).find('.tooltip-content').append('<button class="btn-close"></button>').html();
        }
    });

    // display some tooltips on touch devices dialog-like popups (centered and with logic: 1st click - show; 2nd click - follow link)
    if (isTouch) {
        $('.promotion .tooltip').on('click', function (e) {
            var $this = $(this);
            if (!$this.data('once-clicked')) {
                $this.data('once-clicked',true);
                e.preventDefault();
                $this.find('.tooltip-content').remove(); // it's hack for disabling tooltip show next time - it should just follow the link)
            }
        });
    }

    $('.share-link').on('click', function (e) {
        e.preventDefault();
        var target = $(this).data('target');
        if (!target) {
            return;
        }
        $(target).toggleClass('active');
    });
};
