/**
 * Salesfloor order tracking component.
 */

/* global sf_widget_events, sf_widget */

const util = require('_core_ext/util'),
    logger = util.log;

function trackOrder(orderData) {
    // Create structure to hold Transaction & Item(s)
    window.sf_widget_events = {
        events: [], 
        tracker: (eventName, data) => {
            sf_widget_events.events.push({eventName, data})
        }
    };
    // Create Transaction
    sf_widget_events.tracker('ecommerce:addTransaction', orderData.details);
    
    // Add items
    orderData.items.forEach((item) => {
        sf_widget_events.tracker('ecommerce:addItem', 
            $.extend({}, item, {trx_id: orderData.details.trx_id})
        );
    });
    
    // Trigger the Sales Tracking event to Salesfloor
    try {
        /* eslint no-unused-expressions: "off" */
        sf_widget.widgets && sf_widget.widgets.eventstracker &&
            sf_widget.widgets.eventstracker.load();
    } catch (err) {
        logger.warn('Error sending Sales Tracking event to Salesfloor, details: ', err);
    }
}

function updateOrder(data) {
    if (data) {
        $.post(pageContext.sfOrderData.updateOrderURL, {
            id: pageContext.sfOrderData.id,
            token: pageContext.sfOrderData.token,
            employee_id: data.employee_id,
            retailer_store_id: data.retailer_store_id
        });
    }
}

module.exports = {
    init: function () {
        const sfOrderData = window.pageContext && pageContext.sfOrderData;
        if (!sfOrderData) {
            return;
        }

        // Salesfloor script with sf_widget variable is loaded via GTM and so later than our code,
        // so we delay our code until it appears
        const TEN_SECONDS = 10000;
        // wait until sf_widget variable appears - means SF script loaded
        util.wait(() => window.sf_widget, 500, TEN_SECONDS)
        .then(() => {
            trackOrder(sfOrderData);

            // then get employee data
            return window.sf_widget.utils.getTrackingEmployeeObjectAsPromise();
        })
        .then(updateOrder)
        .catch((e) => {
            if (e) {
                logger.warn('Error occured during Salesfloor order tracking: ', e);
                return;
            }
            logger.log('Salesfloor sf_widget not loaded/initialized in 10 seconds - stop waiting.');
        });
    }
};
