import Component from '_core_ext/components/Component';
import dialog from '_core_ext/dialog';
import eventMgr from '_core_ext/eventMgr';

export default class ProductHotspot extends Component {
    static get selector() {
        return '.js-hotspot';
    }
    get configDefault() {
        return {
            dialog: {
                dialogClass: 'howtowear_map-dialog howtowear_map'
            },
            contentSelector: '.js-howtowear_map',
            innerComponents: [
                'ProductSlickCarousel',
                'ProductTile',
                'ProductInfo'
            ]
        };
    }
    get configDataAttrName() {
        return 'hotspot-options';
    }
    /**
     * Method called when creating component
     * 
     * @constructor
     * @param {array} args {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_operator}
     */
    init(...args) {
        super.init(...args);
        this.event('click', (element, event) => {
            event.preventDefault();
            dialog.open({
                html: this._getContent(),
                options: this.config.dialog,
                callback: this._onDialogOpen.bind(this, dialog)
            });
            return false;
        });
        eventMgr.on('addToCart.product.added', () => {
            dialog.close();
        });
    }

    _getContent() {
        return $(this.config.contentSelector).clone().removeClass('howtowear_map-desktop').addClass('js-slick-product-carousel')[0];
    }

    _onDialogOpen(dialog) {
        const $dialog = dialog.getCurrent();
        
        this.config.innerComponents.forEach((component) => {
            app.components.initComponent(component, $dialog);
        });
        
        $dialog.find('.js-slick-product-carousel').slick('slickGoTo', this.$el.data('index'), true);
    }
}

module.exports = ProductHotspot;
