/**
 * Gift Cards block on payment step
 */
import Component from '_core_ext/components/Component';
import ajax from '_core_ext/ajax';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('GiftCardsBlock');
const blockSelector = '.js-gift-cards-block';

export default class GiftCardsBlock extends Component {
    static get selector() {
        return blockSelector;
    }

    init(...args) {
        super.init(...args);
        this.initEvents();

        this.onCheckBalance = this.onCheckBalance.bind(this);
        this.onCheckAnotherCard = this.onCheckAnotherCard.bind(this);
        this.onRedeemBalance = this.onRedeemBalance.bind(this);
        this.onRemoveRedemption = this.onRemoveRedemtpion.bind(this);
        this.onEnterPressed = this.onEnterPressed.bind(this);
    }

    initEvents() {
        this.event('keypress', '.js-input-giftnumber', this.onEnterPressed.bind(null, this.onCheckBalance));
        this.event('click', '.js-gift-card-check', this.onCheckBalance);
        this.event('click', '.js-gift-card-check-another', this.onCheckAnotherCard);
        this.event('click', '.js-gift-card-redeem', this.onRedeemBalance);
        this.event('keypress', '.js-input-amount', this.onEnterPressed.bind(null, this.onRedeemBalance));
        this.event('click', '.js-remove-redemption', this.onRemoveRedemtpion);
    }

    reInitComponents() {
        app.components.initComponent('RedesignInput', this.$el);
    }

    onCheckBalance() {
        const $fieldset = this.$el.find('.js-card-number-fieldset');
        if (!this.validateGiftCard($fieldset)) {
            return;
        }

        ajax.load({
            url: this.config.checkUrl,
            target: blockSelector,
            data: this.$el.find('input').serialize(),
            callback: () => {
                this.reInitComponents();
            }
        });
    }

    onCheckAnotherCard() {
        ajax.load({
            url: this.config.checkAnotherUrl,
            target: blockSelector,
            callback: () => {
                this.reInitComponents();
            }
        });
    }

    onRedeemBalance() {
        const $fieldset = this.$el.find('.js-giftcard-amount-fieldset');
        if (!this.validateAmount($fieldset)) {
            return;
        }

        ajax.load({
            url: this.config.redeemUrl,
            target: blockSelector,
            data: this.$el.find('input').serialize(),
            callback: () => {
                this.reInitComponents();
                if (this.$el.find('.js-redeemed-text').length) {
                    emitter.emit('redeemed');
                }
            }
        });
    }

    onEnterPressed(cb, input, event) {
        //If enter is pressed
        if (event.which === 13) {
            cb.bind(this)();
            return false;
        }
    }

    onRemoveRedemtpion(link, event) {
        event.preventDefault();

        ajax.load({
            url: link.href,
            target: blockSelector,
            callback: () => {
                this.reInitComponents();
                if (this.$el.find('.js-gift-card-removed').length) {
                    emitter.emit('removed');
                }
            }
        });
    }

    /**
    * Have to apply validation rules just for the moment of submitting data,
    * as form is inline, and when expanded but not filled - will prevent entire Payment page submission
    * @param {jQuery} fieldset
    * @returns {boolean}
    */
    validateGiftCard(fieldset) {
        var $cardnumber = fieldset.find('#cardnumber');
        $cardnumber.rules('add', {
            required: true,
            digits: true
        });
        var result = $cardnumber.valid();
        $cardnumber.rules('remove', 'required digits');

        return result;
    }

    /**
    * Have to apply validation rules just for the moment of submitting data,
    * as form is inline, and when expanded but not filled - will prevent entire Payment page submission
    * @param {jQuery} fieldset
    * @returns {boolean}
    */
    validateAmount(fieldset) {
        var $amount = fieldset.find('#amount');
        $amount.rules('add', {
            required: true,
            'js-input-money': true,
            min: +$amount.data('min'),
            max: +$amount.data('max')
        });
        var result = $amount.valid();
        $amount.rules('remove', 'required min max js-input-money');

        return result;
    }
}

module.exports = GiftCardsBlock;
