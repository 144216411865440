/**
 * Exports single method - onClick to have ability to override default Click handler of ApplePay button generated by SFCC built-in script.
 * Custom handler used as a check if built-in handler should be executed or not, 
 * so it should return value *true* or *Promise* resolved with value *true* to pass execution further.
 * Called once it will attach handler to either button already existing on page or created in future.
 * 
 * Optional 'Attached' callback can passed as second parameter, will be called at the moment when click handler attached to button. 
 * 
 * Sample usage:
 * 
 *  const apOnClick = require('applepay.onclick');
 *  
 *  apOnClick('js-my-applepay-btn-css-class', function(event) {
 *      if (<canProceedWithApplePay>) {
 *          return true;
 *      }
 *      return false;
 *  }, function (apBtn) {
 *      console.log('ApplePay button click handler attached to #' + apBtn.id);
 *  });
 *  
 *  or:
 *  
 *  apOnClick('js-my-applepay-btn-css-class', function(event) {
 *      return Promise.resolve(<true/false>);   // to continue with AP sheet after some Async checks
 *  });
 */
var handlersMap = {},
    observerStarted = false,
    CLASS_PROCESSED = 'js-custom-click-attached';

/**
 * Check if ApplePay enabled on site and Device supports it (same as SFCC script does).
 * @returns {boolean}
 */
function isAPSession() {
    if (window.dw &&
            window.dw.applepay &&
            window.ApplePaySession &&
            window.ApplePaySession.canMakePayments()) {
        return true;
    }
    return false;
}

/**
 * Override onclick handler added by DW script by ours, which will call DW one depending on passed handler's execution result.
 * @param {Element} btn Button to add custom handler to
 * @param {Object} handlers Hash of 2 functions: 'click' handler, must return either true, to pass execution further, or return a Promise which will resolve with true value for the same;
 *     'attached' - optional, called at the moment when 'click' handler attached to button, button passed as an argument.
 */
function enhanceAPClick(btn, handlers) {
    var origOnClick = btn.onclick;
    btn.onclick = function(ev) {
        var result = handlers.click.call(btn, ev);
        // execute built-in handler immediately if true returned
        if (result === true) {
            origOnClick.call(btn, ev);

        } else if (result && ('then' in result)) {
            // execute built-in handler when returned Promise resolved with true 
            result.then(function(res) {
                if (res === true) {
                    origOnClick.call(btn, ev);
                }
            })
        }
        return false;
    };
    handlers.attached(btn);
}

/**
 * Add custom handlers to passed buttons and mark them as processed
 * @param {NodeList|Array} buttons
 */
function processElements(buttons) {
    var selectors = Object.keys(handlersMap);
    for (var i = 0; i < buttons.length; i++) {
        for (var j = 0; j < selectors.length; j++) {
            if (buttons[i].matches(selectors[j])) {
                enhanceAPClick(buttons[i], handlersMap[selectors[j]]);
                buttons[i].classList.add(CLASS_PROCESSED);
                break;
            }
        }
    }
}

/**
 * Finds not processed and processes AP buttons of passed target element
 * @param {Element} target
 */
function handleChildrenOf(target) {
    var selector = Object.keys(handlersMap).join(',');
    var buttons = target.querySelectorAll(selector);
    processElements(buttons);
}

/**
 * DW script replaces <isapplepay> tags with <button>s client-side, so we catch created elements and process them.
 * This function creates mutation observer, should be called once after page loaded.
 */
function startObserving() {
    function observerCb(mutations) {
        for (let i = 0; i < mutations.length; i++) {
            if (mutations[i].type === 'childList' && mutations[i].addedNodes.length > 0) {
                handleChildrenOf(mutations[i].target);
            }
        }
    }
    new MutationObserver(observerCb).observe(document.body, {
        childList: true, 
        subtree: true, 
        attributes: false
    });
}

function process() {
    if (!observerStarted) {
        startObserving();
        observerStarted = true;
    }
    // to process those which could be created before our code executed
    handleChildrenOf(document.body);
}

function pushHandler(className, handler) {
    var selector = `button.${className}:not(.${CLASS_PROCESSED})`;  // match not-processed buttons only
    handlersMap[selector] = handler;
}

/**
 * 
 * @param {String} className To identify AP button
 * @param {Function} handler Click handler 
 * @param {[Function]} attachedHandler Optional, callback to be called when click handler attached. 
 * @returns
 */
function addClickHandler(className, handler, attachedHandler) {
    // do nothing if ApplePay not enabled or non-AP device
    if (!isAPSession()) {
        return;
    }
    
    pushHandler(className, {
        'click': handler,
        'attached': attachedHandler || function() {}
    });
    process();
}

module.exports = addClickHandler;
