'use strict';

module.exports = {

    /**
     * @function setCookie
     *
     * @param {string} key - The cookie name
     * @param {string} value - The cookie value
     * @param {integer} minutes - The number of minutes to expire the cookie
     * @param {string} path - Path
     * @param {string} domain - Domain
     **/
    setCookie: function (key, value, minutes, path, domain) {
        var exDate = new Date();
        exDate.setTime(exDate.getTime() + (minutes * 60 * 1000));

        value = encodeURIComponent(value) +
            ((minutes === null) ? '' : '; expires=' + exDate.toGMTString()) +
            ((typeof(domain) !== 'undefined') ? ';domain=' + (domain || this.getWildcardDomain()) : '') +
            ';path=' + (typeof(path) !== 'undefined' && path ? path : '/');

        document.cookie = key + '=' + value;
    },

    /**
     * @function getCookie
     *
     * @param {string} key - The cookie name
     * @returns {string} value - the value of the cookie if found, null otherwise
     **/
    getCookie: function(key) {
        var i, x, y, cookies = document.cookie.split(';');
        for (i = 0; i < cookies.length; i++) {
            x = cookies[i].substr(0, cookies[i].indexOf('='));
            y = cookies[i].substr(cookies[i].indexOf('=') + 1);
            x = x.replace(/^\s+|\s+$/g, '');
            if (x === key) {
                return decodeURIComponent(y);
            }
        }
        return null;
    },

    /**
     * @function removeCookie
     *
     * @param {string} key - The cookie name
     * @param {string} domain - Domain
     **/
    removeCookie: function(key, domain) {
        this.setCookie(key, '', -1, domain);
    },

    /**
     * @function getWildcardDomain
     *
     * @param {string} host - host
     * @returns {string} domain - Domain
     **/
    getWildcardDomain: function(host) {
        var domain = host || window.location.host,
            domParts = domain.split('.'),
            cookieDomain = '';

        if (domParts[domParts.length - 2].length <= 3) {
            cookieDomain = '.' + domParts[domParts.length - 3] +
                    '.' + domParts[domParts.length - 2] + '.' +
                    domParts[domParts.length - 1];
        } else {
            cookieDomain = '.' + domParts[domParts.length - 2] +
                '.' + domParts[domParts.length - 1];
        }
        return cookieDomain;
    }
};