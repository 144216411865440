import Slick from '_core_ext/components/global/slick';

export default class ProductSlickCarousel extends Slick {
    static get selector() {
        return '.js-slick-product-carousel';
    }
    get configDefault() {
        // see Slick slider for full list of options
        return {
            'slidesToShow': 4,
            'infinite': false,
            'autoplay': false,
            'fade': false,
            'cssEase': 'ease-in-out',
            'responsive': [{
                'breakpoint': 768,
                'settings': {
                    'slidesToShow': 2
                }
            }]
        };
    }
    get configDataAttrName() {
        return 'carousel';
    }
}

module.exports = ProductSlickCarousel;
