/**
 * @function
 * @description Initializes the Back to Top button
 */
exports.init = function () {
    var effectSpeed = 500,
        appearenceHeight = 230,
        button = this.createButton();

    button.on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, effectSpeed);
    });

    $(window).on('scroll', function() {
        if ($(this).scrollTop() > appearenceHeight) {
            button.fadeIn(effectSpeed)
        } else {
            button.fadeOut(effectSpeed)
        }
    });
};

exports.createButton = function () {
    var button = $('<div/>',{
        'id': 'scrollToTop',
        'class': 'scroll-to-top bticons-up',
        'text': ''
    });

    $('body').append(button);
    return button;
};
