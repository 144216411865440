/**
 * Regular expressions used on both frontend and backend code for data validation.
 */
module.exports = {
    latinChars: /^[\u0020-\u0021\u0023-\u007E\u00A0-\u00FF]*$/, // Basic Lating and Latin-1 Supplement blocks, excluding " (double quote \u0022) as accepted by OC
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    money: /^\d{0,4}(\.\d{0,2})?$/,
    password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])\S{5,}$/,
    email: /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
    numbers: /^[\d]/,
    loyaltynumber: /^(916299[0-9]{13}|60332[0-9]{11,14})$/
};
