var bonusProductsView = require('_core_ext/bonus-products-view'),
    progress = require('_core_ext/progress'),
    ajax = require('_core_ext/ajax'),
    page = require('_core_ext/page'),
    eventMgr = require('_core_ext/eventMgr'),
    cartEmitter = eventMgr.getEmitter('cart');

/**
 * Called when Qty just changed.
 * Show loader for while availability fetched and cart updated.
 */
function onQuantityChange() {
    progress.show('.primary-content', true);
}

/**
 * Handle custom event when availability fetched upon quantity change and update cart.
 * @param {jQuery.Event} e
 * @param {Object} response Availability response
 */
function onAvailabilityFetched(e, response) {
    const $qty = $(this);
    const $form = $qty.closest('form');
    const $updateTrigger = $form.find('[data-name$=_updateCart]');

    if ($qty.valid() && (response.status === 'ZERO_QTY' || response.isAvailable)) {
        $updateTrigger.attr('name', $updateTrigger.attr('data-name'));  // to trigger Update action on server
        submitCart(
            $form.attr('action'), 
            $form.serialize()
        );
    } else {
        progress.hide();
    }
}

/**
 * Handle Remove item button.
 * @param {jQuery.Event} e
 */
function onDeleteProduct(e) {
    e.preventDefault();
    progress.show();
    const $deleteBtn = $(this);
    const $form = $deleteBtn.closest('form');
    
    // Qubit integration
    var data = {
        'pid': $deleteBtn.closest('.cart-row').find('.sku .value').text(),
        'Quantity': $deleteBtn.closest('.cart-row').find('.item-quantity input[name$="quantity"]').val(),
        'action': 'remove'
    };
    $('body').trigger('ecBasketItemAction', [data, 'remove']);

    // remove item
    submitCart(
        $form.attr('action'),
        $form.serialize() + '&' + $deleteBtn.attr('name') + '=' + $deleteBtn.attr('value')
    );
}

/**
 * Ajax submit cart, refresh cart content and minicart
 * @param {String} url 
 * @param {String} data Serialized (URL-encoded) cart data to submit
 * @returns {jqXHR}
 */
function submitCart(url, data) {
    return ajax.load({
        url: url,
        method: 'POST',
        data: data,
        target: '#primary'
    }).then(function() {
        // Re-initialize cart components
        const $container = $('#primary');
        app.components.initComponent('validator', $container);
        app.components.initComponent('availability');
        app.components.initComponent('ProductTile', $container);
        app.components.initComponent('ProductCarousel', $container);
        app.components.initComponent('sidebarScroll', $container);
        app.components.initComponent('input');
        app.components.initComponent('RedesignInput');
        app.components.initComponent('AddToWishlist', $container);
        app.components.initComponent('CouponCode', $container);
        cartEmitter.emit('reloaded');
        initDirectEvents($container);
        // Update minicart (loader will be hidden already, but that's ok)
        app.components.get('minicart').load();
    });
}

function refreshCart() {
    page.redirect(Urls.cartShow);
}

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions)
 */
function initializeEvents() {
    var $document = $(document);

    $document.on('availability.fetched', '.js-cartitem-quantity-wrapper .js-quantity', onAvailabilityFetched);
    $document.on('change', '.js-cartitem-quantity-wrapper .js-quantity', onQuantityChange);
    $document.on('click', '[name$=_deleteProduct]', onDeleteProduct);

    // refresh page when Coupon code applied/removed
    eventMgr.on('Coupon.applied', refreshCart);
    eventMgr.on('Coupon.removed', refreshCart);
}

/**
 * Initialize non-delegated events, which should be re-attached when cart content reloaded.
 * @param {jQuery} [$container=$(document)] 
 */
function initDirectEvents($container) {
    $container = $container || $(document);

    $container.find('.js-choose-bonus-products-dialog-show').on('click', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    });

    // display bonus choice notification, only when JS initialized
    $container.find('.js-cart-bonus-choosing-notification-wrapper').addClass('active');
}

exports.init = function () {
    initializeEvents();

    initDirectEvents();
};

