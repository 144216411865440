var ajax = require('_core_ext/ajax'),
    util = require('_core_ext/util'),
    _isString = require('lodash/lang/isString'),
    imagesLoaded = require('imagesloaded'),
    eventMgr = require('_core_ext/eventMgr');

const dialogEmitter = eventMgr.getEmitter('dialog');

var dialog = {
    /**
     * When this constant passed to options.title in combination with options.url, 
     * dialog title will be taken from 'data-asset-name' attribute of loaded content.
     */
    TITLE_FROM_ASSET_NAME: '_GET_TITLE_FROM_ASSET_NAME_',

    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    create: function (params) {
        var $target, id = 'dialog-container';

        if (_isString(params.target)) {
            if (params.target.charAt(0) === '#') {
                $target = $(params.target);
                id = params.target.substr(1);
            } else {
                $target = $('#' + params.target);
                id = params.target;
            }
        } else if (params.target instanceof jQuery) {
            $target = params.target;
        } else {
            $target = $('#dialog-container');
        }

        // if no element found, create one
        if ($target.length === 0) {
            $target = $('<div>').attr('id', id).addClass('dialog-content').appendTo('body');
        }

        // create the dialog
        this.$container = $target;
        this.$container.dialog($.extend(true, {}, this.settings, params.options || {}));

        if (!this.globalEventsInitialized) {
            $(document.body).on('click', '.ui-widget-overlay', () => {
                this.close();
            });
            this.globalEventsInitialized = true;
        }
    },

    /**
     * @function
     * @description Opens a dialog using the given url (params.url) or html (params.html)
     * @param {Object} params
     * @param {Object} params.url should contain the url
     * @param {String} params.html contains the html of the dialog content
     */
    open: function (params) {
        // close any open dialog
        this.close();
        this.create(params);
        this.replace(params);
        dialogEmitter.emit('opened');
    },
    /**
     * @description populate the dialog with html content, then open it
     * @param {object} params
     * @param {string} params.url - If the url property is provided, an ajax call is performed to get the content to replace
     * @param {string} params.html - If no url property is provided, use html provided to replace
     */
    openWithContent: function (params) {
        var buttons = [],
            content,
            position,
            callback;

        if (!this.$container) { return; }
        content = params.content || params.html;
        if (!content) { return; }
        this.$container.empty().html(content);
        if (!this.$container.dialog('isOpen')) {
            this.$container.dialog('open');
        }
        if (params.options) {
            if (params.options.position) {
                position = params.options.position;
            }
            if (params.options.buttons) {
                buttons = params.options.buttons;
            }
        }
        if (!position) {
            position = this.settings.position;
        }

        imagesLoaded(this.$container).on('done', () => {
            this.$container.dialog('option', 'position', position);
        });

        this.$container.dialog('option', 'buttons', buttons);

        callback = (typeof params.callback === 'function') ? params.callback : function () {};
        callback();

        this.$container.dialog('option', 'position', position);
        this.$container.dialog('option', 'title', params.options.title);
    },
    /**
     * @description Replace the content of current dialog
     * @param {object} params
     * @param {string} params.url - If the url property is provided, an ajax call is performed to get the content to replace
     * @param {string} params.html - If no url property is provided, use html provided to replace
     */
    replace: function (params) {
        if (!this.$container) {
            return;
        }
        if (params.url) {
            params.url = util.appendParamToURL(params.url, 'format', 'ajax');
            var replaceDialog = ajax.load({
                url: params.url,
                data: params.data,
                callback: function (response) {
                    params.content = response;

                    // when this special value passed as a title - take it from data attribute of loaded content
                    if (params.options && params.options.title === this.TITLE_FROM_ASSET_NAME) {
                        params.options.title = $(response).find('[data-asset-name]')
                            .addBack('[data-asset-name]')   // to include top-level nodes into search
                            .attr('data-asset-name');
                    }
                    
                    this.openWithContent(params);
                }.bind(this)
            });

            return replaceDialog;
        } else if ('undefined' !== typeof params.html) {
            this.openWithContent(params);
        }
    },
    /**
     * @function
     * @description Closes the dialog
     */
    close: function () {
        if (!this.$container) {
            return;
        }
        this.$container.dialog('close');
    },
    /**
     * @function
     * @description Submits the dialog form with the given action
     * @param {String} The action which will be triggered upon form submit
     */
    submit: function (action) {
        var $form = this.$container.find('form:first');
        // set the action
        $('<input/>').attr({
            name: action,
            type: 'hidden'
        }).appendTo($form);
        // serialize the form and get the post url
        var data = $form.serialize();
        
        //remove carriage return symbol for correct BE range validation
        data = data.replace(/\%0D/g, '');
        
        var url = $form.attr('action');
        // make sure the server knows this is an ajax request
        if (data.indexOf('ajax') === -1) {
            data += '&format=ajax';
        }
        // post the data and replace current content with response content
        return $.ajax({
            type: 'POST',
            url: url,
            data: data,
            dataType: 'html',
            success: function (html) {
                this.$container.html(html);
            }.bind(this),
            failure: function () {
                window.alert(Resources.SERVER_ERROR);
            }
        });
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    },
    isActive: function () {
        return this.exists() && (this.$container.children.length > 0);
    },
    restore: function ($oldInstance) {
        this.close();
        this.$container = $oldInstance;
        if (!this.$container.dialog('isOpen')) {
            this.$container.dialog('open');
        }
    },
    getCurrent: function () {
        return this.$container;
    },
    isOpen: function ($instance) {
        if ($instance) {
            return $instance.dialog('isOpen');
        }
        return this.$container && this.$container.dialog('isOpen');
    },

    recalculatePosition: function() {
        if (this.isOpen()) {
            var position = this.$container.dialog('option', 'position');
            this.$container.dialog('option', 'position', position);
        }
    },

    destroy: function() {
        if (!this.$container) {
            return;
        }
        this.$container.dialog('destroy');
        this.$container.remove();
        this.$container = null;
    },

    settings: {
        autoOpen: false,
        height: 'auto',
        modal: true,
        overlay: {
            opacity: 0.5,
            background: 'black'
        },
        resizable: false,
        title: '',
        width: '800',
        beforeClose: function () {
            // this called when dialog was actually shown, and closed then
            dialogEmitter.emit('closed');
        },
        position: {
            my: 'center',
            at: 'center',
            of: window,
            collision: 'flipfit',
            using: function() {
                var $this = $(this);
                var $wrapper = $('#wrapper');
                var $window = $(window);

                var verticalOffset = ($window.height() - $this.height()) / 2;
                if (verticalOffset < 0) {
                    verticalOffset = 0;
                }

                var horizontalOffset = ($window.width() - $this.width()) / 2;
                if (horizontalOffset < 0) {
                    horizontalOffset = 0;
                }

                if (!($wrapper.length && $wrapper.css('position') === 'fixed')) {
                    verticalOffset += $window.scrollTop();
                }

                var position = {
                    top: (verticalOffset < 0 ? 0 : (verticalOffset >> 0)) + 'px',
                    left: (horizontalOffset < 0 ? 0 : (horizontalOffset >> 0)) + 'px'
                };

                $this.css(position);
            }
        }
    }
};

module.exports = dialog;
