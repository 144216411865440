var ajax = require('_core_ext/ajax'),
    progress = require('_core_ext/progress'),
    util = require('_core_ext/util'),
    eventMgr = require('_core_ext/eventMgr'),
    validator = require('_core_ext/components/global/validator'),
    summary = require('_core_ext/pages/checkout/summary'),
    dialog = require('_core_ext/dialog'),
    _debounce = require('lodash/function/debounce'),
    _isEqual = require('lodash/lang/isEqual'),
    _once = require('lodash/function/once');

/**
 * This module serves as Mediator for Single shipping page.
 * It wires interaction between ShippingAddress component and Shipping method switcher (also updates minisummary if Shipping method selector present)
 * and controls state of the "Proceed" CTA and other additional elements depending on the state of the above.
 * BillingAddress and Shipping method switcher are optional to present.
 * It must not access directly anything that is inside of shipping/billing address components, but use only component's public methods and events.
 */

var shippingMethods,
    shippingAddressCmp,
    shippingAddress,
    $form,
    $shippingMethodList,
    // on first Delivery step shipping method selector will be rendered hidden as a helper to show Error message in form when delivery to selected country is not possible (no ship methods returned),
    // and so we don't need to do anything beside updating list (no implicit selection, no summary update)
    shippingMethodsFetchOnly = false,
    $ageCheck,
    $continueBtn;

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    const params = shippingAddress || {};
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 * @returns {jqXHR|Promise} Promise resolved with list of fetched shipping methods,
 *  or rejected if methods not changed or there is no shipping method selector on page.
 */
function updateShippingMethodList() {
    if (!$shippingMethodList || $shippingMethodList.length === 0) {
        // no shipping method selector on page, so nothing to do
        return $.Deferred().reject().promise();
    }
    var url = getShippingMethodURL(Urls.shippingMethodsJSON);

    return ajax.getJson({
        url: url
    }).then(function(data) {
        if (!data) {
            window.alert('Couldn\'t get list of applicable shipping methods.'); //NOSONAR
            return $.Deferred().reject().promise();
        }
        if (shippingMethods && shippingMethods.toString() === data.toString()) {
            // No need to update the UI.  The list has not changed.
            return $.Deferred().reject().promise();
        }

        // We need to update the UI.  The list has changed.
        // Cache the array of returned shipping methods.
        shippingMethods = data;

        // load the shipping methods html for the case if there is no methods - take error message out of it
        var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
        
        return ajax.load({
            url: smlUrl,
            target: $shippingMethodList
        });
    }).then(function(response) {
        // update the summary in parallel
        summary.update();

        return response;
    });
}

/**
 * Performs check of page components (ShippingAddress, BillingAddress and Shipping methods) to enable or disable Proceed button.
 */
function updateContinueBtn() {
    let disable = false;
    // disable proceed if shipping method selector present but no shipping methods
    // and when we're on Address step (hidden methods selector, shippingMethodsFetchOnly is true) - there is also a warning to show in form
    // (it would be ideal to transform Shipping Method selector into component as well)
    if ($shippingMethodList.length &&
        ((shippingMethodsFetchOnly && $shippingMethodList.find('.js-no-methods-form-warn').length) ||
        (!shippingMethodsFetchOnly && !$shippingMethodList.find('[name$="_shippingMethodID"]').length))) {
        disable = true;
    }

    // Left here as a sample of how Address validity can be checked from outside of component. 
//    if (!disable && shippingAddressCmp && !shippingAddressCmp.isValid()) {
//        disable = true;
//    }

    $continueBtn.prop('disabled', disable);
}

// Show loader at once only on page load,
// next times it should be shown only when the list of methods UI updated.
const progressShowOnce = _once(progress.show);

// Used to force user switch locale upon Shipping country change, not needed anymore. Left for reference.
//const beforeShippingAddressUpdated = (el, ev) => {
//    var locale = window.pageContext.localeData;
//    if (!locale.isMultiLocale) {
//        return;
//    }
//
//    const $el = $(el);
//    if (!$el.hasClass('js-input-country')) {
//        return;
//    }
//
//    if ($el.val() !== locale.shippingCountry) {
//        ev.preventDefault();    // this will force to revert selection in Address component
//
//        var prevDialog;
//        if (dialog.isOpen()) {
//            prevDialog = dialog.getCurrent();
//        }
//
//        dialog.open({
//            url: Urls.internationalDeliveryDialog,
//            options: {
//                title: dialog.TITLE_FROM_ASSET_NAME,
//                closeOnEscape: false,
//                dialogClass: 'int-delivery-dialog',
//                close: () => {
//                    if (prevDialog) {
//                        dialog.restore(prevDialog);
//                    }
//                }
//            }
//        });
//    }
//};
//
//const beforeDialogShippingAddressUpdated = (el, ev, {addressComponent}) => {
//    // to react on editing ShippingAddress only
//    if (addressComponent === 'ShippingAddress') {
//        beforeShippingAddressUpdated(el, ev);
//    }
//};

/**
 * Handle shipping address update event. Debounced to not trigger multiple times in same tick
 * when registered user switches address and so event fired for each updated element.
 */
const onShippingAddressUpdated = _debounce(() => {
    // check if location part of address changed, if not - no need to update shipping methods
    const udpatedShippingAddress = shippingAddressCmp.getLocationAddress();
    if (_isEqual(shippingAddress, udpatedShippingAddress)) {
        updateContinueBtn();
        return;
    }
    // store updated shipping location
    shippingAddress = udpatedShippingAddress;

    progressShowOnce($shippingMethodList);
    
    updateShippingMethodList().then((shippingMethodsResp) => {
        const methods = $(shippingMethodsResp);
        // show warning message in form if it was returned with SM response and hide fields after country
        const formWarning = methods.find('.js-no-methods-form-warn');
        if (formWarning.length) {
            shippingAddressCmp.showWarning(formWarning);
            $form.addClass('hide-form-part');   // this will hide part of form elements
        } else {
            shippingAddressCmp.hideWarning();
            $form.removeClass('hide-form-part');
        }
    }).always(() => {
        updateContinueBtn();
        progress.hide();
    });
}, 0);

/**
 * Handle address components
 */
function registerAddressComponents() {
    // store links to active components - will be fired upon component init-n, so before other events from them
    eventMgr.on('ShippingAddress.attached', (cmpInstance) => {
        shippingAddressCmp = cmpInstance;
    });

    // This was needed to force user switch locale upon Shipping country change, not needed anymore. Left for reference.
//    eventMgr.on('ShippingAddress.beforeUpdate', beforeShippingAddressUpdated);
//    eventMgr.on('DialogAddress.beforeUpdate', beforeDialogShippingAddressUpdated);

    eventMgr.on('ShippingAddress.updated', onShippingAddressUpdated);
}

function handleShippingPage() {
    // extend default validator settings to keep right error placing
    var defaultInvalidHandler = validator.settings.invalidHandler;
    $.extend(validator.settings, {
        invalidHandler: (e, validator) => { 
            defaultInvalidHandler(e, validator);

            //check if error present
            if (!validator.numberOfInvalids()) {
                return;
            }
        },
        submitHandler: (form, e) => {
            // Is form already submitted
            if ($form.data('isSubmiting') === true) {
                e.preventDefault();
                return false;
            }
            if (!e.isDefaultPrevented()) {
                $form.find('button[type=submit]').addClass('inactive');
                $form.data('isSubmiting', true);
                form.submit();
            }
        }
    });

    $form.validate(validator.settings);
}

function initializeEvents() {
    $ageCheck.on('change', updateContinueBtn);

    eventMgr.on('GiftWrapOptions.refresh', () => {
        dialog.close();
        summary.update();
    });
}

exports.init = function () {
    $form = $('[id$="singleshipping_shippingAddress"]');
    $shippingMethodList = $('#shipping-method-list');
    shippingMethodsFetchOnly = !!$shippingMethodList.data('fetchOnly');
    $continueBtn = $('[name$="shippingAddress_save"]');
    $ageCheck = $('.js-input-confirmTerms[type="checkbox"]');

    // <page>.js initialized before components, so can start observing components here
    registerAddressComponents();

    initializeEvents();
    handleShippingPage();
};
