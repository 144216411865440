import Component from '_core_ext/components/Component';
import ajax from '_core_ext/ajax';
import util from '_core_ext/util';
import page from '_core_ext/page';
import eventMgr from '_core_ext/eventMgr';
const wishlistEmitter = eventMgr.getEmitter('wishlist');

/**
 * Component handling click on AddToWishlist icon.
 * In future here can be added logic for init-g the icon with correct state if item already in WL.
 */
export default class AddToWishlist extends Component {
    static get selector() {
        return '[data-action="wishlist"]';
    }

    init(...args) {
        //call parent init method with same args
        super.init(...args);

        this.event('click', this.handleClick);

        // delay to make sure that 'common.getWishlistItems' action is registered at that moment
        eventMgr.execute('components.initialized').then(this.initIconStatus.bind(this));
    }

    checkIcon() {
        this.$el.addClass('wishlist-checked');
        $(`${AddToWishlist.selector}[data-pid="${this.$el.attr('data-pid')}"]`).addClass('wishlist-checked'); // to also mark tiles cloned by carousel
    }

    handleClick(elem, event) {
        event.preventDefault();
        if (this.$el.hasClass('js-add-to-wishlist-disabled')) {
            return;
        }
        const url = util.appendParamToURL(this.$el.attr('href'), 'format', 'jsonp');
        ajax.getJson({
            url: url,
            dataType: 'jsonp',
            callback: (response) => {
                if (response) {
                    wishlistEmitter.emit('addToWishlist', this.$el);
                    if (response.isRedirect) {
                        page.redirect(response.target);
                    } else if (response.success) {
                        this.checkIcon();

                        var successText = this.$el.data('successText');

                        if (successText) {
                            this.$el.text(successText);
                        }
                        eventMgr.execute('common.updateWishlistCounter', {count : response.count});
                    }
                }
            }
        });
        return false;
    }

    initIconStatus() {
        eventMgr.execute('common.getWishlistItems').then((items) => {
            items = items || [];
            if (items.indexOf(this.$el.attr('data-pid')) > -1) {
                this.checkIcon();
            }
        });
    }
}

module.exports = AddToWishlist;
