module.exports = {
    init: function () {
        $(document).on('click', '.js-hide-show-password', function(event) {
            event.preventDefault();
            var $this = $(this),
                $input  = $this.closest('.form-row').find('input'),
                inputType = $input.attr("type");
            if (inputType === 'text' ) {
                $input.attr('type','password');
                $this.removeClass('hide-password').addClass('show-password').text(Resources.SHOW);
            } else {
                $input.attr('type','text');
                $this.removeClass('show-password').addClass('hide-password').text(Resources.HIDE);
            }
            $input.focus();
        });
    }
};