import Slick from '_core_ext/components/global/slick';

export default class TimeslotSlick extends Slick {
    get configDefault() {
        // see Slick slider for full list of options
        return {
            'fade': false,
            'autoplay': false,
            'slidesToShow': 7,
            'slidesToScroll': 7,
            'arrows': true,
            'infinite': false,
            'prevArrow': $('.slick-prev'),
            'nextArrow': $('.slick-next'),
            'responsive': [{
                'breakpoint': 1023,
                'settings': {
                    'slidesToShow': 5,
                    'slidesToScroll': 5
                }
            }]
        };
    }

    init(...args) {
        //fallback, for direct initialization
        if (!('slick' in $.fn)) {
            return false;
        }
        // call initEvents before initializing
        this.initEvents();

        //call parent init method with same args
        super.init(...args);
    }

    initEvents() {
        this.$el.on('init', (event, slick) => {
            this.setDateslotRange(slick);
        });

        this.$el.on('afterChange', (event, slick) => {
            this.setDateslotRange(slick);
        });
    }

    setDateslotRange(slick) {
        const $activeSliders = slick.$slider.find('.slick-active');
        const firstDate = $activeSliders.first().find('.js-checkout_dateslot').data('date').replaceAll('-', '/');
        const lastDate = $activeSliders.last().find('.js-checkout_dateslot').data('date').replaceAll('-', '/');
        $('.js-dateslot-range').html(`${firstDate} - ${lastDate}`);
    }
}

module.exports = TimeslotSlick;
