var dialog = require('_core_ext/dialog');
var cookies = require('_core_ext/utils/cookies');
var page = require('_core_ext/page');
var util = require('_core_ext/util');


var countrySelector = {

    openPopUp (preselectLocale) {
        var countrySelector = $('.js-countrySelector');
        var link = countrySelector.data('link');
        var dialogUrl = preselectLocale ?
            util.appendParamToURL(link, 'preselectedLocale', preselectLocale) :
            link;

        dialog.open({
            url: dialogUrl,
            options: {
                dialogClass: 'int-country-selector-dialog',
                closeOnEscape: false,
                width: 348,
                close: function() {
                    location.hash = '';
                }
            }
        });
    },

    handleURLHash() {
        if (location.hash === '#selectcountry') {
            this.openPopUp();
        }
    },

    /**
    * @private (but called from outside during page initialization by components manager)
    */
    init () {
        $(document)
            .on('click', '.js-countrySelector', () => this.openPopUp())
            .on('click', '.js-countrySelectorSubmit', () => {
                const locale = $('.js-countrySelectorSelect').val();
                const url = pageContext && pageContext.localizedUrls && pageContext.localizedUrls[locale];
                if (url) {
                    page.redirect(url);
                }
            })
            .on('change', '.js-countrySelectorSelect', (e) => {
                // to show flag of selected country in dropdown
                const locale = $(e.target).val();
                $('.js-countrySelectorSelectWrapper').attr('data-selected-locale', locale);
            });

        this.handleURLHash();

        var cookie = cookies.getCookie('CountrySelector');
        if (cookie) {
            return;
        }

        var matchedLocale, defaultLocale;
        const countries = JSON.parse(SitePreferences.COUNTRY_SELECTOR);
        const currentLocale = $('.js-countrySelector').data('locale');
        const geoipCode = window.User.geolocation === 'GB' ? 'NI' : window.User.geolocation;    

        countries.forEach((countryObj) => {
            if (geoipCode === countryObj.countryCode) {
                matchedLocale = countryObj.locale;
            }
            if (countryObj.default) {
                defaultLocale = countryObj.locale;
            }
        });
        
        var selectLocale;
        if (matchedLocale && matchedLocale !== currentLocale) {
            // suggest to switch to GeoIP matching locale
            selectLocale = matchedLocale;
        } else if (!matchedLocale && defaultLocale !== currentLocale) {
            // suggest to switch to default locale if not on it
            selectLocale = defaultLocale;
        }        

        if (selectLocale) {
            this.openPopUp(selectLocale);
        }

        cookies.setCookie('CountrySelector', true, 365 * 24 * 60);
    },
    
};

module.exports = countrySelector;
